import React from "react";
import styles from "./AppFeedback.module.css";
import AppFeedbackButton from "./AppFeedbackButton";
import { AppFeedbackProvider } from "./AppFeedbackContext";
import AppFeedbackDialog from "./AppFeedbackDialog";

export default function AppFeedback() {
    return (
        <AppFeedbackProvider>
            <div className={styles.Main}>
                <AppFeedbackButton />
                <AppFeedbackDialog />
            </div>
        </AppFeedbackProvider>
    );
}
