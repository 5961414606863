import React, { createContext, useCallback, useContext, useEffect, useReducer } from "react";
import { AppContext } from "../../context/AppContext";
import AppEmailService from "../../services/AppEmailService";

const AppFeedbackContext = createContext({});

const ACTIONS = {
    OPEN_DIALOG: "OPEN_DIALOG",
    CLOSE_DIALOG: "CLOSE_DIALOG",
    SEND_FEEDBACK: "SEND_FEEDBACK",
};

const AppFeedbackReducer = (state, action) => {
    if (action.type === ACTIONS.SEND_FEEDBACK) {
        // ...
        state.isDialogOpen = false;
    }

    return { ...state, ...action.payload };
};

function AppFeedbackProvider({ children }) {
    const { appState } = useContext(AppContext);

    useEffect(() => {}, [appState.user, appState.day]);

    const [appFeedback, dispatch] = useReducer(AppFeedbackReducer, {
        isDialogOpen: false,
    });

    const openDialogWrp = useCallback(
        () => dispatch({ type: ACTIONS.OPEN_DIALOG, payload: { isDialogOpen: true } }),
        [dispatch]
    );

    const closeDialogWrp = useCallback(
        () => dispatch({ type: ACTIONS.CLOSE_DIALOG, payload: { isDialogOpen: false } }),
        [dispatch]
    );

    const sendFeedbackWrp = useCallback(
        async (email) => {
            let isDialogOpen = false;

            try {
                await AppEmailService.sendEmail(email);
            } catch (e) {
                throw Error("Failed to send feedback, please report error via email!");
            }

            return dispatch({
                type: ACTIONS.SEND_FEEDBACK,
                payload: { feedback: null, isDialogOpen },
            });
        },
        [dispatch]
    );

    const value = {
        appFeedback,
        openDialog: openDialogWrp,
        closeDialog: closeDialogWrp,
        sendFeedback: sendFeedbackWrp,
    };

    return <AppFeedbackContext.Provider value={value}>{children}</AppFeedbackContext.Provider>;
}

export { AppFeedbackContext, AppFeedbackProvider };
