import React, { useContext, useReducer } from "react";
import deliveryTShirtsSelectedInventoryStyles from "./TShirtsStockingSelectedInventory.module.css";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import { TShirtsStockingContext } from "../../../context/TShirtsStockingContext";

const computePacks = (quantity, packSize) => {
    return Math.floor(quantity / packSize);
};

const computeItems = (quantity, packSize) => {
    return quantity % packSize;
};

const reducer = (state, { payload: { quantity, tShirt } }) => {
    quantity = Math.max(quantity, 0);
    const changed = quantity !== state.initQuantity;
    const packs = computePacks(quantity, tShirt.data.packSize);
    const items = computeItems(quantity, tShirt.data.packSize);
    return { ...state, changed, quantity, packs, items };
};

const TShirtsStockingSelectedInventory = ({ tShirt }) => {
    const { tShirtsStocking, updateTShirtMasterInventory } = useContext(TShirtsStockingContext);
    const [inventory, dispatch] = useReducer(reducer, {
        changed: false,
        quantity: tShirt.masterInventory,
        initQuantity: tShirt.masterInventory,
        packs: computePacks(tShirt.masterInventory, tShirt.data.packSize),
        items: computeItems(tShirt.masterInventory, tShirt.data.packSize),
    });

    const updateQuantity = (quantity) => dispatch({ payload: { tShirt, quantity } });
    const update = (q, ps = tShirt.data.packSize) => updateQuantity((inventory.quantity += q * ps));

    const onCancel = () => updateQuantity(inventory.initQuantity);
    const onSubmit = () =>
        updateTShirtMasterInventory(
            inventory.quantity - inventory.initQuantity,
            tShirt,
            tShirtsStocking.restaurant
        );

    const resourceEdit = (type, subtractMultiple, subtract, add, addMultiple) => {
        const displayButton = (updateMethod, icon, disabled = false, ariaLabel = "") => {
            if (!updateMethod) {
                return <div />;
            }
            return (
                <IconButton
                    color="inherit"
                    onClick={updateMethod}
                    disabled={disabled}
                    aria-label={ariaLabel}
                >
                    {icon()}
                </IconButton>
            );
        };

        return (
            <div
                key={`resourceEdit-${type}`}
                className={deliveryTShirtsSelectedInventoryStyles.ResourceEdit}
            >
                {displayButton(
                    subtractMultiple,
                    () => (
                        <FiChevronsLeft />
                    ),
                    inventory.quantity === 0,
                    "subtractMultiple"
                )}
                {displayButton(
                    subtract,
                    () => (
                        <FiChevronLeft />
                    ),
                    inventory.quantity === 0,
                    "subtract"
                )}
                <span className={deliveryTShirtsSelectedInventoryStyles.QtyDisplay}>
                    {inventory[type]} {type}
                </span>
                {displayButton(
                    add,
                    () => (
                        <FiChevronRight />
                    ),
                    false,
                    "add"
                )}
                {displayButton(
                    addMultiple,
                    () => (
                        <FiChevronsRight />
                    ),
                    false,
                    "addMultiple"
                )}
            </div>
        );
    };

    if (!tShirtsStocking.restaurant || !tShirt) {
        console.info("Mising tShirtsStocking restaurant or tShirt");
        return <div />;
    }

    return (
        <div className={deliveryTShirtsSelectedInventoryStyles.Main}>
            {resourceEdit(
                "packs",
                () => update(-5),
                () => update(-1),
                () => update(1),
                () => update(5)
            )}
            {resourceEdit(
                "items",
                null,
                () => update(-1, 1),
                () => update(1, 1),
                null
            )}
            <div className={deliveryTShirtsSelectedInventoryStyles.Submit}>
                <Button variant="contained" disabled={!inventory.changed} onClick={onCancel}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    disabled={!inventory.changed}
                    onClick={onSubmit}
                >
                    Submit
                </Button>
            </div>
        </div>
    );
};

export default TShirtsStockingSelectedInventory;
