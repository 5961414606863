import { Button, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styles from "./OffSiteReordersItemAlert.module.css";
import OffSiteReordersUtils from "./OffSiteReordersUtils";

export default function OffSiteReordersItemAlert() {
    const [message, setMessage] = useState("");
    const [historyAlerts, setHistoryAlerts] = useState([]);
    // TODO: build a "text-area"
    // TODO: send button
    // TODO: preview of last 10 alerts

    useEffect(() => {
        OffSiteReordersUtils.getLastOffSiteAlerts().then((alerts) => setHistoryAlerts(alerts));
    }, []);

    const handleMessageUpdate = (event: any) => {
        setMessage(event.target.value);
    };

    const handleSend = () => {
        OffSiteReordersUtils.sendOffSiteAlert(message);
    };

    return (
        <div className={styles.ItemAlertWrp}>
            <div className={styles.ItemAlertEditor}>
                <div className={styles.ItemAlertEditorMessage}>
                    <TextField
                        id="alert-editor"
                        placeholder="Alert Message..."
                        value={message}
                        onChange={handleMessageUpdate}
                        multiline
                        rows={2}
                    />
                </div>
                <div className={styles.ItemAlertEditorSubmit}>
                    <Button
                        onClick={handleSend}
                        disabled={!message || message.length === 0}
                        size={"small"}
                        color={"secondary"}
                        variant={"contained"}
                    >
                        Send
                    </Button>
                </div>
            </div>
            <div className={styles.ItemAlertHistory}>
                <div className={styles.ItemAlertHistoryHeader}>
                    <span>User</span>
                    <span>Created</span>
                    <span>Status</span>
                </div>
                <div className={styles.ItemAlertHistoryItem}>
                    {historyAlerts.map(({ data: { message, status, createdBy, createdAt } }) => {
                        return [
                            <div className={styles.ItemAlertHistoryItemDetails}>
                                <span>{createdBy}</span>
                                <span>{createdAt}</span>
                                <span>{status}</span>
                            </div>,
                            <div>{message}</div>,
                        ];
                    })}
                </div>
            </div>
        </div>
    );
}
