import React, { useContext } from "react";
import { OnHandContext } from "../../../../on-hand/context/OnHandContext";
import onHandItemInputStyles from "../../../../on-hand/components/item/OnHandItemInput.module.css";
import styles from "./OffSiteDeliveryItemInput.module.css";
import OnHandUtils from "../../../../on-hand/OnHandUtils";
import OffSiteDeliveryItemQuickInputs from "../OffSiteDeliveryItemQuickInputs";
import OffSiteDeliveryItemRestaurants from "../restaurants/OffSiteDeliveryItemRestaurants";
import OffSiteDeliveryItemTotal from "../OffSiteDeliveryItemTotal";

export default function OffSiteDeliveryItemInput() {
    const { onHandState } = useContext(OnHandContext) as any;

    if (!OnHandUtils.isSelectedItem(onHandState, onHandState.restaurant.group.item)) {
        return <div />;
    }

    // TODO: build and insert "Input display", REMOVE TITLE
    return (
        <div className={`${onHandItemInputStyles.Input} ${styles.DeliveryInput}`}>
            <OffSiteDeliveryItemRestaurants />
            <OffSiteDeliveryItemTotal />
            <OffSiteDeliveryItemQuickInputs />
        </div>
    );
}
