import React, { createContext, useCallback, useContext, useEffect, useReducer } from "react";
import {
    LOAD_DATA,
    OPEN_EMAIL_DIALOG,
    CLOSE_EMAIL_DIALOG,
    CHANGE_RESTAURANT,
    SEND_EMAIL,
    CHANGE_VENDOR as CHANGE_DISPLAY_VENDOR,
    SELECT_NEXT_EMAIL_VENDOR,
    SELECT_PREV_EMAIL_VENDOR,
    UPDATE_EMAIL_VENDOR_NOTES,
    UPDATE_DELIVERY_ORDER,
    SELECT_EMAIL_VENDOR_ORDERS,
    UPDATE_EMAIL_VENDOR_ORDERS,
    ADD_NEW_VENDOR,
    ENABLE_ADD_NEW_VENDOR,
} from "./DeliveryOrdersContextActions";
import DeliveryOrdersResolver from "./DeliveryOrdersResolver";
import { AppContext } from "../../../../context/AppContext";
import AppContextValidator from "../../../../context/AppContextValidator";
import { useGlobalSpinnerActionsContext } from "../../../../components/global-spinner/GlobalSpinnerContext";
import { ADD_NEW_VENDOR_INDICATOR, ALL_VENDOR } from "../DeliveryOrdersValues";
import { getEmailVendorByPosition } from "./DeliveryOrdersContextService";

const DeliveryOrdersContext = createContext({});

const initDeliveryOrders = {
    restaurants: null,
    restaurant: null,
    isEmailDialogOpened: false,
};

// bring logic to this level if you need current state
const reducer = (state, action) => {
    if (action.type === SEND_EMAIL) {
        // mark the current vendor as "sent"
        state.restaurant.emailVendor = action.payload.vendor;
        // go to next vendor
        action.type = SELECT_NEXT_EMAIL_VENDOR;
    }

    if (action.type === CHANGE_DISPLAY_VENDOR) {
        state.restaurant.displayVendor = action.payload.vendor;
        // do not change delivery vendor if it's "all" vendor
        if (state.restaurant.displayVendor?.data?.id !== ALL_VENDOR.data.id) {
            state.restaurant.deliveryVendor = action.payload.vendor;
        }
        return { ...state, restaurant: state.restaurant };
    }

    if (action.type === SELECT_PREV_EMAIL_VENDOR) {
        if (state.restaurant.emailVendor === ADD_NEW_VENDOR_INDICATOR) {
            if (state.restaurant.deliveryVendors.length === 0) {
                return null;
            }
            state.restaurant.emailVendor =
                state.restaurant.deliveryVendors[state.restaurant.deliveryVendors.length - 1];
        } else {
            const currentDeliveryVendorIndex = state.restaurant.deliveryVendors.findIndex(
                (vendor) => vendor.data.id === state.restaurant.emailVendor?.data?.id
            );
            if (currentDeliveryVendorIndex === 0) {
                state.restaurant.emailVendor = ADD_NEW_VENDOR_INDICATOR;
            } else {
                state.restaurant.emailVendor = getEmailVendorByPosition(state.restaurant, -1);
            }
        }
        return { ...state, restaurant: state.restaurant };
    }

    if (action.type === SELECT_NEXT_EMAIL_VENDOR) {
        const currentDeliveryVendorIndex = state.restaurant.deliveryVendors.findIndex(
            (vendor) => vendor.data.id === state.restaurant.emailVendor?.data?.id
        );
        const currentVendorIsTheLastOne =
            currentDeliveryVendorIndex === state.restaurant.deliveryVendors.length - 1;

        if (currentVendorIsTheLastOne) {
            state.restaurant.emailVendor = ADD_NEW_VENDOR_INDICATOR;
        } else {
            state.restaurant.emailVendor =
                state.restaurant.deliveryVendors[currentDeliveryVendorIndex + 1];
        }

        return { ...state, restaurant: state.restaurant };
    }

    if (action.type === UPDATE_EMAIL_VENDOR_NOTES) {
        state.restaurant.emailVendor.notes = action.payload.notes;
        return { ...state, restaurant: state.restaurant };
    }

    if (action.type === UPDATE_DELIVERY_ORDER) {
        const findOrder = (order) => {
            return order.data.id === action.payload?.order?.data?.id;
        };

        const deliveryOrderIndex = state.restaurant.emailVendor.deliveryOrders.findIndex(findOrder);
        const addedOrderIndex = state.restaurant.emailVendor.addedOrders.findIndex(findOrder);

        if (deliveryOrderIndex !== -1) {
            state.restaurant.emailVendor.deliveryOrders[deliveryOrderIndex].editedQuantity =
                action.payload.quantity;
        } else if (addedOrderIndex !== -1) {
            state.restaurant.emailVendor.addedOrders[addedOrderIndex].editedQuantity =
                action.payload.quantity;
        }

        return { ...state, restaurant: state.restaurant };
    }

    if (action.type === SELECT_EMAIL_VENDOR_ORDERS) {
        state.restaurant.emailVendor.selectedOrderToBeAdded = action.payload.order;
        return { ...state, restaurant: state.restaurant };
    }

    if (action.type === UPDATE_EMAIL_VENDOR_ORDERS) {
        state.restaurant.emailVendor.addedOrders.push(
            state.restaurant.emailVendor.selectedOrderToBeAdded
        );
        state.restaurant.emailVendor.selectedOrderToBeAdded = null;
        return { ...state, restaurant: state.restaurant };
    }

    if (action.type === ADD_NEW_VENDOR) {
        if (!action.payload?.vendor?.data?.id) {
            return null;
        }

        // find the new vendor in the vendors list
        const nonDeliveryVendorIndex = state.restaurant.nonDeliveryVendors.findIndex(
            (vendor) => vendor?.data?.id === action.payload.vendor?.data?.id
        );
        if (nonDeliveryVendorIndex === -1) {
            return null;
        }

        // move the vendor from the delivery to the nonDelivery list
        const vendor = state.restaurant.nonDeliveryVendors[nonDeliveryVendorIndex];
        state.restaurant.deliveryVendors.push(vendor);
        state.restaurant.nonDeliveryVendors = state.restaurant.nonDeliveryVendors.filter(
            (vendor) => vendor?.data?.id !== action.payload.vendor?.data?.id
        );
        state.restaurant.emailVendor = vendor;

        return { ...state, restaurant: state.restaurant };
    }

    if (action.type === ENABLE_ADD_NEW_VENDOR) {
        state.restaurant.emailVendor = ADD_NEW_VENDOR_INDICATOR;
        return { ...state, restaurant: state.restaurant };
    }

    return { ...state, ...action.payload };
};

const DeliveryOrdersProvider = ({ children }) => {
    const { appState } = useContext(AppContext);
    const setGlobalSpinner = useGlobalSpinnerActionsContext();
    const [deliveryOrders, dispatch] = useReducer(reducer, Object.assign({}, initDeliveryOrders));

    // TODO: move all logic from "API" to Actions
    const loadDataWrp = useCallback(
        async (userGroups, day, setGlobalSpinner) => {
            setGlobalSpinner(true);

            const restaurants = await DeliveryOrdersResolver(userGroups, day);
            const restaurant = Object.values(restaurants)[0];
            const isEmailDialogOpened = false;

            const disabledPrint = !restaurant.deliveryVendor?.orders?.some(
                (order) => typeof order?.inventory?.data?.quantity === "number"
            );

            setGlobalSpinner(false);

            return dispatch({
                type: LOAD_DATA,
                payload: { restaurants, restaurant, disabledPrint, isEmailDialogOpened },
            });
        },
        [dispatch]
    );

    const openEmailDialogWrp = useCallback(
        (restaurant) => {
            restaurant.emailVendor = restaurant.emailVendor || restaurant.deliveryVendors[0];
            return dispatch({ type: OPEN_EMAIL_DIALOG, payload: { isEmailDialogOpened: true } });
        },
        [dispatch]
    );

    const closeEmailDialogWrp = useCallback(() => {
        return dispatch({ type: CLOSE_EMAIL_DIALOG, payload: { isEmailDialogOpened: false } });
    }, [dispatch]);

    const changeRestaurantWrp = useCallback(
        // TODO: verify is valid restaurant
        (restaurant) => {
            restaurant.displayVendor = restaurant.allVendor;
            return dispatch({ type: CHANGE_RESTAURANT, payload: { restaurant } });
        },
        [dispatch]
    );

    const sendEmailWrp = useCallback(
        async (vendor, restaurant, setGlobalSpinner) => {
            try {
                setGlobalSpinner(true);
                await vendor.email.send(appState.user.name, restaurant, vendor);
                vendor.emailSent = true;
            } catch (e) {
                throw Error(e);
            } finally {
                setGlobalSpinner(false);
            }
            return dispatch({ type: SEND_EMAIL, payload: { vendor } });
        },
        [dispatch]
    );

    const changeDisplayVendorWrp = useCallback(
        (vendor) => {
            return dispatch({ type: CHANGE_DISPLAY_VENDOR, payload: { vendor } });
        },
        [dispatch]
    );

    const selectPrevEmailVendorWrp = useCallback(() => {
        return dispatch({ type: SELECT_PREV_EMAIL_VENDOR, payload: {} });
    }, [dispatch]);

    const selectNextEmailVendorWrp = useCallback(() => {
        return dispatch({ type: SELECT_NEXT_EMAIL_VENDOR, payload: {} });
    }, [dispatch]);

    const updateEmailVendorNotesWrp = useCallback(
        (notes) => {
            return dispatch({ type: UPDATE_EMAIL_VENDOR_NOTES, payload: { notes } });
        },
        [dispatch]
    );

    const updateDeliveryOrderWrp = useCallback(
        (order, quantity) => {
            return dispatch({ type: UPDATE_DELIVERY_ORDER, payload: { order, quantity } });
        },
        [dispatch]
    );

    const selectEmailVendorOrdersWrp = useCallback(
        (order) => {
            return dispatch({ type: SELECT_EMAIL_VENDOR_ORDERS, payload: { order } });
        },
        [dispatch]
    );

    const updateEmailVendorOrdersWrp = useCallback(() => {
        return dispatch({ type: UPDATE_EMAIL_VENDOR_ORDERS, payload: {} });
    }, [dispatch]);

    const addNewVendorWrp = useCallback(
        (vendor) => {
            return dispatch({ type: ADD_NEW_VENDOR, payload: { vendor } });
        },
        [dispatch]
    );

    const enableAddNewVendorWrp = useCallback(() => {
        return dispatch({ type: ENABLE_ADD_NEW_VENDOR, payload: {} });
    }, [dispatch]);

    useEffect(() => {
        if (AppContextValidator.isValid(appState)) {
            loadDataWrp(appState.user.groups, appState.day, setGlobalSpinner);
        }
    }, [appState.day, appState.user]);

    const value = {
        deliveryOrders,
        openEmailDialog: openEmailDialogWrp,
        closeEmailDialog: closeEmailDialogWrp,
        loadData: loadDataWrp,
        changeRestaurant: changeRestaurantWrp,
        sendEmail: sendEmailWrp,
        changeDisplayVendor: changeDisplayVendorWrp,
        selectPrevEmailVendor: selectPrevEmailVendorWrp,
        selectNextEmailVendor: selectNextEmailVendorWrp,
        updateEmailVendorNotes: updateEmailVendorNotesWrp,
        updateDeliveryOrder: updateDeliveryOrderWrp,
        selectEmailVendorOrders: selectEmailVendorOrdersWrp,
        updateEmailVendorOrders: updateEmailVendorOrdersWrp,
        addNewVendor: addNewVendorWrp,
        enableAddNewVendor: enableAddNewVendorWrp,
    };

    return (
        <DeliveryOrdersContext.Provider value={value}>{children}</DeliveryOrdersContext.Provider>
    );
};

export { DeliveryOrdersContext, DeliveryOrdersProvider };
