import React, { useContext } from "react";
import deliveryTShirtsSelectedStyles from "./TShirtsStockingSelected.module.css";
import Divider from "@material-ui/core/Divider";
import { TShirtsStockingContext } from "../../context/TShirtsStockingContext";
import { INVENTORY, STOCKING } from "../../mode/TShirtsStockingModes";
import TShirtsStockingSelectedStocking from "./stocking/TShirtsStockingSelectedStocking";
import TShirtsStockingSelectedInventory from "./inventory/TShirtsStockingSelectedInventory";
import { IconButton } from "@material-ui/core";
import { MdClose } from "react-icons/md";

const TShirtsStockingSelected = ({ tShirt, header }) => {
    const { tShirtsStocking, selectTShirt } = useContext(TShirtsStockingContext);

    if (!tShirtsStocking.mode || !tShirtsStocking.restaurant) {
        return <div />;
    }

    const deselectTShirt = () => selectTShirt(null);

    const content = () => {
        switch (tShirtsStocking.mode.name) {
            case STOCKING.name:
                return <TShirtsStockingSelectedStocking tShirt={tShirt} />;
            case INVENTORY.name:
                return <TShirtsStockingSelectedInventory tShirt={tShirt} />;
            default:
                return <span>...</span>;
        }
    };

    return (
        <div className={deliveryTShirtsSelectedStyles.Main}>
            <div className={deliveryTShirtsSelectedStyles.Header}>
                <div />
                {header}
                <IconButton
                    className={deliveryTShirtsSelectedStyles.Close}
                    size={"small"}
                    onClick={deselectTShirt}
                    aria-label={"close-selected-btn"}
                >
                    <MdClose />
                </IconButton>
            </div>
            <Divider />
            <div className={deliveryTShirtsSelectedStyles.Content}>{content()}</div>
        </div>
    );
};

export default TShirtsStockingSelected;
