import React, { useContext } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { DeliveryOrdersContext } from "../context/DeliveryOrdersContext";
import DeliveryOrdersUtils from "../DeliveryOrdersUtils";

const DeliveryOrdersRestaurantSelector = () => {
    const { deliveryOrders, changeRestaurant } = useContext(DeliveryOrdersContext);

    if (!DeliveryOrdersUtils.isValidState(deliveryOrders)) {
        return null;
    }

    const handleRestaurantChange = (event) => {
        return changeRestaurant(event?.target?.value);
    };

    return (
        <FormControl>
            <Select
                labelId="delivery-orders-restaurant"
                id="delivery-orders-restaurant"
                value={deliveryOrders.restaurant}
                onChange={handleRestaurantChange}
            >
                {Object.values(deliveryOrders.restaurants).map((restaurant, index) => (
                    <MenuItem key={`delivery-orders-${index}`} value={restaurant}>
                        {restaurant?.data?.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default DeliveryOrdersRestaurantSelector;
