import React, { useContext } from "react";
import deliveryTShirtsStyles from "../TShirtsStocking.module.css";
import { TShirtsStockingContext } from "../context/TShirtsStockingContext";
import { INVENTORY, STOCKING } from "../mode/TShirtsStockingModes";
import TShirtsStockingSelected from "./selected/TShirtsStockingSelected";
import TShirtsStockingUtils from "../TShirtsStockingUtils";

const TShirtsStockingItem = ({ tShirt }) => {
    const { tShirtsStocking, selectTShirt } = useContext(TShirtsStockingContext);

    if (!tShirt || !tShirtsStocking.mode) {
        return <div className={deliveryTShirtsStyles.NA} />;
    }

    const isSelectedTShirt =
        tShirtsStocking?.restaurant?.selectedTShirt?.data?.id === tShirt?.data?.id;

    const onSelect = () => {
        if (
            tShirtsStocking?.mode?.name === INVENTORY.name ||
            (tShirtsStocking?.mode?.canSelect && tShirt?.status?.canSelect)
        ) {
            return selectTShirt(tShirt);
        }
    };

    let header = <div />;
    switch (tShirtsStocking?.mode?.name) {
        case STOCKING.name:
            header = (
                <div onClick={onSelect}>
                    {tShirt?.status?.render(tShirt.requiredStock, isSelectedTShirt)}
                </div>
            );
            if (isSelectedTShirt) {
                return <TShirtsStockingSelected tShirt={tShirt} header={header} />;
            }
            return header;
        case INVENTORY.name:
            let className = `${deliveryTShirtsStyles.MasterInventory}`;
            if (isSelectedTShirt) {
                className += ` ${deliveryTShirtsStyles.Selected}`;
            }
            header = (
                <div onClick={onSelect} className={className}>
                    {TShirtsStockingUtils.displayAvailableInventory(tShirt)}
                </div>
            );
            if (isSelectedTShirt) {
                return <TShirtsStockingSelected tShirt={tShirt} header={header} />;
            }
            return header;
        default:
            return header;
    }
};

export default TShirtsStockingItem;
