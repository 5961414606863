import React, { useContext, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Auth } from "aws-amplify";
import { AppContext } from "../context/AppContext";
import AppContextValidator from "../context/AppContextValidator";
import AppUtilsService from "../services/AppUtilsService";
import "./AppLayout.module.css";
import appLayoutStyles from "./AppLayout.module.css";
import AppHeader from "./header/AppHeader";
import AppMenu from "./menu/AppMenu";

export default function AppLayout(props: any) {
    const { children } = props;
    const { appState } = useContext(AppContext);

    useEffect(() => {}, [appState.day, appState.section, appState.showSectionSelector]);

    if (!AppContextValidator.isValid(appState)) {
        console.info("Invalid app Stat");
        return <div />;
    }

    const envName = AppUtilsService.getEnvName(Auth);

    if (envName === "test") {
        document.title = "Test | OCMD App";
    }

    const className = `${appLayoutStyles.Main} ${
        envName === "test" ? appLayoutStyles.TestEnv : ""
    }`;

    return (
        <div className={className}>
            <CssBaseline />
            <AppHeader />
            <AppMenu />
            <main>{children}</main>
        </div>
    );
}
