import React, { useContext, useEffect } from "react";
import "./components/toolbar/MenusToolbar.module.css";
import menusStyles from "./Menus.module.css";
import { AppContext } from "../../context/AppContext";
import { useGlobalSpinnerActionsContext } from "../../components/global-spinner/GlobalSpinnerContext";
import MenusToolbar from "./components/toolbar/MenusToolbar";
import MenusSalesDropZoneWrp from "./components/drop-zone/MenusSalesDropZoneWrp";
import MenusContent from "./components/MenusContent";
import { MenusContext } from "./context/MenusContext";
import AppContextValidator from "../../context/AppContextValidator";

let isLoading = false;

const Menus = ({ config }) => {
    const setGlobalSpinner = useGlobalSpinnerActionsContext();
    const { appState } = useContext(AppContext);
    const { menusState, loadMenus } = useContext(MenusContext);

    /* ---------- HOOKS ---------- */
    useEffect(() => {
        console.info("Menus: USE EFFECT");
        if (AppContextValidator.isValid(appState) && !isLoading) {
            isLoading = true;
            const {
                section: { name },
                day,
                user,
            } = appState;
            loadMenus(config, name, day, user.name, user.groups, setGlobalSpinner).then(
                () => (isLoading = false)
            );
        }
    }, [appState.day, appState.user, appState.section]);

    if (!(menusState.restaurants && menusState.restaurant)) {
        console.info("Menus: Invalid state!");
        return <div />;
    }

    /* ---------- DISPLAY ---------- */
    return (
        <div className={menusStyles.menus}>
            <MenusToolbar />
            <MenusSalesDropZoneWrp salesConfigs={menusState.config.salesConfigs} />
            <MenusContent />
        </div>
    );
};

export default Menus;
