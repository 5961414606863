import { Button } from "@material-ui/core";
import React, { useContext } from "react";
import { FaUpload } from "react-icons/fa";
import { useGlobalSpinnerActionsContext } from "../../../../components/global-spinner/GlobalSpinnerContext";
import { OnHandContext } from "../../context/OnHandContext";
import onHandToolbarStyles from "../toolbar/OnHandToolbar.module.css";

const OnHandToolbarSync = () => {
    const { onHandState, syncRestaurantOnHand } = useContext(OnHandContext);
    const setGlobalSpinner = useGlobalSpinnerActionsContext();
    const handleSync = async () => {
        setGlobalSpinner(true);
        await syncRestaurantOnHand(onHandState.restaurant, setGlobalSpinner);
        setGlobalSpinner(false);
    };

    // TODO: switch to "Sync" icon after also adding the ability to "fetch latest data"
    return (
        <Button
            color={"default"}
            variant={"text"}
            className={onHandToolbarStyles.Sync}
            onClick={handleSync}
        >
            <FaUpload size="18" color="#555"></FaUpload>
        </Button>
    );
};

export default OnHandToolbarSync;
