import { Resource, ResourceDisplayType } from "../../../resources/Resource";
import { ResourceColumn } from "../../../resources/ResourceColumn";
import {
    DataEditorItemsType,
    DataEditorSortableItem,
    DataEditorState,
} from "../types/DataEditorTypes";

function isValidRefField(dataEditorState: DataEditorState, field: string): boolean {
    return !!dataEditorState.store.mapping.refKeyToStoreKey[field];
}

function findRefItem(resourceRefItems: Resource[], resourceRefValue: any): Resource | undefined {
    // TODO: enforce the fact that a resourceRef MUST have an "id'; alternatively, create some method to set it's ref-field
    return resourceRefItems.find((resourceRefItem: Resource) => {
        return resourceRefItem?.data?.id === resourceRefValue;
    });
}

function getResourceItemRefDisplay(
    dataEditorState: DataEditorState,
    resourceItem: Resource,
    resourceRefKey: string
) {
    let result = resourceItem.data[resourceRefKey];

    const resourceRefKeyOriginalValue = resourceItem.data[resourceRefKey];
    const resourceRefStoreKey = dataEditorState.store.mapping.refKeyToStoreKey[resourceRefKey];

    const resourceRef = dataEditorState.getResourceByStoreKey(resourceRefStoreKey);
    const resourceRefItems = dataEditorState.getItemsByStoreKey(resourceRefStoreKey);
    const resourceRefItem = findRefItem(resourceRefItems, resourceRefKeyOriginalValue);

    if (resourceRefItem) {
        result = resourceRef.getModel().refDisplay(resourceRefItem.data, dataEditorState);
    }

    return result;
}

function getResourceItemDisplay(
    dataEditorState: DataEditorState,
    resource: typeof Resource,
    resourceItem: Resource
): ResourceDisplayType {
    const display: ResourceDisplayType = resource
        .getModel()
        .getColumns()
        .reduce((accDisplay: ResourceDisplayType, column: ResourceColumn) => {
            accDisplay[column.field] = resourceItem.data[column.field];
            const resourceRefKey = column.resourceRefKeyAlias || column.field;

            if (isValidRefField(dataEditorState, resourceRefKey)) {
                accDisplay[column.field] = getResourceItemRefDisplay(
                    dataEditorState,
                    resourceItem,
                    resourceRefKey
                );
            }

            return accDisplay;
        }, {} as ResourceDisplayType);

    return display;
}

function buildDisplayForResourceItems(
    dataEditorState: DataEditorState,
    resource: typeof Resource
): DataEditorItemsType {
    // TODO: fix the duplicated computation due to storing items under store key and ref key
    const storeKey = resource.getModel().getResourceStoreKey();
    const storeKeyItems = dataEditorState.getItemsByStoreKey(storeKey);
    const storeKeyItemsWithDisplay = storeKeyItems.map((resourceItem: Resource) => {
        const display = getResourceItemDisplay(dataEditorState, resource, resourceItem);
        resourceItem.setDisplay(display);

        return resourceItem;
    });
    dataEditorState.setItemsByStoreKey(storeKey, storeKeyItemsWithDisplay);

    const resourceRefKey = resource.getModel().getResourceRefKey();
    const refKeyItems = dataEditorState.getItemsByResourceRefKey(resourceRefKey);
    const refKeyItemsWithDisplay = refKeyItems.map((resourceItem: Resource) => {
        const display = getResourceItemDisplay(dataEditorState, resource, resourceItem);
        resourceItem.setDisplay(display);

        return resourceItem;
    });
    dataEditorState.setItemsByResourceRefKey(resourceRefKey, refKeyItemsWithDisplay);

    return dataEditorState.store.items;
}

function buildDisplaysForAllItems(dataEditorState: DataEditorState): DataEditorItemsType {
    dataEditorState.getAllResources().forEach((resource: typeof Resource) => {
        dataEditorState.store.items = buildDisplayForResourceItems(dataEditorState, resource);
    });

    return dataEditorState.store.items;
}

function saveShuffledItems(
    dataEditorState: DataEditorState,
    shuffledItems: DataEditorSortableItem[],
    pageStartIndex: number,
    pageEndIndex: number
): DataEditorItemsType {
    const shuffledResourceItems: Resource[] = shuffledItems.map(
        (shuffledItem: DataEditorSortableItem) => shuffledItem.item
    );

    const storeKey = dataEditorState.getSelectedResource().getModel().getResourceStoreKey();

    const resourceItems = dataEditorState
        .getItemsByStoreKey(storeKey)
        .map((resourceItem, index) => {
            if (index >= pageStartIndex && index < pageEndIndex) {
                return shuffledResourceItems[index - pageStartIndex];
            }
            return resourceItem;
        });

    dataEditorState.setItemsByStoreKey(storeKey, resourceItems);

    const resourceRefKey = dataEditorState.getSelectedResource().getModel().getResourceRefKey();
    dataEditorState.setItemsByResourceRefKey(resourceRefKey, resourceItems);

    return dataEditorState.store.items;
}

const DataEditorDisplayUtils = {
    getResourceItemDisplay,
    buildDisplayForResourceItems,
    buildDisplaysForAllItems,
    saveShuffledItems,
};

export default DataEditorDisplayUtils;
