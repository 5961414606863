import React, { useContext, useEffect } from "react";
import { OnHandProvider } from "./context/OnHandContext";
import OnHand from "./OnHand";
import OnHandConfigModel from "./models/OnHandConfigModel";
import { AppContext } from "../../context/AppContext";
import AppContextValidator from "../../context/AppContextValidator";

const OnHandWrp = ({ config }) => {
    const { appState } = useContext(AppContext);

    useEffect(() => {
        console.info("USE_EFFECT: OnHandWrp!");
    }, [appState.day, appState.user]);

    if (!config || config.constructor !== OnHandConfigModel) {
        return <div>OnHand type error!</div>;
    }

    if (!AppContextValidator.isValid(appState)) {
        console.info("Invalid appState!");
        return <div />;
    }

    return (
        <OnHandProvider>
            <OnHand config={config} />
        </OnHandProvider>
    );
};

export default OnHandWrp;
