import { Button } from "@material-ui/core";
import _ from "lodash";
import styles from "../OffSiteTasks.module.css";
import { useContext } from "react";
import { useGlobalSpinnerActionsContext } from "../../../../components/global-spinner/GlobalSpinnerContext";
import { OffSiteContext } from "../../context/OffSiteContext";
import {
    OffSiteTasksByIdAndRestType,
    OffSiteTasksByStatusAndIdAndRestType,
} from "../../context/OffSiteContextTypes";
import { AppOffSiteTransactionStatuses } from "../../OffSiteResources";
import { sendLoadedTasksToTransit, updateTasksStatus } from "../../OffSiteUtils";

export default function OffSiteTasksDialogActions() {
    const setGlobalSpinner = useGlobalSpinnerActionsContext();
    const { offSiteState, toggleDeliveryTasksDialog, sendLoadedTasks } = useContext(OffSiteContext);

    const handleSendLoadedTasks = async () => {
        if (!offSiteState.offSiteTasksByStatusAndIdAndRest) {
            return;
        }

        const offSiteTasks: OffSiteTasksByStatusAndIdAndRestType = _.clone(
            offSiteState.offSiteTasksByStatusAndIdAndRest
        );

        try {
            setGlobalSpinner(true);
            // update transactions
            await sendLoadedTasksToTransit(offSiteTasks[AppOffSiteTransactionStatuses.LOADED]);
        } catch (e) {
            throw Error("Unable to send loaded items, please retry!");
        } finally {
            setGlobalSpinner(false);
        }

        const loadedTasks: OffSiteTasksByIdAndRestType =
            offSiteTasks[AppOffSiteTransactionStatuses.LOADED];

        // set loaded tasks as "in transit"
        updateTasksStatus(loadedTasks, AppOffSiteTransactionStatuses.TRANSIT);

        // move LOADED to TRANSIT
        offSiteTasks[AppOffSiteTransactionStatuses.TRANSIT] =
            offSiteTasks[AppOffSiteTransactionStatuses.LOADED];

        // delete LOADED tasks
        delete offSiteState.offSiteTasksByStatusAndIdAndRest[AppOffSiteTransactionStatuses.LOADED];

        sendLoadedTasks(offSiteTasks);
    };

    return (
        <div className={styles.OffSiteDialogActions}>
            <Button onClick={() => toggleDeliveryTasksDialog(false)}>Close</Button>
            <Button onClick={handleSendLoadedTasks} color={"secondary"} variant={"contained"}>
                Send Loaded
            </Button>
        </div>
    );
}
