import { AppOffSiteAlertLocalTypes } from "../../OffSiteResources";
import { OffSiteReordersAlertDisplay } from "../types/OffSiteReordersTypes";

export enum OffSiteReordersContextActionType {
    SET_ALERTS = "SET_ALERTS",
    COMPLETE_ALERT = "COMPLETE_ALERT",
    UNDO_COMPLETE_ALERT = "UNDO_COMPLETE_ALERT",
    SNOOZE_ALERT = "SNOOZE_ALERT",
}

export type OffSiteReordersContextAction = {
    type: keyof typeof OffSiteReordersContextActionType;
    payload: any;
};

export type OffSiteReordersAlertsType = {
    // TODO: replace with type AppOffSiteAlertLocalType
    [alertType: string]: {
        [offSiteItemId: string]: OffSiteReordersAlertDisplay | [OffSiteReordersAlertDisplay];
    };
};

export class OffSiteReordersContextStateType {
    constructor(
        public alerts: OffSiteReordersAlertsType = {},
        public lastUpdatedAlert?: OffSiteReordersAlertDisplay
    ) {}
}

export type OffSiteReordersContextType = {
    offSiteReordersState: OffSiteReordersContextStateType;
    setAlerts: Function;
    completeAlert: Function;
    undoCompleteAlert: Function;
    snoozeAlert: Function;
};
