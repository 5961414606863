import React, { createContext, useCallback, useContext, useEffect, useReducer } from "react";
import { AppContext } from "../../../context/AppContext";
import { retry, updateDailyData } from "../../../resources/ResourcesService";
import AppUtilsService from "../../../services/AppUtilsService";
import OnHandStatus from "../components/status/OnHandStatus";
import OnHandResolver from "../services/OnHandResover";
import OnHandActions from "./OnHandActions";
import OnHandReducer from "./OnHandReducer";
import OnHandUtils from "../OnHandUtils";
import {
    submitCompletedStockingTransaction,
    submitPendingDeliveryTask,
} from "../../off-site/OffSiteUtils";

const OnHandContext = createContext({});

const OnHandProvider = ({ children }) => {
    const { appState } = useContext(AppContext);
    const [onHandState, dispatch] = useReducer(OnHandReducer, {
        config: null,
        restaurants: null,
        restaurant: null,
        status: null,
        day: null,
        lockedSubmit: null,
        view: null,
    });

    useEffect(() => {}, [appState.taskByItemId]);

    async function loadOnHandData(
        config,
        day,
        userGroups,
        userName,
        sectionName,
        setGlobalSpinner
    ) {
        setGlobalSpinner(true);
        const restaurants = await OnHandResolver(
            day,
            userName,
            sectionName,
            userGroups,
            config.context
        );
        const pageUrl = config?.context?.utils?.pageUrl || "on-hand";
        const restaurant = AppUtilsService.loadRestaurant(
            sectionName,
            pageUrl, // TODO: make this a default property value
            restaurants,
            config.router
        );
        OnHandStatus.compute(restaurants);

        let view;
        if (OnHandUtils.isTransactionalOnHand(config)) {
            // TODO: choose default based on user roles
            view = OnHandUtils.loadPersistedView(config.router, config.display?.views);
            OnHandUtils.persistView(view, config.router, config.display?.views);
        }

        setGlobalSpinner(false);

        return dispatch({
            type: OnHandActions.LOAD_DATA,
            payload: { day, config, restaurants, restaurant, sectionName, view },
        });
    }

    async function loadOnHandDataTask(
        config,
        day,
        userGroups,
        userName,
        sectionName,
        setGlobalSpinner,
        taskSelectedKey
    ) {
        setGlobalSpinner(true);
        const restaurants = await OnHandResolver(
            day,
            userName,
            sectionName,
            userGroups,
            config.context
        );
        const pageUrl = config?.context?.utils?.pageUrl || "on-hand";
        const restaurant = AppUtilsService.loadRestaurant(
            sectionName,
            pageUrl, // TODO: make this a default property value
            restaurants,
            config.router
        );
        OnHandStatus.compute(restaurants);

        let view;
        if (OnHandUtils.isTransactionalOnHand(config)) {
            // TODO: choose default based on user roles
            view = OnHandUtils.loadPersistedView(config.router, config.display?.views);
            OnHandUtils.persistView(view, config.router, config.display?.views);
        }

        setGlobalSpinner(false);
        return dispatch({
            type: OnHandActions.LOAD_DATA_TASK,
            payload: { day, config, restaurants, restaurant, sectionName, view, taskSelectedKey },
        });
    }

    function changeRestaurant(restaurantId) {
        return dispatch({ type: OnHandActions.CHANGE_RESTAURANT, payload: { restaurantId } });
    }

    function changeView(view) {
        return dispatch({ type: OnHandActions.CHANGE_VIEW, payload: { view } });
    }

    function updateRestaurant(restaurant) {
        return dispatch({ type: OnHandActions.TOGGLE_GROUP_SELECTION, payload: { restaurant } });
    }

    function changeItem(index) {
        return dispatch({ type: OnHandActions.CHANGE_ITEM, payload: { index } });
    }

    function goToPrevItem() {
        return dispatch({ type: OnHandActions.GO_TO_PREV_ITEM, payload: {} });
    }

    function goToNextItem() {
        return dispatch({ type: OnHandActions.GO_TO_NEXT_ITEM, payload: {} });
    }

    function goToPrevItemTasks(goToPrevTask, taskSelectedKey) {
        return dispatch({
            type: OnHandActions.GO_TO_PREV_ITEM_TASKS,
            payload: {
                goToPrevTask,
                taskSelectedKey,
            },
        });
    }

    function goToNextItemTasks(goToNextTask, taskSelectedKey) {
        return dispatch({
            type: OnHandActions.GO_TO_NEXT_ITEM_TASKS,
            payload: {
                goToNextTask,
                taskSelectedKey,
            },
        });
    }

    function inventoryAttach(inventory) {
        return dispatch({ type: OnHandActions.INVENTORY_ATTACH, payload: { inventory } });
    }

    function onSelectedItemQuantityUpdate(quantity) {
        const userName = appState.user.name;
        return dispatch({ type: OnHandActions.INVENTORY_UPDATE, payload: { quantity, userName } });
    }

    function onSelectedPendingDeliveryItemQuantityUpdate(addedQuantity, userName) {
        return dispatch({
            type: OnHandActions.DELIVERY_TRANSACTION_UPDATE,
            payload: { addedQuantity, userName },
        });
    }

    function onSelectedItemStockingTransactionUpdate(addedQuantity, userName) {
        return dispatch({
            type: OnHandActions.STOCKING_TRANSACTION_UPDATE,
            payload: { addedQuantity, userName },
        });
    }

    function onSelectedItemDeliveryTransactionReset() {
        return dispatch({ type: OnHandActions.DELIVERY_TRANSACTION_RESET, payload: {} });
    }

    function onSelectedItemStockingTransactionReset() {
        return dispatch({ type: OnHandActions.STOCKING_TRANSACTION_RESET, payload: {} });
    }

    function lockSubmission() {
        return dispatch({ type: OnHandActions.LOCK_SUBMISSION, payload: {} });
    }

    function unlockSubmission() {
        return dispatch({ type: OnHandActions.UNLOCK_SUBMISSION, payload: {} });
    }

    function submitSelectedItem(onHandState, inventory) {
        const { restaurants, restaurant } = onHandState;

        restaurant.group.item.inventory = inventory;

        const status = OnHandStatus.compute(restaurants);
        restaurant.goToNextItem();

        return dispatch({
            type: OnHandActions.SUBMIT_SELECTED_ITEM,
            payload: { restaurants, restaurant, status },
        });
    }

    async function submitSelectedDeliveryItem(offSiteTasks, item, goToNextTask, taskSelectedKey) {
        try {
            await submitPendingDeliveryTask(offSiteTasks, item);
        } catch (e) {
            throw Error("Failed to submit Pending Delivery Task");
        }

        return dispatch({
            type: OnHandActions.SUBMIT_SELECTED_DELIVERY_ITEM,
            payload: { goToNextTask, taskSelectedKey },
        });
    }

    async function submitSelectedStockingItem(userName, item, restaurantId) {
        await submitCompletedStockingTransaction(userName, item, restaurantId);
        // update the tasks in the AppContext too

        return dispatch({
            type: OnHandActions.SUBMIT_SELECTED_STOCKING_ITEM,
            payload: {},
        });
    }

    function confirmChanges(restaurant) {
        return dispatch({ type: OnHandActions.CONFIRM_CHANGES, payload: { restaurant } });
    }

    function rejectChanges() {
        return dispatch({ type: OnHandActions.REJECT_CHANGES, payload: {} });
    }

    function unlockRestaurantOnHand(restaurant) {
        // TODO: update restaurant inventoryMeta
        // restaurant.inventoryMeta.data.status = "UNLOCKED";
        // await restaurant.inventoryMeta.model.api.update(restaurant.inventoryMeta.data);
        restaurant.unlocked = true;
        return dispatch({ type: OnHandActions.TOGGLE_LOCK, payload: { restaurant } });
    }

    async function finishRestaurantOnHand(restaurant) {
        // TODO: update restaurant inventoryMeta
        restaurant.inventoryMeta.data.status = "LOCKED";
        await restaurant.inventoryMeta.model.api.update(restaurant.inventoryMeta.data);
        delete restaurant.unlocked;
        return dispatch({ type: OnHandActions.TOGGLE_LOCK, payload: { restaurant } });
    }

    async function syncRestaurantOnHand(restaurant) {
        // submit to S3 all inventory items, from all groups, that are not mocks
        const completedInventories = OnHandUtils.extractCompletedInventories(restaurant.groups);

        try {
            await retry(async () => {
                await updateDailyData(
                    completedInventories,
                    restaurant.inventoryMeta.data.storageKey
                );
            }, 1);
        } catch (e) {
            console.error(e.message);
            alert("An error occurred!\nPlease check your internet connection!");
        }

        return dispatch({ type: OnHandActions.SYNC, payload: { restaurant } });
    }

    function selectItemFromTask(taskSelectedKey) {
        return dispatch({
            type: OnHandActions.SELECT_ITEM_FROM_TASK,
            payload: { taskSelectedKey },
        });
    }

    const value = {
        onHandState,
        loadOnHandData: useCallback(loadOnHandData, [dispatch]),
        loadOnHandDataTask: useCallback(loadOnHandDataTask, [dispatch]),
        changeRestaurant: useCallback(changeRestaurant, [dispatch]),
        changeView: useCallback(changeView, [dispatch]),
        updateRestaurant: useCallback(updateRestaurant, [dispatch]),
        changeItem: useCallback(changeItem, [dispatch]),
        goToPrevItem: useCallback(goToPrevItem, [dispatch]),
        goToNextItem: useCallback(goToNextItem, [dispatch]),
        goToPrevItemTasks: useCallback(goToPrevItemTasks, [dispatch]),
        goToNextItemTasks: useCallback(goToNextItemTasks, [dispatch]),
        inventoryAttach: useCallback(inventoryAttach, [dispatch]),
        onSelectedItemQuantityUpdate: useCallback(onSelectedItemQuantityUpdate, [dispatch]),
        onSelectedPendingDeliveryItemQuantityUpdate: useCallback(
            onSelectedPendingDeliveryItemQuantityUpdate,
            [dispatch]
        ),
        onSelectedItemStockingTransactionUpdate: useCallback(
            onSelectedItemStockingTransactionUpdate,
            [dispatch]
        ),
        onSelectedItemDeliveryTransactionReset: useCallback(
            onSelectedItemDeliveryTransactionReset,
            [dispatch]
        ),
        onSelectedItemStockingTransactionReset: useCallback(
            onSelectedItemStockingTransactionReset,
            [dispatch]
        ),
        lockSubmission: useCallback(lockSubmission, [dispatch]),
        unlockSubmission: useCallback(unlockSubmission, [dispatch]),
        submitSelectedItem: useCallback(submitSelectedItem, [dispatch]),
        submitSelectedDeliveryItem: useCallback(submitSelectedDeliveryItem, [dispatch]),
        submitSelectedStockingItem: useCallback(submitSelectedStockingItem, [dispatch]),
        confirmChanges: useCallback(confirmChanges, [dispatch]),
        rejectChanges: useCallback(rejectChanges, [dispatch]),
        unlockRestaurantOnHand: useCallback(unlockRestaurantOnHand, [dispatch]),
        finishRestaurantOnHand: useCallback(finishRestaurantOnHand, [dispatch]),
        syncRestaurantOnHand: useCallback(syncRestaurantOnHand, [dispatch]),
        selectItemFromTask: useCallback(selectItemFromTask, [dispatch]),
    };

    return (
        <OnHandContext.Provider value={value}>
            {children}
            {console.log("RENDER: OnHandContext")}
        </OnHandContext.Provider>
    );
};

export { OnHandContext, OnHandProvider };
