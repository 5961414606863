import React, { useContext } from "react";
import { OnHandContext } from "../../on-hand/context/OnHandContext";
import OnHandUtils from "../../on-hand/OnHandUtils";
import offSiteOnHandItemStyles from "./OffSiteOnHandItem.module.css";
import OffSiteOnHandItemHeader from "./OffSiteOnHandItemHeader";

const OffSiteOnHandItem = ({ item }: any) => {
    const { onHandState } = useContext(OnHandContext) as any;

    if (!OnHandUtils.isSelectedItem(onHandState, item) || !onHandState.view) {
        return null;
    }

    const key = `on-hand-off-site-item`;

    return (
        <div key={key} className={offSiteOnHandItemStyles.Details}>
            <OffSiteOnHandItemHeader />
            {onHandState.view.component}
        </div>
    );
};

export default OffSiteOnHandItem;
