import React from "react";
import { MdExitToApp } from "react-icons/md";

const SignOutMenuItem = (name: string) => {
    return [
        <MdExitToApp key={`${name}-icon`} size={"1.7em"} />,
        <div key={`${name}-text`}>
            <span>{name}</span>
        </div>,
    ];
};

export default SignOutMenuItem;
