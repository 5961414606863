import React, { useContext, useEffect } from "react";
import Menus from "./Menus";
import { MenusProvider } from "./context/MenusContext";
import { AppContext } from "../../context/AppContext";
import AppContextValidator from "../../context/AppContextValidator";
import MenusSectionConfigs from "./config/MenusSectionConfigs";

const MenusWrp = ({ history, location }) => {
    const { appState } = useContext(AppContext);

    useEffect(() => {
        console.info("MenusWrp: Use effect!");
    }, [appState.day, appState.user, appState.section]);

    if (!AppContextValidator.isValid(appState)) {
        console.info("MenusWrp: Missing appState data!");
        return <div />;
    }

    const config = {
        ...MenusSectionConfigs.getConfig(appState.section.name),
        router: {
            history,
            location,
        },
    };

    return (
        <MenusProvider>
            <Menus config={config} />
        </MenusProvider>
    );
};

export default MenusWrp;
