import { FormikErrors, FormikTouched } from "formik";
import dataEditorCellEditWrpStyles from "./DataEditorCellEditWrp.module.css";

type DataEditorCellEditType = {
    key: string;
    field: string;
    index: number;
    content: JSX.Element;
    errors: FormikErrors<any>;
    touched: FormikTouched<any>;
    isSubmitting: boolean;
};

const DataEditorCellEditWrp = ({
    key,
    field,
    index,
    content,
    errors,
    touched,
    isSubmitting,
}: DataEditorCellEditType) => {
    return (
        <div key={key} className={dataEditorCellEditWrpStyles.Main}>
            {content}
            <span>{errors[field] && touched[field]}</span>
        </div>
    );
};

export default DataEditorCellEditWrp;
