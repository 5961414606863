import onHandStatusStyles from "./OnHandStatus.module.css";

function getDisplay(status, isTransactionalOnHand = false) {
    if (isTransactionalOnHand) {
        return "";
    }
    switch (status) {
        case 0:
            return "Not Started";
        case 1:
            return "In Progress...";
        case 2:
            return "Completed!";
        default:
            return "Invalid";
    }
}

function getClassName(status) {
    switch (status) {
        case 0:
            return onHandStatusStyles.Invalid;
        case 1:
            return onHandStatusStyles.InProgress;
        case 2:
            return onHandStatusStyles.Done;
        default:
            return "Invalid";
    }
}

function isCompleted({ status }) {
    return Math.floor(status / 2);
}

function compute(restaurants) {
    Object.values(restaurants).forEach((restaurant) => {
        restaurant.groups.forEach((group) => {
            group.items.forEach((item) => item.updateStatus());
            group.updateStatus(getItemsStatus(group.items));
        });
        restaurant.updateStatus(getItemsStatus(restaurant.groups));
    });

    return getItemsStatus(Object.values(restaurants));

    /* ---------- IMPL ---------- */
    function getItemsStatus(items) {
        if (!items || items.constructor !== Array || items.length === 0) {
            return -1;
        }
        if (items.length === 0) {
            return 2;
        }
        if (items.length === 1) {
            return items[0].status;
        }
        return (
            items.map(isCompleted).reduce(andReducer, true) +
            items.map(isCompleted).reduce(orReducer, false)
        );
    }

    function andReducer(acc, status) {
        acc = acc && !!status;
        return acc;
    }

    function orReducer(acc, status) {
        acc = acc || !!status;
        return acc;
    }
}

const OnHandStatus = {
    getDisplay,
    getClassName,
    compute,
    isCompleted,
};

export default OnHandStatus;
