import { Checkbox, FormControl, FormControlLabel, MenuItem, Select } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { OffSiteContext } from "../context/OffSiteContext";
import { OffSiteRestaurantFilter, OffSiteRestaurantsFilter } from "../context/OffSiteContextTypes";
import styles from "./OffSiteTasksFilter.module.css";

export default function OffSiteTasksFilter() {
    const { offSiteState, updateRestaurantsFilter } = useContext(OffSiteContext);
    const [state, setState] = useState(null as OffSiteRestaurantsFilter | null);

    useEffect(() => {
        setState(offSiteState.restaurantsFilter);
    }, [offSiteState.restaurantsFilter]);

    const handleChange = (event: any, id: string | number) => {
        if (!state) {
            return;
        }

        state[id].isVisible = event.target.checked;
        setState({ ...state, [id]: state[id] });
    };

    const onMenuClose = () => updateRestaurantsFilter(state);

    const filterRestaurants: OffSiteRestaurantFilter[] =
        Object.values(offSiteState.restaurantsFilter) || [];

    const visibleRestaurantsAliases: string[] = filterRestaurants.reduce(
        (acc, restaurant: OffSiteRestaurantFilter) => {
            if (restaurant.isVisible) {
                acc.push(restaurant.data.alias);
            }
            return acc;
        },
        [] as string[]
    );
    const formLabel: string = visibleRestaurantsAliases.join(", ");

    return (
        <div className={styles.Selection}>
            <FormControl className={styles.Main}>
                <Select
                    id="destination-restaurant"
                    value={formLabel}
                    onClose={onMenuClose}
                    style={{ padding: 0 }}
                >
                    <MenuItem
                        value={formLabel}
                        className={`${styles.MenuItem} ${styles.MenuItemHidden}`}
                    >
                        {formLabel}
                    </MenuItem>
                    {Object.values(state || {}).map(
                        (restaurantSelection: OffSiteRestaurantFilter, index: number) => {
                            const alias = restaurantSelection.data.alias;

                            return (
                                <MenuItem
                                    key={`restaurant-selection-${index}`}
                                    value={alias}
                                    className={styles.MenuItem}
                                >
                                    <FormControlLabel
                                        onClick={(e) => e.stopPropagation()}
                                        className={styles.FormControl}
                                        control={
                                            <Checkbox
                                                checked={restaurantSelection.isVisible}
                                                onChange={(e) =>
                                                    handleChange(e, restaurantSelection.data.id)
                                                }
                                                name={alias}
                                                color="primary"
                                            />
                                        }
                                        label={alias}
                                    />
                                </MenuItem>
                            );
                        }
                    )}
                </Select>
            </FormControl>
        </div>
    );
}
