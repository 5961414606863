import React, { useContext, useEffect } from "react";
import { DeliveryFoodContext } from "../../DeliveryFoodContext";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DeliveryFoodSummaryConfigs from "./configs/DeliveryFoodSummaryConfigs";

const DeliveryFoodSummary = () => {
    const { deliveryFoodState, clearSummary } = useContext(DeliveryFoodContext);

    useEffect(() => {}, [deliveryFoodState.summary]);

    if (!deliveryFoodState.summary?.key) {
        return null;
    }

    const config = DeliveryFoodSummaryConfigs[deliveryFoodState.summary.key];

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            onClose={() => {}}
            aria-labelledby="customized-dialog-title"
            open={true}
        >
            <DialogTitle id="customized-dialog-title">
                {config.title(deliveryFoodState.summary)}
            </DialogTitle>
            <DialogContent dividers>{config.content(deliveryFoodState.summary)}</DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => clearSummary()} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeliveryFoodSummary;
