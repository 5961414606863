import {
    ADMINS,
    DEVS,
    GUESTS,
    KITCHEN_DELIVERY_USERS,
    MANAGERS,
    ORDERS_DELIVERY_USERS,
    ON_HAND_UNLOCK,
    ORDERS_ADMIN,
    STORE_USERS,
} from "./AppUserGroupsData";

function checkGroup(groupName, groups) {
    return groups.includes(groupName);
}

function isDev(groups) {
    return checkGroup(DEVS, groups);
}

function isAdmin(groups) {
    return checkGroup(ADMINS, groups);
}

function isOrdersAdmin(groups) {
    return checkGroup(ORDERS_ADMIN, groups);
}

function isManager(groups) {
    return checkGroup(MANAGERS, groups);
}

function isKitchenDelivery(groups) {
    return checkGroup(KITCHEN_DELIVERY_USERS, groups);
}

function isOrdersDelivery(groups) {
    return checkGroup(ORDERS_DELIVERY_USERS, groups);
}

function isOnHandUnlock(groups) {
    return checkGroup(ON_HAND_UNLOCK, groups);
}

function isStoreUser(groups) {
    return checkGroup(STORE_USERS, groups);
}

// this filter is required whenever an user only has access to a specific restaurant
function getUserRestaurantWithFilter(restaurants, userGroups) {
    return { restaurants };
    // todo: must be under food section
    // if (
    //     isDev(userGroups) ||
    //     isAdmin(userGroups) ||
    //     isKitchenDelivery(userGroups) ||
    //     isOrdersAdmin(userGroups) ||
    //     isOrdersDelivery(userGroups || isManager(userGroups))
    // ) {
    //     return { restaurants };
    // }

    // const userRestaurants = restaurants.filter(({ data: { usersGroup } }) =>
    //     userGroups.includes(usersGroup)
    // );

    // if (userRestaurants.length === 0) {
    //     const errorMessage = "No restaurant access configured";

    //     alert(errorMessage);

    //     throw Error(errorMessage);
    // }

    // restaurants = [...userRestaurants];
    // return { restaurants, restaurantFilter: { restaurantId: { eq: restaurants[0].data.id } } };
}

function hasDevAccess(userGroups) {
    return isDev(userGroups);
}

function hasAdminAccess(userGroups) {
    return isAdmin(userGroups) || hasDevAccess(userGroups);
}

function hasDataEditorAdminAccess(userGroups) {
    return isOrdersAdmin(userGroups) || isAdmin(userGroups) || hasDevAccess(userGroups);
}

function hasManagerAccess(userGroups) {
    return isManager(userGroups) || hasAdminAccess(userGroups);
}

function hasTShirtsStockingAccess(userGroups) {
    return isStoreUser(userGroups) || isManager(userGroups) || hasAdminAccess(userGroups);
}

function hasNonGuestAccess(userGroups) {
    return userGroups?.[0] && userGroups[0] !== GUESTS;
}

const AppUserGroups = {
    hasDevAccess,
    hasAdminAccess,
    hasDataEditorAdminAccess,
    hasManagerAccess,
    hasNonGuestAccess,
    isOnHandUnlock,
    getUserRestaurantWithFilter,
    hasTShirtsStockingAccess,
};

export default AppUserGroups;
