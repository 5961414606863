import React, { useContext, useEffect } from "react";
import AppDay from "../../components/day/AppDay";
import AppFeedback from "../../containers/feedback/AppFeedback";
import SectionHeaderSelector from "../../containers/sections/header/SectionHeaderSelector";
import { AppContext } from "../../context/AppContext";
import appHeaderStyles from "./AppHeader.module.css";

export default function AppHeader() {
    const { appState } = useContext(AppContext);

    useEffect(() => {}, [appState.section]);

    const AppHeaderComponent = appState.section.tasksComponent || AppDay;

    return (
        <header className={`no-print MuiPaper-elevation2 ${appHeaderStyles.Main}`}>
            <SectionHeaderSelector />
            <AppFeedback />
            <AppHeaderComponent />
        </header>
    );
}
