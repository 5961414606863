function isValidRestaurant(restaurant) {
    return restaurant && restaurant.data && restaurant.data.id && restaurant.data.name;
}

function hasValidSelectedRestaurant(onHandState) {
    return (
        onHandState &&
        onHandState.restaurants &&
        isValidRestaurant(onHandState.restaurant) &&
        onHandState.restaurants[onHandState.restaurant.data.id]
    );
}

function isValidGroup(group) {
    if (!group) {
        console.info("Invalid group!");
        return false;
    }

    const { data, item, status } = group;

    if (!data) {
        console.info("Invalid group data!");
        return false;
    }

    if (typeof status !== "number") {
        console.info("Invalid group status!");
        return false;
    }

    const { name } = data;

    if (!name) {
        console.info("Invalid group name!");
        return false;
    }

    return true;
}

function isEmptyGroup(group) {
    return !isValidGroup(group) || group.items.every((item) => item.inventory.mock);
}

function hasValidGroups(onHandState) {
    const isValidSelectedRestaurant = hasValidSelectedRestaurant(onHandState);
    const isValidGroupsFormat = Array.isArray(onHandState.restaurant.groups);
    const areValidGroups = onHandState.restaurant.groups.every(isValidGroup);

    return isValidSelectedRestaurant && isValidGroupsFormat && areValidGroups;
}

function hasValidItemInventory(onHandState) {
    return !!onHandState?.restaurant?.group?.item?.inventory?.data;
}

const OnHandContextValidator = {
    hasValidSelectedRestaurant,
    isValidGroup,
    isEmptyGroup,
    hasValidGroups,
    hasValidItemInventory,
};

export default OnHandContextValidator;
