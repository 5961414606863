import React, { useContext, useEffect } from "react";
import onHandItemStyles from "../../../on-hand/components/item/OnHandItem.module.css";
import Button from "@material-ui/core/Button";
import { OnHandContext } from "../../../on-hand/context/OnHandContext";
import OnHandUtils from "../../../on-hand/OnHandUtils";
import { AppContext } from "../../../../context/AppContext";

const OnHandFoodItemActions = () => {
    const { appState } = useContext(AppContext);
    const { onHandState, goToPrevItem, goToNextItem, lockSubmission, submitSelectedItem } =
        useContext(OnHandContext);

    useEffect(() => {}, [onHandState.restaurant.group.item]);

    const handleSubmit = async () => {
        lockSubmission();
        const inventory = OnHandUtils.buildInventory(appState, onHandState);
        await OnHandUtils.handleSubmit(onHandState, submitSelectedItem, inventory);
    };

    const disabledSubmit =
        !onHandState.restaurant.group.item ||
        !onHandState.restaurant.group.item.inventory ||
        onHandState.lockedSubmit;

    return (
        <div className={onHandItemStyles.Actions}>
            <Button variant="contained" onClick={goToPrevItem}>
                Prev
            </Button>
            <Button
                variant="contained"
                color="secondary"
                onClick={handleSubmit}
                disabled={disabledSubmit}
            >
                Submit
            </Button>
            <Button variant="contained" onClick={goToNextItem}>
                Skip
            </Button>
        </div>
    );
};

export default OnHandFoodItemActions;
