import React from "react";
import { MdHome } from "react-icons/md";

function AppHomeMenuItem(name: string) {
    return [
        <MdHome key={`${name}-icon`} size={"1.7em"} />,
        <div key={`${name}-text`}>
            <span>{name}</span>
        </div>,
    ];
}

export default AppHomeMenuItem;
