import moment from "moment-timezone";
import { AppDayType } from "./AppDayType";

export default class AppDayModel {
    public data: AppDayType;

    static WEEK_UNIX = 7 * 24 * 60 * 60;

    static getTodayUnixTime(): number {
        return moment().tz("America/New_York").startOf("day").unix();
    }

    static getTodayFilter(unixTime = AppDayModel.getTodayUnixTime()): { day: { eq: number } } {
        return { day: { eq: unixTime } };
    }

    static getCurrentUnixTime(): number {
        return moment().tz("America/New_York").unix();
    }

    static getTimeDisplay(unixTime: number, format = "h:mm:ss A, MMMM Do"): string {
        return AppDayModel.createMomentFromUnixTime(unixTime).format(format);
    }

    static getDayTimeDisplay(unixTime: number, format = "MMMM Do YYYY"): string {
        return AppDayModel.createMomentFromUnixTime(unixTime).format(format);
    }

    static getPrevDayUnixTime(unixTime = AppDayModel.getTodayUnixTime()): number {
        return AppDayModel.createMomentFromUnixTime(unixTime)
            .subtract(1, "days")
            .tz("America/New_York")
            .unix();
    }

    static getPrevDayFilter(unixTime: number): { day: { eq: number } } {
        return { day: { eq: AppDayModel.getPrevDayUnixTime(unixTime) } };
    }

    static getUnixTimeFromDate(date: Date): number {
        return moment(date).tz("America/New_York").startOf("day").unix();
    }

    static getNextDayUnixTime(unixTime = AppDayModel.getTodayUnixTime()): number {
        return AppDayModel.createMomentFromUnixTime(unixTime)
            .add(1, "days")
            .tz("America/New_York")
            .unix();
    }

    static createMomentFromUnixTime(unixTime: number): moment.Moment {
        return moment(unixTime * 1000);
    }

    static isValid(day: AppDayModel): boolean {
        return day && typeof day.data.time === "number" && typeof day.data.display === "string";
    }

    static getDayLabel(day: AppDayModel): string {
        return AppDayModel.getTimeDisplay(day.data.time, "YYYY-MM-DD");
    }

    constructor(time?: number | Date) {
        let unixTime: number = AppDayModel.getTodayUnixTime();

        if (time?.constructor === Date) {
            unixTime = AppDayModel.getUnixTimeFromDate(time);
        }

        if (typeof time === "number") {
            unixTime = time;
        }

        this.data = {
            time: unixTime,
            display: AppDayModel.getTimeDisplay(unixTime),
        };
    }
}
