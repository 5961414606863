function DeliveryDpsEmailDataPreview(notesToKitchenManager, ingredients) {
    const rowStyle = "";
    const cellStyle = "padding: 4px 8px";

    const buildRow = (name, deliveryDisplay) => {
        const buildCell = (data) => {
            return `
                <td style="${cellStyle}">${data}</td>
            `;
        };

        return `
            <tr style="${rowStyle}">
                ${buildCell(name)}
                ${buildCell(deliveryDisplay)}
            </tr>
        `;
    };

    const body = ingredients
        .map(({ data: { name }, sold, deliveryDisplay }) =>
            sold ? buildRow(name, deliveryDisplay) : ""
        )
        .reduce((a, c) => (a += c), "");

    return `
        <html>
            <body>
                <div>
                    <h3>
                        <strong>Notes to Kitchen Manager</strong>
                    </h3>
                    <p>${notesToKitchenManager || ""}</p>
                </div>
                <br/>
                <table border="1">
                    <tr style="${rowStyle}">
                        <th style="${cellStyle}"><h4><strong>Item</strong></h4></th>
                        <th style="${cellStyle}"><h4><strong>Total Pulled</strong></h4></th>
                    </tr>
                    ${body}
                </table>
                <h4>
                    <em>REPLY TO THIS EMAIL WITH ANY
                    <ul>
                        <li>FOOD QUALITY ISSUES</li>
                        <li>STAFFING CONCERNS</li>
                        <li>EQUIPMENT/MAINTENANCE NEEDS</li>
                        <li>etc.</li>
                    </ul>
                    FOR COMMISSARY
                    </em>
                </h4>
                <br/><br/>
            </body>
        </html>
    `;
}

export default DeliveryDpsEmailDataPreview;
