import React, { useContext } from "react";
import deliveryTShirtsStyles from "./TShirtsStocking.module.css";
import { TShirtsStockingContext } from "./context/TShirtsStockingContext";
import TShirtsStockingStyle from "./t-shirt/TShirtsStockingStyle";

const TShirtsStockingContent = () => {
    const { tShirtsStocking } = useContext(TShirtsStockingContext);

    if (!tShirtsStocking.restaurant || !tShirtsStocking.restaurant.tShirtStyles) {
        console.info("Invalid dataEditorState restaurant or restaurant tShirtStyles");
        return <div />;
    }

    const tShirtModelSets = Object.values(tShirtsStocking.restaurant.tShirtStyles)
        .map((tShirtStyleSet) => {
            return {
                order: tShirtStyleSet[0]?.style?.data?.order,
                tShirtStyleSet,
            };
        })
        .sort((a, b) => a.order - b.order);

    return (
        <div className={deliveryTShirtsStyles.Content}>
            {tShirtModelSets.map(({ tShirtStyleSet }) => (
                <TShirtsStockingStyle tShirtStyleSet={tShirtStyleSet} />
            ))}
        </div>
    );
};

export default TShirtsStockingContent;
