import { useContext, useEffect } from "react";
import { useGlobalSpinnerActionsContext } from "../../../components/global-spinner/GlobalSpinnerContext";
import { AppContext } from "../../../context/AppContext";
import AppContextValidator from "../../../context/AppContextValidator";
import DeliveryFoodContent from "./components/content/DeliveryFoodContent";
import { DeliveryFoodContext } from "./DeliveryFoodContext";
import DeliveryFoodLoadUtils from "./services/DeliveryFoodLoadUtils";
import { DeliveryFoodConfig, DeliveryFoodState } from "./types/DeliveryFoodTypes";

const DeliveryFood = ({ config }: { config: DeliveryFoodConfig }) => {
    const setGlobalSpinner = useGlobalSpinnerActionsContext();
    const { appState } = useContext(AppContext);
    const { deliveryFoodState, updateDeliveryFood } = useContext(DeliveryFoodContext);

    useEffect(() => {
        if (AppContextValidator.isValid(appState) && appState.day?.data.time) {
            setGlobalSpinner(true);

            DeliveryFoodLoadUtils.getDeliveryFood(appState, deliveryFoodState, config).then(
                (updatedDeliveryFoodState: DeliveryFoodState) => {
                    setGlobalSpinner(false);
                    return updateDeliveryFood(updatedDeliveryFoodState);
                }
            );
        }
    }, [appState.day]);

    return <DeliveryFoodContent />;
};

export default DeliveryFood;
