import { AiOutlineFileSearch } from "react-icons/ai";
import { UserGroupType } from "../../services/AppUserGroupsData";
import ContainerBasePaths from "../ContainerBasePaths";
import { ContainerConfig } from "../ContainerConfig";
import { Section } from "../sections/Section";

export class OrdersSection extends Section {
    renderIcon(disabled: boolean) {
        return <AiOutlineFileSearch size={this.iconSize} color={"inherit"} />;
    }

    renderHeaderIcon() {
        return <AiOutlineFileSearch size={this.iconHeaderSize} color={this.iconHeaderColor} />;
    }

    renderHomeIcon() {
        return <AiOutlineFileSearch size={this.iconHomeSize} color={this.iconHomeColor} />;
    }

    getDefaultPath(userGroups: UserGroupType[]) {
        console.error("Couldn't match the user to any known section pages", userGroups);
        return super.getDefaultPath(userGroups);
    }

    constructor(
        pages: ContainerConfig[],
        name: string,
        allowedGroups: UserGroupType[],
        disabled: boolean
    ) {
        super(pages, name, allowedGroups, disabled, ContainerBasePaths.ORDERS);
    }
}
