import React, { useContext } from "react";
import deliveryTShirtsStyles from "./TShirtsStocking.module.css";

import { TShirtsStockingContext } from "./context/TShirtsStockingContext";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";

const TShirtsStockingRestaurant = () => {
    const { tShirtsStocking, changeRestaurant } = useContext(TShirtsStockingContext);

    if (!tShirtsStocking.restaurant) {
        console.info("Invalid restaurant");
        return <div />;
    }

    return (
        <FormControl className={deliveryTShirtsStyles.SelectionRestaurant}>
            <Select
                labelId="delivery-t-shirts-restaurant"
                id="delivery-t-shirts-restaurant"
                value={tShirtsStocking.restaurant}
                onChange={(event) => changeRestaurant(event.target.value)}
            >
                {tShirtsStocking.restaurants.map((restaurant) => {
                    return (
                        <MenuItem key={`r-${restaurant.data.id}`} value={restaurant}>
                            {restaurant.data.name}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

export default TShirtsStockingRestaurant;
