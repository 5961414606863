export type ContainerPathType = string;

export type ContainerPathsType = {
    [key: string]: ContainerPathType;
};

export const ContainerPaths: ContainerPathsType = {
    HOME: "/home",
    SIGN_OUT: "/sign-out",
    MENUS: "/sales",
    ON_HAND: "/on-hand",
    DELIVERY: "/delivery",
    EDITOR: "/editor",
    PULL: "/pull",
    STOCKING: "/stocking",
    REORDERS: "/reorders",
    RECEIVING: "/receiving",
    SALES: "/sales",
};
