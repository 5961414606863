import _ from "lodash";
import {
    DataEditorItemsType,
    DataEditorSortQueueEntry,
    DataEditorState,
    isDataEditorSortQueue as isDataEditorSortQueueLike,
} from "../types/DataEditorTypes";
import DataEditorDisplayUtils from "../utils/DataEditorDisplayUtils";
import DataEditorSortUtils from "../utils/DataEditorSortUtils";
import DataEditorUtils from "../utils/DataEditorUtils";
import { Resource } from "./../../../resources/Resource";
import { DataEditorAction } from "./DataEditorAction";

const DataEditorReducer = (
    dataEditorState: DataEditorState,
    action: DataEditorAction
): DataEditorState => {
    if (action.type === "RE_ORDER_SELECTED_ITEMS") {
        dataEditorState.store.items = DataEditorDisplayUtils.saveShuffledItems(
            dataEditorState,
            action.payload.shuffledItems,
            action.payload.pageStartIndex,
            action.payload.pageEndIndex
        );

        dataEditorState.display.enableReorder = action.payload.enableReorder;
    }

    if (action.type === "CHANGE_RESOURCE") {
        dataEditorState = dataEditorState.setSelectedResource(
            action.payload.selectedResource as typeof Resource
        );
    }

    if (action.type === "CLEAR_ALL_ITEMS_FROM_SELECTED_RESOURCE") {
        dataEditorState.store.items = DataEditorUtils.clearItemsByResource(
            dataEditorState.store.items,
            dataEditorState.getSelectedResource()
        );
    }

    if (action.type === "UPDATE_SELECTED_RESOURCE_SORT_QUEUE") {
        if (!isDataEditorSortQueueLike(action.payload.newSortQueue)) {
            return dataEditorState;
        }

        const storeKey = dataEditorState.getSelectedResource().getModel().getResourceStoreKey();
        dataEditorState.display.sortQueues[storeKey] = action.payload
            .newSortQueue as DataEditorSortQueueEntry[];

        dataEditorState.store.items = DataEditorSortUtils.sortItemsByResource(
            dataEditorState.store.items,
            dataEditorState.getSelectedResource(),
            dataEditorState.display.sortQueues[storeKey]
        );
    }

    if (action.type === "UPDATE_SELECTED_RESOURCE_PAGE_INDEX") {
        dataEditorState.store.resources.selected.getModel().config.pageIndex =
            action.payload.newPageIndex;
    }

    if (action.type === "SAVE_ORDER") {
        dataEditorState.display.enableReorder = action.payload.enableReorder;

        const storeKey = dataEditorState.getSelectedResource().getModel().getResourceStoreKey();
        dataEditorState.setItemsByStoreKey(storeKey, action.payload.resourceItems as Resource[]);

        const resourceRefKey = dataEditorState
            .getSelectedResource()
            .getModel()
            .getResourceStoreKey();

        dataEditorState.setItemsByResourceRefKey(
            resourceRefKey,
            action.payload.resourceItems as Resource[]
        );
    }

    if (action.type === "SET_SEARCH_TEXT") {
        const storeKey = dataEditorState.getSelectedResource().getModel().getResourceStoreKey();
        dataEditorState.display.searchText[storeKey] = action.payload.searchText;
    }

    if (action.type === "UPDATE_ALL_ITEMS") {
        dataEditorState.store.items = action.payload.items;
        dataEditorState.store.items =
            DataEditorDisplayUtils.buildDisplaysForAllItems(dataEditorState);

        const storeKey = dataEditorState.getSelectedResource().getModel().getResourceStoreKey();
        dataEditorState.store.items = DataEditorSortUtils.sortItemsByResource(
            dataEditorState.store.items,
            dataEditorState.getSelectedResource(),
            dataEditorState.display.sortQueues[storeKey]
        );
    }

    if (action.type === "REPLACE_ITEM") {
        const resourceItem: Resource = action.payload.item;
        resourceItem.setDisplay(
            DataEditorDisplayUtils.getResourceItemDisplay(
                dataEditorState,
                dataEditorState.getSelectedResource(),
                resourceItem
            )
        );

        dataEditorState.store.items = DataEditorUtils.replaceItem(
            dataEditorState.store.items,
            resourceItem
        );
    }

    if (action.type === "UPDATE_RESOURCE_ITEMS") {
        // TODO: update items displays too
        const resource = dataEditorState.getSelectedResource();
        const storeKey = resource.getModel().getResourceStoreKey();

        dataEditorState.setItemsByStoreKey(storeKey, action.payload.resourceItems as Resource[]);

        const resourceRefKey = resource.getModel().getResourceStoreKey();
        dataEditorState.setItemsByResourceRefKey(
            resourceRefKey,
            action.payload.resourceItems as Resource[]
        );

        dataEditorState.store.items =
            DataEditorDisplayUtils.buildDisplaysForAllItems(dataEditorState);
    }

    if (action.type === "CLEAR_ITEM") {
        const newStoreItems: DataEditorItemsType = DataEditorUtils.clearItemByResource(
            dataEditorState.store.items,
            dataEditorState.getSelectedResource(),
            action.payload.resourceItem as Resource
        );
        dataEditorState.store.items = newStoreItems;
    }

    if (action.type === "ADD_NEW_ITEM") {
        const item = action.payload.resourceItem as Resource;
        const itemDisplay = DataEditorDisplayUtils.getResourceItemDisplay(
            dataEditorState,
            dataEditorState.getSelectedResource(),
            item
        );
        item.setDisplay(itemDisplay);

        const storeKey = dataEditorState.getSelectedResource().getModel().getResourceStoreKey();

        dataEditorState.store.items = DataEditorUtils.addItemByResource(
            dataEditorState.store.items,
            dataEditorState.getSelectedResource(),
            item
        );

        dataEditorState.store.items = DataEditorSortUtils.sortItemsByResource(
            dataEditorState.store.items,
            dataEditorState.getSelectedResource(),
            dataEditorState.display.sortQueues[storeKey]
        );
    }

    if (action.type === "OPEN_ADD_DIALOG") {
        dataEditorState.display.addDialogOpen = true;
    }

    if (action.type === "CLOSE_ADD_DIALOG") {
        dataEditorState.display.addDialogOpen = false;
    }

    return _.clone(dataEditorState);
};

export { DataEditorReducer };
