import { AppRestaurant, AppRestaurantTypes } from "../../../admin/AdminResources";
import { DeliveryFoodDeliveryMode } from "./DeliveryFoodDeliveryMode";
import DeliveryFoodMode from "./DeliveryFoodMode";
import { DeliveryFoodPrepForDeliveryTimeMode } from "./DeliveryFoodPrepForDeliveryTimeMode";
import { DeliveryFoodPrepMode } from "./DeliveryFoodPrepMode";
import { DeliveryFoodPrepTimeMode } from "./DeliveryFoodPrepTimeMode";
import { DeliveryFoodRestaurantMode } from "./DeliveryFoodRestaurantMode";
import { DeliveryFoodRestaurantFixedMode } from "./DeliveryFoodRestaurantFixedMode";
import { DeliveryFoodSummaryMode } from "./DeliveryFoodSummaryMode";

export default function DeliveryFoodModes(allRestaurants: AppRestaurant[]): DeliveryFoodMode[] {
    const restaurants = allRestaurants.filter(
        (restaurant: AppRestaurant) => restaurant.data.type === AppRestaurantTypes.RESTAURANT
    );

    const restaurantModes = restaurants
        .reverse()
        .filter(
            (restaurant: AppRestaurant) => restaurant.data.type === AppRestaurantTypes.RESTAURANT
        )
        .map((restaurant: AppRestaurant) => [
            new DeliveryFoodRestaurantMode({ restaurants, restaurant }),
            new DeliveryFoodRestaurantFixedMode({ restaurants, restaurant }),
        ])
        .flat();

    const rootData = { restaurants, restaurant: restaurants[0] };

    return [
        new DeliveryFoodSummaryMode(rootData),
        new DeliveryFoodDeliveryMode(rootData),
        new DeliveryFoodPrepMode(rootData),
        new DeliveryFoodPrepTimeMode(rootData),
        new DeliveryFoodPrepForDeliveryTimeMode(rootData),
        ...restaurantModes,
    ];
}
