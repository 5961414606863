import { useContext } from "react";
import { OffSiteReordersContext } from "./context/OffSiteReordersContext";
import styles from "./OffSiteReorders.module.css";
import OffSiteReordersCompleteButton from "./buttons/OffSiteReordersCompleteButton";
import { OffSiteReordersAlertDisplay } from "./types/OffSiteReordersTypes";

export default function OffSiteReordersSnoozedAlert(props: any) {
    const { alert, index }: { alert: OffSiteReordersAlertDisplay; index: number } = props;

    const { offSiteReordersState } = useContext(OffSiteReordersContext);

    if (!alert?.data?.status) {
        return null;
    }

    const className =
        offSiteReordersState.lastUpdatedAlert == alert
            ? `${styles.SnoozedAlert} ${styles.LastUpdatedAlert}`
            : styles.SnoozedAlert;

    return (
        <div key={`offsite-reorders-snoozed-alert-${index}`} className={className}>
            <span className={styles.ItemName}>{alert?.offSiteItem?.data.name}</span>
            <span className={styles.ItemStock}>{alert.stock}</span>
            <span className={styles.ItemPar}>{alert?.offSiteItem?.data.alertThreshold}</span>
            <span className={styles.ItemSnoozedUntil}>{alert?.snoozedUntilMoment?.fromNow()}</span>
            <div className={styles.Controls}>
                <OffSiteReordersCompleteButton alert={alert} />
            </div>
        </div>
    );
}
