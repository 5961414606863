import { FormControl, Input, InputAdornment } from "@material-ui/core";
import React, { useContext } from "react";
import { AppContext } from "../../../../context/AppContext";
import OnHandItemClearInput from "../../../on-hand/components/item/OnHandItemClearInput";
import onHandItemInputStyles from "../../../on-hand/components/item/OnHandItemInput.module.css";
import { OnHandContext } from "../../../on-hand/context/OnHandContext";
import OffSiteTasksUtils from "../../tasks/OffSiteTasksUtils";

export default function OffSiteDeliveryItemTotal() {
    const { appState } = useContext(AppContext);
    const { onHandState, onSelectedPendingDeliveryItemQuantityUpdate } = useContext(
        OnHandContext
    ) as any;

    const handleQuantityChange = (event: any) => {
        const addedQuantity = Math.max(+event.target.value || 0, 0);
        return addedQuantity === 0
            ? null
            : onSelectedPendingDeliveryItemQuantityUpdate(addedQuantity, appState.user.name);
    };

    // TODO: until a solution is found, do not allow values below the sum of required values
    // TODO: propose solution for partial completion
    const itemUnitDisplay = OffSiteTasksUtils.getUnitDisplay(onHandState.restaurant?.group?.item);

    return (
        <div className={onHandItemInputStyles.Middle}>
            <div
                className={onHandItemInputStyles.InputWrp}
                style={{ margin: "0.25em 0.5em", width: "unset" }}
            >
                <FormControl>
                    <Input
                        id="taking-amount"
                        value={onHandState.restaurant?.group?.item?.deliveryQuantity || 0}
                        onChange={handleQuantityChange}
                        endAdornment={
                            <InputAdornment position="end">{itemUnitDisplay}</InputAdornment>
                        }
                        aria-describedby="taking-amount-helper-text"
                        inputProps={{
                            "aria-label": "taking-amount",
                        }}
                    />
                </FormControl>
            </div>
            {/* <OnHandItemClearInput /> */}
        </div>
    );
}
