import { ResourceColumn } from "../../../resources/ResourceColumn";
import {
    DataEditorItemsType,
    DataEditorSortDirection,
    DataEditorSortQueueEntry,
    DataEditorSortQueues,
    DataEditorState,
} from "../types/DataEditorTypes";
import { Resource } from "./../../../resources/Resource";

function sortSortQueueByColumnsSortRank(
    a: DataEditorSortQueueEntry,
    b: DataEditorSortQueueEntry
): number {
    if (a.column.defaultSortRank === null || b.column.defaultSortRank === null) {
        return 0;
    }

    return a.column.defaultSortRank - b.column.defaultSortRank;
}

function getDefaultResourceSortQueue(resource: typeof Resource): DataEditorSortQueueEntry[] {
    let result: DataEditorSortQueueEntry[] = [];
    const columns = resource.getModel().getColumns();

    for (const column of columns) {
        if (column.defaultSortRank === -1) {
            continue;
        }
        result.push(new DataEditorSortQueueEntry(column));
    }

    result.sort(sortSortQueueByColumnsSortRank);
    result = result.splice(0, 2);

    return result;
}

function getDefaultSortQueue(resources: typeof Resource[]): DataEditorSortQueues {
    const result: DataEditorSortQueues = {};

    resources.forEach((resource: typeof Resource) => {
        const storeKey = resource.getModel().getResourceStoreKey();
        result[storeKey] = getDefaultResourceSortQueue(resource);
    });

    return result;
}

function getNextSortDirection(
    sortQueue: DataEditorSortQueueEntry[],
    column: ResourceColumn
): DataEditorSortDirection {
    const sortQueueEntry = sortQueue.find((s) => s.column === column);

    if (sortQueueEntry?.sortDirection === "ASC") {
        return "DESC";
    }

    return "ASC";
}

function removeElementByIndex<T>(list: T[], index: number): T[] {
    return list.slice(0, index).concat(list.slice(index + 1));
}

function getNewSortQueue(
    dataEditorState: DataEditorState,
    selectedColumn: ResourceColumn
): DataEditorSortQueueEntry[] {
    const selectedResource = dataEditorState.getSelectedResource();
    const storeKey = selectedResource.getModel().getResourceStoreKey();
    const currentSortQueue = dataEditorState.display.sortQueues[storeKey];
    const selectedColumnIndex = currentSortQueue.findIndex((r) => r.column === selectedColumn);

    let result = [...currentSortQueue];

    // new sort criteria
    if (selectedColumnIndex === -1) {
        result = [new DataEditorSortQueueEntry(selectedColumn, "ASC")].concat(result);
        return result;
    }

    // existing sort criteria
    const selectedColumnEntry = result[selectedColumnIndex];
    selectedColumnEntry.addClickCount();

    // it's been clicked twice already
    if (selectedColumnEntry.clickCount > 1) {
        result = removeElementByIndex(result, selectedColumnIndex);
        return result;
    }

    // it's been clicked only once, update just it's sortDirection
    if (selectedColumnEntry.clickCount === 1) {
        selectedColumnEntry.sortDirection = getNextSortDirection(result, selectedColumn);
        result[selectedColumnIndex] = selectedColumnEntry;
        return result;
    }

    result = removeElementByIndex(result, selectedColumnIndex);
    result = [selectedColumnEntry].concat(result);

    return result;
}

function getDisplayColumnSortDirection(
    dataEditorState: DataEditorState,
    column: ResourceColumn
): DataEditorSortDirection | null {
    const storeKey = dataEditorState.getSelectedResource().getModel().getResourceStoreKey();
    const sortQueue = dataEditorState.display.sortQueues[storeKey];

    return sortQueue.find((s) => s.column === column)?.sortDirection || null;
}

function sortResourceItems(resourceItems: Resource[], sortQueue?: DataEditorSortQueueEntry[]) {
    if (!sortQueue) {
        return resourceItems[0].getModel().editor.sort(resourceItems);
    }

    return resourceItems.sort((resourceA: Resource, resourceB: Resource) => {
        for (let i = sortQueue.length - 1; i >= 0; i--) {
            const sortQueueEntry = sortQueue[i];
            const resourceADisplay = resourceA.getDisplay()[sortQueueEntry.column.field];
            const resourceBDisplay = resourceB.getDisplay()[sortQueueEntry.column.field];

            const fieldSortResult =
                sortQueueEntry.sortDirection === "ASC"
                    ? sortQueueEntry.sortCallback(resourceADisplay, resourceBDisplay)
                    : sortQueueEntry.sortCallback(resourceBDisplay, resourceADisplay);

            if (fieldSortResult === true || fieldSortResult > 0) {
                return 1;
            }

            if (fieldSortResult === false || fieldSortResult < 0) {
                return -1;
            }
        }

        return 0;
    });
}

function sortItemsByResource(
    items: DataEditorItemsType,
    resource: typeof Resource,
    sortQueue?: DataEditorSortQueueEntry[]
): DataEditorItemsType {
    // sort by the storeKey
    const storeKey = resource.getModel().getResourceStoreKey();
    items.byResourceStoreKey[storeKey] = sortResourceItems(
        items.byResourceStoreKey[storeKey],
        sortQueue
    );

    // sort by the resourceRefKey
    const resourceRefKey = resource.getModel().getResourceRefKey();
    items.byResourceRefKey[resourceRefKey] = sortResourceItems(
        items.byResourceRefKey[resourceRefKey],
        sortQueue
    );

    return items;
}

const DataEditorSortUtils = {
    getDefaultSortQueue,
    getNextSortDirection,
    getNewSortQueue,
    getDisplayColumnSortDirection,
    sortResourceItems,
    sortItemsByResource,
};

export default DataEditorSortUtils;
