import React from "react";
import onHandTShirtItemStyles from "./OnHandTShirtItem.module.css";

const OnHandTShirtItemTitle = ({ item }) => {
    const { data, size, inventory } = item;
    let quantity = "?";
    if (inventory.data.lastUpdatedBy && typeof inventory.data.quantity === "number") {
        quantity = inventory.data.quantity;
    }

    return (
        <div className={onHandTShirtItemStyles.Title}>
            <span>
                {quantity} <strong>{data.name}</strong> size <strong>{size.data.name}</strong> left
            </span>
        </div>
    );
};

export default OnHandTShirtItemTitle;
