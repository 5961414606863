import { IconButton } from "@material-ui/core";
import { MdEdit } from "react-icons/md";
import { DataEditorItemModeParams } from "./DataEditorItem";

export default function DataEditorItemEditButton({
    item,
    index,
    setIsEditing,
}: DataEditorItemModeParams) {
    const onEdit = () => setIsEditing(true);

    return (
        <IconButton
            key={`item-edit-button-${index}`}
            disabled={!item.getModel().editor.isEditable}
            size={"small"}
            onClick={onEdit}
        >
            <MdEdit />
        </IconButton>
    );
}
