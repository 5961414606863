import React, { useContext } from "react";
import appSectionHeaderSelectorStyles from "./SectionHeaderSelector.module.css";

import { AppContext } from "../../../context/AppContext";
import AppContextValidator from "../../../context/AppContextValidator";
import { Button } from "@material-ui/core";

const SectionHeaderSelector = () => {
    const { appState, showSectionSelector } = useContext(AppContext);

    if (!AppContextValidator.isValid(appState)) {
        console.info("Invalid app state for SectionHeaderSelector");
        return <div />;
    }

    return (
        <Button
            onClick={showSectionSelector}
            color={"secondary"}
            variant={"contained"}
            className={appSectionHeaderSelectorStyles.Main}
        >
            <div className={appSectionHeaderSelectorStyles.Icon}>
                {appState.section.renderHeaderIcon()}
            </div>
            <div className={appSectionHeaderSelectorStyles.Label}>{appState.section.name}</div>
        </Button>
    );
};

export default SectionHeaderSelector;
