import React, { useContext, useEffect } from "react";
import DeliveryFood from "./DeliveryFood";
import { DeliveryFoodProvider } from "./DeliveryFoodContext";
import { AppContext } from "../../../context/AppContext";
import AppContextValidator from "../../../context/AppContextValidator";
import { DeliveryFoodConfig } from "./types/DeliveryFoodTypes";

const DeliveryFoodWrp = ({ history, location }: any) => {
    const { appState } = useContext(AppContext);

    useEffect(() => {
        console.info("DeliveryFoodWrp: Use effect!");
    }, [appState.day, appState.user, appState.section]);

    if (!AppContextValidator.isValid(appState)) {
        console.info("DeliveryFoodWrp: Invalid app state!");
        return <div />;
    }

    const config: DeliveryFoodConfig = {
        router: {
            history,
            location,
        },
    };

    return (
        <DeliveryFoodProvider>
            <DeliveryFood config={config} />
        </DeliveryFoodProvider>
    );
};

export default DeliveryFoodWrp;
