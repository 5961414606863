import { fetchResources } from "../../../resources/ResourcesService";
import AppUserGroups from "../../../services/AppUserGroups";
import OnHandDailyGenerator from "../services/OnHandDailyGenerator";
import AppUtilsService from "../../../services/AppUtilsService";
import OnHandGroupModel from "../models/OnHandGroupModel";
import OnHandRestaurantModel from "../models/OnHandRestaurantModel";
import { AppRestaurant, AppRestaurantTypes } from "../../admin/AdminResources";

const ID = "id";
const RESTAURANT_ID = "restaurantId";

export default class OnHandItemContext {
    static attachMetaToRestaurants(restaurants, inventoriesMeta, day) {
        const inventoriesMetaById = inventoriesMeta.reduce(
            AppUtilsService.groupDataByItem("restaurantId"),
            {}
        );
        return restaurants.map((restaurant) => {
            restaurant.inventoryMeta = inventoriesMetaById[restaurant.data.id];
            restaurant.unlocked = restaurant.inventoryMeta?.data?.status === "UNLOCKED";
            return restaurant;
        });
    }

    async loadDataInventory(
        day,
        userName,
        sectionName,
        userGroups,
        inventoryMetaResource,
        useMocks = false
    ) {
        const [appRestaurants] = await fetchResources([[AppRestaurant]], useMocks);
        const { restaurants, restaurantFilter } = AppUserGroups.getUserRestaurantWithFilter(
            appRestaurants,
            userGroups
        );
        const [items, groups] = await fetchResources(
            [[this.resource], [this.groupResource, restaurantFilter]],
            useMocks
        );

        // get or create inventories Meta
        const currentInventoriesMeta = await OnHandDailyGenerator.listMeta(
            inventoryMetaResource,
            day,
            useMocks
        );

        // STEP ?: build meta records
        const missingInventoriesMeta = await AppUtilsService.buildMissingInventoriesMeta(
            restaurants,
            currentInventoriesMeta,
            inventoryMetaResource,
            day,
            userName,
            sectionName,
            AppUtilsService.createMetaInventoryPromise
        );

        const inventoriesMeta = currentInventoriesMeta.concat(missingInventoriesMeta);

        this.rawItems = items;
        this.rawGroups = groups;
        this.rawRestaurants = OnHandItemContext.attachMetaToRestaurants(
            restaurants,
            inventoriesMeta,
            day
        );

        console.log("Loaded data!");
    }

    async loadDataTransactional(userGroups) {
        const [appRestaurants] = await fetchResources([
            [AppRestaurant, { type: { eq: AppRestaurantTypes.OFF_SITE } }],
        ]);
        // TODO: use actual user groups
        const { restaurants, restaurantFilter } = AppUserGroups.getUserRestaurantWithFilter(
            appRestaurants,
            userGroups
        );
        // TODO: include units too here and attach to each item under "offSiteUnit" and "offSiteSubUnit"
        const [items, groups] = await fetchResources([
            [this.resource],
            [this.groupResource, restaurantFilter],
        ]);

        this.rawItems = items;
        this.rawGroups = groups;
        this.rawRestaurants = restaurants;
    }

    async loadDependencies(day) {
        // should be implemented by extension
        return null;
    }

    decorate(item) {
        // should be implemented by extension
        return item;
    }

    buildDataHierarchy(sectionName) {
        // items
        this.items = this.rawItems.map(this.decorate.bind(this)).filter((x) => x);
        this.itemsByGroup = this.items.reduce(
            AppUtilsService.groupDataByList(this.groupRefKey),
            {}
        );

        // groups
        this.groups = this.rawGroups.map(
            (g) => new OnHandGroupModel(g, this.itemsByGroup[g.data.id])
        );
        this.groupsByRestaurant = this.groups.reduce(
            AppUtilsService.groupDataByList(RESTAURANT_ID),
            {}
        );

        // restaurants
        this.restaurants = this.rawRestaurants.map(
            (r) => new OnHandRestaurantModel(r, this.groupsByRestaurant[r.data.id], sectionName)
        );
        this.restaurantsById = this.restaurants.reduce(AppUtilsService.groupDataByItem(ID), {});
    }

    constructor(
        resource,
        inventoryResource,
        inventoryMetaResource,
        groupResource,
        utils,
        type,
        transactionResource
    ) {
        // configs
        this.resource = resource;
        this.inventoryResource = inventoryResource;
        this.inventoryMetaResource = inventoryMetaResource;
        this.transactionResource = transactionResource;
        this.groupResource = groupResource;
        const resourceConfig = resource.getModel().config;
        this.resourceRefKey = resourceConfig.resourceRefKey;
        const groupResourceConfig = groupResource.getModel().config;
        this.groupRefKey = groupResourceConfig.resourceRefKey;
        this.generator = new OnHandDailyGenerator(
            inventoryMetaResource,
            inventoryResource,
            resourceConfig.resourceRefKey
        );

        // utils
        this.utils = utils;

        // type
        this.type = type;

        // data
        this.rawRestaurants = [];
        this.restaurants = [];
        this.rawGroups = [];
        this.groups = [];
        this.rawItems = [];
        this.items = [];

        // data hierarchy
        this.itemsByGroup = {};
        this.groupsByRestaurant = {};
        this.restaurantsById = {};
    }
}
