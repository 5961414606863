import AppDayModel from "../../components/day/AppDayModel";
import {
    DpsEmail,
    DpsEmailRecipient,
    DpsIngredient,
    DpsMenuItem,
    DpsMenuItemSale,
    DpsMenuItemSaleMeta,
    DpsPack,
    DpsRecipeItem,
} from "../../graphql/API";
import { Model } from "../../model/Model";
import { ModelConfig } from "../../model/ModelConfig";
import { ResourceColumnDate } from "../../resources/columns/ResourceColumnDate";
import { ResourceColumnNumber } from "../../resources/columns/ResourceColumnNumber";
import { ResourceColumnSelect } from "../../resources/columns/select/ResourceColumnSelect";
import { ResourceColumnText } from "../../resources/columns/ResourceColumnText";
import { Resource } from "../../resources/Resource";
import { DataEditorState } from "../data-editor/types/DataEditorTypes";

/* ---------- DpsEmail ---------- */
const appDpsEmailModel = new Model(
    new ModelConfig("DpsEmail", 1000, 200, "dpsEmail", "Emails", "DpsEmail", "dpsEmail", [
        new ResourceColumnDate("sentAt", "Sent At")
            .setHideOnAdd()
            .setVisible()
            .setSortDirection("DESC", 0)
            .setKey(),
        new ResourceColumnText("sentBy", "Sent By").setHideOnAdd().setVisible().setKey(),
        new ResourceColumnText("restaurantId", "RST").setHideOnAdd().setVisible().setKey(),
        new ResourceColumnText("subject", "Subject").setHideOnAdd().setVisible(),
        new ResourceColumnText("recipients", "#").setHideOnAdd().setVisible(),
    ])
);

class AppDpsEmail extends Resource {
    static getModel(): Model {
        return appDpsEmailModel;
    }

    getModel(): Model {
        return appDpsEmailModel;
    }

    constructor(data: DpsEmail) {
        super(data, appDpsEmailModel);
    }
}

/* ---------- DpsEmailRecipient ---------- */
const appDpsEmailRecipientModel = new Model(
    new ModelConfig(
        "DpsEmailRecipient",
        1000,
        200,
        "dpsEmailRecipient",
        "Recipients",
        "DpsEmailRecipient",
        "dpsEmailRecipient",
        [
            new ResourceColumnText("name", "Name")
                .setKey()
                .setVisible()
                .setWidth("S")
                .setSortDirection("DESC", 0),
            new ResourceColumnSelect("restaurantId", "Restaurant")
                .setVisible()
                .setWidth("S")
                .setSortDirection("DESC", 1)
                .setKey(),
            new ResourceColumnText("email", "Email").setVisible().setEditable().setWidth("M"),
            new ResourceColumnNumber("isSubscribed", "Subscribed")
                .setVisible()
                .setEditable()
                .setWidth("S"),
        ]
    )
);

class AppDpsEmailRecipient extends Resource {
    static getModel(): Model {
        return appDpsEmailRecipientModel;
    }

    getModel(): Model {
        return appDpsEmailRecipientModel;
    }

    constructor(data: DpsEmailRecipient) {
        super(data, appDpsEmailRecipientModel);
    }
}

/* ---------- DpsIngredient ---------- */
class AppDpsIngredientModel extends Model {
    refDisplay(data: DpsIngredient) {
        return super.refDisplay(data);
    }
}

const appDpsIngredientModel = new AppDpsIngredientModel(
    new ModelConfig(
        "DpsIngredient",
        1000,
        200,
        "dpsIngredients",
        "Ingredients",
        "DpsIngredient",
        "dpsIngredientId",
        [
            new ResourceColumnText("id", "Id").setHideOnAdd().setKey(),
            new ResourceColumnText("name", "Name")
                .setKey()
                .setVisible()
                .setWidth("S")
                .setSortDirection("ASC", 1),
            new ResourceColumnSelect("dpsPackId", "Pack").setVisible().setEditable().setWidth("XS"),
            new ResourceColumnNumber("dpsPackSize", "Pack Size")
                .setVisible()
                .setWidth("XS")
                .setEditable(),
            new ResourceColumnSelect("subDpsPackId", "Sub-Pack")
                .setResourceRefKeyAlias("dpsPackId")
                .setVisible()
                .setEditable()
                .setWidth("XS")
                .setOptional(),
            new ResourceColumnNumber("subDpsPackSize", "Sub-Pack Size")
                .setVisible()
                .setWidth("XS")
                .setEditable()
                .setOptional(),
            new ResourceColumnSelect("subSubDpsPackId", "Sub-Sub-Pack")
                .setResourceRefKeyAlias("dpsPackId")
                .setVisible()
                .setEditable()
                .setWidth("XS")
                .setOptional(),
            new ResourceColumnNumber("useSubPack", "Use Sub-Pack")
                .setVisible()
                .setEditable()
                .setWidth("XS")
                .setOptional(),
            new ResourceColumnNumber("ratio", "Ratio", 0.1)
                .setVisible()
                .setWidth("XS")
                .setEditable()
                .setOptional(),
            new ResourceColumnNumber("order", "#")
                .setHideOnAdd()
                .setEditable()
                .setVisible()
                .setWidth("XS")
                .setSortDirection("ASC", 0)
                .setSortCallback((a, b) => parseInt(a) - parseInt(b)),
            new ResourceColumnText("lastUpdatedBy", "By").setOptional(),
            new ResourceColumnDate("lastUpdatedAt", "At").setOptional(),
        ]
    )
);

class AppDpsIngredient extends Resource {
    static getModel(): AppDpsIngredientModel {
        return appDpsIngredientModel;
    }

    getModel(): AppDpsIngredientModel {
        return appDpsIngredientModel;
    }

    constructor(data: DpsIngredient) {
        super(data, appDpsIngredientModel);
    }
}

/* ---------- DpsPack ---------- */
const appDpsPackModel = new Model(
    new ModelConfig("DpsPack", 1000, 200, "dpsPack", "Packs", "DpsPack", "dpsPackId", [
        new ResourceColumnText("id", "Id").setHideOnAdd().setKey(),
        new ResourceColumnText("name", "Name")
            .setVisible()
            .setKey()
            .setWidth("M")
            .setSortDirection("ASC", 1),
        new ResourceColumnNumber("order", "#")
            .setHideOnAdd()
            .setEditable()
            .setVisible()
            .setWidth("XS")
            .setSortDirection("ASC", 0)
            .setSortCallback((a, b) => parseInt(a) - parseInt(b)),
        new ResourceColumnText("lastUpdatedBy", "By").setOptional(),
        new ResourceColumnDate("lastUpdatedAt", "At").setOptional(),
    ])
);

class AppDpsPack extends Resource {
    static getModel(): Model {
        return appDpsPackModel;
    }

    getModel(): Model {
        return appDpsPackModel;
    }

    constructor(data: DpsPack) {
        super(data, appDpsPackModel);
    }
}

/* ---------- DpsMenuItem ---------- */
class AppDpsMenuItemModel extends Model {
    refDisplay(data: DpsMenuItem, dataEditorState?: DataEditorState) {
        if (dataEditorState) {
            return super.refChainDisplay(data, dataEditorState, "restaurantId");
        }
        return super.refDisplay(data);
    }
}

const appDpsMenuItemModel = new AppDpsMenuItemModel(
    new ModelConfig(
        "DpsMenuItem",
        10000,
        200,
        "dpsMenuItems",
        "Menu Items",
        "DpsMenuItem",
        "dpsMenuItemId",
        [
            new ResourceColumnText("id", "Id").setHideOnAdd().setKey(),
            new ResourceColumnSelect("restaurantId", "Rest.")
                .setKey()
                .setVisible()
                .setWidth("XS")
                .setSortDirection("ASC", 0)
                .setSortCallback((a, b) => parseInt(a) - parseInt(b)),
            new ResourceColumnText("name", "Name")
                .setKey()
                .setSortDirection("ASC", 1)
                .setSortCallback((a, b) => a.localeCompare(b))
                .setEditable()
                .setVisible()
                .setWidth("M"),
            new ResourceColumnNumber("order", "#")
                .setHideOnAdd()
                .setEditable()
                .setVisible()
                .setWidth("XS")
                .setSortCallback((a, b) => parseInt(a) - parseInt(b)),
            new ResourceColumnText("lastUpdatedBy", "By").setOptional(),
            new ResourceColumnDate("lastUpdatedAt", "At").setOptional(),
        ]
    )
);

class AppDpsMenuItem extends Resource {
    static getModel(): AppDpsMenuItemModel {
        return appDpsMenuItemModel;
    }

    getModel(): AppDpsMenuItemModel {
        return appDpsMenuItemModel;
    }

    constructor(data: DpsMenuItem) {
        super(data, appDpsMenuItemModel);
    }
}

/* ---------- DpsRecipeItem ---------- */
const appDpsRecipeItemModel = new Model(
    new ModelConfig(
        "DpsRecipeItem",
        10000,
        200,
        "dpsRecipeItems",
        "Recipe Items",
        "DpsRecipeItem",
        "dpsRecipeItemId",
        [
            new ResourceColumnSelect("dpsMenuItemId", "Menu Item")
                .setVisible()
                .setWidth("S")
                .setKey()
                .setSortDirection("ASC", 1)
                .setSortCallback((a, b) => parseInt(a) - parseInt(b)),
            new ResourceColumnSelect("dpsIngredientId", "Ingr.")
                .setVisible()
                .setKey()
                .setWidth("S"),
            new ResourceColumnNumber("quantity", "Qty").setVisible().setEditable().setWidth("XS"),
            new ResourceColumnNumber("order", "#")
                .setHideOnAdd()
                .setEditable()
                .setVisible()
                .setWidth("XS")
                .setSortDirection("ASC", 0)
                .setSortCallback((a, b) => parseInt(a) - parseInt(b)),
            new ResourceColumnText("lastUpdatedBy", "By").setOptional(),
            new ResourceColumnDate("lastUpdatedAt", "At").setOptional(),
        ]
    )
);

class AppDpsRecipeItem extends Resource {
    static getModel(): Model {
        return appDpsRecipeItemModel;
    }

    getModel(): Model {
        return appDpsRecipeItemModel;
    }

    constructor(data: DpsRecipeItem) {
        super(data, appDpsRecipeItemModel);
    }
}

/* ---------- DpsMenuItemSale ---------- */
class DpsMenuItemSaleModel extends Model {
    getFilter(appState: any) {
        return {
            day: { eq: appState.day.data.time || AppDayModel.getTodayUnixTime() },
        };
    }
}

const appDpsMenuItemSaleModel = new DpsMenuItemSaleModel(
    new ModelConfig(
        "DpsMenuItemSale",
        100000,
        200,
        "dpsMenuItemSales",
        "Sales Today",
        "DpsMenuItemSale",
        "dpsMenuItemSaleId",
        [
            new ResourceColumnSelect("dpsMenuItemId", "Rest - Menu Item")
                .setKey()
                .setVisible()
                .setWidth("M")
                .setSortDirection("ASC", 0),
            new ResourceColumnDate("day", "Day").setKey(),
            new ResourceColumnText("saleType", "Type")
                .setKey()
                .setVisible()
                .setWidth("S")
                .setSortDirection("ASC", 1),
            new ResourceColumnNumber("quantity", "Qty")
                .setOptional()
                .setVisible()
                .setWidth("XS")
                .setEditable(),
            new ResourceColumnText("lastUpdatedBy", "By").setVisible().setWidth("XS").setOptional(),
            new ResourceColumnDate("lastUpdatedAt", "At").setVisible().setWidth("S").setOptional(),
        ]
    )
);

class AppDpsMenuItemSale extends Resource {
    static getModel(): DpsMenuItemSaleModel {
        return appDpsMenuItemSaleModel;
    }

    getModel(): DpsMenuItemSaleModel {
        return appDpsMenuItemSaleModel;
    }

    constructor(data: DpsMenuItemSale) {
        super(data, appDpsMenuItemSaleModel);
    }
}

/* ---------- DpsMenuItemSaleMeta ---------- */
const appDpsMenuItemSaleMetaModel = new Model(
    new ModelConfig(
        "DpsMenuItemSaleMeta",
        1000,
        200,
        "dpsMenuItemSalesMeta",
        "Sales Records",
        "DpsMenuItemSaleMeta",
        "dpsMenuItemSaleMetaId",
        [
            new ResourceColumnDate("day", "Day", "D MMM")
                .setKey()
                .setSortDirection("DESC", 1)
                .setSortCallback((a, b) => parseInt(b) - parseInt(a))
                .setVisible()
                .setWidth("XS"),
            new ResourceColumnSelect("restaurantId", "Rest")
                .setKey()
                .setSortDirection("ASC", 0)
                .setSortCallback((a, b) => parseInt(a) - parseInt(b))
                .setVisible()
                .setWidth("XS"),
            new ResourceColumnSelect("status", "Status", ["UNLOCKED", "LOCKED"]).setOptional(),
            new ResourceColumnText("storageKey", "S3 Path")
                .setEditable()
                .setVisible()
                .setWidth("L"),
            new ResourceColumnText("completion", "%").setOptional(),
            new ResourceColumnText("lastUpdatedBy", "By").setOptional().setVisible().setWidth("XS"),
            new ResourceColumnDate("lastUpdatedAt", "At").setOptional().setVisible().setWidth("XS"),
        ]
    )
);

class AppDpsMenuItemSaleMeta extends Resource {
    static getModel(): Model {
        return appDpsMenuItemSaleMetaModel;
    }

    getModel(): Model {
        return appDpsMenuItemSaleMetaModel;
    }

    constructor(data: DpsMenuItemSaleMeta) {
        super(data, appDpsMenuItemSaleMetaModel);
    }
}

export {
    AppDpsIngredient,
    AppDpsEmail,
    AppDpsEmailRecipient,
    AppDpsPack,
    AppDpsMenuItem,
    AppDpsRecipeItem,
    AppDpsMenuItemSale,
    AppDpsMenuItemSaleMeta,
};
