import React, { useContext } from "react";
import onHandItemStyles from "../../../on-hand/components/item/OnHandItem.module.css";
import onHandContentStyles from "../../../on-hand/components/content/OnHandContent.module.css";
import OnHandOrderItemTitle from "./OnHandOrderItemTitle";
import OnHandOrderItemActions from "./OnHandOrderItemActions";
import OnHandOrderItemMeta from "./OnHandOrderItemMeta";
import { OnHandContext } from "../../../on-hand/context/OnHandContext";
import OnHandUtils from "../../../on-hand/OnHandUtils";
import OnHandItemInput from "../../../on-hand/components/item/OnHandItemInput";

const OnHandOrderItem = (group, item) => {
    const { onHandState } = useContext(OnHandContext);

    if (!OnHandUtils.isSelectedItem(onHandState, item)) {
        return <div />;
    }

    const key = `on-hand-order-item-${item.data.id}`;

    return (
        <div key={key} className={onHandItemStyles.Details}>
            <div className={onHandContentStyles.ContentHeader}>
                <OnHandOrderItemTitle />
            </div>
            <OnHandItemInput />
            <OnHandOrderItemActions />
            <OnHandOrderItemMeta />
        </div>
    );
};

export default OnHandOrderItem;
