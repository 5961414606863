import React from "react";
import deliveryOrdersStyles from "../DeliveryOrders.module.css";

const DeliveryOrdersContentHeader = () => {
    return (
        <div className={`no-print ${deliveryOrdersStyles.TableHeader} ${deliveryOrdersStyles.Row}`}>
            <span>Name</span>
            <span className={`no-print ${deliveryOrdersStyles.Explained}`}>On Hand</span>
            <span className={`no-print ${deliveryOrdersStyles.Explained}`}>PAR</span>
            <span className={`no-print ${deliveryOrdersStyles.Explained}`}>Conversion</span>
            <span>Delivery</span>
        </div>
    );
};

export default DeliveryOrdersContentHeader;
