import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useGlobalSpinnerActionsContext } from "../../../../components/global-spinner/GlobalSpinnerContext";
import { OnHandContext } from "../../../on-hand/context/OnHandContext";
import OffSiteTasksUtils from "../../tasks/OffSiteTasksUtils";
import styles from "./OffSiteHistoryItem.module.css";
import { OffSiteContext } from "../../context/OffSiteContext";
import { OffSiteTaskType } from "../../context/OffSiteContextTypes";

export default function OffSiteHistoryItem() {
    const { offSiteState } = useContext(OffSiteContext);
    const { onHandState } = useContext(OnHandContext) as any;
    const [transactions, setTransactions] = useState([] as OffSiteTaskType[]);
    const setGlobalSpinner = useGlobalSpinnerActionsContext();

    useEffect(() => {
        // fetch the transactions  history of the selected item and display them ordered by createdAt
        setGlobalSpinner(true);
        OffSiteTasksUtils.fetchItemTransactions(onHandState.restaurant.group.item.data.id).then(
            (transactions = []) => {
                setGlobalSpinner(false);
                setTransactions(transactions);
            }
        );
    }, []);

    return (
        <ol className={styles.Main}>
            <li className={styles.Item}>
                <span className={styles.Value}>
                    <strong>Type</strong>
                </span>
                <span className={styles.Value}>
                    <strong>Status</strong>
                </span>
                <span className={styles.Value}>
                    <strong>Rst</strong>
                </span>
                <span className={styles.Value}>
                    <strong>Qty</strong>
                </span>
                <span className={styles.Value}>
                    <strong>Created</strong>
                </span>
                <span className={styles.Value}>
                    <strong>By</strong>
                </span>
                <span className={styles.Value}>
                    <strong>Full Date</strong>
                </span>
            </li>
            {transactions
                .sort(
                    (transactionA, transactionB) =>
                        transactionA?.data?.restaurantId - transactionB?.data?.restaurantId ||
                        transactionA?.data?.createdAt - transactionB?.data?.createdAt
                )
                .map(({ data: { status, type, quantity, restaurantId, createdAt, createdBy } }) => {
                    const createdAtMoment = moment(createdAt).tz("America/New_York");
                    const createdAtDisplay = createdAtMoment.format("h:mm:ss A, MMM Do");
                    const timeSinceCreated = createdAtMoment.fromNow();
                    const restaurantAlias = offSiteState.destinationRestaurants.find(
                        ({ data: { id } }) => id === restaurantId
                    )?.data?.alias;

                    return (
                        <li className={styles.Item}>
                            <span className={styles.Value}>{type}</span>
                            <span className={styles.Value}>{status}</span>
                            <span className={styles.Value}>{restaurantAlias}</span>
                            <span className={styles.Value}>{quantity}</span>
                            <span className={styles.Value}>{timeSinceCreated}</span>
                            <span className={styles.Value}>{createdBy}</span>
                            <span className={styles.Value}>{createdAtDisplay}</span>
                        </li>
                    );
                })}
        </ol>
    );
}
