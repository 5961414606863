class AppEmail {
    subject = "";
    bodyHTML = "";
    to = null;
    bcc = null;
    sentAt = -1;
    replyTo = "";

    constructor(sender) {
        if (typeof sender !== "string") {
            throw Error("Invalid sender!");
        }

        this.sender = sender;
    }
}

export default AppEmail;
