import { useContext } from "react";
import { AiOutlineAlert } from "react-icons/ai";
import { OffSiteReordersContext } from "./context/OffSiteReordersContext";
import styles from "./OffSiteReorders.module.css";
import OffSiteReordersOpenAlert from "./OffSiteReordersOpenAlert";
import OffSiteReordersUtils from "./OffSiteReordersUtils";
import { OffSiteReordersAlertDisplay } from "./types/OffSiteReordersTypes";

export default function OffSiteReordersOpenAlerts() {
    const { offSiteReordersState } = useContext(OffSiteReordersContext);

    const openAlerts = OffSiteReordersUtils.getOpenAlertsList(offSiteReordersState.alerts);

    return (
        <div key="offsite-reorders-open-alerts" className={styles.OpenAlerts}>
            <div className={styles.AlertTypeDividers}>
                <div>
                    <AiOutlineAlert size={20} />
                </div>
                <span>Reorders</span>
                <span />
                <span className={styles.ItemsCount}>{openAlerts.length}</span>
            </div>
            {openAlerts.map((alert: OffSiteReordersAlertDisplay, index: number) => (
                <OffSiteReordersOpenAlert alert={alert} index={index} />
            ))}
        </div>
    );
}
