import React, { useContext, useEffect } from "react";
import appSectionInitSelectorStyles from "./SectionInitSelector.module.css";
import Button from "@material-ui/core/Button";
import { AppContext } from "../../../context/AppContext";
import AppContextValidator from "../../../context/AppContextValidator";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";

const SectionInitSelector = () => {
    const { appState, signOut, selectSection, hideSectionSelector } = useContext(AppContext);

    useEffect(() => {}, [appState.user, appState.sections, appState.section]);

    if (!AppContextValidator.isValidUser(appState.user)) {
        console.info("Invalid user!");
        return <div />;
    }

    const SignOutButton = () => {
        return (
            <Button onClick={signOut} variant={"contained"} color="secondary">
                Sign out
            </Button>
        );
    };

    if (appState.sections.length === 0) {
        return (
            <Dialog onClose={() => {}} aria-labelledby="customized-dialog-title" open={true}>
                <DialogTitle
                    id="customized-dialog-title"
                    className={appSectionInitSelectorStyles.Title}
                    onClose={() => {}}
                >
                    Need Access
                </DialogTitle>
                <DialogContent className={appSectionInitSelectorStyles.Main} dividers>
                    <Typography gutterBottom>
                        Please contact one of the admins for access rights!
                    </Typography>
                </DialogContent>
                <DialogActions className={appSectionInitSelectorStyles.Actions}>
                    {SignOutButton()}
                </DialogActions>
            </Dialog>
        );
    }

    if (appState.sections.length === 1) {
        selectSection(appState.sections[0]);
        return null;
    }

    const CancelButton = (appState) => {
        const handleCancelSelection = () => hideSectionSelector();

        return (
            <Button
                onClick={handleCancelSelection}
                color={"secondary"}
                variant={"outlined"}
                disabled={!appState.section}
            >
                Cancel
            </Button>
        );
    };

    const onSectionSelect = (section) => selectSection(section);

    return (
        <Dialog onClose={() => {}} aria-labelledby="customized-dialog-title" open={true}>
            <DialogContent className={appSectionInitSelectorStyles.Main} dividers>
                {appState.sections.map((section) =>
                    section.renderInitCard(onSectionSelect, appState.section)
                )}
            </DialogContent>
            <DialogActions className={appSectionInitSelectorStyles.Actions}>
                {SignOutButton()}
                {CancelButton(appState)}
            </DialogActions>
        </Dialog>
    );
};

export default SectionInitSelector;
