import IconButton from "@material-ui/core/IconButton";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useContext } from "react";
import { MdCheck, MdClear } from "react-icons/md";
import { useGlobalSpinnerActionsContext } from "../../../../../components/global-spinner/GlobalSpinnerContext";
import { DataEditorContext } from "../../../context/DataEditorContext";
import dataEditorStyles from "../../../DataEditor.module.css";
import DataEditorUtils from "../../../utils/DataEditorUtils";
import { DataEditorItemModeParams } from "./DataEditorItem";

const DataEditorItemEdit = ({ item, index, setIsEditing }: DataEditorItemModeParams) => {
    const setGlobalSpinner = useGlobalSpinnerActionsContext();
    const { dataEditorState, updateItem: replaceItem } = useContext(DataEditorContext);

    const onEditCancel = () => setIsEditing(false);

    const handleEditSubmit = async (fmkProps: FormikProps<any>) => {
        try {
            setGlobalSpinner(true);

            item.data = await item.getModel().api.update(fmkProps.values);

            onEditCancel();

            replaceItem(item);
        } catch (e: any) {
            throw Error(e.message);
        } finally {
            setGlobalSpinner(false);
        }
    };

    const onSubmit = (values: any, { setSubmitting }: FormikHelpers<any>) => {
        handleEditSubmit(values).then(() => setSubmitting(false));
    };

    const validator = (data: any) => {
        const columns = item.getModel().config.columns;
        return item.getModel().editor.validator(columns, data);
    };

    return (
        <Formik
            enableReinitialize={true}
            validate={validator}
            handleSubmit={handleEditSubmit}
            onSubmit={onSubmit}
            initialValues={item.data}
        >
            {(fmkProps) => {
                const displayColumns = item
                    .getModel()
                    .getColumns()
                    .filter(({ hide }) => !hide);
                const columnStyles = DataEditorUtils.getColumnStyles(displayColumns);

                return (
                    <form
                        onSubmit={fmkProps.handleSubmit}
                        className={dataEditorStyles.Row}
                        key={`item-${index}`}
                    >
                        <div className={dataEditorStyles.ItemActions}>
                            <IconButton
                                type="submit"
                                disabled={fmkProps.isSubmitting}
                                size={"small"}
                                onClick={() => handleEditSubmit(fmkProps)}
                            >
                                <MdCheck />
                            </IconButton>

                            <IconButton
                                onClick={onEditCancel}
                                size={"small"}
                                disabled={fmkProps.isSubmitting}
                            >
                                <MdClear />
                            </IconButton>
                        </div>

                        <div className={dataEditorStyles.ItemContent} style={columnStyles}>
                            {displayColumns.map((column) => {
                                // TODO: make all columns editable
                                //  - handle key changes with delete + add
                                //  - handle cascade updates

                                if (!column.editable) {
                                    const key = `${column.name}-${index}`;
                                    const value = item.getDisplay()[column.field];

                                    return column.renderDisplay(key, value);
                                }

                                return column.prepRenderEdit(index, fmkProps, dataEditorState);
                            })}
                        </div>
                    </form>
                );
            }}
        </Formik>
    );
};

export default DataEditorItemEdit;
