import { ADMINS, DATA_EDITOR_USERS, DEVS, MANAGERS } from "../../services/AppUserGroupsData";
import ContainerConfigBuilder from "../ContainerConfigBuilder";
import { ContainerPaths } from "../ContainerPaths";
import { ContainerConfigSection } from "./../ContainerConfigSection";
import DataEditorOffSite from "./data-editor/DataEditorOffSite";
import DataEditorOffSiteMenuItem from "./data-editor/DataEditorOffSiteMenuItem";
import OffSiteDelivery from "./delivery/OffSiteDelivery";
import OffSiteDeliveryMenuItem from "./delivery/OffSiteDeliveryMenuItem";
import OffSiteDeliveryTasks from "./delivery/OffSiteDeliveryTasks";
import OffSiteContainerConfigBuilder from "./OffSiteContainerConfigBuilder";
import OffSiteReception from "./reception/OffSiteReception";
import OffSiteReceptionMenuItem from "./reception/OffSiteReceptionMenuItem";
import OffSiteReceptionTasks from "./reception/OffSiteReceptionTasks";
import OffSiteReordersMenuItem from "./reorders/OffSiteReordersMenuItem";
import OffSiteReordersTasks from "./reorders/OffSiteReordersTasks";
import OffSiteReordersWrp from "./reorders/OffSiteReordersWrp";
import OffSiteStocking from "./stocking/OffSiteStocking";
import OffSiteStockingMenuItem from "./stocking/OffSiteStockingMenuItem";
import OffSiteStockingTasks from "./stocking/OffSiteStockingTasks";

export const OffSiteContainerConfig: ContainerConfigSection = {
    Delivery: new OffSiteContainerConfigBuilder("Delivery")
        .setPath(ContainerPaths.DELIVERY)
        .setUserAccess([MANAGERS, ADMINS, DEVS])
        .setComponent(OffSiteDelivery)
        .setMenuItem(OffSiteDeliveryMenuItem)
        .setTasksHeaderComponent(OffSiteDeliveryTasks)
        .build(),
    Reception: new OffSiteContainerConfigBuilder("Receiving")
        .setPath(ContainerPaths.RECEIVING)
        .setUserAccess([MANAGERS, ADMINS, DEVS])
        .setComponent(OffSiteReception)
        .setMenuItem(OffSiteReceptionMenuItem)
        .setTasksHeaderComponent(OffSiteReceptionTasks)
        .build(),
    Stocking: new OffSiteContainerConfigBuilder("Stocking")
        .setPath(ContainerPaths.STOCKING)
        .setUserAccess([MANAGERS, ADMINS, DEVS])
        .setComponent(OffSiteStocking)
        .setMenuItem(OffSiteStockingMenuItem)
        .setTasksHeaderComponent(OffSiteStockingTasks)
        .build(),
    Reorders: new OffSiteContainerConfigBuilder("Reorders")
        .setPath(ContainerPaths.REORDERS)
        .setUserAccess([MANAGERS, ADMINS, DEVS])
        .setComponent(OffSiteReordersWrp)
        .setMenuItem(OffSiteReordersMenuItem)
        .setTasksHeaderComponent(OffSiteReordersTasks)
        .build(),
    DataEditor: new ContainerConfigBuilder("Editor")
        .setPath(ContainerPaths.EDITOR)
        .setUserAccess([DATA_EDITOR_USERS, ADMINS])
        .setComponent(DataEditorOffSite)
        .setMenuItem(DataEditorOffSiteMenuItem)
        .build(),
};
