import { useContext, useEffect } from "react";
import deliveryFoodStyles from "../../DeliveryFood.module.css";
import { DeliveryFoodContext } from "../../DeliveryFoodContext";
import DeliveryFoodHeader from "../header/DeliveryFoodHeader";
import DeliveryFoodSummary from "../summary/DeliveryFoodSummary";
import DeliveryFoodToolbar from "../toolbar/DeliveryFoodToolbar";
import DeliveryFoodItem from "./DeliveryFoodItem";
import DeliveryFoodSections from "./DeliveryFoodSections";

const DeliveryFoodContent = () => {
    const { deliveryFoodState } = useContext(DeliveryFoodContext);

    useEffect(() => {}, [
        deliveryFoodState.ingredients,
        deliveryFoodState.modes,
        deliveryFoodState.mode,
    ]);

    // Apply mode-ingredientFilter
    const { ingredients, mode } = deliveryFoodState;
    const displayedIngredients = ingredients.filter(mode.ingredientsFilter.bind(null, mode));

    const DeliveryFoodContentMain = () => {
        if (deliveryFoodState.mode.restaurant) {
            // display section by section
            return <DeliveryFoodSections key={"delivery-food-delivery-sections"} />;
        }

        // display ingredient by ingredient
        return (
            <div key={"delivery-food-items"} className={deliveryFoodStyles.Content}>
                {displayedIngredients.map(DeliveryFoodItem)}
            </div>
        );
    };

    return (
        <div className={deliveryFoodStyles.Main}>
            <DeliveryFoodToolbar />
            <DeliveryFoodHeader />
            <DeliveryFoodContentMain />
            {deliveryFoodState.mode ? <DeliveryFoodSummary /> : <div />}
        </div>
    );
};

export default DeliveryFoodContent;
