import React from "react";
import { DeliveryFoodSummaryConfigsType } from "../../../types/DeliveryFoodTypes";
import { DeliveryFoodSummaryDeliveryConfigs } from "./DeliveryFoodSummaryDeliveryConfigs";
import { DeliveryFoodSummaryDeliveryFixedConfigs } from "./DeliveryFoodSummaryDeliveryFixedConfigs";
import { DeliveryFoodSummaryDeliverySalesConfigs } from "./DeliveryFoodSummaryDeliverySalesConfigs";
import { DeliveryFoodSummaryOnHandConfigs } from "./DeliveryFoodSummaryOnHandConfigs";
import { DeliveryFoodSummaryPrepConfigs } from "./DeliveryFoodSummaryPrepConfigs";
import { DeliveryFoodSummaryPrepForDeliveryConfigs } from "./DeliveryFoodSummaryPrepForDeliveryConfigs";
import { DeliveryFoodSummaryPrepForDeliveryTimeConfigs } from "./DeliveryFoodSummaryPrepForDeliveryTimeConfigs";
import { DeliveryFoodSummaryPrepTimeConfigs } from "./DeliveryFoodSummaryPrepTimeConfigs";
import { DeliveryFoodSummarySalesConfigs } from "./DeliveryFoodSummarySalesConfigs";

const DeliveryFoodSummaryConfigs: DeliveryFoodSummaryConfigsType = {
    onHand: DeliveryFoodSummaryOnHandConfigs,
    sales: DeliveryFoodSummarySalesConfigs,
    delivery: DeliveryFoodSummaryDeliveryConfigs,
    deliveryFixed: DeliveryFoodSummaryDeliveryFixedConfigs,
    deliverySales: DeliveryFoodSummaryDeliverySalesConfigs,
    prepForDelivery: DeliveryFoodSummaryPrepForDeliveryConfigs,
    prep: DeliveryFoodSummaryPrepConfigs,
    prepTime: DeliveryFoodSummaryPrepTimeConfigs,
    prepForDeliveryTime: DeliveryFoodSummaryPrepForDeliveryTimeConfigs,
};

export default DeliveryFoodSummaryConfigs;
