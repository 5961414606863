import React from "react";
import deliveryOrdersStyles from "../../DeliveryOrders.module.css";
import DeliveryOrdersUtils from "../../DeliveryOrdersUtils";

const DeliveryOrdersCellConversion = ({ order }) => {
    const conversion = DeliveryOrdersUtils.extractConversion(order);
    const onHandUnit = order.onHandUnit.data.name;
    const deliveryUnit = order.deliveryUnit.data.name;

    if (onHandUnit === deliveryUnit) {
        return <span className={`no-print ${deliveryOrdersStyles.Explained}`} />;
    }
    return (
        <span className={`no-print ${deliveryOrdersStyles.Explained}`}>
            {conversion} x {onHandUnit} = 1 x {deliveryUnit}
        </span>
    );
};

export default DeliveryOrdersCellConversion;
