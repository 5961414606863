import React from "react";
import { MdAttachMoney } from "react-icons/md";

function MenusMenuItem(name: string) {
    return [
        <MdAttachMoney key={`${name}-icon`} size={"1.5em"} />,
        <div key={`${name}-text`}>
            <span>{name}</span>
        </div>,
    ];
}

export default MenusMenuItem;
