import { Button } from "@material-ui/core";
import { useContext, useEffect } from "react";
import { useGlobalSpinnerActionsContext } from "../../../../components/global-spinner/GlobalSpinnerContext";
import { OnHandContext } from "../../../on-hand/context/OnHandContext";
import { OffSiteContext } from "../../context/OffSiteContext";
import OffSiteTasksUtils from "../../tasks/OffSiteTasksUtils";

export default function OffSiteDeliveryItemSubmit() {
    const { offSiteState, goToNextTask } = useContext(OffSiteContext);
    const { onHandState, lockSubmission, unlockSubmission, submitSelectedDeliveryItem } =
        useContext(OnHandContext) as any;
    const setGlobalSpinner = useGlobalSpinnerActionsContext();

    useEffect(() => {}, [onHandState?.restaurant?.group?.item]);

    if (!offSiteState.offSiteTasksByStatusAndIdAndRest) {
        return null;
    }

    const isNotTheExactlyRequiredValue = !OffSiteTasksUtils.hasExactRequiredValue(
        offSiteState.offSiteTasksByStatusAndIdAndRest,
        onHandState.restaurant.group.item
    );

    const isLockedSubmit = !!onHandState?.lockedSubmit;
    const disabledSubmit = isNotTheExactlyRequiredValue || isLockedSubmit;

    const handleSubmit = async () => {
        try {
            lockSubmission();
            setGlobalSpinner(true);

            await submitSelectedDeliveryItem(
                offSiteState.offSiteTasksByStatusAndIdAndRest,
                onHandState.restaurant?.group?.item,
                goToNextTask,
                offSiteState.taskSelectedKey
            );
            // TODO: update in offsite context too
        } catch (e) {
            throw Error("Failed to submit transaction");
        } finally {
            setGlobalSpinner(false);
            unlockSubmission();
        }
    };

    return (
        <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            disabled={disabledSubmit}
        >
            Submit
        </Button>
    );
}
