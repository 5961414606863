import { Resource } from "./../resources/Resource";
import { ResourceColumn } from "../resources/ResourceColumn";
import { ModelConfig } from "./ModelConfig";

class ModelEditor {
    public isEditable = true;
    public isDeletable = true;

    getSortCallback(sortColumn: ResourceColumn) {
        return (a: Resource, b: Resource): number => {
            if (sortColumn.defaultSortDirection === "DESC") {
                [a, b] = [b, a];
            }
            return sortColumn.sortCallback(a.data[sortColumn.field], b.data[sortColumn.field]);
        };
    }

    sort(items: Resource[] = []): Resource[] {
        const sortableColumns = this.config.columns.filter(
            ({ defaultSortDirection: sortKey }) => typeof sortKey === "string"
        );
        const columnsBySortRank = sortableColumns.sort((a, b) => {
            if (!!a.defaultSortRank && !!b.defaultSortRank) {
                return b?.defaultSortRank - a?.defaultSortRank;
            }
            return 0;
        });
        const sortCallbacks = columnsBySortRank.map(this.getSortCallback);

        return items.sort((a, b) => {
            return sortCallbacks.reduce((acc, sortCallback) => {
                return acc || sortCallback(a, b);
            }, 0);
        });
    }

    getNewItemTemplate(): { [key: string]: any } {
        return Object.fromEntries(
            this.config.columns.map(({ field, defaultValue }) => [field, defaultValue])
        );
    }

    validator(columns: ResourceColumn[], item: any): any {
        return columns
            .filter(({ isRequired }) => isRequired)
            .reduce((acc: any, current: ResourceColumn) => {
                if (item[current.field] === null || item[current.field] === undefined) {
                    acc[current.field] = "Required";
                } else if (!current.validator(item[current.field])) {
                    acc[current.field] = "Invalid";
                }
                return acc;
            }, {});
    }

    equals(itemA: Resource, itemB: Resource): boolean {
        return areNotEqualOnFields(
            itemA,
            itemB,
            this.config.columns.filter(({ isKey }) => isKey).map(({ field }) => field)
        );

        /* ---------- IMPL ---------- */
        function areNotEqualOnFields(oldItem: Resource, newItem: Resource, fields: string[]) {
            return fields.reduce((acc, field) => {
                return acc && areNotEqualOnField(oldItem, newItem, field);
            }, true);
        }

        function areNotEqualOnField(oldItem: Resource, newItem: Resource, field: string) {
            return oldItem.data[field] !== newItem.data[field];
        }
    }

    constructor(public config: ModelConfig) {}
}

export default ModelEditor;
