import { Button } from "@material-ui/core";
import React from "react";
import styles from "./OnHandItemClearInput.module.css";

export default function OnHandItemClearInput({ handleReset }) {
    return (
        <div className={styles.Main}>
            <Button onClick={handleReset} size={"small"} variant="contained" color={"primary"}>
                <span>Clear</span>
            </Button>
        </div>
    );
}
