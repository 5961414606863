import moment from "moment";
import { OffSiteReordersSnoozedDurationType } from "./OffSiteReordersSnoozedDurationType";

const ONE_WEEK: OffSiteReordersSnoozedDurationType = {
    display: "1 week",
    getMoment: () => {
        return moment().tz("America/New_York").add(7, "days");
    },
};

const ONE_MONTH: OffSiteReordersSnoozedDurationType = {
    display: "1 month",
    getMoment: () => {
        return moment().tz("America/New_York").add(1, "months");
    },
};

const THREE_MONTHS: OffSiteReordersSnoozedDurationType = {
    display: "3 months",
    getMoment: () => {
        return moment().tz("America/New_York").add(3, "months");
    },
};

const ALL_DURATIONS: OffSiteReordersSnoozedDurationType[] = [ONE_WEEK, ONE_MONTH, THREE_MONTHS];

export { ONE_WEEK, ONE_MONTH, THREE_MONTHS, ALL_DURATIONS as ALL_REORDERS_DURATIONS };
