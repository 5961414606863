import React from "react";
import { BiFridge } from "react-icons/bi";
import {
    ADMINS,
    DEVS,
    KITCHEN_USERS,
    MANAGERS,
    UserGroupType,
} from "../../services/AppUserGroupsData";
import ContainerBasePaths from "../ContainerBasePaths";
import { ContainerConfig } from "../ContainerConfig";
import { AppHomeContainerConfig } from "../home/AppHomeContainerConfig";
import { MenusContainerConfig } from "../menus-sales/MenusContainerConfig";
import { Section } from "../sections/Section";
import { DpsContainerConfig } from "./DpsContainerConfig";

export class DpsSection extends Section {
    renderIcon(disabled: boolean) {
        return <BiFridge size={this.iconSize} color={"inherit"} />;
    }

    renderHeaderIcon() {
        return <BiFridge size={this.iconHeaderSize} color={this.iconHeaderColor} />;
    }

    renderHomeIcon() {
        return <BiFridge size={this.iconHomeSize} color={this.iconHomeColor} />;
    }

    getDefaultPath(userGroups: UserGroupType[]) {
        if (userGroups.includes(DEVS)) {
            return DpsContainerConfig.DataEditor.url;
        }

        if (userGroups.includes(ADMINS) || userGroups.includes(MANAGERS)) {
            return MenusContainerConfig.url;
        }

        if (userGroups.includes(KITCHEN_USERS)) {
            return AppHomeContainerConfig.url;
        }

        console.error("Couldn't match the user to any known section pages", userGroups);
        return super.getDefaultPath(userGroups);
    }

    constructor(
        pages: ContainerConfig[],
        name: string,
        allowedGroups: UserGroupType[],
        disabled: boolean
    ) {
        super(pages, name, allowedGroups, disabled, ContainerBasePaths.DPS);
    }
}
