import { AppRestaurant } from "../../../admin/AdminResources";
import {
    DeliveryFoodModeDisplay,
    DeliveryFoodModeDisplayCommissary,
    DeliveryFoodModeDisplayRestaurantById,
    DeliveryFoodModeRootData,
} from "../types/DeliveryFoodModeTypes";

function buildModeDisplay(restaurants: AppRestaurant[]): DeliveryFoodModeDisplay {
    const COM: DeliveryFoodModeDisplayCommissary = {
        prep: false,
        onHand: false,
        prepForDelivery: false,
        prepTime: false,
        prepForDeliveryTime: false,
    };

    const RST: DeliveryFoodModeDisplayRestaurantById = restaurants.reduce(
        (acc: DeliveryFoodModeDisplayRestaurantById, restaurant: AppRestaurant) => {
            if (!restaurant.data.id) {
                return acc;
            }

            acc[restaurant.data.id] = {
                delivery: false,
                deliveryFixed: false,
                deliverySales: false,
                onHand: false,
                sales: {
                    SINGLE: false,
                    MULTIPLE: false,
                },
            };
            return acc;
        },
        {}
    );

    return {
        COM,
        RST,
    };
}

function buildDeliveryModeDisplay(restaurants: AppRestaurant[]): DeliveryFoodModeDisplay {
    const COM: DeliveryFoodModeDisplayCommissary = {
        prep: false,
        onHand: false,
        prepForDelivery: true,
        prepTime: false,
        prepForDeliveryTime: false,
    };

    const RST: DeliveryFoodModeDisplayRestaurantById = restaurants.reduce(
        (acc: DeliveryFoodModeDisplayRestaurantById, restaurant: AppRestaurant) => {
            if (!restaurant?.data?.id) {
                return acc;
            }

            acc[restaurant.data.id] = {
                delivery: true,
                deliveryFixed: false,
                deliverySales: false,
                onHand: false,
                sales: {
                    SINGLE: false,
                    MULTIPLE: false,
                },
            };
            return acc;
        },
        {}
    );

    return {
        COM,
        RST,
    };
}

function buildDeliveryFixedModeDisplay(restaurants: AppRestaurant[]): DeliveryFoodModeDisplay {
    const COM: DeliveryFoodModeDisplayCommissary = {
        prep: false,
        onHand: false,
        prepForDelivery: true,
        prepTime: false,
        prepForDeliveryTime: false,
    };

    const RST: DeliveryFoodModeDisplayRestaurantById = restaurants.reduce(
        (acc: DeliveryFoodModeDisplayRestaurantById, restaurant: AppRestaurant) => {
            if (!restaurant?.data?.id) {
                return acc;
            }

            acc[restaurant.data.id] = {
                delivery: false,
                deliveryFixed: true,
                deliverySales: false,
                onHand: false,
                sales: {
                    SINGLE: false,
                    MULTIPLE: false,
                },
            };
            return acc;
        },
        {}
    );

    return {
        COM,
        RST,
    };
}

function buildDeliverySalesModeDisplay(restaurants: AppRestaurant[]): DeliveryFoodModeDisplay {
    const COM: DeliveryFoodModeDisplayCommissary = {
        prep: false,
        onHand: false,
        prepForDelivery: true,
        prepTime: false,
        prepForDeliveryTime: false,
    };

    const RST: DeliveryFoodModeDisplayRestaurantById = restaurants.reduce(
        (acc: DeliveryFoodModeDisplayRestaurantById, restaurant: AppRestaurant) => {
            if (!restaurant?.data?.id) {
                return acc;
            }

            acc[restaurant.data.id] = {
                delivery: false,
                deliveryFixed: false,
                deliverySales: true,
                onHand: false,
                sales: {
                    SINGLE: false,
                    MULTIPLE: false,
                },
            };
            return acc;
        },
        {}
    );

    return {
        COM,
        RST,
    };
}

function buildPrepForDeliveryModeDisplay(restaurants: AppRestaurant[]): DeliveryFoodModeDisplay {
    const COM: DeliveryFoodModeDisplayCommissary = {
        prep: false,
        onHand: false,
        prepForDelivery: true,
        prepTime: false,
        prepForDeliveryTime: true,
    };

    const RST: DeliveryFoodModeDisplayRestaurantById = restaurants.reduce(
        (acc: DeliveryFoodModeDisplayRestaurantById, restaurant: AppRestaurant) => {
            if (!restaurant?.data?.id) {
                return acc;
            }

            acc[restaurant.data.id] = {
                delivery: false,
                deliveryFixed: false,
                deliverySales: false,
                onHand: false,
                sales: {
                    SINGLE: false,
                    MULTIPLE: false,
                },
            };
            return acc;
        },
        {}
    );

    return {
        COM,
        RST,
    };
}

function buildPrepModeDisplay(restaurants: AppRestaurant[]): DeliveryFoodModeDisplay {
    const COM: DeliveryFoodModeDisplayCommissary = {
        prep: true,
        onHand: false,
        prepForDelivery: false,
        prepTime: false,
        prepForDeliveryTime: false,
    };

    const RST: DeliveryFoodModeDisplayRestaurantById = restaurants.reduce(
        (acc: DeliveryFoodModeDisplayRestaurantById, restaurant: AppRestaurant) => {
            if (!restaurant?.data?.id) {
                return acc;
            }

            acc[restaurant.data.id] = {
                delivery: false,
                deliveryFixed: false,
                deliverySales: false,
                onHand: false,
                sales: {
                    SINGLE: false,
                    MULTIPLE: false,
                },
            };
            return acc;
        },
        {}
    );

    return {
        COM,
        RST,
    };
}

function buildPrepTimesModeDisplay(restaurants: AppRestaurant[]): DeliveryFoodModeDisplay {
    const COM: DeliveryFoodModeDisplayCommissary = {
        prep: true,
        onHand: false,
        prepForDelivery: false,
        prepTime: true,
        prepForDeliveryTime: false,
    };

    const RST: DeliveryFoodModeDisplayRestaurantById = restaurants.reduce(
        (acc: DeliveryFoodModeDisplayRestaurantById, restaurant: AppRestaurant) => {
            if (!restaurant?.data?.id) {
                return acc;
            }

            acc[restaurant.data.id] = {
                delivery: false,
                deliveryFixed: false,
                deliverySales: false,
                onHand: false,
                sales: {
                    SINGLE: false,
                    MULTIPLE: false,
                },
            };
            return acc;
        },
        {}
    );

    return {
        COM,
        RST,
    };
}

function buildSummaryModeDisplay(restaurants: AppRestaurant[]): DeliveryFoodModeDisplay {
    const COM: DeliveryFoodModeDisplayCommissary = {
        prep: true,
        onHand: true,
        prepForDelivery: true,
        prepTime: false,
        prepForDeliveryTime: false,
    };

    const RST: DeliveryFoodModeDisplayRestaurantById = restaurants.reduce(
        (acc: DeliveryFoodModeDisplayRestaurantById, restaurant: AppRestaurant) => {
            if (!restaurant?.data?.id) {
                return acc;
            }

            acc[restaurant.data.id] = {
                delivery: true,
                deliveryFixed: true,
                deliverySales: true,
                onHand: true,
                sales: {
                    SINGLE: true,
                    MULTIPLE: true,
                },
            };
            return acc;
        },
        {}
    );

    return {
        COM,
        RST,
    };
}

function buildRestaurantModeDisplay(rootData: DeliveryFoodModeRootData): DeliveryFoodModeDisplay {
    const COM: DeliveryFoodModeDisplayCommissary = {
        prep: false,
        onHand: false,
        prepForDelivery: false,
        prepTime: false,
        prepForDeliveryTime: false,
    };

    const RST: DeliveryFoodModeDisplayRestaurantById = rootData.restaurants.reduce(
        (acc: DeliveryFoodModeDisplayRestaurantById, restaurant: AppRestaurant) => {
            const isRestaurant = rootData.restaurant?.data.id === restaurant.data.id;

            if (!restaurant?.data?.id) {
                return acc;
            }

            acc[restaurant.data.id] = {
                delivery: isRestaurant,
                deliveryFixed: isRestaurant,
                deliverySales: isRestaurant,
                onHand: false,
                sales: {
                    SINGLE: false,
                    MULTIPLE: false,
                },
            };

            return acc;
        },
        {}
    );

    return {
        COM,
        RST,
    };
}

const DeliveryFoodModeUtils = {
    buildModeDisplay,
    buildDeliveryModeDisplay,
    buildDeliveryFixedModeDisplay,
    buildDeliverySalesModeDisplay,
    buildPrepForDeliveryModeDisplay,
    buildPrepModeDisplay,
    buildPrepTimesModeDisplay,
    buildSummaryModeDisplay,
    buildRestaurantModeDisplay,
};

export default DeliveryFoodModeUtils;
