import { fetchResources, readSectionS3Files } from "../../../resources/ResourcesService";
import AppUtilsService from "../../../services/AppUtilsService";
import OnHandUtils from "../OnHandUtils";

export default class OnHandDailyGenerator {
    static createMock(inventoryResource, refItemKey, day, id) {
        const newInventory = new inventoryResource({ [refItemKey]: id, day: day.data.time });
        newInventory.mock = true;
        return newInventory;
    }

    static async listMeta(inventoryMetaResource, day, useMocks = false) {
        const dayFilter = { day: { eq: day.data.time } };
        const [inventoriesMeta] = await fetchResources(
            [[inventoryMetaResource, dayFilter]],
            useMocks
        );
        return inventoriesMeta;
    }

    async createInventories(restaurants, items, day, userName, sectionName, refItemKey) {
        const storageKeys = restaurants
            .map((r) => r.inventoryMeta?.data?.storageKey)
            .filter((x) => x);
        const localInventories = restaurants
            .map((restaurant) =>
                OnHandUtils.getLocalItems(
                    OnHandUtils.getLocalStorageConfig(
                        sectionName,
                        restaurant.data.alias,
                        day.data.time
                    )
                )
            )
            .flat()
            .map((localInventory) => new this.inventoryResource(localInventory))
            .map((localInventory) => {
                localInventory.dirty = true;
                return localInventory;
            });
        const localInventoriesByItemId = localInventories.reduce(
            AppUtilsService.groupDataByItem(refItemKey),
            {}
        );

        // STEP 1: Read daily data from S3
        const s3Inventories = await readSectionS3Files(storageKeys, this.inventoryResource);

        const s3InventoriesByItemId = s3Inventories.reduce(
            AppUtilsService.groupDataByItem(refItemKey),
            {}
        );

        const inventoriesByItemId = Object.assign(localInventoriesByItemId, s3InventoriesByItemId);
        const inventories = Object.values(inventoriesByItemId);

        const idsOfItemsWithoutInventory = items
            .map((item) => (inventoriesByItemId[item.data.id] ? null : item.data.id))
            .filter((x) => x);

        const inventoryMocks = idsOfItemsWithoutInventory.map(
            OnHandDailyGenerator.createMock.bind(null, this.inventoryResource, this.refItemKey, day)
        );

        return inventories.concat(inventoryMocks);
    }

    async loadInventories(restaurants, items, day, userName, sectionName) {
        return await this.createInventories(
            restaurants,
            items,
            day,
            userName,
            sectionName,
            this.refItemKey
        );
    }

    constructor(inventoryMetaResource, inventoryResource, refItemKey) {
        this.inventoryMetaResource = inventoryMetaResource;
        this.inventoryResource = inventoryResource;
        this.refItemKey = refItemKey;
    }
}
