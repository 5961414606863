import React, { useContext, useEffect } from "react";
import menusSalesDropZoneStylesWrp from "./MenusSalesDropZoneWrp.module.css";
import MenusSalesDropZone from "./MenusSalesDropZone";
import MenuSalesParser from "./parsers/MenuSalesParser";
import { MenusContext } from "../../context/MenusContext";
import { AppContext } from "../../../../context/AppContext";
import { useGlobalSpinnerActionsContext } from "../../../../components/global-spinner/GlobalSpinnerContext";
import AppContextValidator from "../../../../context/AppContextValidator";
import MenusSalesNameDescriptor from "../MenusSalesNameDescriptor";

const MenusSalesDropZoneWrp = ({ salesConfigs }) => {
    const { appState } = useContext(AppContext);
    const setGlobalSpinner = useGlobalSpinnerActionsContext();
    const { menusState, salesUpload } = useContext(MenusContext);

    useEffect(() => {}, [menusState.restaurant]);

    if (!(menusState.restaurant && menusState.restaurant.data)) {
        console.info("MenusSalesDropZoneWrp: Invalid restaurant");
        return <div />;
    }

    if (!AppContextValidator.isValid(appState)) {
        console.info("MenusSalesDropZoneWrp: Invalid appState");
        return <div />;
    }

    const handleSalesUpload = (saleType, data) => {
        return salesUpload(
            appState.section.name,
            data,
            menusState.restaurant,
            saleType,
            appState.day,
            appState.user.name,
            setGlobalSpinner
        );
    };

    const getContent = (salesConfig) => {
        const { saleType, keyPrefix, action, fileNameSuffix } = salesConfig;

        const restaurantAlias = menusState.restaurant.data.alias;
        const restaurantInitial = menusState.restaurant.data.alias[0].toLowerCase();
        const requiredFileName = `${restaurantInitial}${fileNameSuffix}.csv`;
        const name = (
            <MenusSalesNameDescriptor
                alias={restaurantAlias}
                salesType={saleType}
                fileName={requiredFileName}
            />
        );

        return (
            <MenusSalesDropZone
                key={`${keyPrefix} Days Sales`}
                name={name}
                requiredFileName={requiredFileName}
                action={action}
                onUpdate={handleSalesUpload.bind(null, saleType)}
                onLoad={MenuSalesParser}
            />
        );
    };

    return (
        <div className={menusSalesDropZoneStylesWrp.menusUploads}>
            <div className={menusSalesDropZoneStylesWrp.menusUploadsDropZones}>
                {salesConfigs.map(getContent)}
            </div>
        </div>
    );
};

export default MenusSalesDropZoneWrp;
