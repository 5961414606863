import React, { useContext, useEffect } from "react";
import { OnHandContext } from "../../../on-hand/context/OnHandContext";
import styles from "./OffSiteStockingItem.module.css";
import OffSiteStockingItemActions from "./OffSiteStockingItemActions";
import OffSiteStockingItemInput from "./OffSiteStockingItemInput";

export default function OffSiteStockingItem() {
    const { onHandState } = useContext(OnHandContext) as any;

    const key = `on-hand-off-site-delivery-item-${onHandState.restaurant?.item?.data?.id}`;

    useEffect(() => {}, []);

    return (
        <div key={key} className={styles.Main}>
            <OffSiteStockingItemInput />
            <OffSiteStockingItemActions />
        </div>
    );
}
