import { FormikProps } from "formik";
import React from "react";
import AppDayModel from "../../components/day/AppDayModel";
import DataEditorCellDisplay from "../../containers/data-editor/components/content/item/cell/DataEditorCellDisplay";
import { ResourceColumn } from "../ResourceColumn";

export class ResourceColumnDate extends ResourceColumn {
    constructor(
        public field: string,
        public name: string,
        public format: string = "h:mm:ss A, MMMM Do"
    ) {
        super(field, name, "date");
    }

    prepRenderEdit(index: number, fmkProps: FormikProps<any>) {
        const { touched, errors, isSubmitting } = fmkProps;
        // TODO: add some date-related display
        const content = <div>...</div>;

        return super.renderEdit(index, this.field, content, errors, touched, isSubmitting);
    }

    renderDisplay(key: string, value: any) {
        return (
            <DataEditorCellDisplay
                key={key}
                value={AppDayModel.getTimeDisplay(value, this.format)}
            />
        );
    }
}
