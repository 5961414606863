import React from "react";
import DeliveryOrdersUtils from "../../DeliveryOrdersUtils";
import deliveryOrdersStyles from "../../DeliveryOrders.module.css";
import DeliveryOrdersCellName from "../../item/cell/DeliveryOrdersCellName";
import DeliveryOrdersCellDelivery from "../../item/cell/DeliveryOrdersCellDelivery";

const DeliveryOrdersVendorItem = (order) => {
    // MISSING ON HAND
    if (!DeliveryOrdersUtils.filterForValidDelivery(order)) {
        return null;
    }

    // NEEDS DELIVERY
    return (
        <div className={`${deliveryOrdersStyles.Row} ${deliveryOrdersStyles.VendorRow}`}>
            <DeliveryOrdersCellName order={order} />
            <DeliveryOrdersCellDelivery order={order} />
        </div>
    );
};

export default DeliveryOrdersVendorItem;
