import { ContainerConfigSection } from "./../ContainerConfigSection";
import ContainerConfigBuilder from "../ContainerConfigBuilder";
import { ContainerPaths } from "../ContainerPaths";
import {
    ADMINS,
    DATA_EDITOR_USERS,
    KITCHEN_DELIVERY_USERS,
    ORDERS_DELIVERY_USERS,
} from "../../services/AppUserGroupsData";
import DeliveryDps from "./delivery/DeliveryDps";
import DeliveryDpsMenuItem from "./delivery/DeliveryDpsMenuItem";
import DataEditorDps from "./data-editor/DataEditorDps";
import DataEditorDpsMenuItem from "./data-editor/DataEditorDpsMenuItem";

export const DpsContainerConfig: ContainerConfigSection = {
    Delivery: new ContainerConfigBuilder("Pull")
        .setPath(ContainerPaths.PULL)
        .setUserAccess([ORDERS_DELIVERY_USERS, KITCHEN_DELIVERY_USERS, ADMINS])
        .setComponent(DeliveryDps)
        .setMenuItem(DeliveryDpsMenuItem)
        .build(),
    DataEditor: new ContainerConfigBuilder("Editor")
        .setPath(ContainerPaths.EDITOR)
        .setUserAccess([DATA_EDITOR_USERS, ADMINS])
        .setComponent(DataEditorDps)
        .setMenuItem(DataEditorDpsMenuItem)
        .build(),
};
