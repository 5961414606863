import { fetchResources, readSectionS3Files } from "../../../resources/ResourcesService";

import AppUserGroups from "../../../services/AppUserGroups";
import AppUtilsService from "../../../services/AppUtilsService";
import { AppRestaurant } from "../../admin/AdminResources";
import MenusSectionConfigs from "../config/MenusSectionConfigs";

export default async function MenusResolver(
    userGroups,
    day,
    userName,
    sectionName,
    useMocks = false
) {
    const {
        menuItemResource,
        menuItemSaleResource,
        menuItemSaleMetaResource,
        ingredientResource,
        recipeItemResource,
    } = MenusSectionConfigs.getConfig(sectionName).resources;
    const { recipeItemMenuItemFilter, attachIngredientToRecipe, findSales } =
        MenusSectionConfigs.getUtils(sectionName);

    const [appRestaurants] = await fetchResources(
        [[AppRestaurant, { type: { eq: "RESTAURANT" } }]],
        useMocks
    );
    let { restaurants, restaurantFilter } = AppUserGroups.getUserRestaurantWithFilter(
        appRestaurants,
        userGroups
    );

    // TODO: call items only from the remaining restaurants
    let [menuItems, recipeItems, ingredients] = await fetchResources(
        [[menuItemResource, restaurantFilter], [recipeItemResource], [ingredientResource]],
        useMocks
    );

    const dayFilter = { day: { eq: day.data.time } };
    const [currentMetaItems] = await fetchResources(
        [[menuItemSaleMetaResource, dayFilter]],
        useMocks
    );
    const missingMetaItems = await AppUtilsService.buildMissingInventoriesMeta(
        restaurants,
        currentMetaItems,
        menuItemSaleMetaResource,
        day,
        userName,
        sectionName,
        AppUtilsService.createMetaSalesPromise
    );
    const menuItemSalesMeta = currentMetaItems.concat(missingMetaItems);
    const storageKeys = menuItemSalesMeta
        .map(({ data: { storageKey } }) => storageKey)
        .filter((x) => x);
    const menuItemSales = await readSectionS3Files(storageKeys, menuItemSaleResource);

    const ingredientsById = ingredients.reduce(AppUtilsService.groupDataByItem("id"), {});
    const menuItemSalesMetaByRestaurantId = menuItemSalesMeta.reduce(
        AppUtilsService.groupDataByList("restaurantId"),
        {}
    );

    return restaurants.reduce((acc, restaurant) => {
        const restaurantId = restaurant.data.id;
        // ATTACH MENU
        restaurant.menu = menuItems
            .filter((menuItem) => menuItem.data.restaurantId === restaurantId)
            .map((menuItem) => {
                // ATTACH RECIPE
                menuItem.recipe = recipeItems
                    .filter(recipeItemMenuItemFilter.bind(null, menuItem.data.id))
                    .map(attachIngredientToRecipe.bind(null, ingredientsById));

                // ATTACH SALES
                menuItem.sales = findSales(menuItem.data.id, menuItemSales);
                return menuItem;
            });
        acc[restaurantId] = restaurant;
        // ATTACH META
        restaurant.meta = menuItemSalesMetaByRestaurantId[restaurantId].reduce((acc, meta) => {
            acc[meta.data.saleType] = meta;
            return acc;
        }, {});
        return acc;
    }, {});
}
