import { useContext } from "react";
import { DataEditorContext } from "../../context/DataEditorContext";
import dataEditorStyles from "../../DataEditor.module.css";
import DataEditorUtils from "../../utils/DataEditorUtils";

const DataEditorItemsCount = () => {
    const { dataEditorState } = useContext(DataEditorContext);

    if (!dataEditorState.store || !dataEditorState.getSelectedResource()) {
        return <div>?</div>;
    }

    const itemsCount = DataEditorUtils.getSelectedResourceItemsCount(dataEditorState);

    return <div className={dataEditorStyles.ItemsCount}>{`${itemsCount} rows`}</div>;
};

export default DataEditorItemsCount;
