import { useContext, useEffect } from "react";
import { OffSiteContext } from "../context/OffSiteContext";
import { OffSiteInitDataType } from "../context/OffSiteContextTypes";
import OffSiteTasksDialog from "../tasks/dialog/OffSiteTasksDialog";
import styles from "../tasks/OffSiteTasks.module.css";
import OffSiteTasksCounter from "../tasks/OffSiteTasksCounter";
import OffSiteTasksNavigator from "../tasks/OffSiteTasksNavigator";
import OffSiteTasksStart from "../tasks/OffSiteTasksStart";
import OffSiteTasksUtils from "../tasks/OffSiteTasksUtils";

export default function OffSiteDeliveryTasks() {
    const { offSiteState, loadDeliveryTasks } = useContext(OffSiteContext);

    useEffect(() => {
        if (!offSiteState.offSiteTasksByStatusAndIdAndRest) {
            OffSiteTasksUtils.getOffSiteOpenDeliveryTasksDecorated().then(
                (offSiteInitData: OffSiteInitDataType) => {
                    return loadDeliveryTasks(offSiteInitData);
                }
            );
        }
    }, [loadDeliveryTasks, offSiteState.offSiteTasksByStatusAndIdAndRest]);

    return (
        <div className={styles.TasksWrp}>
            <div className={styles.TasksNavigatorWrp}>
                {offSiteState.taskSelectedKey ? <OffSiteTasksNavigator /> : <OffSiteTasksStart />}
            </div>
            <OffSiteTasksCounter />
            <OffSiteTasksDialog />
        </div>
    );
}
