import { DataEditorState } from "../../../containers/data-editor/types/DataEditorTypes";
import { Resource } from "../../Resource";
import { ResourceColumnSelect } from "./ResourceColumnSelect";
import {
    ResourceColumnSelectOption,
    ResourceColumnSelectRawOption,
} from "./ResourceColumnSelectTypes";

function transformRawOptions(
    rawOptions: ResourceColumnSelectRawOption[]
): ResourceColumnSelectOption[] {
    let result: ResourceColumnSelectOption[] = [];

    result = rawOptions.map((rawOption: ResourceColumnSelectRawOption) => {
        if (typeof rawOption === "string") {
            return transformStringToOption(rawOption);
        }

        if (rawOption instanceof Resource) {
            return transformResourceToOption(rawOption);
        }

        return rawOption;
    });

    return result;
}

function getResourceRefKey(
    dataEditorState: DataEditorState,
    column: ResourceColumnSelect
): string | null {
    if (dataEditorState.store.mapping.refKeyToStoreKey[column.field]) {
        return column.field;
    }

    if (
        column.resourceRefKeyAlias &&
        dataEditorState.store.mapping.refKeyToStoreKey[column.resourceRefKeyAlias]
    ) {
        return column.resourceRefKeyAlias;
    }

    return null;
}

function sortAvailableOptions(options: ResourceColumnSelectOption[]): ResourceColumnSelectOption[] {
    return options.sort(
        (optionA: ResourceColumnSelectOption, optionB: ResourceColumnSelectOption) => {
            return optionA.label.localeCompare(optionB.label);
        }
    );
}

function transformStringToOption(item: string): ResourceColumnSelectOption {
    return {
        label: item,
        selectValue: item,
        originalValue: item,
    };
}

function transformResourceToOption(
    item: Resource,
    dataEditorState?: DataEditorState
): ResourceColumnSelectOption {
    return {
        label: item.getModel().refDisplay(item.data, dataEditorState),
        selectValue: item.data.id,
        originalValue: item,
    };
}

function getAvailableOptions(
    dataEditorState: DataEditorState,
    column: ResourceColumnSelect,
    resourceRefKey: string | null
): ResourceColumnSelectOption[] {
    let result: ResourceColumnSelectOption[] = [];

    if (!resourceRefKey) {
        result = column.options;
    } else {
        result = dataEditorState
            .getItemsByStoreKey(dataEditorState.store.mapping.refKeyToStoreKey[resourceRefKey])
            .map((item: Resource) => transformResourceToOption(item, dataEditorState));
    }

    result = sortAvailableOptions(result);

    return result;
}

function getSelectedOption(
    column: ResourceColumnSelect,
    options: ResourceColumnSelectOption[],
    values: any
): ResourceColumnSelectOption | undefined {
    let result: ResourceColumnSelectOption | undefined;

    const selectedOption: ResourceColumnSelectOption | undefined = options.find(
        (option: ResourceColumnSelectOption) => {
            if (option.originalValue instanceof Resource) {
                return option.originalValue.data.id === values[column.field];
            }
            return option.originalValue === values[column.field];
        }
    );

    result = selectedOption;

    return result;
}

const ResourceColumnSelectUtils = {
    transformRawOptions,
    getResourceRefKey,
    getAvailableOptions,
    getSelectedOption,
};

export default ResourceColumnSelectUtils;
