import React, { useContext, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { DeliveryOrdersContext } from "../context/DeliveryOrdersContext";
import DeliveryOrdersEmailDialogSend from "./DeliveryOrdersEmailSendButton";
import deliveryOrdersStyles from "../DeliveryOrders.module.css";
import DeliveryOrdersEmailDialogSubject from "./DeliveryOrdersEmailSubject";
import DeliveryOrdersEmailDialogNotes from "./DeliveryOrdersEmailNotes";
import DeliveryOrdersEmailDialogEditor from "./DeliveryOrdersEmailOrdersEditor";
import DeliveryOrdersEmailDialogAddVendor from "./DeliveryOrdersEmailDialogAddVendor";
import { ADD_NEW_VENDOR_INDICATOR } from "../DeliveryOrdersValues";

export default function DeliveryOrdersEmailDialog() {
    const {
        deliveryOrders,
        closeEmailDialog,
        selectPrevEmailVendor,
        selectNextEmailVendor,
        enableAddNewVendor,
    } = useContext(DeliveryOrdersContext);

    useEffect(() => {}, [
        deliveryOrders.isEmailDialogOpened,
        deliveryOrders.restaurant.emailVendor,
    ]);

    if (!deliveryOrders.isEmailDialogOpened) {
        return null;
    }

    const thereAreNoDeliveryVendors = deliveryOrders.restaurant.deliveryVendors.length === 0;
    const reachedTheEndOfDeliveryVendorsList =
        deliveryOrders.restaurant.emailVendor === ADD_NEW_VENDOR_INDICATOR;

    const showAddNewVendor = thereAreNoDeliveryVendors || reachedTheEndOfDeliveryVendorsList;
    const disableNavigation = thereAreNoDeliveryVendors;

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            onClose={() => {}}
            aria-labelledby="customized-dialog-title"
            open={true}
        >
            <DialogTitle id="customized-dialog-title" onClose={() => {}}>
                <div className={deliveryOrdersStyles.DialogTitleContent}>
                    {showAddNewVendor ? (
                        <div>Add new Vendor</div>
                    ) : (
                        [<DeliveryOrdersEmailDialogSubject />, <DeliveryOrdersEmailDialogSend />]
                    )}
                </div>
            </DialogTitle>
            <DialogContent dividers className={deliveryOrdersStyles.DialogContent}>
                {showAddNewVendor ? (
                    <DeliveryOrdersEmailDialogAddVendor />
                ) : (
                    [<DeliveryOrdersEmailDialogNotes />, <DeliveryOrdersEmailDialogEditor />]
                )}
            </DialogContent>
            <DialogActions className={deliveryOrdersStyles.DialogActions}>
                <Button
                    disabled={disableNavigation}
                    onClick={selectPrevEmailVendor}
                    color="primary"
                    variant="contained"
                >
                    Prev
                </Button>
                <Button autoFocus onClick={closeEmailDialog} color="primary" variant="outlined">
                    Close
                </Button>
                <Button
                    disabled={disableNavigation}
                    onClick={selectNextEmailVendor}
                    color="primary"
                    variant="contained"
                >
                    Next
                </Button>
                <Button onClick={enableAddNewVendor} color="secondary" variant="outlined">
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
}
