import {
    DeliveryFoodSummaryConfigsSubType,
    DeliveryFoodIngredient,
} from "../../../types/DeliveryFoodTypes";

export const DeliveryFoodSummaryPrepForDeliveryConfigs: DeliveryFoodSummaryConfigsSubType = {
    title: ({ ingredient }: { ingredient: DeliveryFoodIngredient }) => {
        return (
            <div>
                Prep for delivery <strong>{ingredient.data.name}</strong>
            </div>
        );
    },
    content: ({
        data,
        total,
        totalDisplay,
    }: {
        data: any;
        total: number | string | null;
        totalDisplay: number | string | null;
    }) => {
        return (
            <div>
                <ul>
                    <li>...</li>
                </ul>
                <div>
                    <strong>Total before rounding</strong>: {total} Bulks
                </div>
                <div>
                    <strong>Total</strong>: {totalDisplay} Bulks
                </div>
            </div>
        );
    },
};
