import { AppStateType } from "./AppContextTypes";

const AppContextInit: AppStateType = {
    user: {
        name: "Test",
        groups: [],
    },
    day: null,
    sections: [],
    section: null,
    showSectionSelector: false,
    showErrors: false,
};

export { AppContextInit };
