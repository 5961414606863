import { Button } from "@material-ui/core";
import { useContext } from "react";
import { useGlobalSpinnerActionsContext } from "../../../../components/global-spinner/GlobalSpinnerContext";
import { AppContext } from "../../../../context/AppContext";
import { AppOffSiteAlertLocalTypes } from "../../OffSiteResources";
import { OffSiteReordersContext } from "../context/OffSiteReordersContext";
import OffSiteReordersUtils from "../OffSiteReordersUtils";
import { OffSiteReordersAlertDisplay } from "../types/OffSiteReordersTypes";

export default function OffSiteReordersUndoButton({
    alert,
}: {
    alert: OffSiteReordersAlertDisplay;
}) {
    const setGlobalSpinner = useGlobalSpinnerActionsContext();
    const { appState } = useContext(AppContext);
    const { undoCompleteAlert } = useContext(OffSiteReordersContext);

    const handleAlertUndoComplete = async () => {
        try {
            setGlobalSpinner(true);
            alert = await OffSiteReordersUtils.undoCompleteAlert(alert, appState.user.name);

            undoCompleteAlert(alert, AppOffSiteAlertLocalTypes.COMPLETED);
        } catch (e) {
            throw Error("Cannot complete alert!");
        } finally {
            setGlobalSpinner(false);
        }
    };

    return (
        <Button onClick={handleAlertUndoComplete} color={"primary"} variant={"contained"}>
            Undo
        </Button>
    );
}
