import React from "react";
import OnHandConfigModel from "../../on-hand/models/OnHandConfigModel";
import OnHandWrp from "../../on-hand/OnHandWrp";
import OnHandOrderItemTab from "./display/OnHandOrderItemTab";
import OnHandOrderItem from "./display/OnHandOrderItem";
import OnHandItemDisplayModel from "../../on-hand/models/OnHandItemDisplayModel";
import OnHandOrdersContextConfig from "./context/OnHandOrdersContextConfig";

const OnHandOrders = ({ history, location }) => {
    const config = new OnHandConfigModel(
        OnHandOrdersContextConfig,
        new OnHandItemDisplayModel(OnHandOrderItemTab, OnHandOrderItem),
        { history, location }
    );

    return <OnHandWrp config={config}>{console.info("RENDER: OnHand Orders")}</OnHandWrp>;
};

export default OnHandOrders;
