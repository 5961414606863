const ALL_VENDOR = {
    data: {
        id: -1,
        name: "All",
        order: -1,
    },
    orders: [],
    deliveryOrders: [],
    isAllVendor(vendor) {
        return vendor?.data?.id === -1;
    },
};

const ADD_NEW_VENDOR_INDICATOR = "ADD_NEW_VENDOR_INDICATOR";

export { ALL_VENDOR, ADD_NEW_VENDOR_INDICATOR };
