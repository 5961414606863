import { Auth } from "aws-amplify";
import { createContext, ReactNode, useCallback, useReducer } from "react";
import AppDayModel from "../components/day/AppDayModel";
import { Section } from "../containers/sections/Section";
import SectionsService from "../containers/sections/SectionsService";
import { AppContextInit } from "./AppContextInit";
import { AppContextReducer } from "./AppContextReducer";
import { AppContextAction, AppContextActionType, AppContextType } from "./AppContextTypes";
import { APP_LOCAL_KEYS } from "./AppLocalKeys";

const AppContext = createContext({} as AppContextType);

function AppContextProvider({ children }: { children: ReactNode }) {
    const [appState, dispatch] = useReducer(AppContextReducer, AppContextInit);

    const initApp = useCallback(async () => {
        const authUser = await Auth.currentAuthenticatedUser();

        const user = {
            name: authUser.username,
            groups: authUser.signInUserSession.idToken.payload["cognito:groups"],
        };

        let day = new AppDayModel();
        let sections = SectionsService.getSectionsFromGroups(user.groups);
        let section = null;
        const showSectionSelector = false;

        // LOAD LOCAL SECTION
        const localSectionName = localStorage.getItem(APP_LOCAL_KEYS.SECTION_NAME);
        const localSections = sections.filter(({ name }) => name === localSectionName);
        if (localSections.length === 1 && !showSectionSelector) {
            section = localSections[0];
        }

        const action: AppContextAction = {
            type: AppContextActionType.INIT_APP,
            payload: { user, day, sections, section, showSectionSelector },
        };

        return dispatch(action);
    }, [dispatch]);

    const signOut = useCallback(() => {
        return Auth.signOut({ global: true })
            .then((data) => console.log(data))
            .catch((err) => console.log(err));
    }, [dispatch]);

    const selectDay = useCallback(
        async (day: AppDayModel) => {
            localStorage.setItem(APP_LOCAL_KEYS.DAY_TIME, String(day.data.time));

            const action: AppContextAction = {
                type: AppContextActionType.SELECT_DAY,
                payload: { day },
            };

            return dispatch(action);
        },
        [dispatch]
    );

    const selectSection = useCallback(
        (section: Section) => {
            if (!section || !Section.isValidSection(section)) {
                console.error("Failed to update context because of invalid section provided!");
                return null;
            }

            localStorage.setItem(APP_LOCAL_KEYS.SECTION_NAME, section.name);

            const action: AppContextAction = {
                type: AppContextActionType.SELECT_SECTION,
                payload: { section, showSectionSelector: false },
            };

            return dispatch(action);
        },
        [dispatch]
    );

    const showSectionSelector = useCallback(() => {
        const action: AppContextAction = {
            type: AppContextActionType.SHOW_SECTION_SELECTOR,
            payload: { showSectionSelector: true },
        };

        return dispatch(action);
    }, [dispatch]);

    const hideSectionSelector = useCallback(() => {
        const action: AppContextAction = {
            type: AppContextActionType.HIDE_SECTION_SELECTOR,
            payload: { showSectionSelector: false },
        };

        return dispatch(action);
    }, [dispatch]);

    const setShowErrors = useCallback(
        (showErrors: boolean) => {
            const action: AppContextAction = {
                type: AppContextActionType.SET_SHOW_ERRORS,
                payload: { showErrors },
            };

            return dispatch(action);
        },
        [dispatch]
    );

    const syncOffSiteTaskSelectedKeyCallback = useCallback(
        (taskSelectedKey: string) => {
            const action: AppContextAction = {
                type: AppContextActionType.SYNC_OFF_SITE_TASK_SELECTED_KEY,
                payload: { offSiteTaskSelectedKey: taskSelectedKey },
            };

            return dispatch(action);
        },
        [dispatch]
    );

    const value: AppContextType = {
        appState,
        initApp,
        signOut,
        selectDay,
        selectSection,
        showSectionSelector,
        hideSectionSelector,
        setShowErrors,
        syncOffSiteTaskSelectedKeyCallback,
    };

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export { AppContext, AppContextProvider };
