import React, { useContext } from "react";
import DeliveryOrdersUtils from "../DeliveryOrdersUtils";
import deliveryOrdersStyles from "../DeliveryOrders.module.css";
import DeliveryOrdersVendorItem from "../header/vendor/DeliveryOrdersVendorItem";
import { DeliveryOrdersContext } from "../context/DeliveryOrdersContext";

const DeliveryOrdersContentVendor = ({ vendor }) => {
    const { changeDisplayVendor } = useContext(DeliveryOrdersContext);

    const hasNoDelivery = vendor.orders.every(
        (order) => !DeliveryOrdersUtils.filterForValidDelivery(order)
    );

    if (hasNoDelivery) {
        return null;
    }

    const handleVendorSelect = () => changeDisplayVendor(vendor);

    return (
        <div className={deliveryOrdersStyles.VendorContent}>
            <h2 className={deliveryOrdersStyles.VendorName} onClick={handleVendorSelect}>
                {vendor.data.name}
            </h2>
            {vendor.orders.map(DeliveryOrdersVendorItem)}
        </div>
    );
};

export default DeliveryOrdersContentVendor;
