import { useContext } from "react";
import { DeliveryFoodContext } from "../../../../DeliveryFoodContext";
import { DeliveryFoodItemRestaurantCell } from "../../../../types/DeliveryFoodTypes";
import DeliveryFoodCell from "../../DeliveryFoodCell";

export const DeliveryFoodItemRestaurantOnHandCell = ({
    config,
}: {
    config: DeliveryFoodItemRestaurantCell;
}) => {
    const { deliveryFoodState } = useContext(DeliveryFoodContext);
    const { ingredient, restaurant, restaurantId, index } = config;

    if (deliveryFoodState.mode.display.RST[restaurantId].onHand === false) {
        return null;
    }

    const key = `on-hand-cell-${index}`;
    const summary = ingredient.results.RST?.[restaurantId].onHand;

    if (!summary) {
        return null;
    }

    return (
        <DeliveryFoodCell
            key={key}
            fieldName={"onHand"}
            styleName={"ON_HAND"}
            summary={summary}
            index={index}
            ingredient={ingredient}
            restaurant={restaurant}
        />
    );
};
