import { useContext } from "react";
import { DeliveryFoodContext } from "../../../../DeliveryFoodContext";
import { DeliveryFoodItemRestaurantCell } from "../../../../types/DeliveryFoodTypes";
import DeliveryFoodCell from "../../DeliveryFoodCell";

export const DeliveryFoodItemRestaurantDeliverySalesCell = ({
    config,
}: {
    config: DeliveryFoodItemRestaurantCell;
}) => {
    const { deliveryFoodState } = useContext(DeliveryFoodContext);
    const { ingredient, restaurant, restaurantId, index } = config;

    if (deliveryFoodState.mode.display.RST[restaurantId].deliverySales === false) {
        return null;
    }

    const key = `delivery-sales-cell-${index}`;
    const summary = ingredient.results.RST?.[restaurantId].delivery;

    if (!summary) {
        return null;
    }

    const totalDisplayWrp = Math.ceil(+(summary.totalSalesNeed || 0) * 100) / 100;

    return (
        <DeliveryFoodCell
            key={key}
            fieldName={"deliverySales"}
            styleName={"DELIVERY_SALES"}
            summary={summary}
            index={index}
            ingredient={ingredient}
            restaurant={restaurant}
            totalDisplayWrp={totalDisplayWrp}
        />
    );
};
