import { useContext } from "react";
import { IconButton } from "@material-ui/core";
import { OffSiteContext } from "../context/OffSiteContext";
import { MdArrowLeft, MdArrowRight } from "react-icons/md";
import styles from "./OffSiteTasks.module.css";
import OffSiteTasksUtils from "./OffSiteTasksUtils";

export default function OffSiteTasksNavigator() {
    const { offSiteState, goToPrevTask, goToNextTask } = useContext(OffSiteContext);

    if (!offSiteState.offSiteTasksByStatusAndIdAndRest) {
        return null;
    }

    if (!offSiteState.taskSelectedKey) {
        return null;
    }

    const selectedOffSiteItem = OffSiteTasksUtils.getOffSitePendingItemForSelectedTask(
        offSiteState.offSiteTasksByStatusAndIdAndRest,
        offSiteState.taskSelectedKey
    );

    return (
        <div className={styles.TasksNavigator}>
            <IconButton
                onClick={() => goToPrevTask()}
                className={styles.TasksNavigatorLeft}
                size={"medium"}
            >
                <MdArrowLeft />
            </IconButton>

            <div className={styles.TaskNavigatorItem} onClick={() => goToNextTask()}>
                {selectedOffSiteItem?.data?.name}
            </div>

            <IconButton
                onClick={() => goToNextTask()}
                className={styles.TasksNavigatorRight}
                size={"medium"}
            >
                <MdArrowRight />
            </IconButton>
        </div>
    );
}
