import React, { useContext, useEffect } from "react";
import onHandContentStyles from "./OnHandContent.module.css";
import OnHandContextValidator from "../../context/OnHandContextValidator";
import { OnHandContext } from "../../context/OnHandContext";
import OnHandGroup from "../group/OnHandGroup";
import OnHandUtils from "../../OnHandUtils";
import { AppContext } from "../../../../context/AppContext";

const OnHandContent = () => {
    const { appState } = useContext(AppContext);
    const { onHandState } = useContext(OnHandContext);

    useEffect(() => {}, [appState.day, onHandState.restaurant.unlocked]);

    if (!OnHandContextValidator.hasValidGroups(onHandState)) {
        console.info("Invalid groups!");
        return <div />;
    }

    const isReadOnly =
        !OnHandUtils.isTransactionalOnHand(onHandState.config) &&
        OnHandUtils.isReadOnly(onHandState.restaurant, appState.day);

    return (
        <div className={onHandContentStyles.Main}>
            {onHandState.restaurant.groups.map((group, groupIndex) => {
                return (
                    <OnHandGroup
                        key={`on-hand-group-${groupIndex}`}
                        group={group}
                        groupIndex={groupIndex}
                        isReadOnly={isReadOnly}
                    />
                );
            })}
        </div>
    );
};

export default OnHandContent;
