import { AppRestaurant } from "../../admin/AdminResources";
import {
    AppOffSiteArea,
    AppOffSiteItem,
    AppOffSiteTransaction,
    OffSiteTransactionStatuses,
} from "../OffSiteResources";

export type OffSiteTaskType = AppOffSiteTransaction & {
    destinationRestaurant: AppRestaurant;
    offSiteItem: AppOffSiteItem;
    offSiteArea: AppOffSiteArea;
    offSiteRestaurant: AppRestaurant;
    duplicates?: any[];
};

export type OffSiteTasksByRestIdType = {
    [restaurantId: string | number]: OffSiteTaskType[];
};

export type OffSiteTasksByRestType = {
    [restaurantId: string | number]: OffSiteTaskType[];
};

export type OffSiteTasksByIdAndRestType = {
    [offSiteItemId: string | number]: OffSiteTasksByRestType;
};

export type OffSiteTasksByStatusAndIdAndRestType = {
    [status in keyof OffSiteTransactionStatuses]: OffSiteTasksByIdAndRestType;
};

export type OffSiteInitDataType = {
    offSiteTasks: OffSiteTaskType[];
    destinationRestaurants: AppRestaurant[];
};

export enum OffSiteContextActionType {
    LOAD_DELIVERY_TASKS = "LOAD_DELIVERY_TASKS",
    TOGGLE_DELIVERY_TASKS_DIALOG = "TOGGLE_DELIVERY_TASKS_DIALOG",
    GO_TO_PREV_TASK = "GO_TO_PREV_TASK",
    GO_TO_NEXT_TASK = "GO_TO_NEXT_TASK",
    SEND_LOADED_TASKS = "SEND_LOADED_TASKS",
    UPDATE_RESTAURANTS_FILTER = "UPDATE_RESTAURANTS_FILTER",
}

export type OffSiteContextAction = {
    type: keyof typeof OffSiteContextActionType;
    payload: any;
};

export type OffSiteRestaurantFilter = {
    isVisible: boolean;
    data: { id: string | number; alias: string };
};

export type OffSiteRestaurantsFilter = {
    [key: string | number]: OffSiteRestaurantFilter;
};

export type OffSiteContextStateType = {
    offSiteTasksByStatusAndIdAndRest: OffSiteTasksByStatusAndIdAndRestType | null;
    destinationRestaurants: AppRestaurant[];
    restaurantsFilter: OffSiteRestaurantsFilter;
    taskSelectedKey?: string;
    showDeliveryTasksDialog: boolean;
};

export type OffSiteContextType = {
    offSiteState: OffSiteContextStateType;
    loadDeliveryTasks: Function;
    goToPrevTask: Function;
    goToNextTask: Function;
    toggleDeliveryTasksDialog: Function;
    updateRestaurantsFilter: Function;
    sendLoadedTasks: Function;
};
