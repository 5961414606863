const DataEditorCellDisplay = ({ key, value }: { key: string; value: string }) => {
    return (
        <span key={`data-editor-cell-${key}`} className={"truncate"}>
            {value}
            <span style={{ color: "transparent" }}>;</span>
        </span>
    );
};

export default DataEditorCellDisplay;
