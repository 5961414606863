import { Button } from "@material-ui/core";
import React from "react";
import { UserGroupType } from "../../services/AppUserGroupsData";
import { ContainerConfig } from "../ContainerConfig";
import { AppHomeContainerConfig } from "../home/AppHomeContainerConfig";
import { SignOutContainerConfig } from "../sign-out/SignOutContainerConfig";
import appSectionsStyle from "./Sections.module.css";

class Section {
    public pathNames;
    public iconSize;
    public iconHeaderSize;
    public iconHomeSize;
    public iconColor;
    public iconHeaderColor;
    public iconHomeColor;

    static isValidSection(section: Section): boolean {
        if (!section) {
            return false;
        }
        const { name, allowedGroups, disabled } = section;
        return typeof name === "string" && Array.isArray(allowedGroups) && disabled === false;
    }

    renderIcon(disabled: boolean): JSX.Element | null {
        return null;
    }

    renderHeaderIcon(): JSX.Element | null {
        return null;
    }

    renderHomeIcon(): JSX.Element | null {
        return null;
    }

    renderInitCard(onClick: any, selectedSection: Section): any {
        const handleSelection = () => {
            if (selectedSection && selectedSection.name === this.name) {
                return true;
            }

            return onClick(this);
        };

        return (
            <Button
                onClick={handleSelection}
                disabled={this.disabled}
                color={"primary"}
                className={appSectionsStyle.CardBtn}
                variant={this.disabled ? "outlined" : "contained"}
            >
                <div className={appSectionsStyle.CardBtnContent}>
                    <div className={appSectionsStyle.CardBtnIcon}>
                        {this.renderIcon(this.disabled)}
                    </div>
                    <div className={appSectionsStyle.CardBtnName}>
                        <span>{this.name}</span>
                    </div>
                </div>
            </Button>
        );
    }

    renderHome() {
        return (
            <div className={appSectionsStyle.Home}>
                {this.renderHomeIcon()}
                <div className={appSectionsStyle.HomeName}>
                    <span>{this.name}</span>
                </div>
            </div>
        );
    }

    constructor(
        public pages: ContainerConfig[] = [],
        public name: string = "?",
        public allowedGroups: UserGroupType[] = [],
        public disabled = false,
        public basename: string,
        public tasksComponent?: any,
        public wrapperContextProvider?: any
    ) {
        this.pages = [AppHomeContainerConfig].concat(pages).concat([SignOutContainerConfig]);
        this.pathNames = this.pages.map(({ url }) => url);
        this.name = name;
        this.allowedGroups = allowedGroups;
        this.iconSize = 36;
        this.iconHeaderSize = 18;
        this.iconHomeSize = 90;
        this.iconColor = "#3f50b6";
        this.iconHeaderColor = "white";
        this.iconHomeColor = "lightgray";
        this.disabled = disabled;
        this.tasksComponent = tasksComponent;
        this.wrapperContextProvider = wrapperContextProvider;
    }

    getDefaultPath(groups?: UserGroupType[]): string {
        return AppHomeContainerConfig.url;
    }
}

export { Section };
