import {
    ADMINS,
    DEVS,
    KITCHEN_USERS,
    ORDERS_DELIVERY_USERS,
    ORDERS_USERS,
    ORDERS_ADMIN,
} from "../../services/AppUserGroupsData";
import { SectionNames } from "../sections/SectionNames";
import { UserGroupType } from "./../../services/AppUserGroupsData";
import { ContainerConfig } from "./../ContainerConfig";
import { OrdersContainerConfig } from "./OrdersContainerConfig";
import { OrdersSection } from "./OrdersSection";

const ordersSectionPages: ContainerConfig[] = [
    OrdersContainerConfig.OnHand,
    OrdersContainerConfig.Delivery,
    OrdersContainerConfig.DataEditor,
];

const ordersSectionAllowedUserGroups: UserGroupType[] = [
    KITCHEN_USERS,
    ORDERS_USERS,
    ORDERS_DELIVERY_USERS,
    ORDERS_ADMIN,
    ADMINS,
    DEVS,
];

const isOrdersSectionDisabled = false;

export const OrdersSectionConfig = new OrdersSection(
    ordersSectionPages,
    SectionNames.ORDERS,
    ordersSectionAllowedUserGroups,
    isOrdersSectionDisabled
);
