import DeliveryFoodModeUtils from "../services/DeliveryFoodModeUtils";
import { DeliveryFoodModeRootData } from "../types/DeliveryFoodModeTypes";
import {
    DeliveryFoodIngredient,
    DeliveryFoodIngredientResultRst,
} from "../types/DeliveryFoodTypes";
import DeliveryFoodMode from "./DeliveryFoodMode";

export class DeliveryFoodDeliveryMode extends DeliveryFoodMode {
    ingredientsFilter(mode: DeliveryFoodMode, ingredient: DeliveryFoodIngredient, index: number) {
        const { dataForDelivery, results } = ingredient;

        if (!dataForDelivery || !results) {
            return false;
        }

        const comHasDeliveryValues = (ingredient.results.COM?.prepForDelivery.total || 0) > 0;

        const restHaveDeliveryValues: boolean = Object.values(ingredient.results.RST || {}).reduce(
            (acc: boolean, result: DeliveryFoodIngredientResultRst) => {
                acc = acc || (result.delivery.total || 0) > 0;
                return acc;
            },
            false
        );

        const hasDeliveryValues = comHasDeliveryValues || restHaveDeliveryValues;

        if (hasDeliveryValues) {
            console.info(`Selected ${ingredient.data.name} for delivery!`);
        }

        return hasDeliveryValues;
    }

    constructor(rootData: DeliveryFoodModeRootData) {
        super(rootData);
        this.label = "Delivery";

        const { restaurants } = rootData;
        this.display = DeliveryFoodModeUtils.buildDeliveryModeDisplay(restaurants);
    }
}
