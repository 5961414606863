import { MdDehaze } from "react-icons/md";
import dataEditorStyles from "../../../DataEditor.module.css";

const DataEditorHandle = () => {
    return (
        <div className={`handle ${dataEditorStyles.Handle}`}>
            <MdDehaze size={18} />
        </div>
    );
};

export default DataEditorHandle;
