import React from "react";
import { FiEdit } from "react-icons/fi";

const DataEditorOffSiteMenuItem = (name: string) => {
    return [
        <FiEdit key={`${name}-icon`} size={"1.5em"} />,
        <div key={`${name}-text`}>
            <span>{name}</span>
        </div>,
    ];
};

export default DataEditorOffSiteMenuItem;
