import React from "react";

const DeliveryOrdersCellName = ({ order }) => {
    return (
        <span>
            {order?.data?.name}
            <span style={{ color: "transparent" }}>,</span>
        </span>
    );
};

export default DeliveryOrdersCellName;
