import { Button } from "@material-ui/core";
import { Component } from "react";
import { buildEmailFeedback } from "../../containers/feedback/AppFeedbackUtils";
import AppEmailService from "../../services/AppEmailService";

class ErrorHandler extends Component {
    state = { hasError: false, errorInfo: "" };

    componentDidCatch(error, errorInfo) {
        const errorInfoString = JSON.stringify(errorInfo, null, 4);
        this.setState({ hasError: true, errorInfo: errorInfoString });
        console.error(errorInfoString);
    }

    render() {
        if (this.state.hasError) {
            const handleSendFeedback = async () => {
                this.props.setGlobalSpinner(true);

                const feedback = {
                    type: "Bug",
                    priority: "p1",
                    date: "<date today>",
                    details: this.state.errorInfo,
                    userName: this.props.appState.user.name,
                    day: this.props.appState.day.data.display,
                    sentAt: new Date().getTime(),
                    location: window.location.href,
                };

                const email = buildEmailFeedback(feedback);

                try {
                    await AppEmailService.sendEmail(email);
                    alert("Error report sent!");
                } catch (e) {
                    alert("Failed to send Error, please contact admin!");
                } finally {
                    this.props.setGlobalSpinner(false);
                }
            };

            return (
                <div className="error-default">
                    <span>Something went wrong!</span>
                    <Button onClick={handleSendFeedback} variant={"contained"}>
                        Send Error Report
                    </Button>
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorHandler;
