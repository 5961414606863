import { useContext } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import AppDayModel from "../../../../../../../components/day/AppDayModel";
import { AppContext } from "../../../../../../../context/AppContext";
import deliveryFoodStyles from "../../../../DeliveryFood.module.css";
import { DeliveryFoodContext } from "../../../../DeliveryFoodContext";
import { DeliveryFoodItemRestaurantCell } from "../../../../types/DeliveryFoodTypes";
import styles from "./DeliveryFoodItemRestaurantDeliveryFixedCell.module.css";

export const DeliveryFoodItemRestaurantDeliveryFixedCell = ({
    config,
}: {
    config: DeliveryFoodItemRestaurantCell;
}) => {
    const { appState } = useContext(AppContext);
    const { deliveryFoodState, updateFixedNeed } = useContext(DeliveryFoodContext);
    const { ingredient, restaurant, restaurantId, index } = config;

    if (deliveryFoodState.mode.display.RST[restaurantId].delivery === false) {
        return null;
    }

    const summary = ingredient.results.RST?.[restaurantId].delivery;

    if (!summary) {
        return null;
    }

    if (typeof summary.totalFixedNeed !== "number" || summary.totalFixedNeed < 0) {
        return <div />;
    }

    const fieldName = "deliveryFixed";
    const styleName = "DELIVERY_FIXED";
    const totalDisplayWrp = Math.ceil(+(summary.totalFixedNeed || 0) * 100) / 100;
    const key = `delivery-fixed-item-cell-${fieldName}-${restaurant.data.id}-${index}`;
    const className = `truncate ${deliveryFoodStyles[styleName]} ${styles.Main}`;
    const textClassName = summary.totalFixedNeed <= 0 ? "no-print" : "";

    const deliveryConfig = ingredient.deliveryConfigsByRestaurantId[restaurantId];

    const handleUpdateFixedNeed = async (quantity: number) => {
        try {
            const newFixedNeed = Math.max(deliveryConfig.data.fixedNeed + quantity, 0);
            const newDeliveryConfig = Object.assign(deliveryConfig.data, {
                fixedNeed: newFixedNeed,
                lastUpdatedBy: appState.user.name,
                lastUpdatedAt: AppDayModel.getCurrentUnixTime(),
            });

            await deliveryConfig.getModel().api.update(newDeliveryConfig);

            ingredient.deliveryConfigsByRestaurantId[restaurantId].data = newDeliveryConfig;

            updateFixedNeed(ingredient);
        } catch (err) {
            alert("Cannot update item!");
        }
    };

    const value = totalDisplayWrp;

    if (typeof value !== "number") {
        return (
            <div key={key} className={className}>
                <span className={textClassName}>{value}</span>
            </div>
        );
    }

    return (
        <div key={key} className={className}>
            <span className={textClassName}>{value}</span>
            {value > 0 ? (
                <div className={styles.Decrease} onClick={() => handleUpdateFixedNeed(-0.25)}>
                    <BsChevronLeft size={"12"} color={"gray"}></BsChevronLeft>
                </div>
            ) : null}
            <div className={styles.Increase} onClick={() => handleUpdateFixedNeed(0.25)}>
                <BsChevronRight size={"12"} color={"gray"}></BsChevronRight>
            </div>
        </div>
    );
};
