import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import { useContext } from "react";
import { MdAddBox } from "react-icons/md";
import { DataEditorContext } from "../../../../context/DataEditorContext";
import dataEditorAddStyles from "./DataEditorAdd.module.css";
import DataEditorAddContent from "./DataEditorAddContent";

const DataEditorAdd = () => {
    const { dataEditorState, openAddDialog, closeAddDialog } = useContext(DataEditorContext);

    return (
        <div className={dataEditorAddStyles.Add}>
            <IconButton type="submit" color="primary" onClick={() => openAddDialog()}>
                <MdAddBox />
            </IconButton>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                onClose={() => closeAddDialog()}
                aria-labelledby="customized-dialog-title"
                open={dataEditorState.display.addDialogOpen}
            >
                <DialogContent dividers>
                    <DataEditorAddContent />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default DataEditorAdd;
