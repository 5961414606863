import React from "react";
import menuStyles from "../Menus.module.css";
import MenusUtils from "../services/MenusUtils";

const MenusItemRecipe = ({ recipe, index }) => {
    if (recipe.length === 0) {
        return (
            <div
                className={`${menuStyles.menuItemRecipe} ${menuStyles.menuItemMissingRecipe}`}
                key={`recipe-${index}`}
            >
                Missing recipe!
            </div>
        );
    }

    const recipeDisplay = recipe.map((i) => MenusUtils.displayRecipeItem(i)).join(", ");

    return (
        <div className={menuStyles.menuItemRecipe} key={`recipe-${index}`}>
            {recipeDisplay}
        </div>
    );
};

export default MenusItemRecipe;
