import React from "react";
import { MdOutlineChecklist } from "react-icons/md";

export default function OffSiteReceptionMenuItem(name: string) {
    return [
        <MdOutlineChecklist key={`${name}-icon`} size={"1.5em"} />,
        <div key={`${name}-text`}>
            <span>{name}</span>
        </div>,
    ];
}
