import { createContext, useCallback, useContext, useReducer } from "react";
import { AppContext } from "../../../context/AppContext";
import OffSiteContextInit from "./OffSiteContextInit";
import { OffSiteContextReducer } from "./OffSiteContextReducer";
import {
    OffSiteContextAction,
    OffSiteContextActionType,
    OffSiteContextType,
    OffSiteInitDataType,
    OffSiteTasksByStatusAndIdAndRestType,
} from "./OffSiteContextTypes";

export const OffSiteContext = createContext({} as OffSiteContextType);

const OffSiteContextProvider = ({ children }: { children: any }) => {
    const { syncOffSiteTaskSelectedKeyCallback } = useContext(AppContext);
    const [offSiteState, dispatch] = useReducer(OffSiteContextReducer, OffSiteContextInit);

    const loadDeliveryTasks = useCallback(
        (offSiteInitData: OffSiteInitDataType) => {
            const action: OffSiteContextAction = {
                type: OffSiteContextActionType.LOAD_DELIVERY_TASKS,
                payload: { offSiteInitData },
            };

            return dispatch(action);
        },
        [dispatch]
    );

    const toggleDeliveryTasksDialog = useCallback(
        (showDeliveryTasksDialog = false) => {
            const action: OffSiteContextAction = {
                type: OffSiteContextActionType.TOGGLE_DELIVERY_TASKS_DIALOG,
                payload: { showDeliveryTasksDialog },
            };

            return dispatch(action);
        },
        [dispatch]
    );

    const goToPrevTask = useCallback(() => {
        const action: OffSiteContextAction = {
            type: OffSiteContextActionType.GO_TO_PREV_TASK,
            payload: { syncOffSiteTaskSelectedKeyCallback },
        };

        return dispatch(action);
    }, [dispatch]);

    const goToNextTask = useCallback(() => {
        const action: OffSiteContextAction = {
            type: OffSiteContextActionType.GO_TO_NEXT_TASK,
            payload: { syncOffSiteTaskSelectedKeyCallback },
        };

        return dispatch(action);
    }, [dispatch]);

    const updateRestaurantsFilter = useCallback(
        (restaurantsFilter = []) => {
            const action: OffSiteContextAction = {
                type: OffSiteContextActionType.UPDATE_RESTAURANTS_FILTER,
                payload: { restaurantsFilter },
            };

            return dispatch(action);
        },
        [dispatch]
    );

    const sendLoadedTasks = useCallback(
        async (offSiteTasks: OffSiteTasksByStatusAndIdAndRestType) => {
            const action: OffSiteContextAction = {
                type: OffSiteContextActionType.SEND_LOADED_TASKS,
                payload: { offSiteTasks },
            };

            return dispatch(action);
        },
        [dispatch]
    );

    const value: OffSiteContextType = {
        offSiteState,
        loadDeliveryTasks,
        goToPrevTask,
        goToNextTask,
        toggleDeliveryTasksDialog,
        updateRestaurantsFilter,
        sendLoadedTasks,
    };

    return (
        <OffSiteContext.Provider key={"offsite-context"} value={value}>
            {children}
        </OffSiteContext.Provider>
    );
};

export default OffSiteContextProvider;
