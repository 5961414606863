import { Auth } from "aws-amplify";

function isValidUser(appUser) {
    if (appUser) {
        const { name, groups } = appUser;
        return name && Array.isArray(groups);
    }

    return Auth?.user?.signInUserSession?.idToken?.payload?.["cognito:groups"];
}

function isValid(appState) {
    return (
        appState &&
        appState.user &&
        Array.isArray(appState.user.groups) &&
        appState.day &&
        appState.day.data.time &&
        appState.section
    );
}

const AppContextValidator = {
    isValidUser,
    isValid,
};

export default AppContextValidator;
