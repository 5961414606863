const ContainerBasePaths = {
    FOOD: "/food",
    ORDERS: "/orders",
    OFF_SITE: "/offsite",
    T_SHIRTS: "/tshirts",
    DPS: "/dps",
    ADMIN: "/admin",
};

export default ContainerBasePaths;
