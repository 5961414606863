import { useContext } from "react";
import { OffSiteContext } from "../../context/OffSiteContext";
import { OffSiteTasksByRestIdType } from "../../context/OffSiteContextTypes";
import styles from "../OffSiteTasks.module.css";
import OffSiteTasksUtils from "../OffSiteTasksUtils";
import OffSiteTasksDialogContentItem from "./OffSiteTasksDialogContentItem";
import OffSiteTasksDialogHeader from "./OffSiteTasksDialogHeader";

export default function OffSiteTasksDialogContent() {
    const { offSiteState } = useContext(OffSiteContext);

    if (!offSiteState.offSiteTasksByStatusAndIdAndRest) {
        return null;
    }

    const tasksByDestinationRestaurantId: OffSiteTasksByRestIdType =
        OffSiteTasksUtils.getTasksByDestinationRestaurantId(
            offSiteState.offSiteTasksByStatusAndIdAndRest
        );

    const destinationRestaurantIds: (string | number)[] = Object.keys(
        tasksByDestinationRestaurantId
    );

    return (
        <div>
            {destinationRestaurantIds.map((destinationRestaurantId) => {
                const tasks = tasksByDestinationRestaurantId[destinationRestaurantId]
                    .flat()
                    .sort(OffSiteTasksUtils.sortOffSiteTasksByStatus);
                const restaurantName = tasks?.[0]?.destinationRestaurant?.data?.name;

                if (!tasks || tasks.length === 0 || !restaurantName) {
                    return null;
                }

                return (
                    <div className={styles.RestaurantTasks}>
                        <div className={styles.RestaurantTasksLabel}>{restaurantName}</div>
                        <OffSiteTasksDialogHeader />
                        {tasks.map((task) => (
                            <OffSiteTasksDialogContentItem task={task} />
                        ))}
                    </div>
                );
            })}
        </div>
    );
}
