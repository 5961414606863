import React, { useContext } from "react";
import menusStyles from "../Menus.module.css";
import LinearProgress from "@material-ui/core/LinearProgress";
import { MenusContext } from "../context/MenusContext";

const MenusCompletionStatus = () => {
    const { menusState } = useContext(MenusContext);

    if (!menusState.restaurant) {
        console.info("Invalid menus restaurant");
        return <div />;
    }

    return (
        <div className={menusStyles.menusCompletion}>
            <LinearProgress
                variant="buffer"
                value={Math.ceil(menusState.completionScore * 100)}
                valueBuffer={100}
                color="secondary"
            />
        </div>
    );
};

export default MenusCompletionStatus;
