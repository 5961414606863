import { createContext, ReactNode, useCallback, useReducer } from "react";
import { AppOffSiteAlertLocalTypes } from "../../OffSiteResources";
import { OffSiteReordersAlertDisplay } from "../types/OffSiteReordersTypes";
import OffSiteReordersContextReducer from "./OffSiteReordersContextReducer";
import {
    OffSiteReordersAlertsType,
    OffSiteReordersContextAction,
    OffSiteReordersContextActionType,
    OffSiteReordersContextStateType,
    OffSiteReordersContextType,
} from "./OffSiteReordersContextTypes";

const OffSiteReordersContext = createContext({} as OffSiteReordersContextType);

function OffSiteReordersContextProvider(props: any) {
    const { children }: { children: ReactNode } = props;
    const [offSiteReordersState, dispatch] = useReducer(
        OffSiteReordersContextReducer,
        new OffSiteReordersContextStateType()
    );

    const setAlerts = useCallback(
        (alerts: OffSiteReordersAlertsType) => {
            const action: OffSiteReordersContextAction = {
                type: OffSiteReordersContextActionType.SET_ALERTS,
                payload: { alerts },
            };

            return dispatch(action);
        },
        [dispatch]
    );

    const completeAlert = useCallback(
        (alert: OffSiteReordersAlertDisplay, prevType: keyof typeof AppOffSiteAlertLocalTypes) => {
            const action: OffSiteReordersContextAction = {
                type: OffSiteReordersContextActionType.COMPLETE_ALERT,
                payload: { alert, prevType },
            };

            return dispatch(action);
        },
        [dispatch]
    );

    const undoCompleteAlert = useCallback(
        (alert: OffSiteReordersAlertDisplay, prevType: keyof typeof AppOffSiteAlertLocalTypes) => {
            const action: OffSiteReordersContextAction = {
                type: OffSiteReordersContextActionType.UNDO_COMPLETE_ALERT,
                payload: { alert, prevType },
            };

            return dispatch(action);
        },
        [dispatch]
    );

    const snoozeAlert = useCallback(
        (alert: OffSiteReordersAlertDisplay, prevType: keyof typeof AppOffSiteAlertLocalTypes) => {
            const action: OffSiteReordersContextAction = {
                type: OffSiteReordersContextActionType.SNOOZE_ALERT,
                payload: { alert, prevType },
            };

            return dispatch(action);
        },
        [dispatch]
    );

    const value: OffSiteReordersContextType = {
        offSiteReordersState,
        setAlerts,
        completeAlert,
        undoCompleteAlert,
        snoozeAlert,
    };

    return (
        <OffSiteReordersContext.Provider key={"off_site_reorders_context"} value={value}>
            {children}
        </OffSiteReordersContext.Provider>
    );
}

export { OffSiteReordersContext, OffSiteReordersContextProvider };
