import { AppRestaurant, AppRestaurantTypes } from "../../../admin/AdminResources";
import DeliveryFoodModeUtils from "../services/DeliveryFoodModeUtils";
import { DeliveryFoodModeDisplay, DeliveryFoodModeRootData } from "../types/DeliveryFoodModeTypes";
import { DeliveryFoodIngredient, DeliveryFoodDeliveryTotalTypes } from "../types/DeliveryFoodTypes";

export default class DeliveryFoodMode {
    public label: any;
    public display: DeliveryFoodModeDisplay;
    public restaurant?: AppRestaurant;
    public deliveryTotalType?: keyof typeof DeliveryFoodDeliveryTotalTypes;

    getToolbarInfo(state: any) {
        return <div />;
    }

    renderHeader?(mode: any, day: any): JSX.Element;

    showRestaurant(mode: DeliveryFoodMode, restaurant: any) {
        const {
            data: { type, id },
        } = restaurant;

        if (type === AppRestaurantTypes.COMMISSARY) {
            const { prep, onHand, prepForDelivery, prepTime, prepForDeliveryTime } =
                mode.display.COM;

            return prep || onHand || prepForDelivery || prepTime || prepForDeliveryTime;
        }

        const {
            delivery,
            onHand,
            sales: { MULTIPLE, SINGLE },
        } = mode.display.RST[id];

        return delivery || onHand || MULTIPLE || SINGLE;
    }

    ingredientsFilter(mode: DeliveryFoodMode, ingredient: DeliveryFoodIngredient, index: number) {
        return true;
    }

    constructor(
        rootData: DeliveryFoodModeRootData,
        deliveryType?: keyof typeof DeliveryFoodDeliveryTotalTypes
    ) {
        const { restaurants, restaurant } = rootData;

        this.label = restaurant?.data?.name || "Unknown Label";
        this.display = DeliveryFoodModeUtils.buildModeDisplay(restaurants);
        this.deliveryTotalType = deliveryType;
    }
}
