import React, { useContext } from "react";
import onHandGroupReadOnlyStyles from "./OnHandGroupReadOnly.module.css";
import { OnHandContext } from "../../context/OnHandContext";
import AppDayModel from "../../../../components/day/AppDayModel";
import OnHandContextValidator from "../../context/OnHandContextValidator";
import OnHandUtils from "../../OnHandUtils";

const OnHandGroupReadOnly = ({ group }) => {
    const { onHandState } = useContext(OnHandContext);

    if (
        !OnHandUtils.isTransactionalOnHand(onHandState?.config) &&
        OnHandContextValidator.isEmptyGroup(group)
    ) {
        return <div />;
    }

    const OnHandGroupReadOnlyHeader = () => {
        return (
            <div
                className={`${onHandGroupReadOnlyStyles.Header} ${onHandGroupReadOnlyStyles.Item}`}
            >
                <span className={"truncate"}>Item</span>
                <span className={"truncate"}>Qty</span>
                <span className={"truncate"}>By</span>
                <span className={"truncate"}>At</span>
            </div>
        );
    };

    const OnHandGroupReadOnlyItem = (item) => {
        return (
            <div className={onHandGroupReadOnlyStyles.Item}>
                <span className={"truncate"}>
                    {onHandState.config.context.utils.extractItemName(item)}
                </span>
                <span className={"truncate"}>{item.inventory.data.quantity}</span>
                <span className={"truncate"}>{item.inventory.data.lastUpdatedBy}</span>
                <span className={"truncate"}>
                    {AppDayModel.getTimeDisplay(item.inventory.data.lastUpdatedAt)}
                </span>
            </div>
        );
    };

    // TODO: move this further up the stack
    if (OnHandUtils.isTransactionalOnHand(onHandState.config)) {
        return null;
    }

    return (
        <div className={onHandGroupReadOnlyStyles.Main}>
            <div className={onHandGroupReadOnlyStyles.Name}>{group.data.name}</div>
            <OnHandGroupReadOnlyHeader />
            <div className={onHandGroupReadOnlyStyles.Content}>
                {group.items
                    .filter((item) => item.inventory?.mock !== true)
                    .map(OnHandGroupReadOnlyItem)}
            </div>
        </div>
    );
};

export default OnHandGroupReadOnly;
