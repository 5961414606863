import {
    DeliveryFoodSummaryConfigsSubType,
    DeliveryFoodIngredient,
} from "../../../types/DeliveryFoodTypes";

export const DeliveryFoodSummaryPrepTimeConfigs: DeliveryFoodSummaryConfigsSubType = {
    title: ({ ingredient }: { ingredient: DeliveryFoodIngredient }) => {
        return (
            <div>
                Prep Time for <strong>{ingredient.data.name}</strong>
            </div>
        );
    },
    content: ({ data, total, totalDisplay }: any) => {
        return (
            <div>
                <ul>
                    <li>
                        <strong>Labor time</strong>: {data?.laborTime} minutes
                    </li>
                    <li>
                        <strong>Batch Size</strong>: {data?.batchSize}
                    </li>
                    <li>
                        <strong>Portion time</strong>: {data?.portionTime} minutes
                    </li>
                    <li>
                        <strong>Prep quantity</strong>: {data?.prepTotal}
                    </li>
                </ul>
                <div>
                    <strong>Total minutes:</strong> {total}
                </div>
                <div>
                    <strong>Total time:</strong> {totalDisplay}
                </div>
            </div>
        );
    },
};
