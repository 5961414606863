import { Button } from "@material-ui/core";
import React, { useContext } from "react";
import { OnHandContext } from "../../../on-hand/context/OnHandContext";

export default function OffSiteStockingItemSkip() {
    const { goToNextItem } = useContext(OnHandContext) as any;

    const handleGoToNext = () => goToNextItem();

    return (
        <Button variant="contained" onClick={handleGoToNext}>
            Skip
        </Button>
    );
}
