import { Button, Menu, MenuItem } from "@material-ui/core";
import { useContext, useState } from "react";
import { useGlobalSpinnerActionsContext } from "../../../../components/global-spinner/GlobalSpinnerContext";
import { AppContext } from "../../../../context/AppContext";
import { OffSiteReordersContext } from "../context/OffSiteReordersContext";
import styles from "../OffSiteReorders.module.css";
import { OffSiteReordersSnoozedDurationType } from "../OffSiteReordersSnoozedDurationType";
import { ALL_REORDERS_DURATIONS } from "../OffSiteReordersSnoozeDurations";
import OffSiteReordersUtils from "../OffSiteReordersUtils";

export default function OffSiteReordersSnoozeButton({ alert }: { alert: any }) {
    const setGlobalSpinner = useGlobalSpinnerActionsContext();
    const { appState } = useContext(AppContext);
    const { snoozeAlert } = useContext(OffSiteReordersContext);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = async (duration: OffSiteReordersSnoozedDurationType) => {
        setAnchorEl(null);
        if (duration) {
            try {
                setGlobalSpinner(true);
                const prevType = alert.type;
                alert = await OffSiteReordersUtils.snoozeAlert(alert, appState.user.name, duration);

                snoozeAlert(alert, prevType);
            } catch (e) {
                throw Error("Cannot snooze alert!");
            } finally {
                setGlobalSpinner(false);
            }
        }
    };

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <div className={styles.SnoozeMenu}>
            <Button
                color={"primary"}
                variant={"outlined"}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                Snooze
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {ALL_REORDERS_DURATIONS.map((duration: OffSiteReordersSnoozedDurationType) => {
                    return (
                        <MenuItem onClick={() => handleClose(duration)}>
                            {duration.display}
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
}
