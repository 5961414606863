import React, { useContext } from "react";
import deliveryFoodStyles from "../../DeliveryFood.module.css";
import { DeliveryFoodContext } from "../../DeliveryFoodContext";
import DeliveryFoodItemRestaurant from "./cells/restaurant/DeliveryFoodItemRestaurant";
import DeliveryFoodItemCommissary from "./cells/commissary/DeliveryFoodItemCommissary";
import Tooltip from "@material-ui/core/Tooltip";
import { AppRestaurant, AppRestaurantTypes } from "../../../../admin/AdminResources";
import { DeliveryFoodIngredient } from "../../types/DeliveryFoodTypes";

const DeliveryFoodItem = (ingredient: DeliveryFoodIngredient, index: number) => {
    const { deliveryFoodState } = useContext(DeliveryFoodContext);

    const restaurants: AppRestaurant[] = Object.values(deliveryFoodState.restaurantsById)
        .filter(({ data: { type } }: any) => type === AppRestaurantTypes.RESTAURANT)
        .reverse();

    const commissaries: AppRestaurant[] = Object.values(deliveryFoodState.restaurantsById)
        .filter(({ data: { type } }: any) => type === AppRestaurantTypes.COMMISSARY)
        .reverse();

    const key = `ingredient-${index}`;
    const noPrint = !ingredient.dataForDelivery;
    const className = `${noPrint ? "no-print" : ""} ${deliveryFoodStyles.Item} ${
        deliveryFoodStyles.Row
    }`;
    const mode = deliveryFoodState.mode;

    return (
        <div key={key} className={className}>
            <div>{ingredient.data.name}</div>
            <Tooltip
                title={`Actual size: ${ingredient.pack.data.size}`}
                placement={"right"}
                arrow={true}
            >
                <div>{ingredient.pack.data.name}</div>
            </Tooltip>
            {restaurants
                .filter((restaurant) => mode.showRestaurant(mode, restaurant))
                .map((restaurant: AppRestaurant, index: number) => {
                    if (!restaurant.data.id) {
                        return null;
                    }

                    const restaurantId: string | number = restaurant.data.id;

                    return (
                        <DeliveryFoodItemRestaurant
                            restaurant={restaurant}
                            restaurantId={restaurantId}
                            index={index}
                            ingredient={ingredient}
                        />
                    );
                })}
            {commissaries
                .filter((restaurant) => mode.showRestaurant(mode, restaurant))
                .map((restaurant: AppRestaurant, index: number) => {
                    return (
                        <DeliveryFoodItemCommissary
                            commissary={restaurant}
                            index={index}
                            ingredient={ingredient}
                        />
                    );
                })}
        </div>
    );
};

export default DeliveryFoodItem;
