import { Button } from "@material-ui/core";
import { useContext } from "react";
import { OffSiteContext } from "../context/OffSiteContext";
import styles from "./OffSiteTasks.module.css";

export default function OffSiteTasksStart() {
    const { goToNextTask } = useContext(OffSiteContext);

    // TODO: go to "/delivery" if not there already
    return (
        <div className={styles.TasksNavigatorStart}>
            <Button
                className={styles.TasksNavigatorStartButton}
                onClick={() => goToNextTask()}
                size={"small"}
                variant={"contained"}
            >
                Start Delivery
            </Button>
        </div>
    );
}
