import { ADMINS, DEVS, ORDERS_ADMIN } from "../../services/AppUserGroupsData";
import { SectionNames } from "../sections/SectionNames";
import { UserGroupType } from "./../../services/AppUserGroupsData";
import { ContainerConfig } from "./../ContainerConfig";
import { AdminContainerConfig } from "./AdminContainerConfig";
import { AdminSection } from "./AdminSection";

const adminSectionPages: ContainerConfig[] = [AdminContainerConfig.DataEditor];
const adminSectionAllowedUserGroups: UserGroupType[] = [ORDERS_ADMIN, ADMINS, DEVS];
const isAdminSectionDisabled = false;

export const AdminSectionConfig = new AdminSection(
    adminSectionPages,
    SectionNames.ADMIN,
    adminSectionAllowedUserGroups,
    isAdminSectionDisabled
);
