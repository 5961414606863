import React, { useContext, useEffect } from "react";
import { OnHandContext } from "../../../on-hand/context/OnHandContext";
import onHandItemStyles from "../../../on-hand/components/item/OnHandItem.module.css";

const OnHandFoodItemTitle = () => {
    const { onHandState } = useContext(OnHandContext);

    useEffect(() => {}, [onHandState.restaurant.group.item]);

    const item = onHandState.restaurant.group.item;
    const unitName = item.data.bulk === "BULK" ? item.ingredient.pack.data.alias : item.data.bulk;
    const ingredientName = item.ingredient.data.onHandAlias || item.ingredient.data.name;
    return (
        <div className={onHandItemStyles.Title}>
            <span>
                <strong>{unitName}</strong> of <strong>{ingredientName}</strong>
            </span>
        </div>
    );
};

export default OnHandFoodItemTitle;
