import _ from "lodash";
import moment, { Moment } from "moment";
import { fetchResources } from "../../../resources/ResourcesService";
import AppUtilsService from "../../../services/AppUtilsService";
import {
    AppOffSiteAlert,
    AppOffSiteAlertLocalTypes,
    AppOffSiteAlertStatuses,
    AppOffSiteItem,
    AppOffSiteTransaction,
    AppOffSiteTransactionStatuses,
} from "../OffSiteResources";
import OffSiteTasksUtils from "../tasks/OffSiteTasksUtils";
import { OffSiteReordersAlertsType } from "./context/OffSiteReordersContextTypes";
import { OffSiteReordersSnoozedDurationType } from "./OffSiteReordersSnoozedDurationType";
import { OffSiteReordersAlertDisplay } from "./types/OffSiteReordersTypes";

async function getLastOffSiteAlerts() {
    return [];
}

async function sendOffSiteAlert(message: any) {
    return null;
}

function makeMomentFromAWSDateTime(aWSDateTime: any) {
    return moment(aWSDateTime).tz("America/New_York");
}

function getCurrentUSEast1Moment() {
    return moment().tz("America/New_York");
}

function sortMomentsAsc(momentA?: Moment, momentB?: Moment) {
    return (momentA?.unix() || 0) - (momentB?.unix() || 0);
}

function sortMomentsDesc(momentA?: Moment, momentB?: Moment) {
    return (momentB?.unix() || 0) - (momentA?.unix() || 0);
}

function sortOpenAlerts(alertA: OffSiteReordersAlertDisplay, alertB: OffSiteReordersAlertDisplay) {
    return sortMomentsAsc(alertA.createdAtMoment, alertB.createdAtMoment);
}

function sortSnoozedAlerts(
    alertA: OffSiteReordersAlertDisplay,
    alertB: OffSiteReordersAlertDisplay
) {
    return sortMomentsAsc(alertA.snoozedUntilMoment, alertB.snoozedUntilMoment);
}

function sortCompletedAlerts(
    alertA: OffSiteReordersAlertDisplay,
    alertB: OffSiteReordersAlertDisplay
) {
    return sortMomentsDesc(alertA.updatedAtMoment, alertB.updatedAtMoment);
}

function getAlertType(alert: OffSiteReordersAlertDisplay, currentUSEast1Moment?: Moment) {
    // set conditions
    if (!alert?.data) {
        return undefined;
    }

    // set default
    if (!currentUSEast1Moment) {
        currentUSEast1Moment = getCurrentUSEast1Moment();
    }

    // completed
    if (alert.data.status === AppOffSiteAlertStatuses.COMPLETED) {
        return AppOffSiteAlertLocalTypes.COMPLETED;
    }

    // open or snooze
    if (alert.data.status === AppOffSiteAlertStatuses.PENDING) {
        return alert?.snoozedUntilMoment?.isBefore(currentUSEast1Moment)
            ? AppOffSiteAlertLocalTypes.OPEN
            : AppOffSiteAlertLocalTypes.SNOOZED;
    }

    // kind of error
    return AppOffSiteAlertLocalTypes.OTHER;
}

async function loadReordersAlertsDecorated(): Promise<OffSiteReordersAlertsType> {
    const [reordersAlertsRaw, offSiteItems, offSiteCompletedTransactions] = await fetchResources([
        [AppOffSiteAlert],
        [AppOffSiteItem],
        [AppOffSiteTransaction, { status: { eq: AppOffSiteTransactionStatuses.COMPLETED } }],
    ]);

    // prepare data
    const offSiteItemsById = (offSiteItems || []).reduce(AppUtilsService.groupDataByItem("id"), {});
    const offSiteCompletedTransactionsByItemId = (offSiteCompletedTransactions || []).reduce(
        AppUtilsService.groupDataByList("offSiteItemId"),
        {}
    );

    // decorate
    const currentUSEast1Moment = getCurrentUSEast1Moment();
    const reordersAlerts = (reordersAlertsRaw || []).map((alert: OffSiteReordersAlertDisplay) => {
        alert.offSiteItem = offSiteItemsById[alert?.data?.offSiteItemId];
        alert.createdAtMoment = makeMomentFromAWSDateTime(alert.data.createdAt);
        alert.snoozedUntilMoment = makeMomentFromAWSDateTime(alert.data.snoozedUntil);
        alert.updatedAtMoment = makeMomentFromAWSDateTime(alert.data.updatedAt);
        alert.type = getAlertType(alert, currentUSEast1Moment);
        alert.stock = OffSiteTasksUtils.sumUpItemStock(
            offSiteCompletedTransactionsByItemId[alert?.data?.offSiteItemId]
        );

        return alert;
    });

    // organize
    const alerts: OffSiteReordersAlertsType = {};
    const alertsRaw: [] = reordersAlerts;

    // TODO: move the processing of raw alerts earlier
    // OPEN ALERTS
    alerts[AppOffSiteAlertLocalTypes.OPEN] = alertsRaw
        .filter(OffSiteReordersUtils.isOpenAlert)
        .reduce(AppUtilsService.groupDataByItem("offSiteItemId"), {});

    // SNOOZED ALERTS
    alerts[AppOffSiteAlertLocalTypes.SNOOZED] = alertsRaw
        .filter(OffSiteReordersUtils.isSnoozedAlert)
        .reduce(AppUtilsService.groupDataByItem("offSiteItemId"), {});

    // COMPLETED ALERTS
    alerts[AppOffSiteAlertLocalTypes.COMPLETED] = alertsRaw
        .filter(OffSiteReordersUtils.isCompletedAlert)
        .reduce(AppUtilsService.groupDataByItem("offSiteItemId"), {});

    return alerts;
}

function isOpenAlert(alert: OffSiteReordersAlertDisplay) {
    return alert.type === AppOffSiteAlertLocalTypes.OPEN;
}

function isSnoozedAlert(alert: OffSiteReordersAlertDisplay) {
    return alert.type === AppOffSiteAlertLocalTypes.SNOOZED;
}

function isCompletedAlert(alert: OffSiteReordersAlertDisplay) {
    return alert.type === AppOffSiteAlertLocalTypes.COMPLETED;
}

function getOpenAlertsList(reordersAlerts: OffSiteReordersAlertsType) {
    if (!reordersAlerts) {
        return [];
    }
    return Object.values(reordersAlerts[AppOffSiteAlertLocalTypes.OPEN] || {})
        .flat()
        .sort(sortOpenAlerts);
}

function getSnoozedAlertsList(reordersAlerts: OffSiteReordersAlertsType) {
    if (!reordersAlerts) {
        return [];
    }
    return Object.values(reordersAlerts[AppOffSiteAlertLocalTypes.SNOOZED] || {})
        .flat()
        .sort(sortSnoozedAlerts);
}

function getCompletedAlertsList(reordersAlerts: OffSiteReordersAlertsType) {
    if (!reordersAlerts) {
        return [];
    }
    return Object.values(reordersAlerts[AppOffSiteAlertLocalTypes.COMPLETED] || {})
        .flat()
        .sort(sortCompletedAlerts);
}

async function completeAlert(
    alert: OffSiteReordersAlertDisplay,
    userName: string
): Promise<OffSiteReordersAlertDisplay> {
    const alertUpdateData = {
        ...alert.data,
        status: AppOffSiteAlertStatuses.COMPLETED,
        lastUpdatedBy: userName,
    };

    await AppOffSiteAlert.getModel().api.update(alertUpdateData, true);

    alert.data.status = AppOffSiteAlertStatuses.COMPLETED;
    alert.data.lastUpdatedBy = userName;
    alert.updatedAtMoment = moment().tz("America/New_York");
    alert.type = AppOffSiteAlertLocalTypes.COMPLETED;

    return _.clone(alert);
}

async function undoCompleteAlert(
    alert: OffSiteReordersAlertDisplay,
    userName: string
): Promise<OffSiteReordersAlertDisplay> {
    const alertUpdateData = {
        ...alert.data,
        status: AppOffSiteAlertStatuses.PENDING,
        lastUpdatedBy: userName,
    };

    await AppOffSiteAlert.getModel().api.update(alertUpdateData, true);
    const currentMoment = moment().tz("America/New_York");
    const snoozedUntilMoment = currentMoment.subtract(1, "days");
    const snoozedUntil = snoozedUntilMoment.toISOString();

    alert.data.status = AppOffSiteAlertStatuses.PENDING;
    alert.data.lastUpdatedBy = userName;
    alert.data.snoozedUntil = snoozedUntil;
    alert.snoozedUntilMoment = snoozedUntilMoment;
    alert.updatedAtMoment = currentMoment;
    alert.type = getAlertType(alert);

    return _.clone(alert);
}

async function snoozeAlert(
    alert: OffSiteReordersAlertDisplay,
    userName: string,
    snoozeDuration: OffSiteReordersSnoozedDurationType
): Promise<OffSiteReordersAlertDisplay> {
    const snoozedUntilMoment = snoozeDuration.getMoment();
    const snoozedUntil = snoozedUntilMoment.toISOString();

    const alertUpdateData = {
        ...alert.data,
        snoozedUntil,
        lastUpdatedBy: userName,
    };

    await AppOffSiteAlert.getModel().api.update(alertUpdateData, true);

    alert.snoozedUntilMoment = snoozedUntilMoment;
    alert.data.snoozedUntil = snoozedUntil;
    alert.data.lastUpdatedBy = userName;
    alert.updatedAtMoment = moment().tz("America/New_York");
    alert.type = getAlertType(alert);

    return _.clone(alert);
}

function moveAlertToNewType(
    alerts: OffSiteReordersAlertsType,
    alert: OffSiteReordersAlertDisplay,
    prevType: string,
    newType: string
): OffSiteReordersAlertsType {
    const result = _.clone(alerts);
    const offSiteItemId = alert.data.offSiteItemId;

    result[newType][offSiteItemId] = result?.[prevType]?.[offSiteItemId];
    delete result[prevType]?.[offSiteItemId];

    return result;
}

const OffSiteReordersUtils = {
    getLastOffSiteAlerts,
    sendOffSiteAlert,
    loadReordersAlertsDecorated,
    sortOpenAlerts,
    sortSnoozedAlerts,
    sortCompletedAlerts,
    isOpenAlert,
    isSnoozedAlert,
    isCompletedAlert,
    getOpenAlertsList,
    getSnoozedAlertsList,
    getCompletedAlertsList,
    getAlertType,
    completeAlert,
    undoCompleteAlert,
    snoozeAlert,
    moveAlertToNewType,
};

export default OffSiteReordersUtils;
