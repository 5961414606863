import ContainerConfigBuilder from "../ContainerConfigBuilder";
import { ContainerPaths } from "../ContainerPaths";
import SignOut from "./SignOut";
import SignOutMenuItem from "./SignOutMenuItem";

export const SignOutContainerConfig = new ContainerConfigBuilder("Sign Out")
    .setPath(ContainerPaths.SIGN_OUT)
    .setUserAccess([])
    .setComponent(SignOut)
    .setMenuItem(SignOutMenuItem)
    .build();
