import React, { useContext, useEffect } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import AppUtilsService from "../../../services/AppUtilsService";
import { AppContext } from "../../../context/AppContext";

const DeliveryDpsRestaurantSelector = ({ state, setState }) => {
    const { appState } = useContext(AppContext);

    useEffect(() => {}, [appState.section.name]);

    if (!appState?.section?.name) {
        return <div />;
    }

    const onSelectRestaurant = (event) => {
        const restaurant = event.target.value;
        AppUtilsService.setRestaurant(
            appState.section.name,
            "pull",
            restaurant,
            state.config.router
        );
        setState((prevState) => {
            return { ...prevState, restaurant };
        });
    };

    return (
        <FormControl>
            <Select
                labelId="delivery-dps-restaurant"
                id="delivery-dps-restaurant"
                value={state.restaurant}
                onChange={onSelectRestaurant}
            >
                {Object.values(state.restaurants).map((restaurant, index) => (
                    <MenuItem key={`delivery-dps-${index}`} value={restaurant}>
                        {restaurant.data.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default DeliveryDpsRestaurantSelector;
