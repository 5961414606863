import React from "react";

const MenusSalesNameDescriptor = ({ salesType, alias, fileName }) => {
    const day = salesType === "SINGLE" ? "day" : "days";
    return (
        <span>
            <strong>{alias}</strong>'s <strong>SALES</strong> for <strong>{salesType}</strong> {day}
            : {fileName}
        </span>
    );
};

export default MenusSalesNameDescriptor;
