import React, { useContext } from "react";
import DeliveryOrdersContentHeader from "./DeliveryOrdersContentHeader";
import deliveryOrdersStyles from "../DeliveryOrders.module.css";
import DeliveryOrdersItem from "../item/DeliveryOrdersItem";
import DeliveryOrdersContentVendor from "./DeliveryOrdersContentVendor";
import { DeliveryOrdersContext } from "../context/DeliveryOrdersContext";
import { ALL_VENDOR } from "../DeliveryOrdersValues";

const DeliveryOrdersContent = () => {
    const { deliveryOrders } = useContext(DeliveryOrdersContext);

    const vendor = deliveryOrders.restaurant.displayVendor;
    const vendors = deliveryOrders.restaurant.vendors;

    if (ALL_VENDOR.isAllVendor(vendor)) {
        return (
            <div>
                <div className={deliveryOrdersStyles.Content}>
                    {vendors.map((vendor) => (
                        <DeliveryOrdersContentVendor vendor={vendor} />
                    ))}
                </div>
            </div>
        );
    }

    return (
        <div>
            <DeliveryOrdersContentHeader />
            <div className={deliveryOrdersStyles.Content}>
                {vendor.orders.map(DeliveryOrdersItem)}
            </div>
        </div>
    );
};

export default DeliveryOrdersContent;
