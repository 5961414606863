import deliveryFoodStyles from "../../../../DeliveryFood.module.css";
import { DeliveryFoodItemCommissaryCell } from "../../../../types/DeliveryFoodTypes";
import { DeliveryFoodItemCommissaryOnHandCell } from "./DeliveryFoodItemCommissaryOnHandCell";
import { DeliveryFoodItemCommissaryPrepCell } from "./DeliveryFoodItemCommissaryPrepCell";
import { DeliveryFoodItemCommissaryPrepForDeliveryCell } from "./DeliveryFoodItemCommissaryPrepForDeliveryCell";
import { DeliveryFoodItemCommissaryPrepForDeliveryTimeCell } from "./DeliveryFoodItemCommissaryPrepForDeliveryTimeCell";
import { DeliveryFoodItemCommissaryPrepTimeCell } from "./DeliveryFoodItemCommissaryPrepTimeCell";

const DeliveryFoodItemCommissary = (config: DeliveryFoodItemCommissaryCell) => {
    const { commissary, index } = config;

    const key = `food-item-commissary-${commissary.data.id}-${index}`;

    return (
        <div key={key} className={deliveryFoodStyles.ItemRestaurant}>
            <DeliveryFoodItemCommissaryPrepForDeliveryCell config={config} />
            <DeliveryFoodItemCommissaryOnHandCell config={config} />
            <DeliveryFoodItemCommissaryPrepCell config={config} />
            <DeliveryFoodItemCommissaryPrepTimeCell config={config} />
            <DeliveryFoodItemCommissaryPrepForDeliveryTimeCell config={config} />
        </div>
    );
};

export default DeliveryFoodItemCommissary;
