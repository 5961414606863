import { IconButton } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { useContext } from "react";
import { FcAddDatabase } from "react-icons/fc";
import { useGlobalSpinnerActionsContext } from "../../../../components/global-spinner/GlobalSpinnerContext";
import { AppContext } from "../../../../context/AppContext";
import { Resource } from "../../../../resources/Resource";
import { seriesPromises } from "../../../../resources/ResourcesService";
import AppUserGroups from "../../../../services/AppUserGroups";
import { DataEditorContext } from "../../context/DataEditorContext";
import DataEditorUtils from "../../utils/DataEditorUtils";

const DataEditorAddAllBtn = () => {
    const setGlobalSpinner = useGlobalSpinnerActionsContext();
    const { appState } = useContext(AppContext);
    const { dataEditorState, updateResourceItems: replaceResourceItems } =
        useContext(DataEditorContext);

    const addAllFromBackup = async () => {
        if (!window.confirm("Are you sure you want to add all items from last backup?")) {
            return;
        }

        // fetch items from backuo
        setGlobalSpinner(true);
        let itemsDataFromBackup = [];

        try {
            itemsDataFromBackup = await DataEditorUtils.fetchItemsFromBackup(
                dataEditorState.getSelectedResource()
            );
        } catch (e: any) {
            throw Error(e.message);
        } finally {
            setGlobalSpinner(false);
        }

        // create items from backup
        setGlobalSpinner(true);

        try {
            const items = itemsDataFromBackup?.data?.map((itemFromBackup: any) => {
                const selectedResource = dataEditorState.getSelectedResource();
                return new selectedResource(itemFromBackup);
            });

            const itemsCreationPromises = items.map((item: Resource) => {
                return dataEditorState.getSelectedResource().getModel().api.create(item.data);
            });

            await seriesPromises(itemsCreationPromises);

            replaceResourceItems(items);
        } catch (e: any) {
            throw Error(e.message);
        } finally {
            setGlobalSpinner(false);
        }
    };

    if (!AppUserGroups.hasDevAccess(appState.user.groups)) {
        return null;
    }

    return (
        <Tooltip title={"Add All from last backup"}>
            <IconButton onClick={addAllFromBackup} color="secondary" style={{ padding: "0 2px" }}>
                <FcAddDatabase size={16} />
            </IconButton>
        </Tooltip>
    );
};

export default DataEditorAddAllBtn;
