import DeliveryFoodModeUtils from "../services/DeliveryFoodModeUtils";
import DeliveryFoodUtils from "../services/DeliveryFoodUtils";
import { DeliveryFoodModeRootData } from "../types/DeliveryFoodModeTypes";
import { DeliveryFoodIngredient } from "../types/DeliveryFoodTypes";
import DeliveryFoodMode from "./DeliveryFoodMode";

export class DeliveryFoodPrepTimeMode extends DeliveryFoodMode {
    getToolbarInfo(state: any) {
        // in the case of prepTime extract the sum of all times for commissary, using context state
        const prepTimeTotalMinutes = state?.ingredients
            ?.map((ingredient: DeliveryFoodIngredient) => {
                return ingredient.results.COM?.prepTime.total || 0;
            })
            .reduce((a: any, c: any) => a + c, 0);

        const totalTimeDisplay = DeliveryFoodUtils.getHoursDisplay(prepTimeTotalMinutes);

        return <div>Total: {totalTimeDisplay}</div>;
    }

    ingredientsFilter(mode: DeliveryFoodMode, ingredient: DeliveryFoodIngredient, index: number) {
        const { dataForDelivery, results } = ingredient;

        if (!dataForDelivery || !results) {
            return false;
        }

        const hasPrepValues = (ingredient.results.COM?.prep.total || 0) > 0;

        if (hasPrepValues) {
            console.info(`Selected ${ingredient.data.name} for prep!`);
        }

        return hasPrepValues;
    }

    constructor(rootData: DeliveryFoodModeRootData) {
        super(rootData);
        this.label = "Prep Times";
        const { restaurants } = rootData;
        this.display = DeliveryFoodModeUtils.buildPrepTimesModeDisplay(restaurants);
    }
}
