import ContainerConfigBuilder from "../ContainerConfigBuilder";
import { ContainerPaths } from "../ContainerPaths";
import { ADMINS, MANAGERS } from "../../services/AppUserGroupsData";
import MenusWrp from "./MenusWrp";
import MenusMenuItem from "./MenusMenuItem";

export const MenusContainerConfig = new ContainerConfigBuilder("Menus")
    .setPath(ContainerPaths.MENUS)
    .setUserAccess([MANAGERS, ADMINS])
    .setComponent(MenusWrp)
    .setMenuItem(MenusMenuItem)
    .build();
