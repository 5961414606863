import React from "react";
import deliveryOrdersStyles from "../DeliveryOrders.module.css";
import DeliveryOrdersRestaurantSelector from "./DeliveryOrdersRestaurantSelector";
import DeliveryOrdersVendorSelector from "./vendor/DeliveryOrdersVendorSelector";
import DeliveryOrdersActions from "./DeliveryOrdersActions";
import DeliveryOrdersEmailDialog from "../email/DeliveryOrdersEmailDialog";

const DeliveryOrdersHeader = () => {
    return (
        <div className={deliveryOrdersStyles.Header}>
            <div>
                <DeliveryOrdersRestaurantSelector />
                <DeliveryOrdersVendorSelector />
                <DeliveryOrdersEmailDialog />
            </div>
            <DeliveryOrdersActions />
        </div>
    );
};

export default DeliveryOrdersHeader;
