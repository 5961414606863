import React from "react";

export default function OnHandTShirtItemConfirm(item, itemIndex) {
    return (
        <div key={`confirm-changed-item-${itemIndex}`}>
            <strong style={{ textAlign: "right" }}>{item.data.display}</strong>
            <span style={{ textAlign: "center" }}>{item.inventory.data.quantity}</span>
        </div>
    );
}
