import React from "react";
import deliveryOrdersStyles from "../../DeliveryOrders.module.css";
import DeliveryOrdersUtils from "../../DeliveryOrdersUtils";

const DeliveryOrdersCellOnHand = ({ order }) => {
    const inventory = DeliveryOrdersUtils.extractInventoryQty(order);
    const onHandUnit = order.onHandUnit.data.name;

    return (
        <span className={`no-print ${deliveryOrdersStyles.Explained}`}>{`${
            inventory === null ? "?" : inventory
        } ${onHandUnit}`}</span>
    );
};

export default DeliveryOrdersCellOnHand;
