import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { useContext } from "react";
import { OffSiteContext } from "../../context/OffSiteContext";
import styles from "../OffSiteTasks.module.css";
import OffSiteTasksDialogActions from "./OffSiteTasksDialogActions";
import OffSiteTasksDialogContent from "./OffSiteTasksDialogContent";

export default function OffSiteTasksDialog() {
    const { offSiteState } = useContext(OffSiteContext);

    return (
        <Dialog
            className={styles.TasksDialog}
            maxWidth={false}
            fullWidth={true}
            open={offSiteState.showDeliveryTasksDialog}
        >
            <DialogContent className={styles.TasksDialogContent} dividers>
                <OffSiteTasksDialogContent />
            </DialogContent>
            <DialogActions className={styles.TasksDialogActions}>
                <OffSiteTasksDialogActions />
            </DialogActions>
        </Dialog>
    );
}
