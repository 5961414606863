import { ReactNode } from "react";
import ContainerConfigBuilder from "../ContainerConfigBuilder";

class OffSiteContainerConfigBuilder extends ContainerConfigBuilder {
    public tasksHeaderComponent: ReactNode = null;

    setTasksHeaderComponent(tasksHeaderComponent: React.ReactNode) {
        this.tasksHeaderComponent = tasksHeaderComponent;
        return this;
    }

    build() {
        const containerConfig = super.build();
        containerConfig.tasksHeaderComponent = this.tasksHeaderComponent;
        return containerConfig;
    }
}

export default OffSiteContainerConfigBuilder;
