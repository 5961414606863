import {
    DeliveryFoodSummaryConfigsSubType,
    DeliveryFoodIngredient,
} from "../../../types/DeliveryFoodTypes";

export const DeliveryFoodSummaryPrepForDeliveryTimeConfigs: DeliveryFoodSummaryConfigsSubType = {
    title: ({ ingredient }: { ingredient: DeliveryFoodIngredient }) => {
        return (
            <div>
                Prep for delivery Time for <strong>{ingredient.data.name}</strong>
            </div>
        );
    },
    content: ({
        data,
        total,
        totalDisplay,
    }: {
        data: any;
        total: number | string | null;
        totalDisplay: number | string | null;
    }) => {
        return (
            <div>
                <ul>
                    <li>
                        <strong>Labor time</strong>: {data?.laborTime} minutes
                    </li>
                    <li>
                        <strong>Batch Size</strong>: {data?.batchSize}
                    </li>
                    <li>
                        <strong>Portion time</strong>: {data?.portionTime} minutes
                    </li>
                    <li>
                        <strong>Prep quantity</strong>: {data?.prepTotal}
                    </li>
                </ul>
                <div>
                    <strong>Total minutes:</strong> {total}
                </div>
                <div>
                    <strong>Total time:</strong> {totalDisplay}
                </div>
            </div>
        );
    },
};
