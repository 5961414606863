import React from "react";
import deliveryTShirtsStyles from "../TShirtsStocking.module.css";
import { MdBlock, MdDone } from "react-icons/md";

const INVALID = {
    name: "INVALID",
    render: () => {
        return (
            <div
                className={`${deliveryTShirtsStyles.Status} ${deliveryTShirtsStyles.StatusInvalid}`}
            >
                <MdBlock />
            </div>
        );
    },
};

const NEEDS_ON_HAND = {
    name: "NEEDS_ON_HAND",
    render: (text, selected) => {
        if (typeof text !== "string") {
            text = null;
        }
        let className = `${deliveryTShirtsStyles.Status} ${deliveryTShirtsStyles.StatusNeedsOnHand}`;
        if (selected) {
            className += ` ${deliveryTShirtsStyles.Selected}`;
        }
        return (
            <div className={className}>
                <span>{text || "No On Hand"}</span>
            </div>
        );
    },
};

const NEEDS_STOCKING = {
    name: "NEEDS_STOCKING",
    canSelect: true,
    render: (requiredStocks, selected) => {
        const content = requiredStocks === 1 ? " 1 Pack" : ` ${requiredStocks} Packs`;
        let className = `${deliveryTShirtsStyles.Status} ${deliveryTShirtsStyles.StatusNeedsStocking}`;
        if (selected) {
            className += ` ${deliveryTShirtsStyles.Selected}`;
        }
        return (
            <div className={className}>
                Needs <strong>{content}</strong>
            </div>
        );
    },
};

const COMPLETED = {
    name: "COMPLETED",
    render: () => {
        return (
            <div
                className={`${deliveryTShirtsStyles.Status} ${deliveryTShirtsStyles.StatusCompleted}`}
            >
                <MdDone />
            </div>
        );
    },
};

const getStatus = (requiredStocks, lastUpdatedBy) => {
    if (!lastUpdatedBy) {
        return NEEDS_ON_HAND;
    }
    if (requiredStocks === 0) {
        return COMPLETED;
    }
    if (requiredStocks > 0) {
        return NEEDS_STOCKING;
    }
    return INVALID;
};

export { getStatus, INVALID, NEEDS_ON_HAND, NEEDS_STOCKING, COMPLETED };
