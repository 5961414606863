import styles from "./OffSiteStockingItemClearInput.module.css";
import { Button } from "@material-ui/core";
import React, { useContext } from "react";
import { OnHandContext } from "../../../on-hand/context/OnHandContext";

export default function OffSiteStockingItemClearInput() {
    const { onSelectedItemStockingTransactionReset } = useContext(OnHandContext) as any;

    const handleReset = () => {
        // TODO: do not allow values below the total amount required; until a "partial completion" solution is found
        return onSelectedItemStockingTransactionReset();
    };

    return (
        <div className={styles.Main}>
            <Button onClick={handleReset} size={"small"} variant="contained" color={"primary"}>
                <span>Clear</span>
            </Button>
        </div>
    );
}
