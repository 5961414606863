import React, { useContext } from "react";
import { DataEditorProvider } from "../../data-editor/context/DataEditorContext";
import DataEditor from "../../data-editor/DataEditor";
import { AppRestaurant } from "../../admin/AdminResources";
import {
    AppDpsIngredient,
    AppDpsMenuItem,
    AppDpsMenuItemSale,
    AppDpsRecipeItem,
    AppDpsMenuItemSaleMeta,
    AppDpsPack,
    AppDpsEmail,
    AppDpsEmailRecipient,
} from "../DpsResources";
import { DataEditorResource } from "../../data-editor/types/DataEditorTypes";
import { AppContext } from "../../../context/AppContext";
import AppUserGroups from "../../../services/AppUserGroups";

const DataEditorDps = (props: any) => {
    const { appState } = useContext(AppContext);
    const { history, location } = props;

    let resources = [];

    const adminResources = [
        new DataEditorResource(AppDpsIngredient, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppDpsMenuItem, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppDpsRecipeItem, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppDpsPack, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppDpsEmail, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppDpsEmailRecipient, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppDpsMenuItemSale, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppDpsMenuItemSaleMeta, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppRestaurant, { isVisibleInDataEditor: false }),
    ];

    const nonAdminResources: DataEditorResource[] = [
        new DataEditorResource(AppDpsIngredient, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppDpsPack, { isVisibleInDataEditor: false }),
        new DataEditorResource(AppRestaurant, { isVisibleInDataEditor: false }),
    ];

    if (AppUserGroups.hasAdminAccess(appState.user.groups)) {
        resources = adminResources;
    } else {
        resources = nonAdminResources;
    }

    return (
        <DataEditorProvider resources={resources} location={location} history={history}>
            <DataEditor location={location} />
        </DataEditorProvider>
    );
};

export default DataEditorDps;
