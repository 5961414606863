import React, { useContext, useEffect } from "react";
import OnHandItemMetaData from "../../../on-hand/components/item/OnHandItemMetaData";
import { OnHandContext } from "../../../on-hand/context/OnHandContext";
import onHandItemStyles from "../../../on-hand/components/item/OnHandItem.module.css";

// TODO: make generic
const OnHandTShirtItemMeta = () => {
    const { onHandState } = useContext(OnHandContext);

    useEffect(() => {}, [onHandState.restaurant.group.item]);

    const item = onHandState.restaurant.group.item;

    if (!(item.inventory && item.inventory.data)) {
        console.info("Invalid or missing inventory!");
        return <div />;
    }

    const {
        inventory: {
            data: { lastUpdatedBy, lastUpdatedAt },
        },
    } = item;

    return (
        <div className={onHandItemStyles.Meta}>
            <OnHandItemMetaData lastUpdatedBy={lastUpdatedBy} lastUpdatedAt={lastUpdatedAt} />
        </div>
    );
};

export default OnHandTShirtItemMeta;
