import React from "react";
import OnHandWrp from "../../on-hand/OnHandWrp";
import OnHandConfigModel from "../../on-hand/models/OnHandConfigModel";
import OnHandFoodItemTab from "./display/OnHandFoodItemTab";
import OnHandFoodItem from "./display/OnHandFoodItem";
import OnHandItemDisplayModel from "../../on-hand/models/OnHandItemDisplayModel";
import OnHandFoodContextConfig from "./context/OnHandFoodContextConfig";

const OnHandFood = ({ history, location }) => {
    const config = new OnHandConfigModel(
        OnHandFoodContextConfig,
        new OnHandItemDisplayModel(OnHandFoodItemTab, OnHandFoodItem),
        { history, location }
    );

    return <OnHandWrp config={config}>{console.info("RENDER: OnHandFood")}</OnHandWrp>;
};

export default OnHandFood;
