import React from "react";
import { FaWarehouse } from "react-icons/fa";
import { DEVS, MANAGERS, UserGroupType } from "../../services/AppUserGroupsData";
import ContainerBasePaths from "../ContainerBasePaths";
import { ContainerConfig } from "../ContainerConfig";
import { Section } from "../sections/Section";
import { OffSiteContainerConfig } from "./OffSiteContainerConfig";

export class OffSiteSection extends Section {
    renderIcon(disabled: boolean) {
        return <FaWarehouse size={this.iconSize} color={"inherit"} />;
    }

    renderHeaderIcon() {
        return <FaWarehouse size={this.iconHeaderSize} color={this.iconHeaderColor} />;
    }

    renderHomeIcon() {
        return <FaWarehouse size={this.iconHomeSize} color={this.iconHomeColor} />;
    }

    getDefaultPath(userGroups: UserGroupType[]) {
        if (userGroups.includes(DEVS)) {
            return OffSiteContainerConfig.DataEditor.url;
        }

        if (userGroups.includes(MANAGERS)) {
            return OffSiteContainerConfig.Delivery.url;
        }

        console.error("Couldn't match the user to any known section pages", userGroups);
        return super.getDefaultPath(userGroups);
    }

    constructor(
        pages: ContainerConfig[],
        name: string,
        allowedGroups: UserGroupType[],
        disabled: boolean,
        tasksComponent: any,
        wrapperContextProvider: any
    ) {
        super(
            pages,
            name,
            allowedGroups,
            disabled,
            ContainerBasePaths.OFF_SITE,
            tasksComponent,
            wrapperContextProvider
        );
    }
}
