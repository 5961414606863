import {
    ADMINS,
    DEVS,
    KITCHEN_DELIVERY_USERS,
    KITCHEN_USERS,
    MANAGERS,
} from "../../services/AppUserGroupsData";
import { MenusContainerConfig } from "../menus-sales/MenusContainerConfig";
import { SectionNames } from "../sections/SectionNames";
import { UserGroupType } from "./../../services/AppUserGroupsData";
import { ContainerConfig } from "./../ContainerConfig";
import { FoodContainerConfig } from "./FoodContainerConfig";
import { FoodSection } from "./FoodSection";

const foodSectionPages: ContainerConfig[] = [
    FoodContainerConfig.OnHand,
    MenusContainerConfig,
    FoodContainerConfig.Delivery,
    FoodContainerConfig.DataEditor,
];

const foodSectionAllowedUserGroups: UserGroupType[] = [
    KITCHEN_USERS,
    KITCHEN_DELIVERY_USERS,
    MANAGERS,
    ADMINS,
    DEVS,
];

const isFoodSectionDisabled = false;

export const FoodSectionConfig = new FoodSection(
    foodSectionPages,
    SectionNames.FOOD,
    foodSectionAllowedUserGroups,
    isFoodSectionDisabled
);
