import MenusResolver from "../../menus-sales/services/MenusResolver";
import { fetchResources } from "../../../resources/ResourcesService";
import AppUtilsService from "../../../services/AppUtilsService";
import { AppDpsEmailRecipient, AppDpsPack } from "../DpsResources";

async function loadData(
    setGlobalSpinner,
    setState,
    userGroups,
    day,
    userName,
    sectionName,
    router
) {
    setGlobalSpinner(true);
    const restaurants = await MenusResolver(userGroups, day, userName, sectionName);
    const restaurant = AppUtilsService.loadRestaurant(sectionName, "pull", restaurants, router);
    const [AppDpsPacks, AppDpsEmailRecipients] = await fetchResources([
        [AppDpsPack],
        [AppDpsEmailRecipient],
    ]);
    const packsById = AppDpsPacks.reduce(AppUtilsService.groupDataByItem("id"), {});
    const recipients = AppDpsEmailRecipients.reduce(
        AppUtilsService.groupDataByList("restaurantId"),
        {}
    );
    const ingredients = extractIngredientsDelivery(restaurant?.menu || [], packsById || {});
    setGlobalSpinner(false);

    setState((prevState) => {
        return { ...prevState, restaurants, restaurant, ingredients, packsById, recipients };
    });
}

function isValidState({ restaurant }) {
    return !!restaurant;
}

function plural(count, display) {
    return `${display}${count > 1 && display !== "Each" ? "s" : ""}`;
}

function roundOffSeason(value) {
    return Math.ceil(value * 10) / 10;
}

function roundOnSeason(value) {
    return Math.ceil(value);
}

/**
 *  Map ingredients by id after attaching total amount sold, using the menu recipes and sales
 */
function computeSoldIngredient(recipeItem, soldQuantity) {
    if (!recipeItem || !recipeItem.data || typeof soldQuantity !== "number" || soldQuantity <= 0) {
        return null;
    }

    const ingredient = JSON.parse(JSON.stringify(recipeItem?.ingredient));
    const ingredientQuantityInRecipe = recipeItem?.data?.quantity || 0;
    const quantity = ingredientQuantityInRecipe * soldQuantity;

    return { quantity, ingredient };
}

function computeSoldIngredientTotal(menuItem) {
    const {
        recipe,
        sales: { SINGLE },
    } = menuItem;

    if (!Array.isArray(recipe)) {
        return [];
    }

    if (typeof SINGLE?.data?.quantity !== "number" || SINGLE.data.quantity <= 0) {
        return [];
    }

    const soldQuantity = SINGLE?.data?.quantity || 0;

    return recipe
        .map((recipeItem) => computeSoldIngredient(recipeItem, soldQuantity))
        .filter((x) => !!x);
}

function aggregateIngredientSoldTotals(ingredientsById, { quantity, ingredient }) {
    ingredientsById[ingredient.data.id] = ingredientsById[ingredient.data.id] || ingredient;
    ingredientsById[ingredient.data.id].sold = ingredientsById[ingredient.data.id].sold || 0;
    ingredientsById[ingredient.data.id].sold += quantity;
    return ingredientsById;
}

function ingredientsWithSoldTotalReducer(menu) {
    return (
        menu
            // compose an array with each occurrence of an ingredient in a recipe and the total quantity
            .map(computeSoldIngredientTotal)
            .flat()
            // cluster the occurrences of the same ingredient by ingredient Id
            .reduce(aggregateIngredientSoldTotals, {})
    );
}

function computeIngredientDeliveryDisplay(ingredient) {
    const { soldDisplay } = ingredient;

    return `${soldDisplay} ${computeIngredientPacksDeliveryDisplay(ingredient)}`;

    /* ------- IMPL ------- */
    function isMissing(value) {
        return value === null || value === undefined;
    }

    function isInvalidSize(value) {
        return isMissing(value) || typeof value !== "number" || value <= 0;
    }

    function isMissingSubSubPack(ingredient) {
        const {
            subSubDpsPack,
            data: { subDpsPackSize },
        } = ingredient;
        return isMissing(subSubDpsPack) && isInvalidSize(subDpsPackSize);
    }

    function computeIngredientPacksDeliveryDisplay(ingredient) {
        if (isMissingSubSubPack(ingredient)) {
            return computeWithoutSubSubPack(ingredient);
        }
        return computeWithSubSubPack(ingredient);

        /* ------- IMPL ------- */
        function computeWithoutSubSubPack(ingredient) {
            const {
                sold,
                dpsPack,
                data: { dpsPackSize, useSubPack, ratio },
            } = ingredient;

            // since 3rd package doesn't exist and the 2nd one should be used, nothing should be displayed
            if (useSubPack) {
                return "";
            }

            const packTotal = roundOffSeason((sold * (ratio || 1)) / dpsPackSize);
            return `(${packTotal} ${plural(packTotal, dpsPack.data.name)})`;
        }

        function computeWithSubSubPack(ingredient) {
            const {
                sold,
                dpsPack,
                subDpsPack,
                data: { dpsPackSize, subDpsPackSize, useSubPack, ratio },
            } = ingredient;

            // ignore the main pack and divide by the secondary one only
            const packSize = dpsPackSize * subDpsPackSize;
            let packsSold = Math.floor((sold * (ratio || 1)) / packSize);
            const packsSoldRemainder = sold - packsSold * packSize;
            let subPacksSold = roundOffSeason(packsSoldRemainder / dpsPackSize);

            if (useSubPack) {
                subPacksSold += packsSold * dpsPackSize;
                packsSold = 0;
            }

            if (packsSold === 0) {
                // display only subPacks
                return `(${subPacksSold} ${plural(subPacksSold, subDpsPack.data.name)})`;
            }

            if (subPacksSold === 0) {
                // display only packs
                return `(${packsSold} ${plural(packsSold, dpsPack.data.name)})`;
            }

            // display both packs and subPacks
            return (
                `(${packsSold} ${plural(packsSold, dpsPack.data.name)}` +
                " and " +
                `${subPacksSold} ${plural(subPacksSold, subDpsPack.data.name)})`
            );
        }
    }
}

// gets the smallest pack type and the total quantity sold as a string for display
function extractIngredientsDelivery(menu, packsById) {
    const ingredientsWithSoldTotal = ingredientsWithSoldTotalReducer(menu);

    // attach packs
    Object.keys(ingredientsWithSoldTotal).forEach((ingredientId) => {
        let ingredient = ingredientsWithSoldTotal[ingredientId];
        ingredient.dpsPack = packsById[ingredient.data.dpsPackId];
        ingredient.subDpsPack = packsById[ingredient.data.subDpsPackId];
        ingredient.subSubDpsPack = packsById[ingredient.data.subSubDpsPackId];
        ingredient.soldPack =
            ingredient.subSubDpsPack ?? ingredient.subDpsPack ?? ingredient.dpsPack;
        const ratioDisplay = ingredient.data.ratio ? `${ingredient.data.ratio} * ` : "";
        ingredient.soldDisplay = `${ratioDisplay}${roundOffSeason(ingredient.sold)} ${
            ingredient?.soldPack?.data?.name || "Unknown"
        }`;
        ingredient.deliveryDisplay = computeIngredientDeliveryDisplay(ingredient);
        ingredientsWithSoldTotal[ingredientId] = ingredient;
    });

    return Object.values(ingredientsWithSoldTotal);
}

function getRestaurantRecipients(restaurant, recipients) {
    return recipients[restaurant.data.id];
}

const DeliveryDpsUtils = {
    extractIngredientsDelivery,
    loadData,
    isValidState,
    getRestaurantRecipients,
};

export default DeliveryDpsUtils;
