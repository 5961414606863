import { ContainerConfigSection } from "./../ContainerConfigSection";
import ContainerConfigBuilder from "../ContainerConfigBuilder";
import { ContainerPaths } from "../ContainerPaths";
import {
    ADMINS,
    DATA_EDITOR_USERS,
    KITCHEN_DELIVERY_USERS,
    KITCHEN_USERS,
    MANAGERS,
} from "../../services/AppUserGroupsData";
import OnHandFood from "./on-hand/OnHandFood";
import OnHandFoodMenuItem from "./on-hand/OnHandFoodMenuItem";
import DeliveryFoodWrp from "./delivery/DeliveryFoodWrp";
import DeliveryFoodMenuItem from "./delivery/DeliveryFoodMenuItem";
import DataEditorFood from "./data-editor/DataEditorFood";
import DataEditorFoodMenuItem from "./data-editor/DataEditorFoodMenuItem";

export const FoodContainerConfig: ContainerConfigSection = {
    OnHand: new ContainerConfigBuilder("On Hand")
        .setPath(ContainerPaths.ON_HAND)
        .setUserAccess([KITCHEN_USERS, MANAGERS, ADMINS])
        .setComponent(OnHandFood)
        .setMenuItem(OnHandFoodMenuItem)
        .build(),
    Delivery: new ContainerConfigBuilder("Delivery")
        .setPath(ContainerPaths.DELIVERY)
        .setUserAccess([KITCHEN_DELIVERY_USERS, ADMINS])
        .setComponent(DeliveryFoodWrp)
        .setMenuItem(DeliveryFoodMenuItem)
        .build(),
    DataEditor: new ContainerConfigBuilder("Editor")
        .setPath(ContainerPaths.EDITOR)
        .setUserAccess([DATA_EDITOR_USERS, ADMINS])
        .setComponent(DataEditorFood)
        .setMenuItem(DataEditorFoodMenuItem)
        .build(),
};
