import AppDayModel from "../../../components/day/AppDayModel";

export default class OnHandItemModel {
    static isCountedTransaction(item) {
        // TODO: make it countable
        return false;
    }

    static isCountedInventory(item) {
        const {
            status,
            inventory: {
                mock,
                data: { quantity },
            },
        } = item;

        return status === 2 && typeof quantity === "number" && !mock;
    }

    static isCounted(item) {
        return item?.inventory
            ? OnHandItemModel.isCountedInventory(item)
            : OnHandItemModel.isCountedTransaction(item);
    }

    updateStatus() {
        if (this.inventory && this.inventory.data) {
            this.status =
                (this.inventory.data.quantity > 0 || !!this.inventory.data.lastUpdatedBy) +
                (this.inventory.data.quantity >= 0 && !!this.inventory.data.lastUpdatedBy);
        } else {
            this.status = 0;
        }
        return this;
    }

    attachMetaData(userName, dateFormat) {
        if (!!userName && dateFormat) {
            if (this.inventory && this.inventory.data) {
                this.inventory.data.lastUpdatedBy = userName;
                this.inventory.data.lastUpdatedAt = AppDayModel.getCurrentUnixTime();
            }
        }
    }

    updateInventoryData(newInventoryData) {
        if (this.inventory && this.inventory.data) {
            this.inventory.data = newInventoryData;
        }
        return this;
    }

    constructor(appItem, dependencies) {
        Object.assign(this, appItem);
        Object.assign(this, dependencies);
        // making .inventory explicit
        this.inventory = dependencies.inventory;

        this.updateStatus();
    }
}
