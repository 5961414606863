import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import menusSalesDropZoneStyles from "./MenusSalesDropZone.module.css";

const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#dddddd",
    borderStyle: "dashed",
    backgroundColor: "transparent",
    color: "#999999",
    outline: "none",
    transition: "border .24s ease-in-out",
};

const activeStyle = {
    borderColor: "#2196f3",
    backgroundColor: "rgba(33,150,243,0.05)",
};

const acceptStyle = {
    borderColor: "#00e676",
    backgroundColor: "rgba(0,230,118,0.05)",
};

const rejectStyle = {
    borderColor: "#ff1744",
    backgroundColor: "rgba(255,23,68,0.05)",
};

const MenusSalesDropZone = ({ onUpdate, name, action, onLoad, requiredFileName }) => {
    const onDrop = useCallback(
        (acceptedFiles) => {
            if (acceptedFiles.length !== 1) {
                // TODO: make explicit error alert
                console.error("Invalid files count!");
                return;
            }

            if (requiredFileName && acceptedFiles[0].name !== requiredFileName) {
                // TODO: make explicit error alert
                console.error(
                    `Invalid file name; required: ${requiredFileName}, actual: ${acceptedFiles[0].name}`
                );
                return;
            }

            acceptedFiles.forEach((file) => {
                const reader = new FileReader();
                reader.onabort = () => console.log("file reading was aborted");
                reader.onerror = () => console.log("file reading has failed");
                reader.onload = () => onLoad(onUpdate, reader);
                reader.readAsBinaryString(file);
            });
        },
        [requiredFileName]
    );

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        onDrop,
        minSize: 100,
        maxSize: 300000,
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject]
    );

    return (
        <div>
            <div
                className={menusSalesDropZoneStyles.menusDropZoneArea}
                {...getRootProps({ style })}
            >
                <input {...getInputProps()} />
                <span>Drag files or click to upload</span>
                <span className={menusSalesDropZoneStyles.menusDropZoneTitle}>{name}</span>
            </div>
        </div>
    );
};

export default MenusSalesDropZone;
