import React from "react";
import onHandItemStyles from "../../../on-hand/components/item/OnHandItem.module.css";
import OffSiteDeliveryItemPrev from "./OffSiteDeliveryItemPrev";
import OffSiteDeliveryItemSkip from "./OffSiteDeliveryItemSkip";
import OffSiteDeliveryItemSubmit from "./OffSiteDeliveryItemSubmit";

export default function OffSiteDeliveryItemActions() {
    return (
        <div className={onHandItemStyles.Actions}>
            <OffSiteDeliveryItemPrev />
            <OffSiteDeliveryItemSubmit />
            <OffSiteDeliveryItemSkip />
        </div>
    );
}
