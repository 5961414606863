function getHoursDisplay(totalTimeMinutes: number) {
    const hours = Math.floor(totalTimeMinutes / 60);
    const hoursDisplay = `${hours}h`;
    const minutes = Math.ceil(totalTimeMinutes - hours * 60);
    const minutesDisplay = minutes > 0 ? ` ${minutes}m` : "";

    return `${hoursDisplay}${minutesDisplay}`;
}

const DeliveryFoodUtils = {
    getHoursDisplay,
};

export default DeliveryFoodUtils;
