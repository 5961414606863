import React from "react";
import styles from "../OffSiteTasks.module.css";

export default function OffSiteTasksDialogHeader() {
    return (
        <div className={`${styles.RestaurantTasksRow} ${styles.RestaurantTasksHeader}`}>
            <span>Item</span>
            <span>Qty</span>
            <span>From</span>
            <span>Status</span>
        </div>
    );
}
