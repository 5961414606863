import { useContext } from "react";
import { DeliveryFoodContext } from "../../../../DeliveryFoodContext";
import { DeliveryFoodItemCommissaryCell } from "../../../../types/DeliveryFoodTypes";
import DeliveryFoodCell from "../../DeliveryFoodCell";

export const DeliveryFoodItemCommissaryPrepForDeliveryTimeCell = ({
    config,
}: {
    config: DeliveryFoodItemCommissaryCell;
}) => {
    const { deliveryFoodState } = useContext(DeliveryFoodContext);
    const { commissary, index, ingredient } = config;

    if (deliveryFoodState.mode.display.COM.prepForDeliveryTime === false) {
        return null;
    }

    const key = `prep-for-delivery-time-cell-${index}`;
    const commissaryId: string | number = commissary.data?.id || "";

    if (!commissaryId) {
        return null;
    }

    if (!ingredient.results.COM) {
        return null;
    }

    const summary = ingredient.results.COM.prepForDeliveryTime;

    if (!summary) {
        return null;
    }

    return (
        <DeliveryFoodCell
            key={key}
            fieldName={"prepForDeliveryTime"}
            styleName={"SALE"}
            summary={summary}
            index={index}
            ingredient={ingredient}
            restaurant={commissary}
        />
    );
};
