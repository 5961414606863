import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import AppContextValidator from "../../context/AppContextValidator";

const SignOut = ({ location }) => {
    const history = useHistory();
    const { appState, signOut } = useContext(AppContext);

    if (!AppContextValidator.isValidUser(appState.user)) {
        console.info("Invalid user data!");
        return <div />;
    }

    if (window.confirm("Are you sure you want to sign out?")) {
        history.push("/");
        signOut();
    }

    return null;
};

export default SignOut;
