import { Model } from "../model/Model";
import { ModelConfig } from "../model/ModelConfig";
import { ResourceColumn } from "./ResourceColumn";

const defaultModel = new Model(
    new ModelConfig(
        "resource",
        0,
        0,
        "resourcesStoreKey",
        "resource",
        "resource",
        "resourceRef",
        []
    )
);

// TODO: find out why is it necessary for T to " T extends ..."

export type ResourceDataType = {
    __typename: string;
    [key: string]: any;
};

export type ResourceDisplayType = {
    [key: string]: string | undefined;
};

export class Resource {
    // TODO: update this property every time some item is initialized, updated, or replaced
    // - it starts from the equivalent definition in .data
    // - then goes from resourceRefKey to resourceRefKey until it gets a replacement ref value
    // - then it's transformed by the column definition
    private display: ResourceDisplayType = {};

    // All classes that extend Resource have their own static implementation of getModel
    static getModel(): Model {
        return defaultModel;
    }

    getModel(): Model {
        return this.model || defaultModel;
    }

    getDisplay() {
        return this.display;
    }

    setDisplay(display: ResourceDisplayType) {
        this.display = display;
    }

    // compare items based on all key columns
    equals(item: Resource): boolean {
        const keyColumns = this.getModel()
            .getColumns()
            .filter((column: ResourceColumn) => column.isKey);

        return keyColumns.reduce((acc: boolean, column: ResourceColumn) => {
            return acc && this.data[column.field] === item.data[column.field];
        }, true);
    }

    constructor(public data: ResourceDataType, public model?: Model) {}
}
