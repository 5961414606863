import { createContext, useCallback, useReducer } from "react";
import { Resource } from "../../../resources/Resource";
import {
    DataEditorContextState,
    DataEditorItemsType,
    DataEditorResource,
    DataEditorSortableItem,
    DataEditorSortQueueEntry,
    DataEditorState,
} from "../types/DataEditorTypes";
import { DataEditorAction } from "./DataEditorAction";
import { DataEditorReducer } from "./DataEditorReducer";

const DataEditorContext = createContext({} as DataEditorContextState);

const DataEditorProvider = (props: {
    children: any;
    resources: DataEditorResource[];
    history: any;
    location: any;
}): JSX.Element => {
    const { children, resources, history, location } = props;
    const [dataEditorState, dispatch] = useReducer(
        DataEditorReducer,
        new DataEditorState(resources, location)
    );

    const updateAllItems = useCallback(
        (items: DataEditorItemsType) => {
            const action: DataEditorAction = {
                type: "UPDATE_ALL_ITEMS",
                payload: {
                    items,
                },
            };

            return dispatch(action);
        },
        [dispatch]
    );

    const changeResource = useCallback(
        (selectedResource: typeof Resource) => {
            history.push(`/editor?resource=${selectedResource.getModel().config.initDataKey}`);

            const action: DataEditorAction = {
                type: "CHANGE_RESOURCE",
                payload: { selectedResource },
            };

            return dispatch(action);
        },
        [dispatch]
    );

    const reOrderSelectedItems = useCallback(
        (shuffledItems: DataEditorSortableItem[], pageStartIndex: number, pageEndIndex: number) => {
            const action: DataEditorAction = {
                type: "RE_ORDER_SELECTED_ITEMS",
                payload: { shuffledItems, pageStartIndex, pageEndIndex, enableReorder: true },
            };

            return dispatch(action);
        },
        [dispatch]
    );

    const saveOrder = useCallback(
        (resourceItems: Resource[]) => {
            const action: DataEditorAction = {
                type: "SAVE_ORDER",
                payload: { resourceItems, enableReorder: false },
            };

            return dispatch(action);
        },
        [dispatch]
    );

    const updateSelectedResourceSorting = useCallback(
        (newSortQueue: DataEditorSortQueueEntry[]) => {
            const action: DataEditorAction = {
                type: "UPDATE_SELECTED_RESOURCE_SORT_QUEUE",
                payload: { newSortQueue },
            };

            return dispatch(action);
        },
        [dispatch]
    );

    const updateSelectedResourcePageIndex = useCallback(
        (newPageIndex: number) => {
            const action: DataEditorAction = {
                type: "UPDATE_SELECTED_RESOURCE_PAGE_INDEX",
                payload: { newPageIndex },
            };

            return dispatch(action);
        },
        [dispatch]
    );

    const setSearchText = useCallback(
        (searchText: string) => {
            const action: DataEditorAction = {
                type: "SET_SEARCH_TEXT",
                payload: { searchText, enableReorder: searchText.length === 0 },
            };

            return dispatch(action);
        },
        [dispatch]
    );

    const clearAllItemsFromSelectedResource = useCallback(() => {
        const action: DataEditorAction = {
            type: "CLEAR_ALL_ITEMS_FROM_SELECTED_RESOURCE",
            payload: {},
        };

        return dispatch(action);
    }, [dispatch]);

    const updateItem = useCallback(
        (item: Resource) => {
            const action: DataEditorAction = {
                type: "REPLACE_ITEM",
                payload: { item },
            };

            return dispatch(action);
        },
        [dispatch]
    );

    const updateResourceItems = useCallback(
        (resourceItems: Resource[]) => {
            const action: DataEditorAction = {
                type: "UPDATE_RESOURCE_ITEMS",
                payload: { resourceItems },
            };

            return dispatch(action);
        },
        [dispatch]
    );

    const clearItem = useCallback(
        (resourceItem: Resource) => {
            const action: DataEditorAction = {
                type: "CLEAR_ITEM",
                payload: { resourceItem },
            };

            return dispatch(action);
        },
        [dispatch]
    );

    const addNewItem = useCallback(
        (resourceItem: Resource) => {
            const action: DataEditorAction = {
                type: "ADD_NEW_ITEM",
                payload: { resourceItem },
            };

            return dispatch(action);
        },
        [dispatch]
    );

    const openAddDialog = useCallback(
        (resourceItem: Resource) => {
            const action: DataEditorAction = {
                type: "OPEN_ADD_DIALOG",
                payload: { resourceItem },
            };

            return dispatch(action);
        },
        [dispatch]
    );

    const closeAddDialog = useCallback(
        (resourceItem: Resource) => {
            const action: DataEditorAction = {
                type: "CLOSE_ADD_DIALOG",
                payload: { resourceItem },
            };

            return dispatch(action);
        },
        [dispatch]
    );

    const value: DataEditorContextState = {
        dataEditorState,
        changeResource,
        reOrderSelectedItems,
        saveOrder,
        updateSelectedResourceSorting,
        updateSelectedResourcePageIndex,
        setSearchText,
        updateAllItems,
        clearAllItemsFromSelectedResource,
        updateItem,
        updateResourceItems,
        clearItem,
        addNewItem,
        openAddDialog,
        closeAddDialog,
    };

    return <DataEditorContext.Provider value={value}>{children}</DataEditorContext.Provider>;
};

export { DataEditorContext, DataEditorProvider };
