import { useContext, useEffect } from "react";
import { AppContext } from "../../../../../context/AppContext";
import { AppRestaurantTypes } from "../../../../admin/AdminResources";
import deliveryFoodStyles from "../../DeliveryFood.module.css";
import { DeliveryFoodContext } from "../../DeliveryFoodContext";
import DeliveryFoodMode from "../../modes/DeliveryFoodMode";
import DeliveryFoodHeaderCommissary from "./DeliveryFoodHeaderCommissary";
import DeliveryFoodHeaderRestaurant from "./DeliveryFoodHeaderRestaurant";

const DeliveryFoodHeader = () => {
    const { appState } = useContext(AppContext);
    const { deliveryFoodState } = useContext(DeliveryFoodContext);

    useEffect(() => {
        console.info("DeliveryFoodHeader: use effect!");
    }, [deliveryFoodState.restaurantsById]);

    if (!deliveryFoodState.restaurantsById) {
        console.info("Invalid restaurants!");
        return <div />;
    }

    const commissaries = Object.values(deliveryFoodState.restaurantsById)
        .filter(({ data: { type } }: any) => type === AppRestaurantTypes.COMMISSARY)
        .reverse();

    const restaurants = Object.values(deliveryFoodState.restaurantsById)
        .filter(({ data: { type } }: any) => type === AppRestaurantTypes.RESTAURANT)
        .reverse();

    const mode: DeliveryFoodMode = deliveryFoodState.mode;

    if (mode.renderHeader) {
        return mode.renderHeader(mode, appState.day);
    }

    return (
        <div key={"delivery-food-header"} className={deliveryFoodStyles.Header}>
            <div className={deliveryFoodStyles.Row}>
                <div className={"truncate"}>Item</div>
                <div className={"truncate"}>Pack</div>
                {restaurants
                    .filter((restaurant) => mode.showRestaurant(mode, restaurant))
                    .map(DeliveryFoodHeaderRestaurant)}
                {commissaries
                    .filter((restaurant) => mode.showRestaurant(mode, restaurant))
                    .map(DeliveryFoodHeaderCommissary)}
            </div>
        </div>
    );
};

export default DeliveryFoodHeader;
