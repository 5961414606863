import React from "react";
import OnHandConfigModel from "../../on-hand/models/OnHandConfigModel";
import OnHandWrp from "../../on-hand/OnHandWrp";
import OffSiteOnHandItemDisplayModel from "../on-hand/OffSiteOnHandItemDisplayModel";
import { VIEWS } from "../on-hand/OffSiteOnHandItemViews";
import OffSiteOnHandContextConfig from "../on-hand/OffSiteOnHandContextConfig";
import OffSiteOnHandItem from "../on-hand/OffSiteOnHandItem";
import OffSiteOnHandItemTab from "../on-hand/OffSiteOnHandItemTab";

export default function OffSiteDelivery({ history, location }: { history: any; location: any }) {
    const offSiteOnHandItemDisplayModel = new OffSiteOnHandItemDisplayModel({
        itemTab: OffSiteOnHandItemTab,
        item: OffSiteOnHandItem,
        views: VIEWS.DELIVERY,
    });

    const config = new OnHandConfigModel(
        OffSiteOnHandContextConfig,
        offSiteOnHandItemDisplayModel,
        {
            history,
            location,
        }
    );

    return <OnHandWrp config={config} />;
}
