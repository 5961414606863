import { Resource } from "../resources/Resource";

const data: any = {};

async function list(resource: typeof Resource): Promise<any> {
    return data[resource.getModel().config.typeName] || [];
}

function canUseMock(useMock: any, resource: typeof Resource, filter: any): boolean {
    let result = true;

    const resourceConfig = resource.getModel().config;
    const resourceName = resourceConfig.initDataKey;
    const thereIsMockData = data[resourceName];

    result = !!useMock;
    result = result && (!filter || Object.keys(filter)[0] === "day");
    result = result && thereIsMockData;

    return result;
}

const MocksApi = {
    list,
    canUseMock,
};

export default MocksApi;
