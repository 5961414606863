import React, { useContext, useEffect, useRef } from "react";
import onHandItemTabStyles from "./OnHandItemTab.module.css";

import Tab from "@material-ui/core/Tab";
import OnHandStatus from "../status/OnHandStatus";
import { OnHandContext } from "../../context/OnHandContext";

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

const OnHandItemTab = ({ status, name, index, isSelected, dirty }) => {
    const { onHandState, changeItem } = useContext(OnHandContext);
    let tabRef = useRef(`Tab-${name}-${index}`);

    useEffect(() => {}, [onHandState.restaurant?.group?.item]);

    // TODO: find different method for bringing the tab in view
    // useLayoutEffect(() => {
    //     if (isSelected(onHandState, name)) {
    //         tabRef.current.scrollIntoView({
    //             // behavior: "smooth",
    //             block: "center",
    //             inline: "center",
    //         });
    //     }
    // });

    const handleTabSelection = () => changeItem(index);

    const key = `Tab-${index}`;
    const selectedClass = isSelected(onHandState, name) ? "SelectedTab" : "";
    const dirtyClass = dirty ? "DirtyTab" : "";
    const className = `${onHandItemTabStyles.Tab} ${OnHandStatus.getClassName(
        status
    )} ${selectedClass} ${dirtyClass}`;

    return (
        <Tab
            key={key}
            id={key}
            ref={tabRef}
            label={name}
            {...a11yProps(index)}
            onClick={handleTabSelection}
            classes={{ selected: "SelectedTab" }}
            className={className}
        />
    );
};

export default OnHandItemTab;
