import ContainerConfigBuilder from "../ContainerConfigBuilder";
import { ContainerPaths } from "../ContainerPaths";
import AppHome from "./AppHome";
import AppHomeMenuItem from "./AppHomeMenuItem";

export const AppHomeContainerConfig = new ContainerConfigBuilder("Home")
    .setPath(ContainerPaths.HOME)
    .setUserAccess([])
    .setComponent(AppHome)
    .setMenuItem(AppHomeMenuItem)
    .build();
