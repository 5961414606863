import React from "react";
import { AppOffSiteTransactionStatuses } from "../../OffSiteResources";
import styles from "../OffSiteTasks.module.css";

export default function OffSiteTasksDialogContentItem({ task }: { task: any }) {
    const name = task?.offSiteItem?.data?.name;
    const quantity = task?.data?.quantity;
    const from = task?.offSiteRestaurant?.data?.alias;
    const status = task?.data?.status;

    const getStatusStyle = (status: any) => {
        switch (status) {
            case AppOffSiteTransactionStatuses.TRANSIT:
                return styles.RestaurantTasksTransit;
            case AppOffSiteTransactionStatuses.LOADED:
                return styles.RestaurantTasksLoaded;
            case AppOffSiteTransactionStatuses.PENDING:
                return styles.RestaurantTasksPending;
            default:
                return "";
        }
    };
    const className = `${styles.RestaurantTasksRow} ${getStatusStyle(status)} `;

    return (
        <div className={className}>
            <span className={styles.TextEllipsis}>{name}</span>
            <span className={styles.TextEllipsis}>{quantity}</span>
            <span className={styles.TextEllipsis}>{from}</span>
            <span className={styles.TextEllipsis}>{status}</span>
        </div>
    );
}
