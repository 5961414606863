import { useContext } from "react";
import { ResourceColumn } from "../../../../../resources/ResourceColumn";
import { DataEditorContext } from "../../../context/DataEditorContext";
import styles from "../../../DataEditor.module.css";
import { DataEditorSortQueueEntry } from "../../../types/DataEditorTypes";
import DataEditorSortUtils from "../../../utils/DataEditorSortUtils";
import DataEditorHeaderCellSortIcon from "./DataEditorHeaderCellSortIcon";

export default function DataEditorHeaderColumn({
    column,
    index,
}: {
    column: ResourceColumn;
    index: number;
}) {
    const { dataEditorState, updateSelectedResourceSorting } = useContext(DataEditorContext);

    const handleSortUpdate = () => {
        const newSortQueue: DataEditorSortQueueEntry[] = DataEditorSortUtils.getNewSortQueue(
            dataEditorState,
            column
        );
        updateSelectedResourceSorting(newSortQueue);
    };

    const sortDirection = DataEditorSortUtils.getDisplayColumnSortDirection(
        dataEditorState,
        column
    );

    return (
        <div onClick={() => handleSortUpdate()} className={styles.HeaderSort}>
            {sortDirection ? <DataEditorHeaderCellSortIcon sortDirection={sortDirection} /> : null}
            <span key={`header-item-${index}`} className={"truncate"}>
                {column.name}
            </span>
        </div>
    );
}
