import { FormikProps } from "formik";
import { useContext } from "react";
import { ResourceColumn } from "../../../../../../resources/ResourceColumn";
import { DataEditorContext } from "../../../../context/DataEditorContext";
import dataEditorAddStyles from "./DataEditorAdd.module.css";

export default function DataEditorAddDisplayColumn({
    fmkProps,
    column,
    index: columnIndex,
}: {
    fmkProps: FormikProps<any>;
    column: ResourceColumn;
    index: number;
}) {
    const { dataEditorState } = useContext(DataEditorContext);

    if (column.hideOnAdd) {
        return null;
    }

    let style = {};

    if (column.isKey) {
        style = Object.assign(style, { color: "red" });
    }

    if (column.isRequired) {
        style = Object.assign(style, { fontWeight: 600 });
    }

    return (
        <div key={`add-${column.name}`} className={dataEditorAddStyles.AddDialogInput}>
            <span className={dataEditorAddStyles.AddDialogInputLabel} style={style}>
                {column.name}
            </span>
            {column.prepRenderEdit(columnIndex, fmkProps, dataEditorState)}
        </div>
    );
}
