import { Button } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React, { useContext, useEffect } from "react";
import { AppContext } from "../../../../context/AppContext";
import AppUserGroups from "../../../../services/AppUserGroups";
import OffSiteTasksFilter from "../../../off-site/tasks/OffSiteTasksFilter";
import { OnHandContext } from "../../context/OnHandContext";
import OnHandContextValidator from "../../context/OnHandContextValidator";
import OnHandUtils from "../../OnHandUtils";
import OnHandStatus from "../status/OnHandStatus";
import OnHandToolbarSync from "../sync/OnHandToolbarSync";
import onHandToolbarStyles from "./OnHandToolbar.module.css";

const OnHandToolbar = () => {
    const { appState } = useContext(AppContext);
    const { onHandState, changeRestaurant, unlockRestaurantOnHand, finishRestaurantOnHand } =
        useContext(OnHandContext);

    useEffect(() => {}, [onHandState.restaurant.unlocked]);

    if (!OnHandContextValidator.hasValidSelectedRestaurant(onHandState)) {
        return <div />;
    }

    const restaurantItemDisplay = (restaurant) => {
        const key = `r-${restaurant.data.id}`;
        const name = restaurant.data.name;
        const disabled = restaurant.groups.length === 0;
        const value = restaurant.data.id;

        return (
            <MenuItem key={key} value={value} disabled={disabled}>
                {name}
            </MenuItem>
        );
    };

    const handleRestaurantChange = (event) => {
        return changeRestaurant(event.target.value);
    };

    const OnHandToolbarStatus = () => {
        const statusClassName = OnHandStatus.getClassName(onHandState.restaurant.status);
        const restaurantStatusClassName = `${onHandToolbarStyles.RestaurantStatus} ${statusClassName}`;

        return (
            <span className={restaurantStatusClassName}>
                {OnHandStatus.getDisplay(onHandState.restaurant.status)}
            </span>
        );
    };

    const OnHandToolbarUnlock = () => {
        // only admins and "OnHandUnlock" can unlock the items that need to be unlocked
        const canAccessUnlockButton =
            (AppUserGroups.hasAdminAccess(appState.user.groups) ||
                AppUserGroups.isOnHandUnlock(appState.user.groups)) &&
            !onHandState.restaurant.unlocked;

        if (!canAccessUnlockButton) {
            return <div />;
        }

        const handleUnlock = () => unlockRestaurantOnHand(onHandState.restaurant);

        return (
            <Button
                onClick={handleUnlock}
                className={onHandToolbarStyles.Unlock}
                color={"secondary"}
                variant={"outlined"}
            >
                Unlock
            </Button>
        );
    };

    const OnHandToolbarFinish = () => {
        const handleFinish = () => finishRestaurantOnHand(onHandState.restaurant);

        return (
            <Button
                color={"secondary"}
                variant={"contained"}
                className={onHandToolbarStyles.Finish}
                onClick={handleFinish}
                disabled={!OnHandStatus.isCompleted(onHandState.restaurant)}
            >
                Finish
            </Button>
        );
    };

    return (
        <div className={onHandToolbarStyles.Main}>
            <div className={onHandToolbarStyles.Selection}>
                <FormControl className={onHandToolbarStyles.SelectionRestaurant}>
                    <Select
                        id="on-hand-restaurant"
                        labelId="on-hand-restaurant"
                        value={onHandState.restaurant.data.id}
                        onChange={handleRestaurantChange}
                    >
                        {Object.values(onHandState.restaurants).map(restaurantItemDisplay)}
                    </Select>
                </FormControl>
            </div>
            {/* TODO: make the header display flag-based */}
            {OnHandUtils.isTransactionalOnHand(onHandState?.config)
                ? [
                      <div key="unlock-placeholder" />,
                      <div key="status-placeholder" />,
                      <OffSiteTasksFilter key="offSite-tasks-filter" />,
                  ]
                : [
                      <OnHandToolbarUnlock />,
                      <div key="status-or-finish-wrp" className={onHandToolbarStyles.Status}>
                          {onHandState.restaurant.unlocked ? (
                              <OnHandToolbarFinish />
                          ) : (
                              <OnHandToolbarStatus />
                          )}
                      </div>,
                      <div key="sync-wrp" className={onHandToolbarStyles.SyncWrp}>
                          <OnHandToolbarSync />
                      </div>,
                  ]}
            {console.info("RENDER: OnHandToolbar")}
        </div>
    );
};

export default OnHandToolbar;
