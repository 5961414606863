import { useContext } from "react";
import { MdSnooze } from "react-icons/md";
import { OffSiteReordersContext } from "./context/OffSiteReordersContext";
import styles from "./OffSiteReorders.module.css";
import OffSiteReordersSnoozedAlert from "./OffSiteReordersSnoozedAlert";
import OffSiteReordersUtils from "./OffSiteReordersUtils";

export default function OffSiteReordersSnoozedAlerts() {
    const { offSiteReordersState } = useContext(OffSiteReordersContext);

    const snoozedAlerts = OffSiteReordersUtils.getSnoozedAlertsList(offSiteReordersState.alerts);

    return (
        <div key="offsite-reorders-snoozed-alerts" className={styles.SnoozedAlerts}>
            <div className={styles.AlertTypeDividers}>
                <div>
                    <MdSnooze size={20} />
                </div>
                <span>Snoozed</span>
                <span />
                <span className={styles.ItemsCount}>{snoozedAlerts.length}</span>
            </div>
            {snoozedAlerts.map((alert, index) => (
                <OffSiteReordersSnoozedAlert alert={alert} index={index} />
            ))}
        </div>
    );
}
