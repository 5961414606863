import { Button } from "@material-ui/core";
import React from "react";
import styles from "./OnHandItemQuickInput.module.css";

export default function OnHandItemQuickInput({
    handleChange,
    display,
    quickValue,
    disabled,
    label,
}: {
    handleChange: Function;
    display: string;
    quickValue: number;
    disabled?: boolean;
    label?: string;
}) {
    const labelContent = label ? <span className={styles.Label}>{label}</span> : null;

    const displayContent = <span className={styles.Display}>{display}</span>;

    return (
        <Button
            onClick={() => handleChange(quickValue)}
            disabled={disabled}
            size={"small"}
            variant="outlined"
            color={"secondary"}
        >
            {labelContent}
            {displayContent}
        </Button>
    );
}
