import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import AppContextValidator from "../../context/AppContextValidator";
import appHomeStyles from "./AppHome.module.css";

function AppHome({ history, location }) {
    const { appState } = useContext(AppContext);

    if (!AppContextValidator.isValid(appState)) {
        console.info("Invalid appState in AppSectionHome");
        return <div />;
    }

    return <div className={appHomeStyles.Main}>{appState.section.renderHome()}</div>;
}

export default AppHome;
