import React, { useContext, useEffect, useState } from "react";
import deliveryDpsStyles from "./DeliveryDps.module.css";
import { AppContext } from "../../../context/AppContext";
import { useGlobalSpinnerActionsContext } from "../../../components/global-spinner/GlobalSpinnerContext";
import DeliveryDpsUtils from "./DeliveryDpsUtils";
import DeliveryDpsHeader from "./DeliveryDpsHeader";
import DeliveryDpsContent from "./DeliveryDpsContent";
import AppDayModel from "../../../components/day/AppDayModel";

function DeliveryDps({ history, location }) {
    const setGlobalSpinner = useGlobalSpinnerActionsContext();
    const { appState } = useContext(AppContext);
    const [state, setState] = useState({
        restaurants: null,
        restaurant: null,
        packsById: {},
        config: {
            router: {
                history,
                location,
            },
        },
    });

    useEffect(() => {
        DeliveryDpsUtils.loadData(
            setGlobalSpinner,
            setState,
            appState.user.groups,
            appState.day,
            appState.user.name,
            appState.section.name,
            state.config.router
        );
    }, [appState.day]);

    if (!DeliveryDpsUtils.isValidState(state)) {
        return <div />;
    }

    const ingredients = DeliveryDpsUtils.extractIngredientsDelivery(
        state.restaurant?.menu || [],
        state.packsById || {}
    );

    return (
        <div className={deliveryDpsStyles.Main}>
            <DeliveryDpsHeader state={state} setState={setState} ingredients={ingredients} />
            <DeliveryDpsContent ingredients={ingredients} />
        </div>
    );
}

export default DeliveryDps;
