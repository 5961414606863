import AppDayModel from "../../../../components/day/AppDayModel";
import { AppStateType } from "../../../../context/AppContextTypes";
import DeliveryFoodModes from "../modes/DeliveryFoodModes";
import {
    DeliveryFoodConfig,
    DeliveryFoodDays,
    DeliveryFoodResolverData,
    DeliveryFoodState,
} from "../types/DeliveryFoodTypes";
import DeliveryFoodResolver from "./DeliveryFoodResolver";

async function getDeliveryFood(
    appState: AppStateType,
    deliveryFoodState: DeliveryFoodState,
    config: DeliveryFoodConfig
): Promise<DeliveryFoodState> {
    let result: DeliveryFoodState = deliveryFoodState;

    result.config = config;

    const days: DeliveryFoodDays = {
        salesDay: appState.day || new AppDayModel(),
        onHandDay: new AppDayModel(AppDayModel.getPrevDayUnixTime(appState.day?.data.time)),
    };

    const { decoratedIngredients, restaurantsById, deliverySections }: DeliveryFoodResolverData =
        await DeliveryFoodResolver(
            days,
            appState.user.name,
            appState.section?.name || "Food",
            appState.user.groups
        );

    result.ingredients = decoratedIngredients;
    result.restaurantsById = restaurantsById;
    result.deliverySections = deliverySections;

    const modes = DeliveryFoodModes(Object.values(restaurantsById));
    result.modes = modes;
    result.mode = modes[0];

    return result;
}

const DeliveryFoodLoadUtils = {
    getDeliveryFood,
};

export default DeliveryFoodLoadUtils;
