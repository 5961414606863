import ModelApi from "../model/ModelApi";

function validateEmail(emailAddress) {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(emailAddress).toLowerCase());
}

async function sendEmail(emailObj) {
    let response;

    try {
        response = await ModelApi.customMutation({ email: JSON.stringify(emailObj) }, "sendEmail");
    } catch (e) {
        console.log(JSON.stringify(e));
        throw Error("Failed to initiate email call!");
    }

    return response;
}

const AppEmailService = {
    validateEmail,
    sendEmail,
};

export default AppEmailService;
