import { Button } from "@material-ui/core";
import { useContext } from "react";
import { OnHandContext } from "../../../on-hand/context/OnHandContext";
import { OffSiteContext } from "../../context/OffSiteContext";

export default function OffSiteOnHandItemActionsSkip() {
    const { offSiteState, goToNextTask } = useContext(OffSiteContext);
    const { goToNextItemTasks } = useContext(OnHandContext) as any;

    const handleGoToNext = () => goToNextItemTasks(goToNextTask, offSiteState.taskSelectedKey);

    return (
        <Button variant="contained" onClick={handleGoToNext}>
            Skip
        </Button>
    );
}
