import AppDayModel from "../components/day/AppDayModel";
import { Section } from "../containers/sections/Section";
import { UserGroupType } from "../services/AppUserGroupsData";

export type AppUserType = {
    name: string;
    groups: UserGroupType[];
};

export enum AppContextActionType {
    INIT_APP = "INIT_APP",
    SELECT_DAY = "SELECT_DAY",
    SELECT_SECTION = "SELECT_SECTION",
    SHOW_SECTION_SELECTOR = "SHOW_SECTION_SELECTOR",
    HIDE_SECTION_SELECTOR = "HIDE_SECTION_SELECTOR",
    SET_SHOW_ERRORS = "SET_SHOW_ERRORS",
    SYNC_OFF_SITE_TASK_SELECTED_KEY = "SYNC_OFF_SITE_TASK_SELECTED_KEY",
}

export type AppContextAction = {
    type: keyof typeof AppContextActionType;
    payload: any;
};

export type AppStateType = {
    user: AppUserType;
    day: AppDayModel | null;
    sections: Section[];
    section: Section | null;
    showSectionSelector: boolean;
    showErrors: boolean;
    // exception to sync offsite; ideally this should not exist
    offSiteTaskSelectedKey?: undefined;
};

export type AppContextType = {
    appState: AppStateType;
    initApp: Function;
    signOut: Function;
    selectDay: Function;
    selectSection: Function;
    showSectionSelector: Function;
    hideSectionSelector: Function;
    setShowErrors: Function;
    // exception: this should not be here
    syncOffSiteTaskSelectedKeyCallback: Function;
};
