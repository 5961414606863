// export type SectioNameType = string;

export const SectionNames = {
    FOOD: "Food",
    ORDERS: "Orders",
    OFF_SITE: "Off-Site",
    T_SHIRTS: "T-Shirts",
    DPS: "Dps",
    ADMIN: "Admin",
};
