import { RefChainDisplay } from "./ModelTypes";
import { DataEditorState } from "../containers/data-editor/types/DataEditorTypes";
import { AppStateType } from "../context/AppContextTypes";
import { Resource, ResourceDataType } from "./../resources/Resource";
import ModelApi from "./ModelApi";
import { ModelConfig } from "./ModelConfig";
import ModelEditor from "./ModelEditor";

class Model {
    public editor: ModelEditor;
    public api: ModelApi;

    extractKeyObject(item: Resource): any {
        if (item.data === undefined || item.data === null) {
            return console.error("Cannot extract from invalid data");
        }
        const keyColumns = this.config.columns.filter(({ isKey }) => isKey);
        const keyColumnsPairs = keyColumns.map(({ field }) => [field, item.data[field]]);

        return Object.fromEntries(keyColumnsPairs);
    }

    create(
        id: string | number | null,
        newValues: any,
        shouldConfirm: boolean,
        order: number | null
    ): false | Promise<any> {
        if (shouldConfirm && !window.confirm("Are you sure you want to add this item?")) {
            return false;
        }
        let inputForSubmission = newValues;

        if (typeof id === "number" || typeof id === "string") {
            inputForSubmission.id = id;
        }

        if (typeof order === "number") {
            inputForSubmission.order = order;
        }

        const input = this.prepForCreate(inputForSubmission);

        return this.api.create(input);
    }

    prepForCreate(data: any): any {
        return data;
    }

    // returns how some field wants to be displayed when its referenced inside another table
    refDisplay(data: ResourceDataType, dataEditorState?: DataEditorState): string {
        if (data.display) {
            return data.display;
        }
        if (data.name) {
            return data.name;
        }
        if (data.id) {
            return data.id;
        }
        return "???";
    }

    // returns how some field wants to be displayed when it is part of a chain of references
    refChainDisplay(
        data: ResourceDataType,
        dataEditorState: DataEditorState,
        resourceRefKey: string,
        ignoreRefDisplay = false,
        resourceRefKeys?: RefChainDisplay
    ): string {
        if (resourceRefKeys === undefined || resourceRefKeys.length === 0) {
            resourceRefKeys = [resourceRefKey];
        }

        const refDisplay = ignoreRefDisplay ? [] : [this.refDisplay(data)];
        const refDisplays = resourceRefKeys.map(
            (resourceRefKeysEntry: string | string[], index: number) => {
                let refKeyValue: any;

                if (Array.isArray(resourceRefKeysEntry)) {
                    if (index === 0) {
                        return null;
                    }
                    // there should be no case for a array parent
                    const parentResourceRefKey: string | string[] =
                        resourceRefKeys?.[index - 1] || "";
                    if (Array.isArray(parentResourceRefKey)) {
                        return null;
                    }
                    // when a key is an array, take the first item and find it in the previous resourceRefKey resources
                    const parentRefKeyValue = data[parentResourceRefKey];
                    resourceRefKeysEntry = resourceRefKeysEntry[0];
                    const refKeyItems =
                        dataEditorState.getItemsByResourceRefKey(parentResourceRefKey);
                    const refKeyItem = refKeyItems.find(
                        (item) => item.data[parentResourceRefKey] === parentRefKeyValue
                    );
                    refKeyValue = refKeyItem?.data[resourceRefKeysEntry];
                }

                if (typeof resourceRefKeysEntry === "string") {
                    refKeyValue = data[resourceRefKeysEntry];
                }

                if (refKeyValue === null || refKeyValue === undefined) {
                    // throw Error(`Invalid reference to ${refKeysEntry}`);
                    return "";
                }

                const refKeyItems = dataEditorState.getItemsByResourceRefKey(resourceRefKeysEntry);
                const refKeyItem = refKeyItems.find((item) => item.data?.id === refKeyValue);

                let refDisplay = "";
                if (refKeyItem) {
                    refDisplay = refKeyItem.getModel().refDisplay(refKeyItem.data) || "Invalid";
                }

                return refDisplay;
            }
        );

        const displays = [refDisplay, refDisplays]
            .flat()
            .filter((x) => x)
            .reverse()
            .join(" - ");

        return displays;
    }

    getFilter(appState?: AppStateType): any {
        return null;
    }

    getResourceStoreKey() {
        return this.config.resourceStoreKey;
    }

    getResourceRefKey() {
        return this.config.resourceRefKey;
    }

    getColumns() {
        return this.config.columns;
    }

    constructor(public config: ModelConfig) {
        this.editor = new ModelEditor(this.config);
        this.api = new ModelApi(this.config);
    }
}

export { Model };
