import { TRANSACTIONAL } from "../../on-hand/models/OnHandTypes";
import { AppOffSiteArea, AppOffSiteItem, AppOffSiteTransaction } from "../OffSiteResources";
import OffSiteOnHandItemContext from "./OffSiteOnHandItemContext";

const extractItemName = (item: any) => item?.data?.name;

const OffSiteOnHandContextConfig = new OffSiteOnHandItemContext(
    AppOffSiteItem,
    null, // TODO: remove the need for these params when transactional
    null,
    AppOffSiteArea,
    { extractItemName, pageUrl: "delivery" },
    TRANSACTIONAL,
    AppOffSiteTransaction
);

export default OffSiteOnHandContextConfig;
