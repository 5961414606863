import React from "react";
import { MdDone, MdErrorOutline, MdHelpOutline } from "react-icons/md";
import salesTShirtStyle from "./SalesTShirt.module.css";

const getStatusIcon = (status) => {
    switch (status) {
        case 2:
            return <MdDone />;
        case 1:
            return <MdErrorOutline />;
        default:
            return <MdHelpOutline />;
    }
};
const getStatusClassName = (status) => {
    switch (status) {
        case 2:
            return salesTShirtStyle.done;
        case 1:
            return salesTShirtStyle.incomplete;
        default:
            return salesTShirtStyle.unknown;
    }
};

class SalesTShirtState {
    static checkUpload(tShirts) {
        return tShirts.reduce((acc, tShirt) => {
            acc = acc || !!tShirt.sale.data.lastUpdatedBy;
            return acc;
        }, false);
    }

    static attachStatusToTShirts(uploaded, tShirts) {
        return tShirts.map((tShirt) => {
            if (!uploaded) {
                tShirt.status = 0;
            } else {
                tShirt.status = tShirt.sale.data.lastUpdatedBy ? 2 : 1;
            }
            tShirt.statusIcon = getStatusIcon(tShirt.status);
            tShirt.statusClassName = getStatusClassName(tShirt.status);
            return tShirt;
        });
    }

    static computeCompletionScore(tShirts) {
        const score = tShirts.reduce((acc, tShirt) => {
            acc += tShirt.status === 2;
            return acc;
        }, 0);
        const total = tShirts.length;
        return score / total;
    }

    constructor({ day, restaurants, tShirts, sizes, sales }) {
        this.restaurants = [];
        this.restaurant = null;

        if (!day) {
            return;
        }

        tShirts = tShirts.map((tShirt) => {
            tShirt.size = sizes.find(({ data: { id } }) => id === tShirt.data.sizeId);
            tShirt.sale = sales.find(({ data: { tShirtId } }) => tShirtId === tShirt.data.id);
            return tShirt;
        });
        restaurants = restaurants.map((restaurant, index) => {
            restaurant.index = index;
            restaurant.tShirts = tShirts.filter(
                (tShirt) => tShirt.data.restaurantId === restaurant.data.id
            );
            restaurant.uploaded = SalesTShirtState.checkUpload(restaurant.tShirts);
            restaurant.tShirts = SalesTShirtState.attachStatusToTShirts(
                restaurant.uploaded,
                restaurant.tShirts
            );
            restaurant.completionScore = SalesTShirtState.computeCompletionScore(
                restaurant.tShirts
            );
            return restaurant;
        });
        this.restaurants = restaurants;
        this.restaurant = restaurants[0];
    }
}

export default SalesTShirtState;
