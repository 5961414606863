import OnHandItemTab from "../../on-hand/components/item/OnHandItemTab";

export default function OffSiteOnHandItemTab(item: any, index: any) {
    const isSelected = (onHandState: any, name: any) => {
        let result = false;

        if (onHandState?.restaurant?.group?.item?.data?.name === name) {
            result = true;
        }

        return result;
    };

    return (
        <OnHandItemTab
            key={`on-hand-item-tab-${index}`}
            //  TODO: validate this definition for a "dirty" item
            dirty={item?.transaction}
            // TODO: define a way to compute status
            status={item.status}
            name={item.data.name}
            index={index}
            isSelected={isSelected}
        />
    );
}
