import React, { useContext, useState } from "react";
import styles from "./AppFeedback.module.css";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
} from "@material-ui/core";
import { AppFeedbackContext } from "./AppFeedbackContext";
import { AppContext } from "../../context/AppContext";
import { useGlobalSpinnerActionsContext } from "../../components/global-spinner/GlobalSpinnerContext";
import { buildEmailFeedback } from "./AppFeedbackUtils";

const TYPES = [
    { value: "Bug", label: "Bug" },
    { value: "Proposal", label: "Proposal" },
];

const DEFAULT_TYPE_INDEX = 0;

const PRIORITIES = [
    { value: "p1", label: "High" },
    { value: "p2", label: "Medium" },
    { value: "p3", label: "Low" },
];

const DEFAULT_PRIORITY_INDEX = 1;

export default function AppFeedbackDialog() {
    const { appState } = useContext(AppContext);
    const setGlobalSpinner = useGlobalSpinnerActionsContext();
    const { appFeedback, closeDialog, sendFeedback } = useContext(AppFeedbackContext);
    const [typeIndexState, setTypeIndex] = useState(DEFAULT_TYPE_INDEX);
    const [priorityIndexState, setPriorityIndex] = useState(DEFAULT_PRIORITY_INDEX);
    const [detailsState, setDetails] = useState("");

    const handleTypeChange = (event) => {
        return setTypeIndex(+event.target.value);
    };

    const handlePriorityChange = (event) => {
        return setPriorityIndex(+event.target.value);
    };

    const handleDetailsChange = (event) => {
        return setDetails(event.target.value);
    };

    const handleSendFeedback = async () => {
        setGlobalSpinner(true);
        const feedback = {
            type: TYPES[typeIndexState].value,
            priority: PRIORITIES[priorityIndexState].value,
            date: "<date today>",
            details: detailsState,
            userName: appState.user.name,
            day: appState.day.data.display,
            sentAt: new Date().getTime(),
            location: window.location.href,
        };
        const email = buildEmailFeedback(feedback);

        await sendFeedback(email);

        setGlobalSpinner(false);
    };

    return (
        <Dialog
            key={"feedback-dialog"}
            className={styles.DialogMain}
            open={appFeedback.isDialogOpen}
        >
            <DialogTitle key={"feedback-dialog-title"}>
                <span>Feedback Report</span>
            </DialogTitle>
            <DialogContent
                key={"feedback-dialog-content"}
                style={{ display: "grid", gridAutoFlow: "row", gridGap: "0.5em" }}
                dividers
            >
                <FormControl component="fieldset" row>
                    <FormLabel component="legend">
                        <strong>Type</strong>
                    </FormLabel>
                    <RadioGroup
                        aria-label="type"
                        name="Type"
                        style={{ display: "grid", gridAutoFlow: "column" }}
                        value={typeIndexState}
                        onChange={handleTypeChange}
                    >
                        {TYPES.map((type, typeIndex) => {
                            return (
                                <FormControlLabel
                                    key={`type-feedback-${typeIndex}`}
                                    value={typeIndex}
                                    control={<Radio />}
                                    label={type.label}
                                />
                            );
                        })}
                    </RadioGroup>
                </FormControl>
                <FormControl component="fieldset" row>
                    <FormLabel component="legend">
                        <strong>Priority</strong>
                    </FormLabel>
                    <RadioGroup
                        aria-label="priority"
                        name="Priority"
                        style={{ display: "grid", gridAutoFlow: "column" }}
                        value={priorityIndexState}
                        onChange={handlePriorityChange}
                    >
                        {PRIORITIES.map((priority, priorityIndex) => {
                            return (
                                <FormControlLabel
                                    key={`app-feedback-dialog-priority-${priorityIndex}`}
                                    value={priorityIndex}
                                    control={<Radio />}
                                    label={priority.label}
                                />
                            );
                        })}
                    </RadioGroup>
                </FormControl>
                <FormControl>
                    <TextField
                        id="standard-multiline-static"
                        multiline
                        rows={3}
                        style={{ width: "100%", fontStyle: "italic", fontSize: "0.9em" }}
                        value={detailsState}
                        onChange={handleDetailsChange}
                        placeholder="Describe here..."
                    />
                </FormControl>
            </DialogContent>
            <DialogActions key={"feedback-dialog-actions"} className={styles.DialogActions}>
                <Button onClick={closeDialog}>Close</Button>
                <Button onClick={handleSendFeedback} color={"secondary"} variant={"contained"}>
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    );
}
