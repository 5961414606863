import React, { useContext } from "react";
import deliveryTShirtsSelectedStockingStyles from "./TShirtsStockingSelectedStocking.module.css";
import Button from "@material-ui/core/Button";
import { NEEDS_STOCKING } from "../../TShirtsStockingStatus";
import TShirtsStockingUtils from "../../../TShirtsStockingUtils";
import Divider from "@material-ui/core/Divider";
import { TShirtsStockingContext } from "../../../context/TShirtsStockingContext";

const TShirtsStockingSelectedStocking = ({ tShirt }) => {
    const { tShirtsStocking, stockTShirt } = useContext(TShirtsStockingContext);

    if (!tShirt.status || tShirt.status.name !== NEEDS_STOCKING.name) {
        console.info("Missing tShirt status or not stocking!");
        return <div />;
    }

    const displayRequiredStock = (quantity, packSize) => {
        const disabled = quantity === 0;
        let message = <span>No Stock!</span>;
        if (!disabled) {
            const [takenPacks, takenItems] = TShirtsStockingUtils.splitQuantityIntoPacksAndItems(
                quantity,
                packSize
            );
            message = (
                <span>
                    Take{" "}
                    {TShirtsStockingUtils.computeAvailableInventoryLabel(takenPacks, takenItems)}
                </span>
            );
        }

        return { disabled, message };
    };

    const packSize = tShirt.data.packSize;
    const requiredStockQuantity = tShirt.requiredStock * packSize;
    const availableStockQuantity = TShirtsStockingUtils.getMasterInventoryQuantity(tShirt);
    const [availablePacks, availableItems] = TShirtsStockingUtils.splitQuantityIntoPacksAndItems(
        availableStockQuantity,
        packSize
    );
    const availableStockDisplay = TShirtsStockingUtils.computeAvailableInventoryLabel(
        availablePacks,
        availableItems
    );
    const possibleStockQuantity = Math.min(requiredStockQuantity, availableStockQuantity);

    const { disabled, message } = displayRequiredStock(possibleStockQuantity, packSize);

    const getTakeBtnClassName = () => {
        if (availableStockQuantity === 0) {
            return deliveryTShirtsSelectedStockingStyles.StockNoStock;
        }
        if (availableStockQuantity < requiredStockQuantity) {
            return deliveryTShirtsSelectedStockingStyles.StockPartial;
        }
        return deliveryTShirtsSelectedStockingStyles.StockSuccess;
    };

    return (
        <div className={deliveryTShirtsSelectedStockingStyles.Main}>
            <div className={deliveryTShirtsSelectedStockingStyles.TakeBtnWrp}>
                <Button
                    onClick={() =>
                        stockTShirt(tShirtsStocking.restaurant, tShirt, possibleStockQuantity)
                    }
                    disabled={disabled}
                    size={"small"}
                    className={getTakeBtnClassName()}
                    variant={"contained"}
                >
                    {message}
                </Button>
            </div>
            <Divider />
            <div className={deliveryTShirtsSelectedStockingStyles.InventoryPreview}>
                <strong>Available:</strong>
                <span>{availableStockDisplay}</span>
            </div>
        </div>
    );
};

export default TShirtsStockingSelectedStocking;
