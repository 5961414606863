import React from "react";
import TShirtsStocking from "../TShirtsStocking";
import { TShirtsStockingProvider } from "./TShirtsStockingContext";

const TShirtsStockingWrp = ({ history, location }) => {
    return (
        <TShirtsStockingProvider>
            <TShirtsStocking />
        </TShirtsStockingProvider>
    );
};

export default TShirtsStockingWrp;
