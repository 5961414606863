import { Button } from "@material-ui/core";
import { useContext } from "react";
import { FaTruck } from "react-icons/fa";
import { MdOutlineChecklist, MdPendingActions } from "react-icons/md";
import { OffSiteContext } from "../context/OffSiteContext";
import styles from "./OffSiteTasks.module.css";
import OffSiteTasksUtils from "./OffSiteTasksUtils";

export default function OffSiteTasksCounter() {
    const { offSiteState, toggleDeliveryTasksDialog } = useContext(OffSiteContext);

    const offSiteTasks = offSiteState.offSiteTasksByStatusAndIdAndRest;

    if (!offSiteTasks) {
        return null;
    }

    const pendingTasksCount = OffSiteTasksUtils.getPendingTasksCount(offSiteTasks);
    const loadedTasksCount = OffSiteTasksUtils.getLoadedTasksCount(offSiteTasks);
    const transitTasksCount = OffSiteTasksUtils.getTransitTasksCount(offSiteTasks);

    return (
        <div className={styles.TasksCounter}>
            <Button
                onClick={() => toggleDeliveryTasksDialog(true)}
                className={styles.TasksCounterButton}
                size={"small"}
                variant={"contained"}
                color={"secondary"}
            >
                <div className={styles.TasksStatusCounter}>
                    <div className={styles.ProgressCounter}>{pendingTasksCount}</div>
                    <MdPendingActions />
                </div>
                <div className={styles.TasksStatusCounter}>
                    <div className={styles.ProgressCounter}>{loadedTasksCount}</div>
                    <FaTruck />
                </div>
                <div className={styles.TasksStatusCounter}>
                    <div className={styles.ProgressCounter}>{transitTasksCount}</div>
                    <MdOutlineChecklist />
                </div>
            </Button>
        </div>
    );
}
