import Sections from "./Sections";

function checkIfGroupIsWithinAllowedListInSection(group, section) {
    return section.allowedGroups.includes(group);
}

function checkIfSectionShouldBeVisibleToGroups(section, groups) {
    return groups.some((group) => checkIfGroupIsWithinAllowedListInSection(group, section));
}

function getSectionsFromGroups(groups) {
    return Sections.filter((section) => checkIfSectionShouldBeVisibleToGroups(section, groups));
}

const SectionsService = {
    getSectionsFromGroups,
};

export default SectionsService;
