import React, { useContext } from "react";
import { OnHandContext } from "../../context/OnHandContext";
import OnHandItemClearInput from "./OnHandItemClearInput";
import onHandItemInputStyles from "./OnHandItemInput.module.css";
import OnHandItemQuickInput from "./OnHandItemQuickInput";

const OnHandItemInput = () => {
    const { onHandState, onSelectedItemQuantityUpdate } = useContext(OnHandContext);

    const handleChange = (quickValue) => {
        const newValue =
            (onHandState.restaurant.group.item.inventory?.data?.quantity || 0) + quickValue;
        return onSelectedItemQuantityUpdate(Math.max(newValue, 0));
    };

    const handleReset = () => onSelectedItemQuantityUpdate(0);

    const handleQuantityChange = (event) => onSelectedItemQuantityUpdate(event.target.value);

    if (!onHandState.restaurant.group.item?.inventory?.data) {
        console.info("Missing inventory!");
        return (
            <div className={onHandItemInputStyles.InputWrp}>
                <em className={onHandItemInputStyles.Error}> Loading...</em>
            </div>
        );
    }

    const value = onHandState.restaurant.group.item.inventory.data.quantity || 0;

    return (
        <div className={onHandItemInputStyles.Input}>
            <div className={onHandItemInputStyles.Negatives}>
                <div>
                    <OnHandItemQuickInput
                        handleChange={handleChange}
                        display={"- 1"}
                        quickValue={-1}
                    />
                    <OnHandItemQuickInput
                        handleChange={handleChange}
                        display={"- 0.25"}
                        quickValue={-0.25}
                    />
                </div>
                <div>
                    <OnHandItemQuickInput
                        handleChange={handleChange}
                        display={"- 5"}
                        quickValue={-5}
                    />
                    <OnHandItemQuickInput
                        handleChange={handleChange}
                        display={"- 20"}
                        quickValue={-20}
                    />
                </div>
            </div>
            <div className={onHandItemInputStyles.Middle}>
                <div className={onHandItemInputStyles.InputWrp}>
                    <input
                        value={value}
                        type="number"
                        min={0}
                        max={100}
                        step={0.01}
                        onChange={handleQuantityChange}
                    />
                </div>
                <OnHandItemClearInput handleReset={handleReset} />
            </div>
            <div className={onHandItemInputStyles.Positives}>
                <div>
                    <OnHandItemQuickInput
                        handleChange={handleChange}
                        display={"+ 0.25"}
                        quickValue={0.25}
                    />
                    <OnHandItemQuickInput
                        handleChange={handleChange}
                        display={"+ 1"}
                        quickValue={1}
                    />
                </div>
                <div>
                    <OnHandItemQuickInput
                        handleChange={handleChange}
                        display={"+ 20"}
                        quickValue={20}
                    />
                    <OnHandItemQuickInput
                        handleChange={handleChange}
                        display={"+ 5"}
                        quickValue={5}
                    />
                </div>
            </div>
        </div>
    );
};

export default OnHandItemInput;
