import { AppRestaurant } from "../../../../../admin/AdminResources";
import {
    DeliveryFoodIngredient,
    DeliveryFoodSummaryConfigsSubType,
} from "../../../types/DeliveryFoodTypes";

export const DeliveryFoodSummaryDeliverySalesConfigs: DeliveryFoodSummaryConfigsSubType = {
    title: ({
        ingredient,
        restaurant,
    }: {
        ingredient: DeliveryFoodIngredient;
        restaurant: AppRestaurant;
    }) => {
        return (
            <div>
                Delivery from Sales Need for <strong>{ingredient.data.name}</strong> at{" "}
                <strong>{restaurant.data.name}</strong>
            </div>
        );
    },
    content: ({
        data,
        total,
        totalDisplay,
    }: {
        data: any;
        total: number | string | null;
        totalDisplay: number | string | null;
    }) => {
        return (
            <div>
                <ul>
                    <li>
                        {/* <strong>Sales Need</strong>: {data?.DELIVERY?.fixedNeed} Bulks */}
                        <strong>Sales Need</strong>: ??? Bulks
                    </li>
                </ul>
                <div>
                    <strong>Total before rounding</strong>: {total} Bulks
                </div>
                <div>
                    <strong>Total</strong>: {totalDisplay} Bulks
                </div>
            </div>
        );
    },
};
