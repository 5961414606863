import React, { useContext } from "react";
import { AiOutlineFileDone } from "react-icons/ai";
import { OffSiteReordersContext } from "./context/OffSiteReordersContext";
import styles from "./OffSiteReorders.module.css";
import OffSiteReordersCompletedAlert from "./OffSiteReordersCompletedAlert";
import OffSiteReordersUtils from "./OffSiteReordersUtils";

export default function OffSiteReordersCompletedAlerts() {
    const { offSiteReordersState } = useContext(OffSiteReordersContext);

    const completedAlerts = OffSiteReordersUtils.getCompletedAlertsList(
        offSiteReordersState.alerts
    );

    return (
        <div key="offsite-reorders-completed-alerts" className={styles.CompletedAlerts}>
            <div className={styles.AlertTypeDividers}>
                <div>
                    <AiOutlineFileDone size={20} />
                </div>
                <span>Completed</span>
                <span />
                <span className={styles.ItemsCount}>{completedAlerts.length}</span>
            </div>
            {completedAlerts.map((alert, index) => (
                <OffSiteReordersCompletedAlert alert={alert} index={index} />
            ))}
        </div>
    );
}
