import React, { useContext } from "react";
import { OnHandContext } from "../../../on-hand/context/OnHandContext";
import OnHandUtils from "../../../on-hand/OnHandUtils";
import styles from "./OffSiteStockingItemInput.module.css";
import OffSiteStockingItemQuickInputs from "./OffSiteStockingItemQuickInputs";
import OffSiteStockingItemTotal from "./OffSiteStockingItemTotal";

export default function OffSiteStockingItemInput() {
    const { onHandState } = useContext(OnHandContext) as any;

    if (!OnHandUtils.isSelectedItem(onHandState, onHandState.restaurant.group.item)) {
        return <div />;
    }

    // TODO: build and insert "Input display", REMOVE TITLE
    return (
        <div className={styles.Input}>
            <OffSiteStockingItemQuickInputs
                leftInputs={[
                    { display: "- 5", quickValue: -5 },
                    { display: "- 1", quickValue: -1 },
                ]}
                middleInputs={[
                    { display: "- 20", quickValue: -20 },
                    { display: "- 10", quickValue: -10 },
                ]}
                rightInputs={[
                    { display: "- 50", quickValue: -50 },
                    { display: "- 100", quickValue: -100 },
                ]}
            />
            <OffSiteStockingItemTotal />
            <OffSiteStockingItemQuickInputs
                leftInputs={[
                    { display: "+ 1", quickValue: 1 },
                    { display: "+ 5", quickValue: 5 },
                ]}
                middleInputs={[
                    { display: "+ 10", quickValue: 10 },
                    { display: "+ 20", quickValue: 20 },
                ]}
                rightInputs={[
                    { display: "+ 100", quickValue: 100 },
                    { display: "+ 50", quickValue: 50 },
                ]}
            />
        </div>
    );
}
