import { Button } from "@material-ui/core";
import React, { useContext } from "react";
import { DeliveryOrdersContext } from "../context/DeliveryOrdersContext";

export default function DeliveryOrdersActions() {
    const { deliveryOrders, openEmailDialog } = useContext(DeliveryOrdersContext);

    const handleOpenEmailDialog = () => openEmailDialog(deliveryOrders.restaurant);

    return (
        <div className={"no-print"}>
            <Button onClick={handleOpenEmailDialog} color={"secondary"} variant={"contained"}>
                Email
            </Button>
        </div>
    );
}
