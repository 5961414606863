import { Button } from "@material-ui/core";
import React, { useContext } from "react";
import { OnHandContext } from "../../../on-hand/context/OnHandContext";

export default function OffSiteStockingItemPrev() {
    const { goToPrevItem } = useContext(OnHandContext) as any;

    const handleGoToPrev = () => goToPrevItem();

    return (
        <Button variant="contained" onClick={handleGoToPrev}>
            Prev
        </Button>
    );
}
