import AppDayModel from "../../../../components/day/AppDayModel";
import deliveryFoodStyle from "../DeliveryFood.module.css";
import DeliveryFoodMode from "./DeliveryFoodMode";
import { DeliveryFoodModeRootData } from "../types/DeliveryFoodModeTypes";
import { DeliveryFoodIngredient } from "../types/DeliveryFoodTypes";
import DeliveryFoodModeUtils from "../services/DeliveryFoodModeUtils";

export class DeliveryFoodRestaurantMode extends DeliveryFoodMode {
    renderHeader(mode: DeliveryFoodMode, day: any): JSX.Element {
        return (
            <div className={deliveryFoodStyle.RestaurantDeliveryHeader}>
                <div>{mode.restaurant?.data?.name || "?"} Delivery</div>
                <div>{AppDayModel.getDayTimeDisplay(day.data.time)}</div>
            </div>
        );
    }

    ingredientsFilter(mode: DeliveryFoodMode, ingredient: DeliveryFoodIngredient) {
        const { dataForDelivery, results } = ingredient;

        if (!dataForDelivery || !results || !mode.restaurant?.data?.id) {
            return false;
        }

        const restaurantId = mode.restaurant.data.id;

        if (!results.RST?.[restaurantId]) {
            return false;
        }

        return (results.RST[restaurantId].delivery.total || 0) > 0;
    }

    constructor(rootData: DeliveryFoodModeRootData) {
        super(rootData);

        const { restaurant } = rootData;

        this.label = restaurant?.data?.name || "Unknown Label";
        this.display = DeliveryFoodModeUtils.buildRestaurantModeDisplay(rootData);
        this.restaurant = restaurant;
    }
}
