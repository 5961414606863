import { Button } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { useGlobalSpinnerActionsContext } from "../../../../components/global-spinner/GlobalSpinnerContext";
import { AppContext } from "../../../../context/AppContext";
import { OnHandContext } from "../../../on-hand/context/OnHandContext";

export default function OffSiteStockingItemSubmit() {
    const { appState } = useContext(AppContext);
    const { onHandState, lockSubmission, submitSelectedStockingItem } = useContext(
        OnHandContext
    ) as any;
    const setGlobalSpinner = useGlobalSpinnerActionsContext();

    useEffect(() => {}, [onHandState?.restaurant?.group?.item]);

    const isLockedSubmit = !!onHandState?.lockedSubmit;

    const disabledSubmit =
        !onHandState?.restaurant?.group?.item?.stockingQuantity || isLockedSubmit;

    const handleSubmit = async () => {
        lockSubmission();
        try {
            setGlobalSpinner(true);
            await submitSelectedStockingItem(
                appState.user.name,
                onHandState?.restaurant?.group?.item,
                onHandState?.restaurant?.data?.id
            );
        } catch (e) {
            throw Error("Failed to submit transaction");
        } finally {
            setGlobalSpinner(false);
        }
    };

    return (
        <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            disabled={disabledSubmit}
        >
            Submit
        </Button>
    );
}
