import React from "react";
import deliveryTShirtsStyles from "./TShirtsStocking.module.css";
import { COMPLETED, getStatus, NEEDS_ON_HAND } from "./t-shirt/TShirtsStockingStatus";
import AppDayModel from "../../../components/day/AppDayModel";
import { AppTShirtTransaction } from "../TShirtsResources";
import { STOCKING } from "./mode/TShirtsStockingModes";

function computeGridColumns({ sizes, restaurant: { selectedTShirt } }, width) {
    if (!selectedTShirt?.data || !Array.isArray(sizes)) {
        return;
    }

    let labelWidth = 120; // px size of label on regular screen
    let selectedItemWidth = 300; // px size of selected item on regular screen

    if (width < 500) {
        labelWidth = 40; // px size of label on <500px screen
        selectedItemWidth = 120; // px size of selected item on <500px screen
    }

    if (width < 1000) {
        labelWidth = 60; // px size of label btw 500px and 1000px screen
        selectedItemWidth = 180; // px size of selected item btw 500px and 1000px screen
    }

    const gridTemplateColumns = sizes.reduce((acc, size) => {
        acc += `${
            size.data.id === selectedTShirt?.size?.data?.id
                ? `minmax(${selectedItemWidth}px, 2fr)`
                : "1fr"
        } `;
        return acc;
    }, `${labelWidth}px `);

    return { gridTemplateColumns };
}

function computeRequiredPacks(tShirt) {
    const max = tShirt.data.stockCapacity;
    const par = tShirt.data.par;
    const packSize = tShirt.data.packSize;
    const quantity = getInventoryQuantity(tShirt) - tShirt.stockedQuantity;

    return quantity >= par ? 0 : Math.floor((max - quantity) / packSize);
}

function computeAvailableInventory(tShirt) {
    const quantity = getMasterInventoryQuantity(tShirt);
    const packSize = tShirt.data.packSize;
    return splitQuantityIntoPacksAndItems(quantity, packSize);
}

function computeAvailableInventoryLabel(packs, items, quantity) {
    const display = (qty, nameSgl) => {
        if (qty === 1) {
            return <span>{`${qty} ${nameSgl}`}</span>;
        }
        return <span>{`${qty} ${nameSgl}s`}</span>;
    };

    const itemSgl = "T-Shirt";
    const packSgl = "Pack";

    let label = [];
    if (packs === 0) {
        if (items === 0) {
            label.push(<span>No stock!</span>);
        } else {
            label.push(display(items, itemSgl));
        }
    } else if (packs > 0) {
        if (items === 0) {
            label.push(display(packs, packSgl));
        } else {
            label.push([display(packs, packSgl), " + ", display(items, itemSgl)]);
        }
    }
    if (typeof quantity === "number") {
        label.push(<strong>Total: {quantity}</strong>);
    }

    return label;
}

function displayAvailableInventory(tShirt) {
    const quantity = getMasterInventoryQuantity(tShirt);
    const [packs, items] = computeAvailableInventory(tShirt);

    return (
        <div className={deliveryTShirtsStyles.MasterInventoryLabel}>
            {computeAvailableInventoryLabel(packs, items, quantity)}
        </div>
    );
}

function splitQuantityIntoPacksAndItems(quantity, packSize) {
    return [Math.floor(quantity / packSize), quantity % packSize];
}

function getMasterInventoryQuantity(tShirt) {
    return tShirt.masterInventory;
}

function getInventoryQuantity(tShirt) {
    if (tShirt.inventory && tShirt.inventory.data) {
        return tShirt.inventory.data.quantity || 0;
    } else {
        throw Error("Invalid or missing inventory!");
    }
}

function findNextTShirt(tShirtStyles, tShirt, status) {
    const tShirts = Object.values(tShirtStyles).flat();
    const index = tShirt
        ? tShirts.findIndex(
              ({ data: { id, name } }) => id === tShirt.data.id && name === tShirt.data.name
          )
        : -1;
    for (let i = index + 1; i < tShirts.length + index - 1; i++) {
        if (!status || tShirts[i % tShirts.length]?.status?.name === status.name) {
            return tShirts[i % tShirts.length];
        }
    }
    return null;
}

function updateTShirtMetadata(tShirt) {
    if (!tShirt?.inventory?.data) {
        return tShirt;
    }

    const requiredStock = TShirtsStockingUtils.computeRequiredPacks(tShirt);
    const status = getStatus(requiredStock, tShirt.inventory.data.lastUpdatedBy);
    return Object.assign(tShirt, {
        requiredStock,
        status,
    });
}

async function createTShirtTransaction(tShirtId, type, quantity, createdAt, createdBy) {
    return AppTShirtTransaction.getModel().api.create({
        tShirtId,
        type,
        quantity,
        createdAt,
        createdBy,
    });
}

async function submitStockTShirtTransaction(tShirt, quantity, appState) {
    const createdAt = AppDayModel.getCurrentUnixTime();
    const createdBy = appState.user.name;
    return createTShirtTransaction(tShirt.data.id, "STOCK", -quantity, createdAt, createdBy);
}

async function supplyTShirt(tShirt, quantity, appState) {
    const createdAt = AppDayModel.getCurrentUnixTime();
    const createdBy = appState.user.name;
    return createTShirtTransaction(tShirt.data.id, "SUPPLY", quantity, createdAt, createdBy);
}

function findTShirtBySize(size, tShirt) {
    return tShirt?.size?.data?.id === size?.data?.id;
}

function isValidTShirtStocking(tShirtsStocking, tShirtStyleSet, appState) {
    return (
        !tShirtsStocking?.sizes ||
        !tShirtsStocking?.restaurant ||
        !tShirtsStocking?.mode ||
        !Array.isArray(tShirtStyleSet) ||
        !appState?.user
    );
}

function isValidTShirtStockingForStatus(tShirtsStocking) {
    return tShirtsStocking?.restaurant?.tShirtModels && tShirtsStocking?.mode;
}

function hasNoStocking(tShirtsStocking, tShirtStyleSet) {
    return (
        tShirtsStocking?.mode?.name === STOCKING.name &&
        tShirtStyleSet.every((tShirt) => tShirt?.status?.name === COMPLETED.name)
    );
}

function tShirtToSizeMappingReduce(tShirtStyleSet, tShirtStyleName, mapping, size) {
    const tShirt = tShirtStyleSet.find(TShirtsStockingUtils.findTShirtBySize.bind(null, size));
    const key = `tShirt-${tShirtStyleName}-size-${size?.data?.id}`;

    mapping.push({ key, tShirt, size });
    return mapping;
}

function isStockingMode(tShirtsStocking) {
    return tShirtsStocking?.mode?.name === STOCKING.name;
}

function extractAllTShirtsFromStyles(tShirtsStocking) {
    return Object.values(tShirtsStocking?.restaurant?.tShirtStyles).flat();
}

function someTShirtsNeedOnHand(tShirts) {
    return tShirts.some((tShirt) => tShirt?.status?.name === NEEDS_ON_HAND.name);
}

function allTShirtsAreCompleted(tShirts) {
    return tShirts.every((tShirt) => tShirt?.status?.name === COMPLETED.name);
}

const TShirtsStockingUtils = {
    computeGridColumns,
    computeRequiredPacks,
    computeAvailableInventoryLabel,
    splitQuantityIntoPacksAndItems,
    displayAvailableInventory,
    getMasterInventoryQuantity,
    findNextTShirt,
    updateTShirtStockMetadata: updateTShirtMetadata,
    submitStockTShirtTransaction,
    supplyTShirt,
    findTShirtBySize,
    isValidTShirtStocking,
    hasNoStocking,
    tShirtToSizeMappingReduce,
    isValidTShirtStockingForStatus,
    isStockingMode,
    extractAllTShirtsFromStyles,
    someTShirtsNeedOnHand,
    allTShirtsAreCompleted,
};

export default TShirtsStockingUtils;
