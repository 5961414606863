import { IconButton } from "@material-ui/core";
import { useContext } from "react";
import { MdDelete } from "react-icons/md";
import { useGlobalSpinnerActionsContext } from "../../../../../components/global-spinner/GlobalSpinnerContext";
import { AppContext } from "../../../../../context/AppContext";
import { Resource } from "../../../../../resources/Resource";
import AppUserGroups from "../../../../../services/AppUserGroups";
import { DataEditorContext } from "../../../context/DataEditorContext";

export default function DataEditorItemDeleteButton({ item }: { item: Resource }) {
    const { appState } = useContext(AppContext);
    const setGlobalSpinner = useGlobalSpinnerActionsContext();
    const { clearItem } = useContext(DataEditorContext);

    const handleDelete = async (event: any) => {
        try {
            setGlobalSpinner(true);

            await item.getModel().api.delete(item);

            return clearItem(item);
        } catch (e: any) {
            throw Error(e);
        } finally {
            setGlobalSpinner(false);
        }
    };

    if (!AppUserGroups.hasDataEditorAdminAccess(appState.user.groups)) {
        return null;
    }

    return (
        <IconButton
            disabled={!item.getModel().editor.isDeletable}
            color={"secondary"}
            size={"small"}
            onClick={handleDelete}
        >
            <MdDelete />
        </IconButton>
    );
}
