import React from "react";
import { OffSiteReordersContextProvider } from "./context/OffSiteReordersContext";
import OffSiteReorders from "./OffSiteReorders";

export default function OffSiteReordersWrp() {
    return (
        <OffSiteReordersContextProvider>
            <OffSiteReorders />
        </OffSiteReordersContextProvider>
    );
}
