import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { useContext } from "react";
import { Resource } from "../../../../resources/Resource";
import { DataEditorContext } from "../../context/DataEditorContext";
import dataEditorStyles from "../../DataEditor.module.css";
import { DataEditorResource } from "../../types/DataEditorTypes";

const DataEditorResourceSelect = () => {
    const { dataEditorState, changeResource } = useContext(DataEditorContext);

    const onSelectResource = (resources: typeof Resource[], resourceIndex: number) => {
        changeResource(resources[resourceIndex]);
    };

    // TODO: wrap resource object to be able to include "isVisibleInDataEditor"
    const visibleResources = dataEditorState.store.resources.allDataEditorResources
        .filter((resource: DataEditorResource) => resource.config.isVisibleInDataEditor)
        .map((resource: DataEditorResource) => resource.resource);

    const selectedResourceIndex = visibleResources.findIndex((resource: typeof Resource) => {
        return (
            resource.getModel().getResourceStoreKey() ===
            dataEditorState.getSelectedResource().getModel().getResourceStoreKey()
        );
    });

    return (
        <div className={dataEditorStyles.ToolbarSelection}>
            <FormControl className={dataEditorStyles.ToolbarForm}>
                <Select
                    labelId="data-editor-select-label"
                    id="data-editor-select"
                    value={selectedResourceIndex}
                    onChange={(event) =>
                        onSelectResource(visibleResources, event.target.value as number)
                    }
                >
                    {visibleResources.map((resource, index) => (
                        <MenuItem key={`data-editor-menu-${index}`} value={index}>
                            {resource.getModel().config.typeNameDisplay}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default DataEditorResourceSelect;
