import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";

export default function OffSiteTasks() {
    const { appState } = useContext(AppContext);
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState(null as any);

    useEffect(() => {
        setCurrentPage(
            appState.section?.pages.find((page) => {
                return window.location.pathname.includes(page?.url);
            })
        );
    }, [location]);

    if (!currentPage?.tasksHeaderComponent) {
        return null;
    }

    const TasksHeader = currentPage.tasksHeaderComponent;

    return <TasksHeader />;
}
