import { AppDeliveryArea } from "../../../FoodResources";
import {
    DeliveryFoodDeliveryTotalTypes,
    DeliveryFoodIngredient,
} from "../../types/DeliveryFoodTypes";

function doesIngredientBelongToSection(
    deliverySection: AppDeliveryArea,
    ingredient: DeliveryFoodIngredient,
    restaurantId: string | number
): boolean {
    let result: boolean;

    result =
        ingredient.deliveryConfigsByRestaurantId[restaurantId]?.data.deliveryAreaId ===
        deliverySection.data.id;

    return result;
}

function sectionIngredientFilter(
    deliverySection: AppDeliveryArea,
    ingredient: DeliveryFoodIngredient,
    restaurantId: string | number | undefined
): boolean {
    let result: boolean = true;

    if (!restaurantId) {
        return false;
    }

    if (!doesIngredientBelongToSection(deliverySection, ingredient, restaurantId)) {
        return false;
    }

    return result;
}

function nonEmptyIngredientDeliveryFilter(
    ingredient: DeliveryFoodIngredient,
    restaurantId: string | number | undefined,
    deliveryTotalType: keyof typeof DeliveryFoodDeliveryTotalTypes = "total"
): boolean {
    let result = true;

    if (!restaurantId) {
        return false;
    }

    if (!ingredient.dataForDelivery || !ingredient.results || !ingredient.results.RST) {
        return false;
    }

    result = (ingredient.results.RST[restaurantId].delivery[deliveryTotalType] || 0) > 0;

    return result;
}

function sortFoodDeliverySectionSortByPackType(
    ingredientA: DeliveryFoodIngredient,
    ingredientB: DeliveryFoodIngredient
) {
    const packNameA = ingredientA.pack.data.name;
    const packNameB = ingredientB.pack.data.name;

    return packNameA.localeCompare(packNameB);
}

export {
    sectionIngredientFilter,
    sortFoodDeliverySectionSortByPackType,
    nonEmptyIngredientDeliveryFilter,
};
