function getEmailVendorByPosition(restaurant, position) {
    const currentEmailVendorIndex = restaurant.deliveryVendors.findIndex((vendor) => {
        return vendor.data?.id === restaurant.emailVendor?.data?.id;
    });

    if (currentEmailVendorIndex === -1) {
        return null;
    }

    if (!Array.isArray(restaurant.deliveryVendors)) {
        return null;
    }

    return restaurant.deliveryVendors[
        (currentEmailVendorIndex + position) % restaurant.deliveryVendors.length
    ];
}

export { getEmailVendorByPosition };
