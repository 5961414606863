import OnHandItemTab from "../../../on-hand/components/item/OnHandItemTab";

export default function OnHandTShirtItemTab(item, index) {
    const isSelected = (onHandState, name) => {
        let result = false;

        if (onHandState?.restaurant?.group?.item?.data?.name === name) {
            result = true;
        }

        return result;
    };

    return (
        <OnHandItemTab
            key={`on-hand-item-tab-${index}`}
            dirty={item.inventory.dirty}
            status={item.status}
            name={item.size.data.name}
            index={index}
            isSelected={isSelected}
        />
    );
}
