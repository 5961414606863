import React from "react";
import { BiFridge } from "react-icons/bi";

const DeliveryDpsMenuItem = (name: string) => {
    return [
        <BiFridge key={`${name}-icon`} size={"1.5em"} />,
        <div key={`${name}-text`}>
            <span>{name}</span>
        </div>,
    ];
};

export default DeliveryDpsMenuItem;
