import React, { useContext, useEffect } from "react";
import deliveryTShirtsStyles from "./TShirtsStocking.module.css";
import tShirtsStockingItemStyles from "./TShirtsStockingItem.module.css";
import tShirtsStockingHeaderStyles from "./TShirtsStockingHeader.module.css";
import { TShirtsStockingContext } from "./context/TShirtsStockingContext";
import TShirtsStockingUtils from "./TShirtsStockingUtils";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const TShirtsStockingHeader = () => {
    const { width } = useWindowDimensions();
    const { tShirtsStocking } = useContext(TShirtsStockingContext);

    useEffect(() => {}, [width]);

    if (!tShirtsStocking.sizes || !tShirtsStocking.restaurant) {
        console.info("Invalid tShirtsStocking sizes or restaurant");
        return <div />;
    }

    const selectedSizeId = tShirtsStocking.restaurant.selectedTShirt
        ? tShirtsStocking.restaurant.selectedTShirt.size.data.id
        : null;

    return [
        <div
            key={"DeliveryTShirtHeader"}
            style={TShirtsStockingUtils.computeGridColumns(tShirtsStocking, width)}
            className={`${tShirtsStockingHeaderStyles.Header} ${tShirtsStockingItemStyles.Item}`}
        >
            <span>Style/Size</span>
            {tShirtsStocking.sizes.map(({ data: { id, name } }) => {
                const className = id === selectedSizeId ? deliveryTShirtsStyles.SizeSelected : "";
                return <span className={className}>{name}</span>;
            })}
        </div>,
    ];
};

export default TShirtsStockingHeader;
