import React, { useContext } from "react";
import { AppContext } from "../../../../context/AppContext";
import { OnHandContext } from "../../../on-hand/context/OnHandContext";
import onHandItemInputStyles from "../../../on-hand/components/item/OnHandItemInput.module.css";
import OnHandItemQuickInput from "../../../on-hand/components/item/OnHandItemQuickInput";

export default function OffSiteDeliveryItemQuickInputs() {
    const { appState } = useContext(AppContext);
    const { onSelectedPendingDeliveryItemQuantityUpdate } = useContext(OnHandContext) as any;

    const handleChange = (addedQuickValue: number) => {
        return onSelectedPendingDeliveryItemQuantityUpdate(addedQuickValue, appState.user.name);
    };

    return (
        <div className={onHandItemInputStyles.Positives}>
            <div>
                <OnHandItemQuickInput handleChange={handleChange} display={"+ 1"} quickValue={1} />
                <OnHandItemQuickInput handleChange={handleChange} display={"+ 5"} quickValue={5} />
            </div>
            <div>
                <OnHandItemQuickInput handleChange={handleChange} display={"- 1"} quickValue={-1} />
                <OnHandItemQuickInput handleChange={handleChange} display={"- 5"} quickValue={-5} />
            </div>
        </div>
    );
}
