export default class OnHandGroupModel {
    updateStatus(newStatus) {
        this.status = newStatus;
        return this;
    }

    selectItemByIndex(newItemIndex) {
        if (
            typeof newItemIndex !== "number" ||
            newItemIndex < 0 ||
            newItemIndex > this.items.length
        ) {
            return;
        }
        this.selectedItemIndex = newItemIndex;
        this.item = this.items[this.selectedItemIndex];
        return this;
    }

    deselectItem() {
        this.selectedItemIndex = null;
        this.item = null;
        return this;
    }

    selectFirstIncompleteItem() {
        let firstIncompleteItemIndex = this.items.findIndex((i) => i.status < 2);
        if (firstIncompleteItemIndex === -1) {
            firstIncompleteItemIndex = this.items.length - 1;
            if (firstIncompleteItemIndex === -1) {
                return this.deselectItem();
            }
        }
        return this.selectItemByIndex(firstIncompleteItemIndex);
    }

    needsConfirmation() {
        return this.items.some(({ inventory: { dirty } }) => dirty);
    }

    rejectItems() {
        this.showConfirmation = false;
        return this;
    }

    constructor(appGroup, items = []) {
        Object.assign(this, appGroup);
        this.items = items.sort((a, b) => parseInt(a.data.order) - parseInt(b.data.order));
        this.selectFirstIncompleteItem();
    }
}
