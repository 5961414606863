import { ContainerConfigSection } from "./../ContainerConfigSection";
import ContainerConfigBuilder from "../ContainerConfigBuilder";
import { ADMINS, DATA_EDITOR_USERS, ORDERS_ADMIN } from "../../services/AppUserGroupsData";
import DataEditorAdmin from "./data-editor/DataEditorAdmin";
import DataEditorAdminMenuItem from "./data-editor/DataEditorAdminMenuItem";
import { ContainerPaths } from "../ContainerPaths";

export const AdminContainerConfig: ContainerConfigSection = {
    DataEditor: new ContainerConfigBuilder("Editor")
        .setPath(ContainerPaths.EDITOR)
        .setUserAccess([ORDERS_ADMIN, DATA_EDITOR_USERS, ADMINS])
        .setComponent(DataEditorAdmin)
        .setMenuItem(DataEditorAdminMenuItem)
        .build(),
};
