import { useContext, useEffect } from "react";
import { useGlobalSpinnerActionsContext } from "../../../components/global-spinner/GlobalSpinnerContext";
import { OffSiteReordersContext } from "./context/OffSiteReordersContext";
import { OffSiteReordersAlertsType } from "./context/OffSiteReordersContextTypes";
import styles from "./OffSiteReorders.module.css";
import OffSiteReordersCompletedAlerts from "./OffSiteReordersCompletedAlerts";
import OffSiteReordersOpenAlerts from "./OffSiteReordersOpenAlerts";
import OffSiteReordersSnoozedAlerts from "./OffSiteReordersSnoozedAlerts";
import OffSiteReordersUtils from "./OffSiteReordersUtils";

export default function OffSiteReorders() {
    const setGlobalSpinner = useGlobalSpinnerActionsContext();
    const { setAlerts } = useContext(OffSiteReordersContext);

    useEffect(() => {
        try {
            setGlobalSpinner(true);
            OffSiteReordersUtils.loadReordersAlertsDecorated().then(
                (alerts: OffSiteReordersAlertsType) => {
                    return setAlerts(alerts);
                }
            );
        } catch (e) {
            throw Error("Failed to load reorders!");
        } finally {
            setGlobalSpinner(false);
        }
    }, []);

    return (
        <div key="offsite-reorders" className={styles.ReordersAlerts}>
            <OffSiteReordersOpenAlerts />
            <OffSiteReordersSnoozedAlerts />
            <OffSiteReordersCompletedAlerts />
        </div>
    );
}
