import { FormikProps, FormikTouched } from "formik";
import React from "react";
import { ResourceColumn } from "../ResourceColumn";

export class ResourceColumnNumber extends ResourceColumn {
    public min?: number;

    constructor(public field: string, public name: string, public step: number = 1) {
        super(field, name, "number");
        this.min = 0;
        this.defaultValue = this.min;
        this.validator = (data: string) => parseFloat(data) >= 0;
    }

    prepRenderEdit(index: number, fmkProps: FormikProps<any>) {
        const { handleChange, handleBlur, errors, values, isSubmitting } = fmkProps;
        const content = (
            <input
                disabled={isSubmitting}
                type={this.inputType}
                name={this.field}
                min={this.min}
                step={this.step}
                placeholder={this.placeholder}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values[this.field]}
            />
        );

        const touched: FormikTouched<any> = fmkProps.touched;

        return super.renderEdit(index, this.field, content, errors, touched, isSubmitting);
    }
}
