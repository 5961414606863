import React from "react";
import { MdAttachMoney } from "react-icons/md";

const SalesTShirtsMenuItem = (name: string) => {
    return [
        <MdAttachMoney key={`${name}-icon`} size={"1.7em"} />,
        <div key={`${name}-text`}>
            <span>{name}</span>
        </div>,
    ];
};

export default SalesTShirtsMenuItem;
