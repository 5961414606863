import { Button } from "@material-ui/core";
import React, { useContext } from "react";

import { OnHandContext } from "../../on-hand/context/OnHandContext";
import styles from "./OffSiteOnHandItemHeader.module.css";

export default function OffSiteOnHandItemHeader() {
    const { onHandState, changeView } = useContext(OnHandContext) as any;

    const views = onHandState.config.display?.views?.all || [];

    return (
        <div className={styles.Main}>
            {views.map((view: any) => {
                const isSelected = onHandState.view?.name === view.name;
                const isSelectedClass = isSelected ? styles.SelectedMenuItem : "";

                return (
                    <Button
                        disabled={view.disabled}
                        variant={"text"}
                        onClick={() => changeView(view)}
                        color={"primary"}
                        className={`${styles.MenuItem} ${isSelectedClass}`}
                    >
                        {view.name}
                    </Button>
                );
            })}
        </div>
    );
}
