import _ from "lodash";
import { fetchResources } from "../../../../resources/ResourcesService";
import AppUtilsService from "../../../../services/AppUtilsService";
import { AppRestaurant, AppRestaurantTypes } from "../../../admin/AdminResources";
import MenusResolver from "../../../menus-sales/services/MenusResolver";
import OnHandResolver from "../../../on-hand/services/OnHandResover";
import {
    AppDeliveryArea,
    AppIngredient,
    AppIngredientDeliveryArea,
    AppIngredientOnHandArea,
    AppIngredientPack,
} from "../../FoodResources";
import OnHandFoodContextConfig from "../../on-hand/context/OnHandFoodContextConfig";
import {
    DeliveryFoodDays,
    DeliveryFoodDeliveryAreasById,
    DeliveryFoodIngredientPackById,
    DeliveryFoodIngredientsById,
    DeliveryFoodResolverData,
    DeliveryFoodRestaurantsById,
    DeliveryFoodIngredientDeliveryArea,
    DeliveryFoodIngredientRestaurantArea,
    DeliveryFoodIngredient,
    DeliveryFoodDeliveryAreasByRestaurantId,
    DeliveryFoodDeliverySectionsByRestId,
    DeliveryFoodDeliveryAreaById,
    DeliveryFoodOnHandFromItems,
} from "../types/DeliveryFoodTypes";
import { extractIngredientResults } from "./DeliveryFoodExtract";

export default async function DeliveryFoodResolver(
    days: DeliveryFoodDays,
    userName: string,
    sectionName: string,
    userGroups: string[]
): Promise<DeliveryFoodResolverData> {
    const { salesDay, onHandDay } = days;

    const salesData = await MenusResolver(userGroups, salesDay, userName, sectionName, false);
    const onHandData = await OnHandResolver(
        onHandDay,
        userName,
        sectionName,
        userGroups,
        OnHandFoodContextConfig,
        false
    );
    const restaurantsList: AppRestaurant[] = Object.values(onHandData).map(
        ({ data }: any) => new AppRestaurant(data)
    );

    // TRANSFORM DATA FOR DELIVERY
    const { onHand, sales } = Object.keys(onHandData).reduce(
        (acc, restaurantId: any) => {
            const onHand = extractOnHandFromGroups(onHandData[restaurantId].groups);
            const sales = extractSalesFromMenu(salesData[restaurantId]);

            acc.onHand = acc.onHand.concat(onHand.map((item: any) => ({ ...item, restaurantId })));
            acc.sales = acc.sales.concat(sales.map((item: any) => ({ ...item, restaurantId })));

            return acc;
        },
        { onHand: [], sales: [] }
    );

    // DELIVERY-SPECIFIC RESOLVER
    let [ingredients, packs, deliveryAreas, __, ingredientDeliveryAreas] = await fetchResources([
        [AppIngredient],
        [AppIngredientPack],
        [AppDeliveryArea],
        [AppIngredientOnHandArea],
        [AppIngredientDeliveryArea],
    ]);

    // ORGANISE PROPERTIES TO BE ATTACHED
    const restaurantsById: DeliveryFoodRestaurantsById = restaurantsList.reduce(
        AppUtilsService.groupDataByItem("id"),
        {}
    );

    const ingredientsById: DeliveryFoodIngredientsById = ingredients.reduce(
        AppUtilsService.groupDataByItem("id"),
        {}
    );

    const packsById: DeliveryFoodIngredientPackById = packs.reduce(
        AppUtilsService.groupDataByItem("id"),
        {}
    );

    const salesByIngredientId = sales.reduce((acc: any, item: any) => {
        acc[item.ingredientId] = acc[item.ingredientId] || { sales: [], onHand: [] };
        acc[item.ingredientId].sales.push(item);
        return acc;
    }, {});

    const deliveryByIngredientId = onHand.reduce((acc, item: any) => {
        acc[item.ingredientId] = acc[item.ingredientId] || { sales: [], onHand: [] };
        acc[item.ingredientId].onHand.push(item);
        return acc;
    }, salesByIngredientId);

    const deliveryAreasById: DeliveryFoodDeliveryAreasById = deliveryAreas
        .map((deliveryArea: AppDeliveryArea) => {
            const result: DeliveryFoodDeliveryAreaById = Object.assign(deliveryArea, {
                restaurant: restaurantsById[deliveryArea.data.restaurantId],
            });

            return result;
        })
        .reduce(AppUtilsService.groupDataByItem("id"), {});

    const ingredientDeliveryAreasByIngredientAndRestaurantId = ingredientDeliveryAreas.reduce(
        (acc: any, ingredientDeliveryArea: AppDeliveryArea) => {
            const ingredientId = ingredientDeliveryArea.data.ingredientId;
            const deliveryAreaId = ingredientDeliveryArea.data.deliveryAreaId;
            const restaurantId = deliveryAreasById[deliveryAreaId]?.restaurant?.data?.id;

            if (!acc[ingredientId]) {
                acc[ingredientId] = {};
            }

            if (!restaurantId) {
                return acc;
            }

            acc[ingredientId][restaurantId] = _.clone(ingredientDeliveryArea);

            return acc;
        },
        {}
    );

    const ingredientDeliveryAreasDecorated = ingredientDeliveryAreas.map(
        (ingredientDeliveryArea: AppIngredientDeliveryArea) => {
            const {
                data: { ingredientId, deliveryAreaId },
            } = ingredientDeliveryArea;

            const result: DeliveryFoodIngredientDeliveryArea = Object.assign(
                ingredientDeliveryArea,
                {
                    ingredient: ingredientsById[ingredientId],
                    deliveryArea: deliveryAreasById[deliveryAreaId],
                }
            );

            if (!result.ingredient || !result.deliveryArea) {
                return null;
            }

            return result;
        }
    );

    const ingredientRestaurantArea: DeliveryFoodIngredientRestaurantArea =
        ingredientDeliveryAreasDecorated
            .filter((x: DeliveryFoodIngredientDeliveryArea | null) => x)
            .reduce(
                (
                    acc: DeliveryFoodIngredientRestaurantArea,
                    area: DeliveryFoodIngredientDeliveryArea
                ) => {
                    if (!area.ingredient) {
                        return acc;
                    }

                    if (!area.deliveryArea) {
                        return acc;
                    }

                    const ingredientId = area.ingredient.data.id;
                    const fixedNeed = area.data.fixedNeed;
                    const id = area.deliveryArea.data.id;
                    const name = area.deliveryArea.data.name;
                    const restaurantId = area.deliveryArea.restaurant.data.id;

                    if (!restaurantId) {
                        return acc;
                    }

                    acc[ingredientId] = acc[ingredientId] || {};
                    acc[ingredientId][restaurantId] = { id, name, fixedNeed };

                    return acc;
                },
                {} as DeliveryFoodIngredientRestaurantArea
            );

    // DECORATE ALL
    const decoratedIngredients: DeliveryFoodIngredient[] = ingredients.map(
        (rawIngredient: AppIngredient) => {
            const { ingredientPackId, id } = rawIngredient.data;

            const pack = packsById[ingredientPackId];
            const delivery = ingredientRestaurantArea[id];
            const dataForDelivery = deliveryByIngredientId[id];
            const deliveryConfigsByRestaurantId =
                ingredientDeliveryAreasByIngredientAndRestaurantId[id];
            const mockResults = { COM: null, RST: null };

            let ingredient: DeliveryFoodIngredient = Object.assign(rawIngredient, {
                pack,
                delivery,
                dataForDelivery,
                deliveryConfigsByRestaurantId,
                results: mockResults,
            });

            ingredient.results = extractIngredientResults(ingredient, restaurantsList);

            return ingredient;
        }
    );

    // DELIVERY SECTIONS PER NON-COMMISSARY RESTAURANTS
    const deliveryAreasByRestaurantId: DeliveryFoodDeliveryAreasByRestaurantId =
        deliveryAreas.reduce(AppUtilsService.groupDataByList("restaurantId"), {});

    const deliveryRestaurants: AppRestaurant[] = restaurantsList.filter(
        (restaurant: AppRestaurant) => restaurant.data.type === AppRestaurantTypes.RESTAURANT
    );

    const deliverySections: DeliveryFoodDeliverySectionsByRestId = deliveryRestaurants.reduce(
        (acc: DeliveryFoodDeliverySectionsByRestId, restaurant: AppRestaurant) => {
            if (!restaurant.data.id) {
                return acc;
            }

            acc[restaurant.data.id] = deliveryAreasByRestaurantId[restaurant.data.id];

            return acc;
        },
        {} as DeliveryFoodDeliverySectionsByRestId
    );

    return { decoratedIngredients, restaurantsById, deliverySections };
}

function extractOnHandFromItems(items: any): DeliveryFoodOnHandFromItems[] {
    const itemsOnHand = items.reduce((acc: DeliveryFoodOnHandFromItems[], item: any) => {
        const {
            ingredient: {
                data: { id },
            },
            inventory: {
                data: { quantity },
            },
            data: { bulk, portionRatio },
        } = item;

        if (typeof quantity === "number" && quantity >= 0) {
            acc.push({ ingredientId: id, quantity, bulk, portionRatio });
        }
        return acc;
    }, []);

    return itemsOnHand;
}

function extractOnHandFromGroups(groups: any): any {
    return groups
        .reduce((acc: any, group: any) => {
            const {
                items,
                data: { id, name },
            } = group;

            const inventories = extractOnHandFromItems(items).map((inventory: any) => ({
                ...inventory,
                onHandAreaId: id,
                onHandAreaName: name,
            }));
            acc.push(inventories);

            return acc;
        }, [])
        .flat();
}

function extractSalesFromMenu(restaurantSalesData: any): any {
    if (!restaurantSalesData) {
        return [];
    }
    return restaurantSalesData.menu.reduce((acc: any, menuItem: any) => {
        const {
            recipe,
            sales,
            data: { id, name, order },
        } = menuItem;

        const data = Object.keys(sales)
            .filter((saleType) => sales[saleType] && sales[saleType].data)
            .map((saleType: any) => {
                const type = saleType;
                const quantitySold = sales[saleType].data.quantity;

                return recipe.reduce((acc: any, { data: { quantity, ingredientId } }: any) => {
                    if (typeof quantitySold === "number" && quantitySold > 0) {
                        acc.push({
                            type,
                            menuItem: { id, name, order },
                            quantitySold,
                            quantityPerItem: quantity,
                            ingredientId,
                        });
                    }
                    return acc;
                }, []);
            });

        acc = acc.concat(data.flat());
        return acc;
    }, []);
}
