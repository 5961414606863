import { useContext, useEffect } from "react";
import { DataEditorContext } from "../../../context/DataEditorContext";
import styles from "../../../DataEditor.module.css";
import DataEditorAdd from "./add/DataEditorAdd";
import DataEditorHeaderColumns from "./DataEditorHeaderColumns";
import DataEditorSaveOrderBtn from "./order/DataEditorSaveOrderBtn";

const DataEditorHeader = () => {
    const { dataEditorState } = useContext(DataEditorContext);

    useEffect(() => {}, [dataEditorState.getSelectedResource()]);

    return (
        <div className={`${styles.HeaderWrp} ${styles.RowWrp}`}>
            <div className={`${styles.Header} ${styles.Row}`}>
                <DataEditorAdd />
                <DataEditorHeaderColumns />
            </div>
            <DataEditorSaveOrderBtn />
        </div>
    );
};

export default DataEditorHeader;
