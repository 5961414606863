import React, { useContext, useEffect } from "react";

import { useGlobalSpinnerActionsContext } from "../../components/global-spinner/GlobalSpinnerContext";
import { AppContext } from "../../context/AppContext";
import OnHandContent from "./components/content/OnHandContent";
import OnHandToolbar from "./components/toolbar/OnHandToolbar";
import { OnHandContext } from "./context/OnHandContext";
import OnHandContextValidator from "./context/OnHandContextValidator";
import OnHandUtils from "./OnHandUtils";
import OnHandConfigModel from "./models/OnHandConfigModel";
import onHandStyles from "./OnHand.module.css";

function OnHand({ config }) {
    const { appState } = useContext(AppContext);
    const setGlobalSpinner = useGlobalSpinnerActionsContext();
    const { onHandState, loadOnHandData, loadOnHandDataTask, selectItemFromTask } =
        useContext(OnHandContext);

    useEffect(() => {
        if (OnHandUtils.isTransactionalOnHand(config)) {
            loadOnHandDataTask(
                config,
                appState.day,
                appState.user.groups,
                appState.user.name,
                appState.section.name,
                setGlobalSpinner,
                appState.offSiteTaskSelectedKey
            );
        } else {
            loadOnHandData(
                config,
                appState.day,
                appState.user.groups,
                appState.user.name,
                appState.section.name,
                setGlobalSpinner
            );
        }
    }, [appState.day, appState.user]);

    useEffect(() => {
        if (onHandState.restaurant && appState.offSiteTaskSelectedKey) {
            selectItemFromTask(appState.offSiteTaskSelectedKey);
        }
    }, [appState.offSiteTaskSelectedKey, onHandState.restaurant]);

    if (!(config && config.constructor === OnHandConfigModel)) {
        console.info("Invalid OnHand config!");
        return null;
    }

    if (!onHandState.restaurant) {
        console.info("Invalid OnHand restaurant!");
        return null;
    }

    if (!OnHandContextValidator.hasValidGroups(onHandState)) {
        console.info("Invalid groups!");
        return null;
    }

    return (
        <div className={onHandStyles.Main}>
            <OnHandToolbar />
            <OnHandContent />
            {console.info("RENDER: OnHand")}
        </div>
    );
}

export default OnHand;
