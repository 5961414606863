import React, { useContext, useEffect } from "react";
import { useGlobalSpinnerActionsContext } from "../../components/global-spinner/GlobalSpinnerContext";
import { AppContext } from "../../context/AppContext";
import DataEditorItems from "./components/content/DataEditorItems";
import DataEditorHeader from "./components/content/header/DataEditorHeader";
import DataEditorFooter from "./components/footer/DataEditorFooter";
import DataEditorToolbar from "./components/toolbar/DataEditorToolbar";
import { DataEditorContext } from "./context/DataEditorContext";
import dataEditorStyles from "./DataEditor.module.css";
import { DataEditorItemsType } from "./types/DataEditorTypes";
import DataEditorUtils from "./utils/DataEditorUtils";

const DataEditor = (props: any) => {
    const setGlobalSpinner = useGlobalSpinnerActionsContext();
    const { appState } = useContext(AppContext);
    const { dataEditorState, updateAllItems } = useContext(DataEditorContext);

    useEffect(() => {
        setGlobalSpinner(true);
        DataEditorUtils.loadDataEditorItems(appState, dataEditorState.getAllResources()).then(
            (items: DataEditorItemsType) => {
                setGlobalSpinner(false);
                return updateAllItems(items);
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={dataEditorStyles.Main}>
            <div className={dataEditorStyles.ToolbarWrp}>
                <DataEditorToolbar />
            </div>
            <div className={dataEditorStyles.ContentWrp}>
                <div className={dataEditorStyles.Content}>
                    <DataEditorHeader />
                    <DataEditorItems />
                </div>
            </div>
            <div className={dataEditorStyles.FooterWrp}>
                <DataEditorFooter />
            </div>
        </div>
    );
};

export default React.memo(DataEditor);
