const OnHandActions = {
    LOAD_DATA: "LOAD_DATA",
    LOAD_DATA_TASK: "LOAD_DATA_TASK",
    CHANGE_RESTAURANT: "CHANGE_RESTAURANT",
    CHANGE_VIEW: "CHANGE_VIEW",
    TOGGLE_GROUP_SELECTION: "TOGGLE_GROUP_SELECTION",
    CHANGE_ITEM: "CHANGE_ITEM",
    GO_TO_PREV_ITEM: "GO_TO_PREV_ITEM",
    GO_TO_NEXT_ITEM: "GO_TO_NEXT_ITEM",
    GO_TO_PREV_ITEM_TASKS: "GO_TO_PREV_ITEM_TASKS",
    GO_TO_NEXT_ITEM_TASKS: "GO_TO_NEXT_ITEM_TASKS",
    INVENTORY_ATTACH: "INVENTORY_ATTACH",
    INVENTORY_UPDATE: "INVENTORY_UPDATE",
    DELIVERY_TRANSACTION_UPDATE: "DELIVERY_TRANSACTION_UPDATE",
    STOCKING_TRANSACTION_UPDATE: "STOCKING_TRANSACTION_UPDATE",
    DELIVERY_TRANSACTION_RESET: "DELIVERY_TRANSACTION_RESET",
    STOCKING_TRANSACTION_RESET: "STOCKING_TRANSACTION_RESET",
    LOCK_SUBMISSION: "LOCK_SUBMISSION",
    UNLOCK_SUBMISSION: "UNLOCK_SUBMISSION",
    SUBMIT_SELECTED_ITEM: "SUBMIT_SELECTED_ITEM",
    SUBMIT_SELECTED_DELIVERY_ITEM: "SUBMIT_SELECTED_DELIVERY_ITEM",
    SUBMIT_SELECTED_STOCKING_ITEM: "SUBMIT_SELECTED_STOCKING_ITEM",
    CONFIRM_CHANGES: "CONFIRM_CHANGES",
    REJECT_CHANGES: "REJECT_CHANGES",
    TOGGLE_LOCK: "TOGGLE_LOCK",
    SYNC: "SYNC",
    SELECT_ITEM_FROM_TASK: "SELECT_ITEM_FROM_TASK",
};

export default OnHandActions;
