import { ResourceColumn } from "./../resources/ResourceColumn";

export type ResourceStoreKey = string;
export type ResourceRefKey = string;

export class ModelConfig {
    constructor(
        // graphql schema type name
        public typeName: string,
        // dynamodb table limit
        public limit: number,
        // display limit
        public pageSize: number,
        // the key under which items of "type" can be stored (must be unique)
        public resourceStoreKey: ResourceStoreKey,
        // the user friendly name of the model
        public typeNameDisplay: string,
        // the unique key of the initial data for this model
        public initDataKey: string,
        // the unique key to reference this model
        public resourceRefKey: ResourceRefKey,
        // the definition of each property
        public columns: ResourceColumn[],
        // current page index, when it is the case
        public pageIndex: number = 0
    ) {}
}
