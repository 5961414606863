import React from "react";
import deliveryOrdersStyles from "../DeliveryOrders.module.css";

export default function DeliveryOrdersEmailOrderLabel({ order }) {
    const hasDiscount =
        typeof order?.data?.discount === "string" && order.data.discount.trim().length > 0;

    return (
        <div className={deliveryOrdersStyles.DialogOrderLabel}>
            <span>{order?.data?.name || "Unknown!"}</span>
            {hasDiscount ? (
                <span className={deliveryOrdersStyles.DialogOrderLabelDiscount}>
                    {order?.data?.discount}
                </span>
            ) : null}
        </div>
    );
}
