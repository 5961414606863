import { Button } from "@material-ui/core";
import { useContext } from "react";
import { OnHandContext } from "../../../on-hand/context/OnHandContext";
import { OffSiteContext } from "../../context/OffSiteContext";

export default function OffSiteDeliveryItemPrev() {
    const { offSiteState, goToPrevTask } = useContext(OffSiteContext);
    // TODO: replace when on hand is typescript-ified
    const { goToPrevItemTasks } = useContext(OnHandContext) as any;

    const handleGoToPrev = () => goToPrevItemTasks(goToPrevTask, offSiteState.taskSelectedKey);

    return (
        <Button variant="contained" onClick={handleGoToPrev}>
            Prev
        </Button>
    );
}
