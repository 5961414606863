import DateFnsUtils from "@date-io/date-fns";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "date-fns";
import React, { useContext, useEffect } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { TiMediaRecord } from "react-icons/ti";
import { AppContext } from "../../context/AppContext";
import AppUserGroups from "../../services/AppUserGroups";
import appDayStyles from "./AppDay.module.css";
import AppDayModel from "./AppDayModel";

export default function AppDay() {
    const { appState, selectDay } = useContext(AppContext);
    const handleDateChange = (selectedDate, selectedDateLabel) => {
        return selectDay(new AppDayModel(selectedDate));
    };

    useEffect(() => {}, [appState.user]);

    if (!appState.day) {
        console.info("Invalid app day");
        return <div />;
    }

    const currentDayIsToday = appState.day.data.time === AppDayModel.getTodayUnixTime();

    const disableFuture = !AppUserGroups.hasNonGuestAccess(appState.user.groups);

    const AppPrevDay = () => {
        const onGoPrevDay = () => {
            if (appState.day.data.time <= 1592193600) {
                // min date: 2020-06-15
                return;
            }
            selectDay(new AppDayModel(AppDayModel.getPrevDayUnixTime(appState.day.data.time)));
        };

        return (
            <Tooltip title={"Previous Day"}>
                <Button onClick={onGoPrevDay}>
                    <AiOutlineLeft size={24} color={"#c5cae9"} />
                </Button>
            </Tooltip>
        );
    };

    const AppToday = () => {
        const onGoToday = () => {
            if (!(currentDayIsToday && disableFuture)) {
                selectDay(new AppDayModel());
            }
        };

        return (
            <Tooltip title={"Today"}>
                <Button onClick={onGoToday} disabled={currentDayIsToday && disableFuture}>
                    <TiMediaRecord
                        size={24}
                        color={currentDayIsToday && disableFuture ? "#283593" : "#c5cae9"}
                    />
                </Button>
            </Tooltip>
        );
    };

    const AppNextDay = () => {
        const onGoNextDay = () => {
            if (!(currentDayIsToday && disableFuture)) {
                selectDay(new AppDayModel(AppDayModel.getNextDayUnixTime(appState.day.data.time)));
            }
        };

        return (
            <Tooltip title={"Next Day"}>
                <Button onClick={onGoNextDay} disabled={currentDayIsToday && disableFuture}>
                    <AiOutlineRight
                        size={24}
                        color={currentDayIsToday && disableFuture ? "#283593" : "#c5cae9"}
                    />
                </Button>
            </Tooltip>
        );
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around" className={appDayStyles.Day}>
                <div className={appDayStyles.DayNav}>
                    {AppPrevDay()}
                    {AppToday()}
                    {AppNextDay()}
                </div>
                <KeyboardDatePicker
                    margin="normal"
                    minDate={"2020-06-15"}
                    disableFuture={disableFuture}
                    id="day-picker-dialog"
                    format="iii, MMM do"
                    showTodayButton={true}
                    value={appState.day.data.time * 1000}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        "aria-label": "change day",
                    }}
                    className={appDayStyles.DayPicker}
                />
            </Grid>
        </MuiPickersUtilsProvider>
    );
}
