import React, { useContext, useEffect } from "react";
import menusToolbarStyles from "./MenusToolbar.module.css";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { AppContext } from "../../../../context/AppContext";
import { MenusContext } from "../../context/MenusContext";
import MenusToolbarInvalidQueue from "./MenusToolbarInvalidQueue";

const MenusToolbar = () => {
    const { appState } = useContext(AppContext);
    const { menusState, changeRestaurant } = useContext(MenusContext);

    useEffect(() => {}, [
        menusState.restaurants,
        menusState.restaurant,
        menusState.completionScore,
    ]);

    if (!menusState.restaurants) {
        console.info("MenusToolbar: Invalid menus restaurants!");
        return <div />;
    }

    if (!menusState.restaurant) {
        console.info("MenusToolbar: Invalid menus restaurant!");
        return <div />;
    }

    const handleRestaurantChange = (event) => {
        const selectedRestaurantId = event.target.value;
        return changeRestaurant(selectedRestaurantId);
    };

    const completionScore = appState.day ? Math.ceil(menusState.completionScore * 1000) / 10 : "?";

    return (
        <div className={menusToolbarStyles.menusToolbar}>
            <div className={menusToolbarStyles.menusToolbarSelection}>
                <FormControl className={menusToolbarStyles.menusToolbarSelectionRestaurant}>
                    <Select
                        labelId="menus-restaurant"
                        id="menus-restaurant"
                        value={menusState.restaurant.data.id}
                        onChange={handleRestaurantChange}
                    >
                        {Object.values(menusState.restaurants).map((restaurant, i) => (
                            <MenuItem key={`restaurant-${i}`} value={restaurant.data.id}>
                                {restaurant.data.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <MenusToolbarInvalidQueue />

                <div className={menusToolbarStyles.menusToolbarCompletion}>
                    <p>{completionScore} %</p>
                </div>
            </div>
        </div>
    );
};

export default MenusToolbar;
