import { Button } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { useContext, useEffect } from "react";
import { MdPlaylistAddCheck } from "react-icons/md";
import { useGlobalSpinnerActionsContext } from "../../../../../../components/global-spinner/GlobalSpinnerContext";
import { Resource } from "../../../../../../resources/Resource";
import { seriesPromises } from "../../../../../../resources/ResourcesService";
import { DataEditorContext } from "../../../../context/DataEditorContext";
import dataEditorStyles from "../../../../DataEditor.module.css";
import DataEditorUtils from "../../../../utils/DataEditorUtils";

const DataEditorSaveOrderBtn = () => {
    const setGlobalSpinner = useGlobalSpinnerActionsContext();
    const { dataEditorState, saveOrder } = useContext(DataEditorContext);

    useEffect(() => {}, [
        dataEditorState.store.resources.selected,
        dataEditorState.display.enableReorder,
    ]);

    const handleSaveOrder = async () => {
        setGlobalSpinner(true);

        try {
            const selectedResource = dataEditorState.getSelectedResource();
            const storeKey = selectedResource.getModel().getResourceStoreKey();
            const resourceRefKey = selectedResource.getModel().getResourceRefKey();

            const items = dataEditorState.getItemsByStoreKey(storeKey);
            const itemsToBeUpdated: Resource[] = [];
            const updatedItems = items.map((item: Resource, index: number) => {
                if (item.data.order !== index) {
                    item.data.order = index;
                    itemsToBeUpdated.push(item);
                }
                return item;
            });

            const itemsToBeUpdatedPromises = itemsToBeUpdated.map((item) =>
                item.getModel().api.update(item.data)
            );

            await seriesPromises(itemsToBeUpdatedPromises);

            dataEditorState.setItemsByStoreKey(storeKey, updatedItems);
            dataEditorState.setItemsByResourceRefKey(resourceRefKey, updatedItems);

            saveOrder(updatedItems);
        } catch (e: any) {
            throw Error(e.message);
        } finally {
            setGlobalSpinner(false);
        }
    };

    if (
        !DataEditorUtils.containsOrderColumn(
            dataEditorState.getSelectedResource().getModel().getColumns()
        )
    ) {
        // no ordering for this resource
        return <div />;
    }

    if (!dataEditorState.display.enableReorder) {
        // no reordering occurred
        return <div className={dataEditorStyles.Handle} />;
    }

    return (
        <div className={`${dataEditorStyles.OrderSaveBtn} ${dataEditorStyles.Handle}`}>
            <Tooltip title={"Save Order"}>
                <Button onClick={handleSaveOrder}>
                    <MdPlaylistAddCheck size={26} color={"#f50057"} />
                </Button>
            </Tooltip>
        </div>
    );
};

export default DataEditorSaveOrderBtn;
