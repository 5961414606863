import React, { useContext } from "react";
import { useGlobalSpinnerActionsContext } from "../../../../components/global-spinner/GlobalSpinnerContext";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import onHandGroupStyles from "./OnHandGroup.module.css";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { OnHandContext } from "../../context/OnHandContext";

const OnHandGroupConfirmChanges = ({ group }) => {
    const { onHandState, confirmChanges, rejectChanges } = useContext(OnHandContext);
    const setGlobalSpinner = useGlobalSpinnerActionsContext();

    if (!(group.needsConfirmation() && group.showConfirmation)) {
        return null;
    }

    const handleConfirm = async () => {
        const restaurant = await onHandState.restaurant.confirmGroupItems(setGlobalSpinner);

        return confirmChanges(restaurant);
    };

    const handleReject = () => {
        return rejectChanges();
    };

    const itemConfirmDisplay = (item, itemIndex) => {
        // TODO: take these from page item context
        const name = item.ingredient?.data.name || item.data.name;
        const unitName = item.data.bulk || item.onHandUnit?.data.name;
        return (
            <div key={`confirm-changed-item-${itemIndex}`}>
                <strong style={{ textAlign: "right" }}>{name}</strong>
                <span style={{ textAlign: "center" }}>{item.inventory.data.quantity}</span>
                <span style={{ textAlign: "left" }}>{unitName}</span>
            </div>
        );
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            onClose={() => {}}
            aria-labelledby="customized-dialog-title"
            open={true}
        >
            <DialogTitle
                id="customized-dialog-title"
                className={onHandGroupStyles.ConfirmTitle}
                onClose={() => {}}
            >
                <strong>{group.data.name}</strong>
            </DialogTitle>
            <DialogContent dividers>
                <div className={onHandGroupStyles.Confirm}>
                    {group.items
                        .filter(({ inventory: { dirty } }) => dirty)
                        .map(onHandState.config.display.itemConfirm || itemConfirmDisplay)}
                </div>
            </DialogContent>
            <DialogActions
                style={{ display: "grid", gridAutoFlow: "column", placeContent: "space-between" }}
            >
                <Button autoFocus onClick={handleConfirm} variant="contained" color="secondary">
                    Confirm
                </Button>
                <Button autoFocus onClick={handleReject} variant="outlined" color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default OnHandGroupConfirmChanges;
