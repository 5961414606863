import React, { useContext, useEffect } from "react";
import deliveryOrdersStyles from "./DeliveryOrders.module.css";
import DeliveryOrdersHeader from "./header/DeliveryOrdersHeader";
import DeliveryOrdersContent from "./content/DeliveryOrdersContent";
import DeliveryOrdersUtils from "./DeliveryOrdersUtils";
import { DeliveryOrdersContext } from "./context/DeliveryOrdersContext";

const DeliveryOrders = ({ history, location }) => {
    const { deliveryOrders } = useContext(DeliveryOrdersContext);

    useEffect(() => {}, [deliveryOrders.restaurant]);

    if (!DeliveryOrdersUtils.isValidState(deliveryOrders)) {
        return null;
    }

    return (
        <div className={deliveryOrdersStyles.Main}>
            <DeliveryOrdersHeader />
            <DeliveryOrdersContent />
        </div>
    );
};

export default DeliveryOrders;
