import React from "react";
import deliveryTShirtsStyles from "../TShirtsStocking.module.css";
import { NEEDS_ON_HAND } from "../t-shirt/TShirtsStockingStatus";

const TShirtsStockingStatusNeedsOnHand = () => {
    return (
        <div className={deliveryTShirtsStyles.Status}>
            {NEEDS_ON_HAND.render("On Hand Incomplete", true)}
        </div>
    );
};

export default TShirtsStockingStatusNeedsOnHand;
