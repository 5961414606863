import { fetchResources } from "../../../../resources/ResourcesService";
import AppUtilsService from "../../../../services/AppUtilsService";
import OnHandItemContext from "../../../on-hand/context/OnHandItemContext";
import OnHandItemModel from "../../../on-hand/models/OnHandItemModel";
import { AppOrderUnits, AppOrderVendor } from "../../OrdersResources";

const ID = "id";

export default class OnHandOrderItemContext extends OnHandItemContext {
    async loadDependencies(day, userName, sectionName) {
        let [orderUnits, orderVendors] = await fetchResources([[AppOrderUnits], [AppOrderVendor]]);

        this.orderUnitsById = orderUnits.reduce(AppUtilsService.groupDataByItem(ID), {});
        this.orderVendorsById = orderVendors.reduce(AppUtilsService.groupDataByItem(ID), {});
        const inventories = await this.generator.loadInventories(
            this.rawRestaurants,
            this.rawItems,
            day,
            userName,
            sectionName
        );
        this.orderInventoriesById = inventories.reduce(
            AppUtilsService.groupDataByItem(this.resourceRefKey),
            {}
        );
    }

    decorate(item) {
        const {
            data: { id, orderOnHandUnitId, orderDeliveryUnitId, orderVendorId },
        } = item;

        const inventory = this.orderInventoriesById[id];
        const onHandUnit = this.orderUnitsById[orderOnHandUnitId];
        const deliveryUnit = this.orderUnitsById[orderDeliveryUnitId];
        const vendor = this.orderVendorsById[orderVendorId];

        return new OnHandItemModel(item, {
            inventory,
            onHandUnit,
            deliveryUnit,
            vendor,
        });
    }

    constructor(resource, inventoryResource, inventoryMetaResource, groupResource, utils) {
        super(resource, inventoryResource, inventoryMetaResource, groupResource, utils);

        // data dependencies
        this.orderUnitsById = {};
        this.orderVendorsById = {};
        this.orderInventoriesById = {};
    }
}
