import React, { useContext } from "react";
import deliveryTShirtsStyles from "../TShirtsStocking.module.css";
import { TShirtsStockingContext } from "../context/TShirtsStockingContext";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { INVENTORY, STOCKING } from "./TShirtsStockingModes";
import { AppContext } from "../../../../context/AppContext";
import AppContextValidator from "../../../../context/AppContextValidator";

const TShirtsStockingModeSwitch = () => {
    const { appState } = useContext(AppContext);
    const { tShirtsStocking, changeMode } = useContext(TShirtsStockingContext);

    if (!tShirtsStocking.mode || !AppContextValidator.isValidUser(appState.user)) {
        console.info("Invalid tShirtsStocking mode or app user");
        return <div />;
    }

    const getVariant = ({ name }) => {
        return tShirtsStocking.mode.name === name ? "contained" : "outlined";
    };

    return (
        <ButtonGroup
            className={deliveryTShirtsStyles.ModeSwitch}
            color="secondary"
            size="small"
            aria-label="small outlined button group"
        >
            {[STOCKING, INVENTORY].map((mode) => {
                const disabled =
                    mode.checkDisabled(tShirtsStocking) || !mode.canAccess(appState.user.groups);
                return (
                    <Button
                        variant={getVariant(mode)}
                        disabled={disabled}
                        onClick={() => changeMode(mode)}
                    >
                        {mode.name}
                    </Button>
                );
            })}
        </ButtonGroup>
    );
};

export default TShirtsStockingModeSwitch;
