import { FormikProps } from "formik";
import React from "react";
import { ResourceColumn } from "../ResourceColumn";

export class ResourceColumnText extends ResourceColumn {
    constructor(public field: string, public name: string) {
        super(field, name, "text");
        this.defaultValue = "";
        this.validator = (data) => /^[A-Za-z]+$/i.test(data);
        this.sortCallback = (a: any = {}, b: any = {}) =>
            JSON.stringify(a).localeCompare(JSON.stringify(b));
    }

    prepRenderEdit(index: number, fmkProps: FormikProps<any>) {
        // TODO: check against the other items for potential duplicated values
        const { handleChange, handleBlur, touched, errors, values, isSubmitting } = fmkProps;
        const content = (
            <input
                disabled={isSubmitting}
                type={this.inputType}
                name={this.field}
                placeholder={this.placeholder}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values[this.field]}
            />
        );

        return super.renderEdit(index, this.field, content, errors, touched, isSubmitting);
    }
}
