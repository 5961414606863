import { useContext, useEffect } from "react";
import deliveryFoodStyles from "../../DeliveryFood.module.css";
import { DeliveryFoodContext } from "../../DeliveryFoodContext";
import { DeliveryFoodSection } from "./DeliveryFoodSection";
import { DeliveryFoodSignature } from "./DeliveryFoodSignature";

const DeliveryFoodSections = () => {
    const { deliveryFoodState } = useContext(DeliveryFoodContext);

    useEffect(() => {}, [deliveryFoodState.mode, deliveryFoodState.deliverySections]);

    const modeRestaurantId = deliveryFoodState.mode.restaurant?.data?.id;

    if (!modeRestaurantId) {
        return null;
    }

    const modeRestaurantSections = deliveryFoodState.deliverySections[modeRestaurantId];

    return (
        <div className={deliveryFoodStyles.Sections}>
            {modeRestaurantSections.map(DeliveryFoodSection)}
            <DeliveryFoodSignature />
        </div>
    );
};

export default DeliveryFoodSections;
