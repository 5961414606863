import { useContext } from "react";
import { DataEditorContext } from "../../../context/DataEditorContext";
import dataEditorStyles from "../../../DataEditor.module.css";
import DataEditorUtils from "../../../utils/DataEditorUtils";
import DataEditorHeaderColumn from "./DataEditorHeaderColumn";

export default function DataEditorHeaderColumns() {
    const { dataEditorState } = useContext(DataEditorContext);

    const columns = dataEditorState.getSelectedResource().getModel().getColumns();
    const columnStyles = DataEditorUtils.getColumnStyles(columns.filter(({ hide }) => !hide));

    return (
        <div className={dataEditorStyles.ItemContent} style={columnStyles}>
            {columns.map((column, index) => {
                if (column.hide) {
                    return null;
                }

                return (
                    <DataEditorHeaderColumn
                        key={`header-cell-${index}`}
                        column={columns[index]}
                        index={index}
                    />
                );
            })}
        </div>
    );
}
