import OnHandItemContext from "../../../on-hand/context/OnHandItemContext";
import OnHandItemModel from "../../../on-hand/models/OnHandItemModel";
import { fetchResources } from "../../../../resources/ResourcesService";
import { AppTShirtSize, AppTShirtStyle } from "../../TShirtsResources";
import AppUtilsService from "../../../../services/AppUtilsService";

const ID = "id";

export default class OnHandTShirtItemContext extends OnHandItemContext {
    constructor(resource, inventoryResource, inventoryMetaResource, groupResource, utils) {
        super(resource, inventoryResource, inventoryMetaResource, groupResource, utils);

        // TODO: test if another one would be required too
        // data dependencies
        this.tShirtSizesById = {};
        this.tShirtStylesById = {};
        this.tShirtInventoriesById = {};
    }

    async loadDependencies(day, userName, sectionName) {
        // TODO: IMPLEMENT
        let [tShirtSizes, tShirtStyles] = await fetchResources([[AppTShirtSize], [AppTShirtStyle]]);

        this.tShirtSizesById = tShirtSizes.reduce(AppUtilsService.groupDataByItem(ID), {});
        this.tShirtStylesById = tShirtStyles.reduce(AppUtilsService.groupDataByItem(ID), {});

        const inventories = await this.generator.loadInventories(
            this.rawRestaurants,
            this.rawItems,
            day,
            userName,
            sectionName
        );
        this.tShirtInventoriesById = inventories.reduce(
            AppUtilsService.groupDataByItem(this.resourceRefKey),
            {}
        );
    }

    decorate(item) {
        const {
            data: { id, sizeId, styleId },
        } = item;
        // TODO: ADD MORE STUFF
        const size = this.tShirtSizesById[sizeId];
        const style = this.tShirtStylesById[styleId];
        const inventory = this.tShirtInventoriesById[id];

        return new OnHandItemModel(item, {
            inventory,
            size,
            style,
        });
    }
}
