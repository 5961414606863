import AppDayModel from "../../components/day/AppDayModel";
import { Model } from "../../model/Model";
import { ModelConfig } from "../../model/ModelConfig";
import { ResourceColumnDate } from "../../resources/columns/ResourceColumnDate";
import { ResourceColumnNumber } from "../../resources/columns/ResourceColumnNumber";
import { ResourceColumnSelect } from "../../resources/columns/select/ResourceColumnSelect";
import { ResourceColumnText } from "../../resources/columns/ResourceColumnText";
import { Resource } from "../../resources/Resource";
import { DataEditorState } from "../data-editor/types/DataEditorTypes";
import {
    DeliveryArea,
    Ingredient,
    IngredientDeliveryArea,
    IngredientInventory,
    IngredientInventoryMeta,
    IngredientOnHandArea,
    IngredientPack,
    MenuItem,
    MenuItemSale,
    MenuItemSaleMeta,
    OnHandArea,
    RecipeItem,
} from "./../../graphql/API";

/* ---------- IngredientPack ---------- */
class AppIngredientPackModel extends Model {
    prepForCreate(data: IngredientPack) {
        data = { ...data, display: `${data.name} (${data.size})` };
        return super.prepForCreate(data);
    }
}

const appIngredientPackModel = new AppIngredientPackModel(
    new ModelConfig(
        "IngredientPack",
        1000,
        200,
        "ingredientPacks",
        "Packs",
        "IngredientPack",
        "ingredientPackId",
        [
            new ResourceColumnText("id", "Id").setHideOnAdd().setKey(),
            new ResourceColumnText("display", "Display").setKey(),
            new ResourceColumnText("name", "Name").setVisible().setWidth("M"),
            new ResourceColumnText("alias", "Alias").setVisible().setWidth("S"),
            new ResourceColumnNumber("size", "Size").setVisible().setWidth("XS").setEditable(),
            new ResourceColumnNumber("portionRatio", "Portion Ratio")
                .setVisible()
                .setOptional()
                .setEditable()
                .setWidth("XS"),
            new ResourceColumnNumber("order", "#")
                .setHideOnAdd()
                .setEditable()
                .setVisible()
                .setWidth("XS")
                .setSortDirection("ASC", 0)
                .setSortCallback((a, b) => parseInt(a) - parseInt(b)),
            new ResourceColumnText("lastUpdatedBy", "By").setOptional(),
            new ResourceColumnDate("lastUpdatedAt", "At").setOptional(),
        ]
    )
);

class AppIngredientPack extends Resource {
    static getModel(): AppIngredientPackModel {
        return appIngredientPackModel;
    }

    getModel(): AppIngredientPackModel {
        return appIngredientPackModel;
    }

    constructor(data: IngredientPack) {
        super(data, appIngredientPackModel);
    }
}

/* ---------- Ingredient ---------- */
const appIngredientModel = new Model(
    new ModelConfig(
        "Ingredient",
        10000,
        200,
        "ingredients",
        "Ingredients",
        "Ingredient",
        "ingredientId",
        [
            new ResourceColumnText("id", "Id").setHideOnAdd().setKey(),
            new ResourceColumnText("name", "Name").setKey().setVisible().setWidth("S"),
            new ResourceColumnSelect("ingredientPackId", "Pack")
                .setVisible()
                .setEditable()
                .setWidth("S"),
            new ResourceColumnNumber("salesRatio", "Ratio")
                .setVisible()
                .setWidth("S")
                .setEditable()
                .setOptional(),
            new ResourceColumnNumber("laborTime", "Labor (min)")
                .setVisible()
                .setOptional()
                .setEditable()
                .setWidth("S"),
            new ResourceColumnNumber("batchSize", "Batch Size")
                .setVisible()
                .setOptional()
                .setEditable()
                .setWidth("S"),
            new ResourceColumnNumber("portionTime", "Portion (min)")
                .setVisible()
                .setOptional()
                .setEditable()
                .setWidth("S"),
            new ResourceColumnNumber("order", "#")
                .setHideOnAdd()
                .setEditable()
                .setVisible()
                .setWidth("XS")
                .setSortDirection("ASC", 0)
                .setSortCallback((a, b) => parseInt(a) - parseInt(b)),
            new ResourceColumnText("onHandAlias", "Alias")
                .setVisible()
                .setOptional()
                .setEditable()
                .setWidth("S"),
            new ResourceColumnText("lastUpdatedBy", "By").setOptional(),
            new ResourceColumnDate("lastUpdatedAt", "At").setOptional(),
        ]
    )
);

class AppIngredient extends Resource {
    static getModel(): Model {
        return appIngredientModel;
    }

    getModel(): Model {
        return appIngredientModel;
    }

    constructor(data: Ingredient) {
        super(data, appIngredientModel);
    }
}

/* ---------- IngredientInventory ---------- */
class AppIngredientInventoryModel extends Model {
    getFilter(appState: any) {
        return {
            day: { eq: appState.day.data.time || AppDayModel.getTodayUnixTime() },
        };
    }
}

const appIngredientInventoryModel = new AppIngredientInventoryModel(
    new ModelConfig(
        "IngredientInventory",
        200000,
        200,
        "ingredientInventories",
        "On Hand Today",
        "IngredientInventory",
        "ingredientInventoryId",
        [
            new ResourceColumnSelect("ingredientOnHandAreaId", "Rest - Area - Ingr")
                .setVisible()
                .setWidth("S")
                .setSortDirection("ASC", 1)
                .setSortCallback((a, b) => parseInt(a) - parseInt(b))
                .setKey(),
            new ResourceColumnDate("day", "Day").setKey(),
            new ResourceColumnNumber("quantity", "Qty").setVisible().setWidth("XS").setOptional(),
            new ResourceColumnText("lastUpdatedBy", "By").setVisible().setWidth("XS").setOptional(),
            new ResourceColumnDate("lastUpdatedAt", "At").setVisible().setWidth("S").setOptional(),
        ]
    )
);

class AppIngredientInventory extends Resource {
    static getModel(): AppIngredientInventoryModel {
        return appIngredientInventoryModel;
    }

    getModel(): AppIngredientInventoryModel {
        return appIngredientInventoryModel;
    }

    constructor(data: IngredientInventory) {
        super(data, appIngredientInventoryModel);
    }
}

/* ---------- IngredientInventoryMeta ---------- */
const appIngredientInventoryMetaModel = new Model(
    new ModelConfig(
        "IngredientInventoryMeta",
        10000,
        200,
        "ingredientInventoriesMeta",
        "On Hand Records",
        "IngredientInventoryMeta",
        "ingredientInventoryMetaId",
        [
            new ResourceColumnDate("day", "Day", "D MMM")
                .setKey()
                .setSortDirection("ASC", 0)
                .setSortCallback((a, b) => parseInt(b) - parseInt(a))
                .setVisible()
                .setWidth("XS"),
            new ResourceColumnSelect("restaurantId", "Rest")
                .setKey()
                .setSortDirection("ASC", 1)
                .setSortCallback((a, b) => parseInt(a) - parseInt(b))
                .setVisible()
                .setWidth("XS"),
            new ResourceColumnSelect("status", "Status", ["UNLOCKED", "LOCKED"])
                .setEditable()
                .setOptional()
                .setVisible()
                .setWidth("XS"),
            new ResourceColumnText("storageKey", "S3 Path")
                .setEditable()
                .setVisible()
                .setWidth("L"),
            new ResourceColumnText("completion", "%").setOptional().setWidth("XS"),
            new ResourceColumnText("lastUpdatedBy", "By").setVisible().setOptional().setWidth("XS"),
            new ResourceColumnDate("lastUpdatedAt", "At").setOptional().setWidth("S"),
        ]
    )
);

class AppIngredientInventoryMeta extends Resource {
    static getModel(): Model {
        return appIngredientInventoryMetaModel;
    }

    getModel(): Model {
        return appIngredientInventoryMetaModel;
    }

    constructor(data: IngredientInventoryMeta) {
        super(data, appIngredientInventoryMetaModel);
    }
}

/* ---------- IngredientOnHandArea ---------- */
class AppIngredientOnHandAreaModel extends Model {
    refDisplay(data: IngredientOnHandArea, dataEditorState?: DataEditorState) {
        if (dataEditorState) {
            return super.refChainDisplay(
                data,
                dataEditorState,
                "ingredientOnHandAreaId",
                true,
                // The ["restaurantId"] array means:
                //  from the object with the same value for "onHandAreaId"
                //  get the restaurantId and display it
                ["ingredientId", "onHandAreaId", ["restaurantId"]]
            );
        }
        return super.refDisplay(data);
    }
}

const appIngredientOnHandAreaModel = new AppIngredientOnHandAreaModel(
    new ModelConfig(
        "IngredientOnHandArea",
        1000,
        200,
        "ingredientOnHandAreas",
        "On Hand Config",
        "IngredientOnHandArea",
        "ingredientOnHandAreaId",
        [
            new ResourceColumnText("id", "Id").setHideOnAdd().setKey(),
            new ResourceColumnSelect("ingredientId", "Ingr.").setVisible().setKey().setWidth("S"),
            new ResourceColumnSelect("onHandAreaId", "Rest - Area")
                .setVisible()
                .setKey()
                .setSortCallback((a, b) => parseInt(a) - parseInt(b))
                .setWidth("S"),
            new ResourceColumnText("bulk", "Bulk").setVisible().setWidth("XS").setEditable(),
            new ResourceColumnNumber("portionRatio", "Ratio")
                .setVisible()
                .setOptional()
                .setEditable()
                .setWidth("XS"),
            new ResourceColumnNumber("order", "#")
                .setHideOnAdd()
                .setEditable()
                .setVisible()
                .setWidth("XS")
                .setSortDirection("ASC", 0)
                .setSortCallback((a, b) => parseInt(a) - parseInt(b)),
            new ResourceColumnText("lastUpdatedBy", "Updated By").setOptional(),
            new ResourceColumnDate("lastUpdatedAt", "Update Time").setOptional(),
        ]
    )
);

class AppIngredientOnHandArea extends Resource {
    static getModel(): AppIngredientOnHandAreaModel {
        return appIngredientOnHandAreaModel;
    }

    getModel(): AppIngredientOnHandAreaModel {
        return appIngredientOnHandAreaModel;
    }

    constructor(data: IngredientOnHandArea) {
        super(data, appIngredientOnHandAreaModel);
    }
}

/* ---------- IngredientDeliveryArea ---------- */
const appIngredientDeliveryAreaModel = new Model(
    new ModelConfig(
        "IngredientDeliveryArea",
        1000,
        200,
        "ingredientDeliveryAreas",
        "Delivery Config",
        "IngredientDeliveryArea",
        "ingredientDeliveryAreaId",
        [
            new ResourceColumnText("id", "Id").setHideOnAdd().setKey(),
            new ResourceColumnSelect("ingredientId", "Ingr.").setVisible().setKey().setWidth("S"),
            new ResourceColumnSelect("deliveryAreaId", "Area")
                .setVisible()
                .setKey()
                .setSortCallback((a, b) => parseInt(a) - parseInt(b))
                .setWidth("S"),
            new ResourceColumnNumber("fixedNeed", "Fixed Need")
                .setVisible()
                .setEditable()
                .setWidth("XS"),
            new ResourceColumnNumber("order", "#")
                .setHideOnAdd()
                .setEditable()
                .setVisible()
                .setWidth("XS")
                .setSortDirection("ASC", 0)
                .setSortCallback((a, b) => parseInt(a) - parseInt(b)),
            new ResourceColumnText("lastUpdatedBy", "By").setOptional(),
            new ResourceColumnDate("lastUpdatedAt", "At").setOptional(),
        ]
    )
);

class AppIngredientDeliveryArea extends Resource {
    static getModel(): Model {
        return appIngredientDeliveryAreaModel;
    }

    getModel(): Model {
        return appIngredientDeliveryAreaModel;
    }

    constructor(data: IngredientDeliveryArea) {
        super(data, appIngredientDeliveryAreaModel);
    }
}

/* ---------- MenuItem ---------- */
class AppMenuItemModel extends Model {
    refDisplay(data: MenuItem, dataEditorState?: DataEditorState) {
        if (dataEditorState) {
            return super.refChainDisplay(data, dataEditorState, "restaurantId");
        }
        return super.refDisplay(data);
    }
}

const appMenuItemModel = new AppMenuItemModel(
    new ModelConfig("MenuItem", 10000, 200, "menuItems", "Menu Items", "MenuItem", "menuItemId", [
        new ResourceColumnText("id", "Id").setHideOnAdd().setKey(),
        new ResourceColumnSelect("restaurantId", "Rest.")
            .setKey()
            .setVisible()
            .setWidth("XS")
            .setSortCallback((a, b) => parseInt(a) - parseInt(b)),
        new ResourceColumnText("name", "Name")
            .setKey()
            .setSortCallback((a, b) => a.localeCompare(b))
            .setEditable()
            .setVisible()
            .setWidth("M"),
        new ResourceColumnNumber("order", "#")
            .setHideOnAdd()
            .setEditable()
            .setVisible()
            .setWidth("XS")
            .setSortDirection("ASC", 0)
            .setSortCallback((a, b) => parseInt(a) - parseInt(b)),
        new ResourceColumnText("lastUpdatedBy", "By").setOptional(),
        new ResourceColumnDate("lastUpdatedAt", "At").setOptional(),
    ])
);

class AppMenuItem extends Resource {
    static getModel(): AppMenuItemModel {
        return appMenuItemModel;
    }

    getModel(): AppMenuItemModel {
        return appMenuItemModel;
    }

    constructor(data: MenuItem) {
        super(data, appMenuItemModel);
    }
}

/* ---------- RecipeItem ---------- */
const appRecipeItemModel = new Model(
    new ModelConfig(
        "RecipeItem",
        10000,
        200,
        "recipeItems",
        "Recipe Items",
        "RecipeItem",
        "recipeItemId",
        [
            new ResourceColumnSelect("menuItemId", "Menu Item")
                .setVisible()
                .setWidth("S")
                .setKey()
                .setSortCallback((a, b) => parseInt(a) - parseInt(b)),
            new ResourceColumnSelect("ingredientId", "Ingr.").setVisible().setKey().setWidth("S"),
            new ResourceColumnNumber("quantity", "Qty").setVisible().setEditable().setWidth("XS"),
            new ResourceColumnNumber("order", "#")
                .setHideOnAdd()
                .setEditable()
                .setVisible()
                .setWidth("XS")
                .setSortDirection("ASC", 0)
                .setSortCallback((a, b) => parseInt(a) - parseInt(b)),
            new ResourceColumnText("lastUpdatedBy", "By").setOptional(),
            new ResourceColumnDate("lastUpdatedAt", "At").setOptional(),
        ]
    )
);

class AppRecipeItem extends Resource {
    static getModel(): Model {
        return appRecipeItemModel;
    }

    getModel(): Model {
        return appRecipeItemModel;
    }

    constructor(data: RecipeItem) {
        super(data, appRecipeItemModel);
    }
}

/* ---------- MenuItemSale ---------- */
class AppMenuItemSaleModel extends Model {
    getFilter(appState: any) {
        return {
            day: { eq: appState.day.data.time || AppDayModel.getTodayUnixTime() },
        };
    }
}

const appMenuItemSaleModel = new AppMenuItemSaleModel(
    new ModelConfig(
        "MenuItemSale",
        100000,
        200,
        "menuItemSales",
        "Sales Today",
        "MenuItemSale",
        "menuItemSaleId",
        [
            new ResourceColumnSelect("menuItemId", "Rest - Menu Item")
                .setKey()
                .setVisible()
                .setWidth("M")
                .setSortDirection("ASC", 0),
            new ResourceColumnDate("day", "Day").setKey(),
            new ResourceColumnText("type", "Type")
                .setKey()
                .setVisible()
                .setWidth("S")
                .setSortDirection("ASC", 1),
            new ResourceColumnNumber("quantity", "Qty")
                .setOptional()
                .setVisible()
                .setWidth("XS")
                .setEditable(),
            new ResourceColumnText("lastUpdatedBy", "By").setVisible().setWidth("XS").setOptional(),
            new ResourceColumnDate("lastUpdatedAt", "At").setVisible().setWidth("S").setOptional(),
        ]
    )
);

class AppMenuItemSale extends Resource {
    static getModel(): AppMenuItemSaleModel {
        return appMenuItemSaleModel;
    }

    getModel(): AppMenuItemSaleModel {
        return appMenuItemSaleModel;
    }

    constructor(data: MenuItemSale) {
        super(data, appMenuItemSaleModel);
    }
}

/* ---------- MenuItemSaleMeta ---------- */
const appMenuItemSaleMetaModel = new Model(
    new ModelConfig(
        "MenuItemSaleMeta",
        10000,
        200,
        "menuItemSalesMeta",
        "Sales Records",
        "MenuItemSaleMeta",
        "menuItemSaleMetaId",
        [
            new ResourceColumnDate("day", "Day", "D MMM")
                .setKey()
                .setSortDirection("ASC", 0)
                .setSortCallback((a, b) => parseInt(b) - parseInt(a))
                .setVisible()
                .setWidth("XS"),
            new ResourceColumnSelect("restaurantId", "Rest")
                .setKey()
                .setSortDirection("ASC", 1)
                .setSortCallback((a, b) => parseInt(a) - parseInt(b))
                .setVisible()
                .setWidth("XS"),
            new ResourceColumnSelect("saleType", "Type", ["SINGLE"])
                .setKey()
                .setVisible()
                .setWidth("XS"),
            new ResourceColumnSelect("status", "Status", ["UNLOCKED", "LOCKED"]).setOptional(),
            new ResourceColumnText("storageKey", "S3 Path")
                .setEditable()
                .setVisible()
                .setWidth("L"),
            new ResourceColumnText("completion", "%").setOptional(),
            new ResourceColumnText("lastUpdatedBy", "By").setOptional().setVisible().setWidth("XS"),
            new ResourceColumnDate("lastUpdatedAt", "At").setOptional().setVisible().setWidth("XS"),
        ]
    )
);

class AppMenuItemSaleMeta extends Resource {
    static getModel(): Model {
        return appMenuItemSaleMetaModel;
    }

    getModel(): Model {
        return appMenuItemSaleMetaModel;
    }

    constructor(data: MenuItemSaleMeta) {
        super(data, appMenuItemSaleMetaModel);
    }
}

/* ---------- DeliveryArea ---------- */
class AppDeliveryAreaModel extends Model {
    refDisplay(data: DeliveryArea, dataEditorState?: DataEditorState) {
        if (dataEditorState) {
            return super.refChainDisplay(data, dataEditorState, "restaurantId");
        }
        return super.refDisplay(data);
    }
}

const appDeliveryAreaModel = new AppDeliveryAreaModel(
    new ModelConfig(
        "DeliveryArea",
        1000,
        200,
        "deliveryAreas",
        "Delivery Areas",
        "DeliveryArea",
        "deliveryAreaId",
        [
            new ResourceColumnText("id", "Id").setHideOnAdd().setKey(),
            new ResourceColumnText("name", "Name").setVisible().setEditable().setWidth("S"),
            new ResourceColumnSelect("restaurantId", "Rest")
                .setKey()
                .setSortCallback((a, b) => parseInt(a) - parseInt(b))
                .setVisible()
                .setWidth("S"),
            new ResourceColumnNumber("order", "#")
                .setHideOnAdd()
                .setEditable()
                .setVisible()
                .setWidth("XS")
                .setSortDirection("ASC", 0)
                .setSortCallback((a, b) => parseInt(a) - parseInt(b)),
            new ResourceColumnText("lastUpdatedBy", "By").setOptional(),
            new ResourceColumnDate("lastUpdatedAt", "At").setOptional(),
        ]
    )
);

class AppDeliveryArea extends Resource {
    static getModel(): AppDeliveryAreaModel {
        return appDeliveryAreaModel;
    }

    getModel(): AppDeliveryAreaModel {
        return appDeliveryAreaModel;
    }

    constructor(data: DeliveryArea) {
        super(data, appDeliveryAreaModel);
    }
}

/* ---------- OnHandArea ---------- */
class AppOnHandAreaModel extends Model {
    // replace with data editor context type
    refDisplay(data: OnHandArea, dataEditorState?: DataEditorState) {
        if (dataEditorState) {
            return super.refChainDisplay(data, dataEditorState, "restaurantId");
        }
        return super.refDisplay(data);
    }
}

const appOnHandAreaModel = new AppOnHandAreaModel(
    new ModelConfig(
        "OnHandArea",
        1000,
        200,
        "onHandAreas",
        "On Hand Areas",
        "OnHandArea",
        "onHandAreaId",
        [
            new ResourceColumnText("id", "Id").setHideOnAdd().setKey(),
            new ResourceColumnText("name", "Name").setVisible().setEditable().setWidth("S"),
            new ResourceColumnSelect("restaurantId", "Rest")
                .setKey()
                .setSortCallback((a, b) => parseInt(a) - parseInt(b))
                .setVisible()
                .setWidth("S"),
            new ResourceColumnNumber("order", "#")
                .setHideOnAdd()
                .setEditable()
                .setVisible()
                .setWidth("XS")
                .setSortDirection("ASC", 0)
                .setSortCallback((a, b) => parseInt(a) - parseInt(b)),
            new ResourceColumnText("lastUpdatedBy", "By").setOptional(),
            new ResourceColumnDate("lastUpdatedAt", "At").setOptional(),
        ]
    )
);

class AppOnHandArea extends Resource {
    static getModel(): AppOnHandAreaModel {
        return appOnHandAreaModel;
    }

    getModel(): AppOnHandAreaModel {
        return appOnHandAreaModel;
    }

    constructor(data: OnHandArea) {
        super(data, appOnHandAreaModel);
    }
}

export {
    AppIngredientPack,
    AppIngredient,
    AppIngredientInventory,
    AppIngredientInventoryMeta,
    AppIngredientOnHandArea,
    AppIngredientDeliveryArea,
    AppMenuItem,
    AppRecipeItem,
    AppMenuItemSale,
    AppMenuItemSaleMeta,
    AppDeliveryArea,
    AppOnHandArea,
};
