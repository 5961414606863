import { AppRestaurant } from "../../../../../admin/AdminResources";
import {
    DeliveryFoodSummaryConfigsSubType,
    DeliveryFoodIngredient,
    DeliveryFoodOnHandFromItems,
} from "../../../types/DeliveryFoodTypes";

export const DeliveryFoodSummaryOnHandConfigs: DeliveryFoodSummaryConfigsSubType = {
    title: ({
        ingredient,
        restaurant,
    }: {
        ingredient: DeliveryFoodIngredient;
        restaurant: AppRestaurant;
    }) => {
        return (
            <div>
                On Hand for <strong>{ingredient.data.name}</strong> at{" "}
                <strong>{restaurant.data.name}</strong>
            </div>
        );
    },
    content: ({
        data,
        total,
        ingredient,
    }: {
        data: DeliveryFoodOnHandFromItems[];
        total: string | number | null;
        ingredient: DeliveryFoodIngredient;
    }) => {
        return (
            <div>
                <ul>
                    {data.map((onHandItem: DeliveryFoodOnHandFromItems) => {
                        const { onHandAreaName, quantity, bulk, portionRatio } = onHandItem;
                        return (
                            <li>
                                <span>{onHandAreaName}</span>: <strong>{quantity}</strong>{" "}
                                <span>{bulk}</span>
                                {portionRatio ? (
                                    <span>
                                        {" "}
                                        x <strong>{portionRatio}</strong> Portion Ratio
                                    </span>
                                ) : null}
                            </li>
                        );
                    })}
                </ul>
                <div>Bulk Size: {ingredient.pack.data.size} Portions</div>
                <div>
                    Total: {total} {ingredient.pack.data.alias}
                </div>
            </div>
        );
    },
};
