import React from "react";
import { IoIosShirt } from "react-icons/io";
import {
    ADMINS,
    DEVS,
    MANAGERS,
    STORE_USERS,
    UserGroupType,
} from "../../services/AppUserGroupsData";
import ContainerBasePaths from "../ContainerBasePaths";
import { ContainerConfig } from "../ContainerConfig";
import { Section } from "../sections/Section";
import { TShirtsContainerConfig } from "./TShirtsContainerConfig";

export class TShirtsSection extends Section {
    renderIcon(disabled: boolean) {
        return <IoIosShirt size={this.iconSize} color={"inherit"} />;
    }

    renderHeaderIcon() {
        return <IoIosShirt size={this.iconHeaderSize} color={this.iconHeaderColor} />;
    }

    renderHomeIcon() {
        return <IoIosShirt size={this.iconHomeSize} color={this.iconHomeColor} />;
    }

    getDefaultPath(userGroups: UserGroupType[]) {
        if (userGroups.includes(DEVS)) {
            return TShirtsContainerConfig.DataEditor.url;
        }

        if (userGroups.includes(ADMINS)) {
            return TShirtsContainerConfig.Sales.url;
        }

        if (userGroups.includes(MANAGERS)) {
            return TShirtsContainerConfig.Delivery.url;
        }

        if (userGroups.includes(STORE_USERS)) {
            return TShirtsContainerConfig.OnHand.url;
        }

        console.error("Couldn't match the user to any known section pages", userGroups);
        return super.getDefaultPath(userGroups);
    }

    constructor(
        pages: ContainerConfig[],
        name: string,
        allowedGroups: UserGroupType[],
        disabled: boolean
    ) {
        super(pages, name, allowedGroups, disabled, ContainerBasePaths.T_SHIRTS);
    }
}
