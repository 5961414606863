import { useContext } from "react";
import { OffSiteReordersContext } from "./context/OffSiteReordersContext";
import styles from "./OffSiteReorders.module.css";
import OffSiteReordersCompleteButton from "./buttons/OffSiteReordersCompleteButton";
import OffSiteReordersSnoozeButton from "./buttons/OffSiteReordersSnoozeButton";
import { OffSiteReordersAlertDisplay } from "./types/OffSiteReordersTypes";

export default function OffSiteReordersOpenAlert({
    alert,
    index,
}: {
    alert: OffSiteReordersAlertDisplay;
    index: number;
}) {
    const { offSiteReordersState } = useContext(OffSiteReordersContext);

    if (!alert?.data?.status) {
        return null;
    }

    const className =
        offSiteReordersState.lastUpdatedAlert == alert
            ? `${styles.OpenAlert} ${styles.LastUpdatedAlert}`
            : styles.OpenAlert;

    return (
        <div key={`offsite-reorders-open-alert-${index}`} className={className}>
            <span className={styles.ItemName}>{alert?.offSiteItem?.data?.name}</span>
            <span className={styles.ItemStock}>{alert.stock}</span>
            <span className={styles.ItemPar}>{alert?.offSiteItem?.data?.alertThreshold}</span>
            <span>{alert?.createdAtMoment?.fromNow()}</span>
            <div className={styles.Controls}>
                <OffSiteReordersSnoozeButton alert={alert} />
                <OffSiteReordersCompleteButton alert={alert} />
            </div>
        </div>
    );
}
