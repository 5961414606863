import Papa from "papaparse";
import SalesParser from "./SalesParser";

const MenuSalesParser = (onUpdate, reader) => {
    // TODO: expose these column names in settings
    // TODO: throw meaningful errors
    // TODO: check that all rows have the same size
    // TODO: test if quantities can be parsed
    try {
        let salesData = Papa.parse(reader.result).data;
        console.log("Raw sales data: \n", salesData);

        salesData = SalesParser.parseRawSales(salesData);
        console.log("Parsed sales data: \n", salesData);

        // filter out rows with invalid data
        salesData = salesData.filter((row) => {
            return (
                row &&
                // first element is a non-empty string
                typeof row[0] === "string" &&
                row[0].length > 0 &&
                // second element is a number, but non NaN
                typeof row[1] === "number" &&
                !isNaN(row[1]) &&
                // there are only 2 elements
                row.length === 2
            );
        });

        salesData = SalesParser.sortParsedSales(salesData);
        console.log("Sorted sales data: \n", salesData);

        salesData = SalesParser.filterSortedSales(salesData);
        console.log("Filtered sales data: \n", salesData);

        // TODO: 99% this should be removed because we should only merge against known menu items
        salesData = SalesParser.mergeFilteredSales(salesData);
        console.log("Merged sales data: \n", salesData);

        return onUpdate(salesData);
    } catch (e) {
        throw Error(`Cannot parse given file because of ${e}`);
    }
};

export default MenuSalesParser;
