import {
    AppIngredient,
    AppMenuItem,
    AppMenuItemSale,
    AppMenuItemSaleMeta,
    AppRecipeItem,
} from "../../food/FoodResources";
import {
    AppDpsIngredient,
    AppDpsMenuItem,
    AppDpsMenuItemSale,
    AppDpsRecipeItem,
    AppDpsMenuItemSaleMeta,
} from "../../dps/DpsResources";
import { SectionNames } from "../../sections/SectionNames";

const CONFIGS = {
    DPS: {
        displayConfigs: {
            hideMultiple: true,
        },
        salesConfigs: [
            {
                saleType: "SINGLE",
                keyPrefix: "Single",
                action: "uploadSingleDaySales",
                fileNameSuffix: "dps",
            },
        ],
        resources: {
            menuItemResource: AppDpsMenuItem,
            menuItemSaleResource: AppDpsMenuItemSale,
            menuItemSaleMetaResource: AppDpsMenuItemSaleMeta,
            ingredientResource: AppDpsIngredient,
            recipeItemResource: AppDpsRecipeItem,
        },
    },
    FOOD: {
        displayConfigs: {
            hideMultiple: false,
        },
        salesConfigs: [
            {
                saleType: "SINGLE",
                keyPrefix: "Single",
                action: "uploadSingleDaySales",
                fileNameSuffix: "ss",
            },
            {
                saleType: "MULTIPLE",
                keyPrefix: "Multiple",
                action: "uploadMultipleDaysSales",
                fileNameSuffix: "sm",
            },
        ],
        resources: {
            menuItemResource: AppMenuItem,
            menuItemSaleResource: AppMenuItemSale,
            menuItemSaleMetaResource: AppMenuItemSaleMeta,
            ingredientResource: AppIngredient,
            recipeItemResource: AppRecipeItem,
        },
    },
};

function getConfig(sectionName) {
    if (sectionName === SectionNames.DPS) {
        return CONFIGS.DPS;
    }

    return CONFIGS.FOOD;
}

function getUtils(sectionName) {
    const INGREDIENT_ID = sectionName === SectionNames.FOOD ? "ingredientId" : "dpsIngredientId";
    const MENU_ITEM_ID = sectionName === SectionNames.FOOD ? "menuItemId" : "dpsMenuItemId";

    function recipeItemMenuItemFilter(menuItemId, recipeItem) {
        return recipeItem.data[MENU_ITEM_ID] === menuItemId;
    }

    function attachIngredientToRecipe(ingredientsById, recipeItem) {
        recipeItem.ingredient = ingredientsById[recipeItem.data[INGREDIENT_ID]];
        return recipeItem;
    }

    function attachMenuItemId(obj, menuItemId) {
        obj[MENU_ITEM_ID] = menuItemId;
        return obj;
    }

    function findSales(targetId, sales) {
        const findSalesType = (targetType) => {
            return sales.find((sale) => {
                return sale.data[MENU_ITEM_ID] === targetId && sale.data.type === targetType;
            });
        };

        return {
            SINGLE: findSalesType("SINGLE"),
            MULTIPLE: findSalesType("MULTIPLE"),
        };
    }

    return {
        recipeItemMenuItemFilter,
        attachIngredientToRecipe,
        attachMenuItemId,
        findSales,
    };
}

const MenusSectionConfigs = {
    getConfig,
    getUtils,
};

export default MenusSectionConfigs;
