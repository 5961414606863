import React, { useContext } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { DeliveryOrdersContext } from "../../context/DeliveryOrdersContext";

const DeliveryOrdersVendorSelector = () => {
    const { deliveryOrders, changeDisplayVendor } = useContext(DeliveryOrdersContext);
    const allVendors = [deliveryOrders.restaurant.allVendor, ...deliveryOrders.restaurant.vendors];

    return (
        <FormControl>
            <Select
                labelId="delivery-orders-vendor"
                id="delivery-orders-vendor"
                value={deliveryOrders.restaurant.displayVendor}
                onChange={(event) => changeDisplayVendor(event.target.value)}
            >
                {allVendors.map((vendor, index) => {
                    return (
                        <MenuItem key={`delivery-orders-${index}`} value={vendor}>
                            {vendor?.data?.name}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

export default DeliveryOrdersVendorSelector;
