import { ADMINS, DEVS, KITCHEN_USERS, MANAGERS } from "../../services/AppUserGroupsData";
import { MenusContainerConfig } from "../menus-sales/MenusContainerConfig";
import { SectionNames } from "../sections/SectionNames";
import { UserGroupType } from "./../../services/AppUserGroupsData";
import { ContainerConfig } from "./../ContainerConfig";
import { DpsContainerConfig } from "./DpsContainerConfig";
import { DpsSection } from "./DpsSection";

const dpsSectionPages: ContainerConfig[] = [
    DpsContainerConfig.Delivery,
    MenusContainerConfig,
    DpsContainerConfig.DataEditor,
];

const dpsSectionAllowedUserGroups: UserGroupType[] = [MANAGERS, KITCHEN_USERS, ADMINS, DEVS];

const isDpsSectionDisabled = false;

export const DpsSectionConfig = new DpsSection(
    dpsSectionPages,
    SectionNames.DPS,
    dpsSectionAllowedUserGroups,
    isDpsSectionDisabled
);
