import _ from "lodash";
import { AppOffSiteAlertLocalTypes } from "../../OffSiteResources";
import OffSiteReordersUtils from "../OffSiteReordersUtils";
import {
    OffSiteReordersContextAction,
    OffSiteReordersContextActionType,
    OffSiteReordersContextStateType,
} from "./OffSiteReordersContextTypes";

function OffSiteReordersContextReducer(
    state: OffSiteReordersContextStateType,
    action: OffSiteReordersContextAction
): OffSiteReordersContextStateType {
    if (action.type === OffSiteReordersContextActionType.SET_ALERTS) {
        state.alerts = action.payload.alerts;
    }

    if (action.type === OffSiteReordersContextActionType.COMPLETE_ALERT) {
        state.lastUpdatedAlert = action.payload.alert;
        state.alerts = OffSiteReordersUtils.moveAlertToNewType(
            state.alerts,
            action.payload.alert,
            action.payload.prevType,
            AppOffSiteAlertLocalTypes.COMPLETED
        );
    }

    if (action.type === OffSiteReordersContextActionType.UNDO_COMPLETE_ALERT) {
        state.lastUpdatedAlert = action.payload.alert;
        state.alerts = OffSiteReordersUtils.moveAlertToNewType(
            state.alerts,
            action.payload.alert,
            action.payload.prevType,
            AppOffSiteAlertLocalTypes.OPEN
        );
    }

    if (action.type === OffSiteReordersContextActionType.SNOOZE_ALERT) {
        state.lastUpdatedAlert = action.payload.alert;
        state.alerts = OffSiteReordersUtils.moveAlertToNewType(
            state.alerts,
            action.payload.alert,
            action.payload.prevType,
            AppOffSiteAlertLocalTypes.SNOOZED
        );
    }

    return _.clone(state);
}

export default OffSiteReordersContextReducer;
