import { GrAscend, GrDescend } from "react-icons/gr";
import styles from "../../../DataEditor.module.css";
import { DataEditorSortDirection } from "../../../types/DataEditorTypes";

export default function DataEditorHeaderCellSortIcon({
    sortDirection,
}: {
    sortDirection: DataEditorSortDirection;
}) {
    const size = 16;

    return (
        <div className={styles.HeaderSortIcon}>
            {sortDirection === "ASC" ? <GrAscend size={size} /> : <GrDescend size={size} />}
        </div>
    );
}
