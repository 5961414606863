import React, { useContext } from "react";
import styles from "./OffSiteStockingItemQuickInputs.module.css";
import OnHandItemQuickInput from "../../../on-hand/components/item/OnHandItemQuickInput";
import { OnHandContext } from "../../../on-hand/context/OnHandContext";

export default function OffSiteStockingItemQuickInputs({
    leftInputs,
    middleInputs,
    rightInputs,
}: {
    leftInputs: any;
    middleInputs: any;
    rightInputs: any;
}) {
    const { onSelectedItemStockingTransactionUpdate } = useContext(OnHandContext) as any;

    const handleChange = (addedQuickValue: any) => {
        return onSelectedItemStockingTransactionUpdate(addedQuickValue);
    };

    const OffSiteStockingItemQuickInputsDisplay = ({
        display,
        quickValue,
    }: {
        display: any;
        quickValue: any;
    }) => {
        return (
            <OnHandItemQuickInput
                handleChange={handleChange}
                display={display}
                quickValue={quickValue}
            />
        );
    };

    return (
        <div key={"offsite-stocking-quick-input"} className={styles.QuickInputs}>
            <div key={"offsite-stocking-quick-input-left"}>
                {leftInputs.map(OffSiteStockingItemQuickInputsDisplay)}
            </div>
            <div key={"offsite-stocking-quick-input-middle"}>
                {middleInputs.map(OffSiteStockingItemQuickInputsDisplay)}
            </div>
            <div key={"offsite-stocking-quick-input-right"}>
                {rightInputs.map(OffSiteStockingItemQuickInputsDisplay)}
            </div>
        </div>
    );
}
