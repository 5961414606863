import React, { useState, useEffect, useContext } from "react";
import styles from "./OffSiteReception.module.css";
import { FormControl, MenuItem, Select, Button } from "@material-ui/core";
import { useGlobalSpinnerActionsContext } from "../../../components/global-spinner/GlobalSpinnerContext";
import {
    AppOffSiteAlert,
    AppOffSiteAlertStatuses,
    AppOffSiteTransaction,
    AppOffSiteTransactionStatuses,
} from "../OffSiteResources";
import moment from "moment";
import { AppContext } from "../../../context/AppContext";
import OffSiteTasksUtils from "../tasks/OffSiteTasksUtils";
import { OffSiteTaskType } from "../context/OffSiteContextTypes";
import { AppRestaurant } from "../../admin/AdminResources";

type SelectedDestinationRestaurantId = string | number | null;

type TransitTasksByRestaurantId = { [restaurantId: string]: OffSiteTaskType[] } | null;

export default function OffSiteReception() {
    const setGlobalSpinner = useGlobalSpinnerActionsContext();
    const { appState } = useContext(AppContext);

    const [transitTasksByRestaurantId, setTransitTasksByRestaurantId] = useState(
        null as TransitTasksByRestaurantId
    );
    const [destinationRestaurants, setDestinationRestaurants] = useState([] as AppRestaurant[]);
    const [selectedDestinationRestaurantId, setSelectedDestinationRestaurantId] = useState(
        null as SelectedDestinationRestaurantId
    );

    useEffect(() => {
        setGlobalSpinner(true);
        OffSiteTasksUtils.loadReceptionData().then(
            ({ transitTasksByRestaurantId, destinationRestaurants }) => {
                setTransitTasksByRestaurantId(transitTasksByRestaurantId);
                setDestinationRestaurants(destinationRestaurants);
                setSelectedDestinationRestaurantId(destinationRestaurants[0].data.id);
                setGlobalSpinner(false);
            }
        );
    }, []);

    const onSelectRestaurant = (event: any) => {
        setSelectedDestinationRestaurantId(event.target.value);
    };

    const handleItemUpdate = async (task: OffSiteTaskType) => {
        const updatedTaskData = { ...task.data, status: AppOffSiteTransactionStatuses.COMPLETED };

        // TODO: move this to some lambda
        try {
            setGlobalSpinner(true);
            await AppOffSiteTransaction.getModel().api.update(updatedTaskData, true);

            // compute item total
            const itemInStockAmount = await OffSiteTasksUtils.getOffSiteItemInStockAmount(
                task.data.offSiteItemId
            );

            if (
                typeof itemInStockAmount === "number" &&
                itemInStockAmount < task.offSiteItem?.data?.alertThreshold
            ) {
                const offSiteItemId = task.data.offSiteItemId;
                const status = AppOffSiteAlertStatuses.PENDING;
                const snoozedUntil = moment()
                    .tz("America/New_York")
                    .subtract(1, "days")
                    .toISOString();
                const message = `Item stock: ${itemInStockAmount}, below threshold of ${task.offSiteItem?.data?.alertThreshold}`;
                const createdBy = appState.user.name;

                // create alert if below threshold
                const newPendingAlertData = {
                    offSiteItemId,
                    status,
                    // createdAt: automatically added
                    snoozedUntil,
                    message,
                    createdBy,
                };
                await AppOffSiteAlert.getModel().api.create(newPendingAlertData);
            }

            if (!selectedDestinationRestaurantId) {
                return null;
            }

            if (!transitTasksByRestaurantId) {
                return null;
            }

            const updatedRestaurant = transitTasksByRestaurantId[selectedDestinationRestaurantId];
            updatedRestaurant[task.data.offSiteItemId].data.status =
                AppOffSiteTransactionStatuses.COMPLETED;

            return setTransitTasksByRestaurantId((prevState: TransitTasksByRestaurantId) => {
                return { ...prevState, [selectedDestinationRestaurantId]: updatedRestaurant };
            });
        } catch (e) {
            throw new Error("Couldn't update item!");
        } finally {
            setGlobalSpinner(false);
        }
    };

    if (!transitTasksByRestaurantId || !destinationRestaurants) {
        return null;
    }

    if (!selectedDestinationRestaurantId) {
        return null;
    }

    const tasks: OffSiteTaskType[] = Object.values(
        transitTasksByRestaurantId[selectedDestinationRestaurantId] || {}
    );
    const destinationRestaurantsList: AppRestaurant[] = Object.values(destinationRestaurants) || [];

    return (
        <div className={styles.Reception}>
            <div className={styles.ReceptionToolbar}>
                <FormControl>
                    <Select
                        labelId="delivery-dps-restaurant"
                        id="delivery-dps-restaurant"
                        value={selectedDestinationRestaurantId}
                        onChange={onSelectRestaurant}
                    >
                        {destinationRestaurantsList.map(
                            (restaurant: AppRestaurant, index: number) => (
                                <MenuItem
                                    key={`delivery-restaurant-${index}`}
                                    value={restaurant.data.id}
                                >
                                    {restaurant.data.name}
                                </MenuItem>
                            )
                        )}
                    </Select>
                </FormControl>
            </div>
            <div className={styles.ReceptionContent}>
                {tasks.map((task: OffSiteTaskType) => {
                    const offSiteItemName = task.offSiteItem.data.name;
                    const quantity = task.data.quantity;
                    const updatedAt = moment(task.data.updatedAt).tz("America/New_York").fromNow();
                    const status = task.data.status;

                    return (
                        <div className={styles.ReceptionContentTask}>
                            <span>{offSiteItemName}</span>
                            <span>{quantity}</span>
                            <span>{updatedAt}</span>
                            {status === AppOffSiteTransactionStatuses.COMPLETED ? (
                                <Button color={"primary"} variant={"text"} size={"small"}>
                                    Done!
                                </Button>
                            ) : (
                                <Button
                                    color={"secondary"}
                                    variant={"contained"}
                                    onClick={() => handleItemUpdate(task)}
                                    size={"small"}
                                >
                                    Got it!
                                </Button>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
