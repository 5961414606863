import React, { useContext, useEffect, useReducer } from "react";
import salesTShirtStyle from "./SalesTShirt.module.css";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SalesTShirtState from "./SalesTShirtState";
import { AppContext } from "../../../context/AppContext";
import { fetchResources, seriesPromises } from "../../../resources/ResourcesService";
import MenusSalesDropZone from "../../menus-sales/components/drop-zone/MenusSalesDropZone";
import TShirtSalesParser from "../../menus-sales/components/drop-zone/parsers/TShirtSalesParser";
import { useGlobalSpinnerActionsContext } from "../../../components/global-spinner/GlobalSpinnerContext";
import LinearProgress from "@material-ui/core/LinearProgress";
import AppDayModel from "../../../components/day/AppDayModel";
import { AppRestaurant } from "../../admin/AdminResources";
import { AppTShirt, AppTShirtSize } from "../TShirtsResources";

const INIT = "init";
const CHANGE_RESTAURANT = "changeRestaurant";
const reducer = (state, action) => {
    if (action.type === CHANGE_RESTAURANT) {
        const restaurant = action.payload;
        const restaurants = state.restaurants;
        restaurants[restaurant.index] = restaurant;
        return { ...state, restaurant, restaurants };
    }

    if (action.type === INIT) {
        return new SalesTShirtState(action.payload);
    }
};

const SalesTShirts = ({ history, location }) => {
    const setGlobalSpinner = useGlobalSpinnerActionsContext();
    const { appState } = useContext(AppContext);
    const [state, dispatch] = useReducer(reducer, new SalesTShirtState({}));

    const setStateData = async () => {
        setGlobalSpinner(true);

        let [restaurants, tShirts, sizes] = await fetchResources([
            [AppRestaurant, { type: { eq: "RESTAURANT" } }],
            [AppTShirt],
            [AppTShirtSize],
        ]);

        // const sales = await AppTShirtSale.getModel().getDailyValues(AppTShirtSale, tShirts, appState.day);
        const sales = []; // TODO: Update pattern

        const type = INIT;
        const payload = { day: appState.day, restaurants, tShirts, sizes, sales };
        return dispatch({ type, payload });
    };

    const changeRestaurant = (event) => {
        return dispatch({
            type: CHANGE_RESTAURANT,
            payload: event.target.value,
        });
    };

    const updateSales = async (salesData) => {
        setGlobalSpinner(true);
        const promises = [];
        salesData.forEach(({ name, size, quantity }) => {
            const tShirtIndex = state.restaurant.tShirts.findIndex((tShirt) => {
                return tShirt.data.name === name && tShirt.size.data.name === size;
            });
            if (tShirtIndex >= 0) {
                state.restaurant.tShirts[tShirtIndex].sale.data.quantity = quantity;
                state.restaurant.tShirts[tShirtIndex].sale.data.lastUpdatedBy = appState.user.name;
                state.restaurant.tShirts[tShirtIndex].sale.data.lastUpdatedAt =
                    AppDayModel.getCurrentUnixTime();
                const saleData = state.restaurant.tShirts[tShirtIndex].sale.data;
                const promise =
                    state.restaurant.tShirts[tShirtIndex].sale.model.api.update(saleData);
                promises.push(promise);
            } else {
                console.error("Cannot find ", name, size);
            }
        });
        await seriesPromises(promises);

        setStateData();
    };

    useEffect(() => {
        setStateData();
    }, [appState.day]);

    if (!state || !state.restaurant || !state.restaurant.tShirts) {
        console.info("Invalid restaurant or restaurant tShirts");
        return <div />;
    }

    const toolbar = () => {
        return (
            <div className={salesTShirtStyle.Toolbar}>
                <div className={salesTShirtStyle.ToolbarSelection}>
                    <FormControl className={salesTShirtStyle.ToolbarSelectionForm}>
                        <Select
                            labelId="t-shirt-sales-select-label"
                            id="t-shirt-sales-select"
                            value={state.restaurant}
                            onChange={changeRestaurant}
                        >
                            {state.restaurants.map((restaurant, index) => (
                                <MenuItem key={`t-shirt-sales-menu-${index}`} value={restaurant}>
                                    {restaurant.data.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>
        );
    };

    const salesDropZone = () => {
        const onUpdate = (salesData) => {
            return updateSales(salesData).then((data) => {
                return setStateData();
            });
        };

        return (
            <MenusSalesDropZone
                onLoad={TShirtSalesParser}
                onUpdate={onUpdate}
                name={state.restaurant.name}
            />
        );
    };

    const content = () => {
        return (
            <div className={salesTShirtStyle.Content}>
                {state.restaurant.tShirts
                    .filter((tShirt) => tShirt.data.restaurantId === state.restaurant.data.id)
                    .map((tShirt, index) => {
                        return (
                            <div
                                key={`t-shirt-sale-${index}`}
                                className={`${salesTShirtStyle.Row} ${tShirt.statusClassName}`}
                            >
                                {tShirt.statusIcon}
                                <span>{tShirt.data.name}</span>
                                <span>{tShirt.size.data.name}</span>
                                <span>{tShirt.sale.data.quantity}</span>
                            </div>
                        );
                    })}
            </div>
        );
    };

    const completion = () => {
        return (
            <div className={salesTShirtStyle.Footer}>
                <LinearProgress
                    variant="buffer"
                    value={Math.ceil(state.restaurant.completionScore * 100)}
                    valueBuffer={100}
                    color="secondary"
                />
            </div>
        );
    };

    return (
        <div className={salesTShirtStyle.Items}>
            {toolbar()}
            {salesDropZone()}
            <div
                key={`t-shirt-sale`}
                className={`${salesTShirtStyle.Row} ${salesTShirtStyle.Header}`}
            >
                <strong>Status</strong>
                <strong>Name</strong>
                <strong>Size</strong>
                <strong>Items Sold</strong>
            </div>
            {completion()}
            {content()}
            {setGlobalSpinner(false)}
        </div>
    );
};

export default SalesTShirts;
