import { useContext } from "react";
import { AppDeliveryArea } from "../../../FoodResources";
import deliveryFoodStyles from "../../DeliveryFood.module.css";
import { DeliveryFoodContext } from "../../DeliveryFoodContext";
import { DeliveryFoodIngredient, DeliveryFoodSummary } from "../../types/DeliveryFoodTypes";
import { DeliveryFoodSectionHeader } from "./DeliveryFoodSectionHeader";
import {
    nonEmptyIngredientDeliveryFilter,
    sectionIngredientFilter,
    sortFoodDeliverySectionSortByPackType,
} from "./DeliveryFoodSectionsUtils";

export const DeliveryFoodSection = (deliverySection: AppDeliveryArea, index: number) => {
    const { deliveryFoodState } = useContext(DeliveryFoodContext);

    const sectionName = deliverySection.data.name;
    const restaurantId = deliveryFoodState.mode.restaurant?.data?.id;

    const sectionIngredients: DeliveryFoodIngredient[] = deliveryFoodState.ingredients.filter(
        (ingredient) => sectionIngredientFilter(deliverySection, ingredient, restaurantId)
    );

    if (sectionIngredients.length === 0) {
        return null;
    }

    const sectionIngredientsWithDelivery = sectionIngredients.filter((ingredient) =>
        nonEmptyIngredientDeliveryFilter(
            ingredient,
            restaurantId,
            deliveryFoodState.mode.deliveryTotalType
        )
    );

    if (sectionIngredientsWithDelivery.length === 0) {
        return null;
    }

    return (
        <div key={`delivery-section-${index}`} className={deliveryFoodStyles.Section}>
            <DeliveryFoodSectionHeader
                key={`delivery-section-header-${index}`}
                name={sectionName}
                index={index}
            />
            <div className={deliveryFoodStyles.SectionRow}>
                <div>Delivery</div>
                <div>Loaded</div>
                <div>Item</div>
                <div>Delivery (R)</div>
                <div>Loaded (R)</div>
            </div>
            {sectionIngredientsWithDelivery
                .sort(sortFoodDeliverySectionSortByPackType)
                .map((ingredient: DeliveryFoodIngredient, ingredientIndex: number) => {
                    if (!ingredient.results) {
                        return null;
                    }

                    if (!restaurantId) {
                        return null;
                    }

                    const name = ingredient.data.name;
                    const quantityField: keyof DeliveryFoodSummary =
                        deliveryFoodState.mode.deliveryTotalType === "totalFixedNeedWithoutOnHand"
                            ? "totalFixedNeedWithoutOnHand"
                            : "totalDisplay";
                    const quantity = ingredient.results.RST?.[restaurantId].delivery[quantityField];
                    const packType = ingredient.pack.data.alias;

                    return (
                        <div
                            key={`delivery-section-${index}-${ingredientIndex}`}
                            className={deliveryFoodStyles.SectionRow}
                        >
                            <div className={deliveryFoodStyles.SectionRowQuantity}>{quantity}</div>
                            <div className={deliveryFoodStyles.SectionRowPackType}>{packType}</div>
                            <div />
                            <div>{name}</div>
                            <div className={deliveryFoodStyles.SectionRowQuantity}>{quantity}</div>
                            <div className={deliveryFoodStyles.SectionRowPackType}>{packType}</div>
                            <div />
                        </div>
                    );
                })}
        </div>
    );
};
