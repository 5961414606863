import { AppS3ClientResponse, AppS3ClientResponseBody, AppS3RequestType } from "./AppS3Types";

export function isAppS3ClientResponse(
    deserializedResponse: any
): deserializedResponse is AppS3ClientResponse {
    let result: boolean = true;

    if (!deserializedResponse) {
        result = false;
    }

    if (typeof deserializedResponse.code !== "number") {
        result = false;
    }

    if (typeof deserializedResponse.data !== "string" && deserializedResponse.data !== null) {
        result = false;
    }

    if (!Array.isArray(deserializedResponse.body)) {
        result = false;
    }

    if (!deserializedResponse.body.every(isAppS3ClientResponseBody)) {
        result = false;
    }

    return result;
}

export function isAppS3ClientResponseBody(item: any): item is AppS3ClientResponseBody {
    let result: boolean = true;

    if (!item) {
        result = false;
    }

    if (!isAppS3RequestType(item.type)) {
        result = false;
    }

    if (typeof item.key !== "string") {
        result = false;
    }

    if (item.data !== null && !Array.isArray(item.data)) {
        result = false;
    }

    if (typeof item.error !== "string" && item.error !== null) {
        result = false;
    }

    return result;
}

export function isAppS3RequestType(type: any): type is AppS3RequestType {
    let result: boolean;

    result = type === "GET_S3_OBJECTS" || type === "PUT_S3_OBJECTS";

    return result;
}
