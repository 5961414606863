import React, { useContext } from "react";
import onHandTShirtItemStyles from "./OnHandTShirtItem.module.css";
import Slider from "@material-ui/core/Slider";
import { OnHandContext } from "../../../on-hand/context/OnHandContext";

const OnHandTShirtItemInput = ({ item }) => {
    const { onSelectedItemQuantityUpdate } = useContext(OnHandContext);

    const {
        data: { par, packSize, stockCapacity },
        inventory: {
            data: { quantity },
        },
        prevOnHand,
    } = item;

    const buildMarks = (par, packSize, stockCapacity) => {
        return Array.from(Array(stockCapacity + 1).keys()).map((value) => {
            let label = `${value}`;
            return { value, label };
        });
    };

    return (
        <div className={onHandTShirtItemStyles.DetailsInput}>
            <Slider
                defaultValue={quantity + (prevOnHand || 0)}
                max={stockCapacity}
                min={0}
                marks={buildMarks(par, packSize, stockCapacity)}
                aria-labelledby="discrete-slider-always"
                onChangeCommitted={(event, value) => onSelectedItemQuantityUpdate(value)}
                step={1}
                valueLabelDisplay="on"
            />
        </div>
    );
};

export default OnHandTShirtItemInput;
