import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

const DeliveryFoodHeaderCell = ({ config }: any) => {
    return (
        <div>
            <div className="do-print">{config.headerCellPrintContent()}</div>
            <Tooltip title="">
                <div className={"truncate no-print"}>{config.headerCellContent()}</div>
            </Tooltip>
        </div>
    );
};

export default DeliveryFoodHeaderCell;
