import {
    TShirt,
    TShirtSize,
    TShirtStyle,
    TShirtInventory,
    TShirtInventoryMeta,
    TShirtSale,
    TShirtSaleMeta,
    TShirtTransaction,
} from "./../../graphql/API";
import { Resource } from "../../resources/Resource";
import AppDayModel from "../../components/day/AppDayModel";
import { Model } from "../../model/Model";
import { ResourceColumnText } from "../../resources/columns/ResourceColumnText";
import { ResourceColumnSelect } from "../../resources/columns/select/ResourceColumnSelect";
import { ResourceColumnNumber } from "../../resources/columns/ResourceColumnNumber";
import { ResourceColumnDate } from "../../resources/columns/ResourceColumnDate";
import { ModelConfig } from "../../model/ModelConfig";

/* ---------- TShirt ---------- */
class AppTShirtModel extends Model {
    prepForCreate(data: TShirt) {
        // TODO: get the name of the style
        data = { ...data, display: data.display || `${data.styleId} (${data.sizeId})` };
        return super.prepForCreate(data);
    }
}

const appTShirtModel = new AppTShirtModel(
    new ModelConfig("TShirt", 10000, 200, "tShirts", "T-Shirts", "TShirt", "tShirtId", [
        // visible
        new ResourceColumnText("display", "Style (Size)").setKey(),
        new ResourceColumnText("restaurantId", "Restaurant")
            .setKey()
            .setVisible()
            .setWidth("XS")
            .setSortDirection("ASC", 0),
        new ResourceColumnText("styleId", "Style").setWidth("XS").setVisible(),
        new ResourceColumnText("sizeId", "Size")
            .setVisible()
            .setResourceRefKeyAlias("sizeId")
            .setSortDirection("ASC", 1),
        new ResourceColumnText("name", "Name").setVisible(),
        new ResourceColumnSelect("packSize", "Pack Size").setVisible(),
        // (sort) keys
        new ResourceColumnText("id", "Id").setHideOnAdd().setKey(),
        // meta
        // others
        new ResourceColumnNumber("stockCapacity", "Max Stock"),
        new ResourceColumnNumber("par", "PAR"),
        new ResourceColumnNumber("discount", "Discount"),
        new ResourceColumnNumber("order", "#")
            .setHideOnAdd()
            .setEditable()
            .setVisible()
            .setWidth("XS")
            .setSortCallback((a, b) => parseInt(a) - parseInt(b)),
        new ResourceColumnText("lastUpdatedBy", "Updated By").setOptional(),
        new ResourceColumnNumber("lastUpdatedAt", "Update Time").setOptional(),
    ])
);

class AppTShirt extends Resource {
    static getModel(): AppTShirtModel {
        return appTShirtModel;
    }

    getModel(): AppTShirtModel {
        return appTShirtModel;
    }

    constructor(data: TShirt) {
        super(data, appTShirtModel);
    }
}

/* ---------- TShirtSize ---------- */
const appTShirtSizeModel = new Model(
    new ModelConfig("TShirtSize", 1000, 200, "tShirtSizes", "Sizes", "TShirtSize", "sizeId", [
        new ResourceColumnText("id", "Id").setHideOnAdd().setKey(),
        new ResourceColumnText("name", "Name").setKey().setVisible(),
        new ResourceColumnNumber("order", "#")
            .setHideOnAdd()
            .setEditable()
            .setVisible()
            .setWidth("XS")
            .setSortDirection("ASC", 0)
            .setSortCallback((a, b) => parseInt(a) - parseInt(b)),
        new ResourceColumnText("lastUpdatedBy", "Updated By").setOptional(),
        new ResourceColumnNumber("lastUpdatedAt", "Update Time").setOptional(),
    ])
);

class AppTShirtSize extends Resource {
    static getModel(): Model {
        return appTShirtSizeModel;
    }

    getModel(): Model {
        return appTShirtSizeModel;
    }

    constructor(data: TShirtSize) {
        super(data, appTShirtSizeModel);
    }
}

/* ---------- TShirtStyle ---------- */
const appTShirtStyleModel = new Model(
    new ModelConfig("TShirtStyle", 1000, 200, "tShirtStyles", "Styles", "TShirtStyle", "styleId", [
        new ResourceColumnText("id", "Id").setHideOnAdd().setKey(),
        new ResourceColumnText("name", "Name").setKey().setVisible().setSortDirection("ASC", 1),
        new ResourceColumnText("restaurantId", "Restaurant")
            .setVisible()
            .setSortDirection("ASC", 0),
        new ResourceColumnNumber("order", "#")
            .setHideOnAdd()
            .setEditable()
            .setVisible()
            .setWidth("XS")
            .setSortCallback((a, b) => parseInt(a) - parseInt(b)),
        new ResourceColumnText("lastUpdatedBy", "Updated By").setOptional(),
        new ResourceColumnNumber("lastUpdatedAt", "Update Time").setOptional(),
    ])
);

class AppTShirtStyle extends Resource {
    static getModel(): Model {
        return appTShirtStyleModel;
    }

    getModel(): Model {
        return appTShirtStyleModel;
    }

    constructor(data: TShirtStyle) {
        super(data, appTShirtStyleModel);
    }
}

/* ---------- TShirtInventory ---------- */
class AppTShirtInventoryModel extends Model {
    getFilter(appState: any) {
        return {
            day: { eq: appState.day.data.time || AppDayModel.getTodayUnixTime() },
        };
    }
}

const appTShirtInventoryModel = new AppTShirtInventoryModel(
    new ModelConfig(
        "TShirtInventory",
        200000,
        200,
        "tShirtInventories",
        "On Hand",
        "TShirtInventory",
        "tShirtInventoryId",
        [
            new ResourceColumnText("tShirtId", "TShirt")
                .setVisible()
                .setKey()
                .setSortDirection("ASC", 1)
                .setSortCallback((a, b) => parseInt(a) - parseInt(b)),
            new ResourceColumnDate("day", "Day").setSortDirection("DESC", 0).setKey(),
            new ResourceColumnNumber("quantity", "Qty").setOptional().setVisible().setEditable(),
            new ResourceColumnText("lastUpdatedBy", "Updated By").setOptional(),
            new ResourceColumnNumber("lastUpdatedAt", "Update Time").setOptional(),
        ]
    )
);

class AppTShirtInventory extends Resource {
    static getModel(): AppTShirtInventoryModel {
        return appTShirtInventoryModel;
    }

    getModel(): AppTShirtInventoryModel {
        return appTShirtInventoryModel;
    }

    constructor(data: TShirtInventory) {
        super(data, appTShirtInventoryModel);
    }
}

/* ---------- TShirtInventoryMeta ---------- */
const appTShirtInventoryMetaModel = new Model(
    new ModelConfig(
        "TShirtInventoryMeta",
        10000,
        200,
        "tShirtInventoriesMeta",
        "On Hand Records",
        "TShirtInventoryMeta",
        "tShirtInventoryMetaId",
        [
            new ResourceColumnDate("day", "Day")
                .setKey()
                .setSortDirection("DESC", 0)
                .setSortCallback((a, b) => parseInt(b) - parseInt(a))
                .setVisible()
                .setWidth("S"),
            new ResourceColumnSelect("restaurantId", "Rest")
                .setKey()
                .setSortDirection("ASC", 1)
                .setSortCallback((a, b) => parseInt(a) - parseInt(b))
                .setVisible()
                .setWidth("XS"),
            new ResourceColumnText("storageKey", "S3 Path")
                .setEditable()
                .setVisible()
                .setWidth("L"),
            new ResourceColumnSelect("status", "Status", ["UNLOCKED", "LOCKED"])
                .setEditable()
                .setOptional()
                .setVisible()
                .setWidth("XS"),
            new ResourceColumnText("completion", "%").setOptional().setWidth("XS"),
            new ResourceColumnText("lastUpdatedBy", "By").setOptional().setWidth("XS"),
            new ResourceColumnDate("lastUpdatedAt", "At").setOptional().setWidth("S"),
        ]
    )
);

class AppTShirtInventoryMeta extends Resource {
    static getModel(): Model {
        return appTShirtInventoryMetaModel;
    }

    getModel(): Model {
        return appTShirtInventoryMetaModel;
    }

    constructor(data: TShirtInventoryMeta) {
        super(data, appTShirtInventoryMetaModel);
    }
}

/* ---------- TShirtSale ---------- */
class AppTShirtSaleModel extends Model {
    getFilter(appState: any) {
        return {
            day: { eq: appState.day.data.time || AppDayModel.getTodayUnixTime() },
        };
    }
}

const appTShirtSaleModel = new AppTShirtSaleModel(
    new ModelConfig(
        "TShirtSale",
        200000,
        200,
        "tShirtSales",
        "Sales",
        "TShirtSale",
        "tShirtSaleId",
        [
            new ResourceColumnText("tShirtId", "TShirt").setVisible().setKey(),
            new ResourceColumnDate("day", "Day").setKey().setSortDirection("DESC", 1),
            new ResourceColumnNumber("quantity", "Items Sold")
                .setOptional()
                .setVisible()
                .setEditable(),
            new ResourceColumnText("lastUpdatedBy", "Updated By").setOptional(),
            new ResourceColumnNumber("lastUpdatedAt", "Update Time").setOptional(),
        ]
    )
);

class AppTShirtSale extends Resource {
    static getModel(): AppTShirtSaleModel {
        return appTShirtSaleModel;
    }

    getModel(): AppTShirtSaleModel {
        return appTShirtSaleModel;
    }

    constructor(data: TShirtSale) {
        super(data, appTShirtSaleModel);
    }
}

/* ---------- TShirtSaleMeta ---------- */
const appTShirtSaleMetaModel = new Model(
    new ModelConfig(
        "TShirtSaleMeta",
        10000,
        200,
        "tShirtSalesMeta",
        "Sales Records",
        "TShirtSaleMeta",
        "tShirtSaleMetaId",
        [
            new ResourceColumnDate("day", "Day")
                .setKey()
                .setSortDirection("DESC", 0)
                .setSortCallback((a, b) => parseInt(b) - parseInt(a))
                .setVisible()
                .setWidth("S"),
            new ResourceColumnSelect("restaurantId", "Rest")
                .setKey()
                .setSortDirection("ASC", 1)
                .setSortCallback((a, b) => parseInt(a) - parseInt(b))
                .setVisible()
                .setWidth("XS"),
            new ResourceColumnText("storageKey", "S3 Path")
                .setEditable()
                .setVisible()
                .setWidth("L"),
            new ResourceColumnSelect("status", "Status", ["UNLOCKED", "LOCKED"])
                .setVisible()
                .setEditable()
                .setOptional()
                .setVisible()
                .setWidth("XS"),
            new ResourceColumnText("completion", "%").setVisible().setOptional().setWidth("XS"),
            new ResourceColumnText("lastUpdatedBy", "By").setOptional().setVisible().setWidth("XS"),
            new ResourceColumnDate("lastUpdatedAt", "At").setOptional().setVisible().setWidth("S"),
        ]
    )
);

class AppTShirtSaleMeta extends Resource {
    static getModel(): Model {
        return appTShirtSaleMetaModel;
    }

    getModel(): Model {
        return appTShirtSaleMetaModel;
    }

    constructor(data: TShirtSaleMeta) {
        super(data, appTShirtSaleMetaModel);
    }
}

/* ---------- TShirtInventory ---------- */
class AppTShirtTransactionModel extends Model {
    getFilter(appState: any) {
        return {
            createdAt: {
                gt:
                    AppDayModel.getPrevDayUnixTime(appState.day.data.time) ||
                    AppDayModel.getTodayUnixTime(),
            },
        };
    }
}

const appTShirtTransactionModel = new AppTShirtTransactionModel(
    new ModelConfig(
        "TShirtTransaction",
        200000,
        200,
        "tShirtTransaction",
        "Transactions",
        "TShirtTransaction",
        "tShirtTransactionId",
        [
            new ResourceColumnText("tShirtId", "TShirt").setVisible().setKey(),
            new ResourceColumnNumber("createdAt", "Created At").setVisible().setKey(),
            new ResourceColumnText("type", "Type").setVisible(),
            new ResourceColumnNumber("quantity", "Qty").setVisible().setEditable(),
            new ResourceColumnText("createdBy", "Created By").setVisible(),
        ]
    )
);

class AppTShirtTransaction extends Resource {
    static getModel(): AppTShirtTransactionModel {
        return appTShirtTransactionModel;
    }

    getModel(): AppTShirtTransactionModel {
        return appTShirtTransactionModel;
    }

    constructor(data: TShirtTransaction) {
        super(data, appTShirtTransactionModel);
    }
}

export {
    AppTShirt,
    AppTShirtSize,
    AppTShirtStyle,
    AppTShirtInventory,
    AppTShirtInventoryMeta,
    AppTShirtSale,
    AppTShirtSaleMeta,
    AppTShirtTransaction,
};
