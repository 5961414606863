import queryString from "query-string";
import AppDayModel from "../../components/day/AppDayModel";
import { retry } from "../../resources/ResourcesService";
import { TRANSACTIONAL } from "./models/OnHandTypes";

function isSelectedByName(onHandState, name) {
    return onHandState?.restaurant?.group?.item?.data?.name === name;
}

function isSelectedByAlias(onHandState, name) {
    return onHandState?.restaurant?.group?.item?.data?.onHandAlias === name;
}

function isSelectedItem(onHandState, currItem) {
    if (
        !(
            onHandState.restaurant &&
            onHandState.restaurant.group &&
            onHandState.restaurant.group.item
        )
    ) {
        return false;
    }
    const {
        restaurant: {
            group: {
                item: { data },
            },
        },
    } = onHandState;
    const {
        data: { id },
    } = currItem;
    return data.id === id;
}

function isReadOnly(restaurant, day) {
    if (restaurant.unlocked) {
        return false;
    }

    const isNew = AppDayModel.getPrevDayUnixTime() <= day.data.time;
    const isUnlocked = restaurant?.inventoryMeta?.data?.status === "UNLOCKED";

    return !isNew || !isUnlocked;
}

function getLocalStorageConfig(sectionName, restaurantAlias, dayTime) {
    return {
        key: `${sectionName}OnHand${restaurantAlias}`,
        subKey: dayTime,
    };
}

function getLocalObj(key) {
    return JSON.parse(localStorage.getItem(key));
}

function getDefaultLocalObj(subKey) {
    return {
        [subKey]: { expires: AppDayModel.getCurrentUnixTime() + AppDayModel.WEEK_UNIX, items: [] },
    };
}

function persistOnHandItem({ key, subKey }, item) {
    try {
        const localObj = getLocalObj(key);
        const content = localObj?.[subKey] ? localObj : getDefaultLocalObj(subKey);
        content[subKey].items.push(item);

        localStorage.setItem(key, JSON.stringify(content));
    } catch (e) {
        console.error("Failed to persist locally!", JSON.stringify(e, null, 2));
    }
}

function getLocalItems({ key, subKey }) {
    return getLocalObj(key)?.[subKey]?.items || [];
}

function persistInventoryLocally(data, sectionName, restaurantAlias, dayTime) {
    try {
        persistOnHandItem(getLocalStorageConfig(sectionName, restaurantAlias, dayTime), data);
    } catch (e) {
        console.error("Failed to persist item locally!");
    }
}

function persistInventoryLocallyWrp(appState, onHandState, inventory) {
    return persistInventoryLocally(
        inventory.data,
        appState?.section?.name,
        onHandState?.restaurant?.data?.alias,
        appState?.day?.time
    );
}

function prepInventoryForSubmission(inventory, appState) {
    return {
        ...inventory,
        dirty: true,
        mock: undefined,
        data: {
            ...inventory.data,
            quantity: inventory.data.quantity || 0,
            lastUpdatedBy: appState?.user?.name,
            lastUpdatedAt: AppDayModel.getCurrentUnixTime(),
        },
    };
}

function buildInventory(appState, onHandState) {
    // extract required inventory variables
    let inventory = onHandState?.restaurant?.group?.item?.inventory;

    // validate required variables
    if (!inventory || !inventory.data) {
        console.error(
            "Something went wrong! \nNo inventory or inventory resource object was attached!"
        );
        return null;
    }

    // update inventory metadata
    inventory = prepInventoryForSubmission(inventory, appState);

    // persist locally the updated inventory
    persistInventoryLocallyWrp(appState, onHandState, inventory);

    return inventory;
}

async function handleSubmit(onHandState, submitSelectedItem, inventory) {
    const inventoryResource = onHandState?.config?.context?.inventoryResource;

    // validate required variables
    if (!inventoryResource) {
        console.error(
            "Something went wrong! \nNo inventory or inventory resource object was attached!"
        );
        return null;
    }

    // persist to dynamo db, with retries
    try {
        await retry(async () => {
            await inventoryResource.getModel().api.create(inventory.data);
        });
    } catch (e) {
        console.error(e.message);
        console.error("Failed to persist item!");
    }

    // save the modification for item in the context
    return submitSelectedItem(onHandState, inventory);
}

async function handleSubmitTransaction(onHandState, submitSelectedItemTransaction, transaction) {
    const transactionResource = onHandState?.config?.context?.transactionResource;

    // validate required variables
    if (!transactionResource) {
        console.error(
            "Something went wrong! \nNo transaction or transaction resource object was attached!"
        );
        return null;
    }

    // persist to dynamo db, with retries
    try {
        await retry(async () => {
            await transactionResource.getModel().api.create(transaction.data);
        });
    } catch (e) {
        console.error(e.message);
        console.error("Failed to persist item!");
    }

    // save the modification for item in the context
    return submitSelectedItemTransaction(onHandState, transaction);
}

// TODO: add extra data description - depending on section
function extractCompletedItem(item) {
    return item?.inventory?.data;
}

function extractCompletedInventories(groups) {
    return groups.reduce((acc, group) => {
        const completedInventories = group.items
            .filter(({ inventory: { mock } }) => !mock)
            .map(extractCompletedItem);
        acc = acc.concat(completedInventories);
        return acc;
    }, []);
}

function isOnHandBelowAlert(alertThreshold, inventoryQuantity) {
    return alertThreshold >= inventoryQuantity;
}

function isTransactionalOnHand(config) {
    return config?.context?.type === TRANSACTIONAL;
}

function loadPersistedView(router, views) {
    if (!views) {
        return null;
    }
    // load from URL
    const urlModeAlias = queryString.parse(router.location.search).view;
    const viewFromUrl = views.all.find(({ name }) => name === urlModeAlias);

    if (viewFromUrl) {
        return viewFromUrl;
    }

    // load from local key
    const localViewAlias = localStorage.getItem(views?.localKey);
    const viewFromLocal = views.all.find(({ name }) => name === localViewAlias);

    if (viewFromLocal) {
        return viewFromLocal;
    }

    return views.default;
}

function persistView(view, router, views) {
    // add to local storage
    localStorage.setItem(views.localKey, view.name);
}

const OnHandUtils = {
    isSelectedByName,
    isSelectedByAlias,
    isSelectedItem,
    isReadOnly,
    getLocalStorageConfig,
    getLocalItems,
    buildInventory,
    handleSubmit,
    handleSubmitTransaction,
    extractCompletedItem,
    extractCompletedInventories,
    isOnHandBelowAlert,
    isTransactionalOnHand,
    loadPersistedView,
    persistView,
};

export default OnHandUtils;
