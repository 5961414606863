import React, { useContext, useEffect } from "react";
import deliveryTShirtsStyles from "../TShirtsStocking.module.css";
import tShirtsStockingItemStyles from "../TShirtsStockingItem.module.css";
import { TShirtsStockingContext } from "../context/TShirtsStockingContext";
import TShirtsStockingItem from "./TShirtsStockingItem";
import TShirtsStockingUtils from "../TShirtsStockingUtils";
import { AppContext } from "../../../../context/AppContext";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

const TShirtsStockingStyle = ({ tShirtStyleSet }) => {
    const { width } = useWindowDimensions();
    const { appState } = useContext(AppContext);
    const { tShirtsStocking } = useContext(TShirtsStockingContext);

    useEffect(() => {}, [width]);

    // expect sizes, restaurant, mode to be set and tShirtStyleSet to be a valid Array
    if (TShirtsStockingUtils.isValidTShirtStocking(tShirtsStocking, tShirtStyleSet, appState)) {
        return <div>Invalid state!</div>;
    }

    // block users without access
    if (!tShirtsStocking.mode.canAccess(appState.user.groups)) {
        return <div>Access Restricted!</div>;
    }

    // filter out fully completed tShirt models from STOCKING
    // if (TShirtsStockingUtils.hasNoStocking(tShirtStyleSet)) {
    // return null;
    // }

    const tShirtStyleName = tShirtStyleSet[0]?.style?.data?.name;
    const tShirtStyleSelected =
        tShirtsStocking?.restaurant?.selectedTShirt?.data?.name === tShirtStyleName;
    const tShirtStyleClassName = tShirtStyleSelected ? deliveryTShirtsStyles.NameSelected : "";
    const tShirtsDisplay = tShirtsStocking.sizes
        .reduce(
            TShirtsStockingUtils.tShirtToSizeMappingReduce.bind(
                null,
                tShirtStyleSet,
                tShirtStyleName
            ),
            []
        )
        .map(({ key, tShirt }) => <TShirtsStockingItem key={key} tShirt={tShirt} />);

    return (
        <div
            style={TShirtsStockingUtils.computeGridColumns(tShirtsStocking, width)}
            className={tShirtsStockingItemStyles.Item}
        >
            <span className={tShirtStyleClassName}>{tShirtStyleName}</span>
            {tShirtsDisplay}
        </div>
    );
};

export default TShirtsStockingStyle;
