import React, { useContext, useEffect, useState } from "react";
import deliveryOrdersStyle from "../DeliveryOrders.module.css";
import { Button, FormControl, MenuItem, Select } from "@material-ui/core";
import { DeliveryOrdersContext } from "../context/DeliveryOrdersContext";

export default function DeliveryOrdersEmailDialogAddVendor() {
    const { deliveryOrders, addNewVendor } = useContext(DeliveryOrdersContext);
    const [state, setState] = useState(null);

    useEffect(() => {}, [deliveryOrders.restaurant.nonDeliveryVendors]);

    const handleAddNewVendor = (event) => {
        return setState(event?.target?.value);
    };

    if (!deliveryOrders.restaurant.nonDeliveryVendors) {
        return <div>Missing any vendors!</div>;
    }

    return (
        <div className={deliveryOrdersStyle.DialogAddVendor}>
            <FormControl>
                <Select
                    labelId="non-delivery-add-vendor-add-select-label"
                    id="non-delivery-add-vendor-add-select"
                    value={state}
                    displayEmpty
                    onChange={handleAddNewVendor}
                >
                    <MenuItem value={null}>
                        <em>Select Vendor</em>
                    </MenuItem>
                    {deliveryOrders.restaurant.nonDeliveryVendors.map(
                        (nonDeliveryVendor, index) => (
                            <MenuItem
                                key={`non-delivery-add-vendor-${index}`}
                                value={nonDeliveryVendor}
                            >
                                {nonDeliveryVendor?.data?.name}
                            </MenuItem>
                        )
                    )}
                </Select>
            </FormControl>
            <Button
                autoFocus
                disabled={!state}
                onClick={() => addNewVendor(state)}
                color="secondary"
                variant="outlined"
            >
                Add
            </Button>
        </div>
    );
}
