import React, { useContext, useEffect } from "react";
import { AppContext } from "../../../context/AppContext";
import AppContextValidator from "../../../context/AppContextValidator";
import DeliveryOrders from "./DeliveryOrders";
import { DeliveryOrdersProvider } from "./context/DeliveryOrdersContext";

export default function DeliveryOrdersWrp({ config }) {
    const { appState } = useContext(AppContext);

    useEffect(() => {}, [appState.day, appState.user]);

    if (!AppContextValidator.isValid(appState)) {
        console.info("Invalid appState!");
        return null;
    }

    return (
        <DeliveryOrdersProvider>
            <DeliveryOrders config={config} />
        </DeliveryOrdersProvider>
    );
}
