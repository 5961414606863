import { Button } from "@material-ui/core";
import React, { useContext } from "react";
import { useGlobalSpinnerActionsContext } from "../../../../components/global-spinner/GlobalSpinnerContext";
import { AppContext } from "../../../../context/AppContext";
import { OffSiteReordersContext } from "../context/OffSiteReordersContext";
import OffSiteReordersUtils from "../OffSiteReordersUtils";
import { OffSiteReordersAlertDisplay } from "../types/OffSiteReordersTypes";

export default function OffSiteReordersCompleteButton({
    alert,
}: {
    alert: OffSiteReordersAlertDisplay;
}) {
    const setGlobalSpinner = useGlobalSpinnerActionsContext();
    const { appState } = useContext(AppContext);
    const { completeAlert } = useContext(OffSiteReordersContext);

    const onAlertDone = async (alert: OffSiteReordersAlertDisplay) => {
        try {
            setGlobalSpinner(true);
            const prevType = alert.type;
            alert = await OffSiteReordersUtils.completeAlert(alert, appState.user.name);

            completeAlert(alert, prevType);
        } catch (e) {
            throw Error("Cannot complete alert!");
        } finally {
            setGlobalSpinner(false);
        }
    };

    return (
        <Button onClick={onAlertDone.bind(null, alert)} color={"secondary"} variant={"contained"}>
            Done
        </Button>
    );
}
