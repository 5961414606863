import OnHandItemContext from "../../../on-hand/context/OnHandItemContext";
import OnHandItemModel from "../../../on-hand/models/OnHandItemModel";
import { fetchResources } from "../../../../resources/ResourcesService";
import AppUtilsService from "../../../../services/AppUtilsService";
import { AppIngredient, AppIngredientPack } from "../../FoodResources";

const ID = "id";

export default class OnHandFoodItemContext extends OnHandItemContext {
    async loadDependencies(day, userName, sectionName, useMocks = false) {
        let [ingredients, ingredientPacks] = await fetchResources(
            [[AppIngredient], [AppIngredientPack]],
            useMocks
        );

        this.ingredientPacksById = ingredientPacks.reduce(AppUtilsService.groupDataByItem(ID), {});
        this.ingredientsById = ingredients.reduce(AppUtilsService.groupDataByItem(ID), {});
        const inventories = await this.generator.loadInventories(
            this.rawRestaurants,
            this.rawItems,
            day,
            userName,
            sectionName
        );
        this.ingredientInventoriesById = inventories.reduce(
            AppUtilsService.groupDataByItem(this.resourceRefKey),
            {}
        );
    }

    decorate(item) {
        const {
            data: { id, ingredientId },
        } = item;

        const ingredient = this.ingredientsById[ingredientId];
        if (!ingredient) {
            return null;
        }
        ingredient.pack = this.ingredientPacksById[ingredient?.data?.ingredientPackId];
        if (!ingredient.pack) {
            return null;
        }
        const inventory = this.ingredientInventoriesById[id];

        return new OnHandItemModel(item, {
            inventory,
            ingredient,
        });
    }

    constructor(resource, inventoryResource, inventoryMetaResource, groupResource, utils) {
        super(resource, inventoryResource, inventoryMetaResource, groupResource, utils);

        // data dependencies
        this.ingredientPacksById = {};
        this.ingredientsById = {};
        this.ingredientInventoriesById = {};
    }
}
