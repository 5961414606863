import ModelApi from "../model/ModelApi";
import { isAppS3ClientResponse } from "./AppS3TypeGuards";
import {
    AppS3ClientGetRequest,
    AppS3ClientPutRequest,
    AppS3ClientRequest,
    AppS3ClientResponse,
    AppS3ClientResponseBody,
} from "./AppS3Types";

export function buildS3GetRequest(key: string): AppS3ClientGetRequest {
    return {
        type: "GET_S3_OBJECTS",
        key,
        data: null,
    } as AppS3ClientGetRequest;
}

export function buildS3PutRequest(key: string, data: Object[]): AppS3ClientPutRequest {
    return {
        type: "PUT_S3_OBJECTS",
        key,
        data,
    } as AppS3ClientPutRequest;
}

async function callS3Client(requests: AppS3ClientRequest[]): Promise<string> {
    return (await ModelApi.customMutation(
        { requests: JSON.stringify(requests) },
        "callS3Client"
    )) as string;
}

export async function sendS3Requests(
    requests: AppS3ClientRequest[]
): Promise<AppS3ClientResponseBody[] | null> {
    let results: AppS3ClientResponseBody[] | null;

    try {
        const rawResponse = await callS3Client(requests);
        const response = deserializeResponse(rawResponse);

        if (response !== null) {
            console.log("Got the responses!");
            results = response.body;
        } else {
            console.warn("Got empty responses!");
            results = null;
        }
    } catch (e) {
        results = null;
        console.error("Failed to retrieve objects!");
    }

    return results;
}

function deserializeResponse(rawResponse: string): AppS3ClientResponse | null {
    let result: AppS3ClientResponse | null;

    const deserializedResponse = JSON.parse(rawResponse);

    if (isAppS3ClientResponse(deserializedResponse)) {
        result = deserializedResponse;
    } else {
        result = null;
    }

    return result;
}
