import { AppRestaurant } from "../../../../../admin/AdminResources";
import {
    DeliveryFoodIngredient,
    DeliveryFoodSummaryConfigsSubType,
} from "../../../types/DeliveryFoodTypes";

export const DeliveryFoodSummaryDeliveryConfigs: DeliveryFoodSummaryConfigsSubType = {
    title: ({
        ingredient,
        restaurant,
    }: {
        ingredient: DeliveryFoodIngredient;
        restaurant: AppRestaurant;
    }) => {
        return (
            <div>
                Delivery for <strong>{ingredient.data.name}</strong> at{" "}
                <strong>{restaurant.data.name}</strong>
            </div>
        );
    },
    content: ({
        data,
        total,
        totalDisplay,
    }: {
        data: any;
        total: number | string | null;
        totalDisplay: number | string | null;
    }) => {
        return (
            <div>
                <ul>
                    <li>
                        <strong>MULTIPLE Sales</strong>: {data?.MULTIPLE?.totalDisplay} Bulks
                    </li>
                    <li>
                        <strong>ON HAND</strong>: {data?.ON_HAND?.totalDisplay} Bulks
                    </li>
                    <li>
                        <strong>SINGLE Sales</strong>: {data?.SINGLE?.totalDisplay} Bulks
                    </li>
                    <li>
                        <strong>Delivered to</strong>: {data?.DELIVERY?.name} Area
                    </li>
                    <li>
                        <strong>Fixed Need</strong>: {data?.DELIVERY?.fixedNeed} Bulks
                    </li>
                </ul>
                <div>
                    <strong>Total before rounding</strong>: {total} Bulks
                </div>
                <div>
                    <strong>Total</strong>: {totalDisplay} Bulks
                </div>
            </div>
        );
    },
};
