import { ResourceRefKey, ResourceStoreKey } from "../../../model/ModelConfig";
import { Resource } from "../../../resources/Resource";
import { ResourceColumn } from "../../../resources/ResourceColumn";
import DataEditorSortUtils from "../utils/DataEditorSortUtils";
import DataEditorUtils from "../utils/DataEditorUtils";

export type DataEditorSortableItem = {
    id: number;
    name: string;
    item: Resource;
    chosen?: boolean;
};

export type ResourcesByStoreKey = {
    [storeKey: ResourceStoreKey]: typeof Resource;
};

export type StoreKeyToRefKey = {
    [storeKey: ResourceStoreKey]: ResourceRefKey;
};

export type RefKeyToStoreKey = {
    [resourceRefKey: ResourceRefKey]: ResourceStoreKey;
};

export type ItemsByResourceStoreKey = {
    [storeKey: ResourceStoreKey]: Resource[];
};

export type ItemsByResourceRefKey = {
    [resourceRefKey: ResourceRefKey]: Resource[];
};

export type ResourceItemsType = {
    resource: typeof Resource;
    items: Resource[];
};

export type DataEditorResourceConfig = {
    isVisibleInDataEditor: boolean;
};

export class DataEditorResource {
    constructor(public resource: typeof Resource, public config: DataEditorResourceConfig) {}
}

export type DataEditorResources = {
    all: typeof Resource[];
    allDataEditorResources: DataEditorResource[];
    selected: typeof Resource;
    byStoreKey: ResourcesByStoreKey;
};

export type DataEditorItemsType = {
    byResourceStoreKey: ItemsByResourceStoreKey;
    byResourceRefKey: ItemsByResourceRefKey;
};

export type DataEditorMappings = {
    storeKeyToRefKey: {
        [storeKey: ResourceStoreKey]: ResourceRefKey;
    };
    refKeyToStoreKey: {
        [resourceRefKey: ResourceRefKey]: ResourceStoreKey;
    };
};

export type DataEditorMappingType = {
    storeKeyToRefKey: StoreKeyToRefKey;
    refKeyToStoreKey: RefKeyToStoreKey;
};

export type DataEditorStore = {
    resources: DataEditorResources;
    items: DataEditorItemsType;
    mapping: DataEditorMappingType;
};

export type DataEditorSortDirection = "ASC" | "DESC";

export class DataEditorSortQueueEntry {
    public sortDirection?: DataEditorSortDirection;
    public clickCount: number = 0;
    public sortCallback: Function = (a: any = {}, b: any = {}) =>
        JSON.stringify(a).localeCompare(JSON.stringify(b));

    addClickCount() {
        this.clickCount++;
    }

    constructor(
        public column: ResourceColumn,
        sortDirection?: DataEditorSortDirection,
        sortCallback?: Function
    ) {
        this.sortDirection = sortDirection || column.defaultSortDirection || "ASC";
        this.sortCallback = sortCallback || column.sortCallback;
    }
}

export function isDataEditorSortQueue(valueToTest: any): valueToTest is DataEditorSortQueueEntry[] {
    return (
        Array.isArray(valueToTest) &&
        valueToTest.every((value) => value instanceof DataEditorSortQueueEntry)
    );
}

export type DataEditorSortQueues = {
    [storeKey: ResourceStoreKey]: DataEditorSortQueueEntry[];
};

export type DataEditorDisplay = {
    searchText: {
        [storeKey: ResourceStoreKey]: string | null;
    };
    filter: {
        [storeKey: ResourceStoreKey]: string | null;
    };
    // an array of column sort configurations
    // that would determine the type of sorting and the order of sorting
    sortQueues: DataEditorSortQueues;
    sortable: boolean; // TODO: are these used?
    enableReorder: boolean; // TODO: are these used?
    addDialogOpen: boolean;
};

// TODO: MAKE THIS A TYPE THAT IS INITIALIZED ONLY FROM "resources: typeof Resources[]", then later loaded
export class DataEditorState {
    public store: DataEditorStore;
    public display: DataEditorDisplay;

    getSelectedResource() {
        return this.store.resources.selected;
    }

    setSelectedResource(resource: typeof Resource) {
        this.store.resources.selected = resource;
        return this;
    }

    getAllResources() {
        return this.store.resources.all;
    }

    getResourceByStoreKey(storeKey: ResourceStoreKey) {
        return this.store.resources.byStoreKey[storeKey];
    }

    getItemsByStoreKey(storeKey: ResourceStoreKey) {
        return this.store.items.byResourceStoreKey[storeKey];
    }

    setItemsByStoreKey(storeKey: ResourceStoreKey, resourceItems: Resource[]) {
        this.store.items.byResourceStoreKey[storeKey] = resourceItems;
    }

    getItemsByResourceRefKey(resourceRefKey: ResourceRefKey) {
        return this.store.items.byResourceRefKey[resourceRefKey];
    }

    setItemsByResourceRefKey(resourceRefKey: ResourceRefKey, resourceItems: Resource[]) {
        this.store.items.byResourceRefKey[resourceRefKey] = resourceItems;
    }

    constructor(dataEditorResources: DataEditorResource[], public location: any) {
        const resources = dataEditorResources.map(
            (dataEditorResource) => dataEditorResource.resource
        );

        this.store = {
            resources: DataEditorUtils.getResourcesInit(dataEditorResources, resources, location),
            items: DataEditorUtils.getItemsDefaults(resources),
            mapping: DataEditorUtils.getMappingDefaults(resources),
        };

        this.display = {
            searchText: {},
            filter: {},
            sortQueues: DataEditorSortUtils.getDefaultSortQueue(resources),
            sortable: false, // TODO: MOVE THIS TO EACH RESOURCE
            enableReorder: false, // TODO: MOVE THIS TO EACH RESOURCE
            addDialogOpen: false,
        };
    }
}

export type DataEditorContextState = {
    dataEditorState: DataEditorState;
    changeResource: Function;
    reOrderSelectedItems: Function;
    saveOrder: Function;
    updateSelectedResourceSorting: Function;
    updateSelectedResourcePageIndex: Function;
    setSearchText: Function;
    updateAllItems: Function;
    clearAllItemsFromSelectedResource: Function;
    updateItem: Function;
    updateResourceItems: Function;
    clearItem: Function;
    addNewItem: Function;
    openAddDialog: Function;
    closeAddDialog: Function;
};
