import React, { useContext } from "react";
import { TShirtsStockingContext } from "../context/TShirtsStockingContext";
import TShirtsStockingUtils from "../TShirtsStockingUtils";
import TShirtsStockingStatusNeedsCompleted from "./TShirtsStockingStatusNeedsCompleted";
import TShirtsStockingStatusNeedsOnHand from "./TShirtsStockingStatusNeedsOnHand";
import TShirtsStockingStatusRequiredStock from "./TShirtsStockingStatusRequiredStock";

const TShirtsStockingStatus = () => {
    const { tShirtsStocking } = useContext(TShirtsStockingContext);

    // invalid data
    if (!TShirtsStockingUtils.isValidTShirtStockingForStatus(tShirtsStocking)) {
        return null;
    }

    // not stocking mode
    if (!TShirtsStockingUtils.isStockingMode(tShirtsStocking)) {
        return null;
    }

    const allTShirts = TShirtsStockingUtils.extractAllTShirtsFromStyles(tShirtsStocking);

    // needs on hand
    if (TShirtsStockingUtils.someTShirtsNeedOnHand(tShirtsStocking)) {
        return <TShirtsStockingStatusNeedsOnHand />;
    }

    // completed
    if (TShirtsStockingUtils.allTShirtsAreCompleted(tShirtsStocking)) {
        return <TShirtsStockingStatusNeedsCompleted />;
    }

    // required stock
    return <TShirtsStockingStatusRequiredStock tShirts={allTShirts} />;
};

export default TShirtsStockingStatus;
