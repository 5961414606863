import React, { useContext } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import { OnHandContext } from "../../context/OnHandContext";
import onHandGroupStyles from "./OnHandGroup.module.css";
import OnHandStatus from "../status/OnHandStatus";
import { MdExpandMore } from "react-icons/md";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import OnHandContextValidator from "../../context/OnHandContextValidator";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import onHandItemStyles from "../item/OnHandItem.module.css";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import OnHandGroupConfirmChanges from "./OnHandGroupConfirmChanges";
import OnHandGroupReadOnly from "./OnHandGroupReadOnly";
import OnHandUtils from "../../OnHandUtils";

const OnHandTabPanelWrp = (onHandState, group, item, itemIndex) => {
    if (!onHandState.restaurant) {
        return <div />;
    }

    if (!onHandState.restaurant.group) {
        return <div key={`missing-group-${itemIndex}`} />;
    }

    function OnHandTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <Typography
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                {...other}
            >
                {value === index && <Box p={3}>{children}</Box>}
            </Typography>
        );
    }

    OnHandTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    return (
        <OnHandTabPanel
            key={`TabPanel-${itemIndex}`}
            className={onHandItemStyles.TabHead}
            value={group.selectedItemIndex}
            index={itemIndex}
        >
            {onHandState.config.display.itemContent(group, item)}
        </OnHandTabPanel>
    );
};

const OnHandGroup = ({ group, groupIndex, isReadOnly }) => {
    const { onHandState, changeItem, updateRestaurant } = useContext(OnHandContext);

    if (!OnHandContextValidator.isValidGroup(group)) {
        console.info("Invalid Group!");
        return <div />;
    }

    if (isReadOnly) {
        return <OnHandGroupReadOnly group={group} />;
    }

    const handleGroupChange = async (onHandState, group, event, isExpanded) => {
        const restaurant = isExpanded
            ? onHandState.restaurant.selectGroup(group)
            : onHandState.restaurant.deselectGroup();
        return updateRestaurant(restaurant);
    };

    const handleItemChange = (event, index) => {
        return changeItem(index);
    };

    const expanded = group === onHandState.restaurant.group;
    const isTransactionalOnHand = OnHandUtils.isTransactionalOnHand(onHandState.config);

    return (
        <ExpansionPanel
            key={`group-${groupIndex}`}
            TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
            expanded={expanded}
            onChange={handleGroupChange.bind(null, onHandState, group)}
        >
            <ExpansionPanelSummary
                id={group.data.id}
                className={`${onHandGroupStyles.Header} ${OnHandStatus.getClassName(group.status)}`}
                expandIcon={<MdExpandMore />}
                aria-controls={group.data.name}
            >
                <Typography className={onHandGroupStyles.primaryHeader}>
                    <strong>{group.data.name}</strong>
                </Typography>
                <Typography className={onHandGroupStyles.secondaryHeader}>
                    <strong className={OnHandStatus.getClassName(group.status)}>
                        {OnHandStatus.getDisplay(group.status)}
                    </strong>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={onHandGroupStyles.Expansion}>
                <Tabs
                    orientation="horizontal"
                    variant="scrollable"
                    value={group.selectedItemIndex}
                    onChange={handleItemChange}
                    aria-label="Horizontal onHands"
                    classes={{ indicator: "hidden" }}
                    className={onHandGroupStyles.Tabs}
                >
                    {group.items.map(onHandState.config.display.itemTab)}
                </Tabs>
                {group.items.map(OnHandTabPanelWrp.bind(null, onHandState, group))}
            </ExpansionPanelDetails>
            {isTransactionalOnHand ? null : (
                <OnHandGroupConfirmChanges group={group} groupIndex={groupIndex} />
            )}
        </ExpansionPanel>
    );
};

export default OnHandGroup;
