import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import React, { useContext, useEffect } from "react";
import "./App.css";
import ErrorHandler from "./components/error-handler/ErrorHandler";
import { useGlobalSpinnerActionsContext } from "./components/global-spinner/GlobalSpinnerContext";
import AppRoutes from "./components/routes/AppRoutes";
import SectionInitSelector from "./containers/sections/init/SectionInitSelector";
import { AppContext } from "./context/AppContext";
import AppContextValidator from "./context/AppContextValidator";

function App() {
    const setGlobalSpinner = useGlobalSpinnerActionsContext();
    const { appState, initApp } = useContext(AppContext);

    useEffect(() => {
        if (AppContextValidator.isValidUser()) {
            initApp();
        }
    }, [initApp]);

    if (!appState.user) {
        return <div>Please wait...</div>;
    }

    if (!appState.section || appState.showSectionSelector) {
        return <SectionInitSelector />;
    }

    console.log("Rendering App");

    return (
        <ErrorHandler src={"App"} appState={appState} setGlobalSpinner={setGlobalSpinner}>
            {appState.section.wrapperContextProvider ? (
                <appState.section.wrapperContextProvider>
                    <AppRoutes />
                </appState.section.wrapperContextProvider>
            ) : (
                <AppRoutes />
            )}
        </ErrorHandler>
    );
}

export default withAuthenticator(App, false);
