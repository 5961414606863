import SalesParser from "../components/drop-zone/parsers/SalesParser";
import MenusSectionConfigs from "../config/MenusSectionConfigs";

function removeOracleIdSuffix(menuItemName) {
    return menuItemName?.split("(")[0].trim() || menuItemName;
}

/**
 * @function findMenuItemCallback
 * @description Callback function to find a menu item by name
 * @param {any} menuItemName
 * @returns {function} Callback function to find a menu item by name
 */
function findMenuItemCallback(menuItemName) {
    return (menuItem) => {
        let result = false;

        // either the menuItemName matches the menuItem name
        // or the menuItemName, without the suffix that starts with '(' would match the menuItem name
        result = menuItem.data.name === removeOracleIdSuffix(menuItemName);

        return result;
    };
}

function findMenuItem(menu, menuItemName) {
    return menu.find(findMenuItemCallback(menuItemName));
}

function findMenuItemIndex(menu, menuItemName) {
    return menu.findIndex(findMenuItemCallback(menuItemName));
}

function digestUploadedSales(sectionName, data, menu, saleType, day, lastUpdatedBy, lastUpdatedAt) {
    // TODO: fix this hack (this is supposed to replace the need to "mergeSalesData") in MenuSalesParser
    const validMenuItemNames = menu.map((menuItem) => menuItem.data.name);
    const nonIllegalCharMenuItems = menu.map((menuItem) =>
        SalesParser.clearIllegalChars(menuItem.data.name)
    );

    // build the data again, by adding up sales for same menu item
    // (or same menu item without " ({id})" suffix)
    // use the trimmed and non-illegal char name as the key
    const mergedData = data.reduce((acc, uploadedSale) => {
        const [name, quantitySold] = uploadedSale;

        // if the menu item is not valid, skip it
        const oracleIdTrimmedName = removeOracleIdSuffix(name);
        const nonIllegalOracleIdTrimmedName = SalesParser.clearIllegalChars(oracleIdTrimmedName);
        const nonIllegalCharName = SalesParser.clearIllegalChars(oracleIdTrimmedName);

        if (
            !nonIllegalCharMenuItems.includes(nonIllegalOracleIdTrimmedName) ||
            !nonIllegalCharMenuItems.includes(nonIllegalCharName)
        ) {
            return acc;
        }

        // name used to index values
        let keyName = "";

        if (nonIllegalCharMenuItems.includes(nonIllegalCharName)) {
            // if the name trimmed, without the oracle suffix, exists as a valid menu item, use it
            keyName = nonIllegalCharName;
        } else if (nonIllegalCharMenuItems.includes(nonIllegalOracleIdTrimmedName)) {
            // if the name trimmed, without the oracle suffix, exists as a valid menu item, use it
            keyName = nonIllegalOracleIdTrimmedName;
        }
        // actual name
        let trueName = null;

        if (validMenuItemNames.includes(name)) {
            trueName = name;
        } else if (validMenuItemNames.includes(oracleIdTrimmedName)) {
            trueName = oracleIdTrimmedName;
        }

        if (!keyName || !trueName) {
            return acc;
        }

        if (!acc[keyName]) {
            // if the menu item is valid, add it to the accumulator
            acc[keyName] = [trueName, 0];
        }

        acc[keyName][1] += quantitySold;

        return acc;
    }, {});

    const mergedDataArray = Object.values(mergedData);

    return mergedDataArray.reduce(
        (acc, uploadedSale) => {
            const [name, quantitySold] = uploadedSale;
            const menuItemIndex = findMenuItemIndex(acc.updatedMenu, name, saleType);
            const menuItem = findMenuItem(acc.updatedMenu, name, saleType);

            if (!menuItem) {
                acc.invalidQueue.push(uploadedSale);
                return acc;
            }

            // item need update
            if (menuItem.sales && menuItem.sales[saleType]) {
                menuItem.sales[saleType].data.quantity = quantitySold;
                menuItem.sales[saleType].data.lastUpdatedBy = lastUpdatedBy;
                menuItem.sales[saleType].data.lastUpdatedAt = lastUpdatedAt;
                acc.updateQueue.push(menuItem.sales[saleType]);
                return acc;
            }

            // item doesn't have a value
            const { attachMenuItemId } = MenusSectionConfigs.getUtils(sectionName);
            const { menuItemSaleResource } = MenusSectionConfigs.getConfig(sectionName).resources;
            menuItem.sales[saleType] = new menuItemSaleResource(
                attachMenuItemId(
                    {
                        day: day.data.time,
                        type: saleType,
                        quantity: quantitySold,
                        lastUpdatedBy,
                        lastUpdatedAt,
                    },
                    menuItem.data.id
                )
            );
            acc.createQueue.push(menuItem.sales[saleType]);

            acc.updatedMenu[menuItemIndex] = menuItem;
            return acc;
        },
        { updateQueue: [], createQueue: [], invalidQueue: [], updatedMenu: menu }
    );
}

function displayRecipeItem(recipeItem) {
    const quantity = recipeItem.data.quantity;
    const name = recipeItem.ingredient.data.name;

    return `${quantity} x ${name}`;
}

function getMenuItemCompletionScore({ sales: { SINGLE, MULTIPLE } }) {
    return !!SINGLE + !!MULTIPLE;
}

function getMenuCompletionScore(menu) {
    const menuItemsCompletionScore = menu
        .map(getMenuItemCompletionScore)
        .reduce((a, c) => a + c, 0);
    return menuItemsCompletionScore / (2 * menu.length);
}

function getTotalCompletionScore(restaurants) {
    const maxCompletionScore = restaurants.reduce((acc, { menu }) => {
        acc += 2 * menu.length;
        return acc;
    }, 0);

    const actualCompletionScore = restaurants.reduce((acc, { menu }) => {
        acc += getMenuCompletionScore(menu);
        return acc;
    }, 0);

    return actualCompletionScore / maxCompletionScore;
}

const MenusUtils = {
    digestUploadedSales,
    displayRecipeItem,
    getMenuCompletionScore,
    getTotalCompletionScore,
};

export default MenusUtils;
