import { fetchResources } from "../../../resources/ResourcesService";
import AppUtilsService from "../../../services/AppUtilsService";
import OnHandItemContext from "../../on-hand/context/OnHandItemContext";
import OnHandItemModel from "../../on-hand/models/OnHandItemModel";
import { INVENTORY } from "../../on-hand/models/OnHandTypes";
import { AppOffSiteUnit } from "../OffSiteResources";

const ID = "id";

export default class OffSiteOnHandItemContext extends OnHandItemContext {
    async loadDependencies() {
        // TODO: also include "MODE"
        let [offSiteUnits] = await fetchResources([[AppOffSiteUnit]]);
        this.offSiteUnitsById = offSiteUnits.reduce(AppUtilsService.groupDataByItem(ID), {});
    }

    decorate(item) {
        return new OnHandItemModel(item, {
            // TODO: fill in with elements to decorate the item
        });
    }

    constructor(
        resource,
        inventoryResource,
        inventoryMetaResource,
        groupResource,
        utils,
        type = INVENTORY,
        transactionResource
    ) {
        super(
            resource,
            inventoryResource,
            inventoryMetaResource,
            groupResource,
            utils,
            type,
            transactionResource
        );
        // data dependencies
        this.offSiteUnitsById = {};
    }
}
