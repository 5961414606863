import React, { useContext } from "react";
import { DeliveryFoodContext } from "../../DeliveryFoodContext";
import deliveryFoodStyles from "../../DeliveryFood.module.css";
import { AppRestaurant } from "../../../../admin/AdminResources";
import { DeliveryFoodIngredient, DeliveryFoodSummary } from "../../types/DeliveryFoodTypes";

const DeliveryFoodCell = ({
    fieldName,
    styleName,
    summary,
    restaurant,
    index,
    ingredient,
    salesType,
    totalDisplayWrp,
}: {
    fieldName: string;
    styleName: string;
    summary: DeliveryFoodSummary;
    restaurant: AppRestaurant;
    index: number;
    ingredient: DeliveryFoodIngredient;
    salesType?: string;
    totalDisplayWrp?: any;
}) => {
    const { setSummary } = useContext(DeliveryFoodContext);

    if (typeof summary.total !== "number" || summary.total < 0) {
        return <div />;
    }

    const key = `item-${fieldName}-${restaurant.data.id}-${index}`;
    const className = `truncate ${deliveryFoodStyles.Cell} ${deliveryFoodStyles[styleName]}`;
    const textClassName = summary.total <= 0 ? "no-print" : "";

    const handleCellClick = () => {
        return setSummary({ ...summary, ingredient, restaurant, key: fieldName });
    };

    return (
        <div key={key} className={className} onClick={handleCellClick}>
            <span className={textClassName}>
                {typeof totalDisplayWrp !== "number" ? summary.totalDisplay : totalDisplayWrp}
            </span>
        </div>
    );
};

export default DeliveryFoodCell;
