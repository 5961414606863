import DeliveryFoodModeUtils from "../services/DeliveryFoodModeUtils";
import { DeliveryFoodModeRootData } from "../types/DeliveryFoodModeTypes";
import DeliveryFoodMode from "./DeliveryFoodMode";

export class DeliveryFoodSummaryMode extends DeliveryFoodMode {
    constructor(rootData: DeliveryFoodModeRootData) {
        super(rootData);
        this.label = "Summary";
        const { restaurants } = rootData;
        this.display = DeliveryFoodModeUtils.buildSummaryModeDisplay(restaurants);
    }
}
