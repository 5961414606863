import React from "react";
import deliveryOrdersStyles from "../DeliveryOrders.module.css";
import DeliveryOrdersUtils from "../DeliveryOrdersUtils";
import DeliveryOrdersCellConversion from "./cell/DeliveryOrdersCellConversion";
import DeliveryOrdersCellName from "./cell/DeliveryOrdersCellName";
import DeliveryOrdersCellOnHand from "./cell/DeliveryOrdersCellOnHand";
import DeliveryOrdersCellPar from "./cell/DeliveryOrdersCellPar";
import DeliveryOrdersCellDelivery from "./cell/DeliveryOrdersCellDelivery";

const DeliveryOrdersItem = (order) => {
    const inventory = DeliveryOrdersUtils.extractInventoryQty(order);
    const delivery = DeliveryOrdersUtils.extractDeliveryQty(order);

    // MISSING ON HAND
    if (inventory === null) {
        return (
            <div className={`no-print ${deliveryOrdersStyles.Row} ${deliveryOrdersStyles.Error}`}>
                <DeliveryOrdersCellName order={order} />
                <DeliveryOrdersCellOnHand order={order} />
                <DeliveryOrdersCellPar order={order} />
                <DeliveryOrdersCellConversion order={order} />
                <DeliveryOrdersCellDelivery order={order} />
            </div>
        );
    }

    // OVER PAR
    if (delivery <= 0) {
        return (
            <div
                className={`no-print ${deliveryOrdersStyles.Row} ${deliveryOrdersStyles.NoDelivery}`}
            >
                <DeliveryOrdersCellName order={order} />
                <DeliveryOrdersCellOnHand order={order} />
                <DeliveryOrdersCellPar order={order} />
                <DeliveryOrdersCellConversion order={order} />
                <DeliveryOrdersCellDelivery order={order} />
            </div>
        );
    }

    // NEEDS DELIVERY
    return (
        <div className={deliveryOrdersStyles.Row}>
            <DeliveryOrdersCellName order={order} />
            <DeliveryOrdersCellOnHand order={order} />
            <DeliveryOrdersCellPar order={order} />
            <DeliveryOrdersCellConversion order={order} />
            <DeliveryOrdersCellDelivery order={order} />
        </div>
    );
};

export default DeliveryOrdersItem;
