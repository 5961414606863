import React from "react";
import tShirtsStockingItemStyles from "./TShirtsStockingItem.module.css";
import TShirtsStockingRestaurant from "./TShirtsStockingRestaurant";
import TShirtsStockingModeSwitch from "./mode/TShirtsStockingModeSwitch";
import TShirtsStockingHeader from "./TShirtsStockingHeader";
import TShirtsStockingContent from "./TShirtsStockingContent";
import TShirtsStockingStatus from "./status/TShirtsStockingStatus";

const TShirtsStocking = () => {
    return (
        <div className={tShirtsStockingItemStyles.Items}>
            <div className={tShirtsStockingItemStyles.Toolbar}>
                <TShirtsStockingRestaurant />
                <TShirtsStockingStatus />
                <TShirtsStockingModeSwitch />
            </div>
            <div className={tShirtsStockingItemStyles.ItemsContent}>
                <TShirtsStockingHeader />
                <TShirtsStockingContent />
            </div>
        </div>
    );
};

export default TShirtsStocking;
