import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { ContainerConfig } from "../../containers/ContainerConfig";
import AppHome from "../../containers/home/AppHome";
import { AppContext } from "../../context/AppContext";
import AppContextValidator from "../../context/AppContextValidator";
import AppLayout from "../../layout/AppLayout";

const AppRoutes = () => {
    const { appState } = useContext(AppContext);

    useEffect(() => {}, [appState.section, appState.user]);

    if (!AppContextValidator.isValidUser(appState.user)) {
        console.info("Invalid app user");
        return <div />;
    }

    const routes =
        appState.section?.pages.filter((page: ContainerConfig) => page.userAccess(appState.user)) ||
        [];

    return (
        <Router basename={appState.section?.basename}>
            <AppLayout>
                <Switch>
                    {routes.map(
                        ({
                            key,
                            path,
                            component,
                        }: {
                            key: string;
                            path: string;
                            component: any;
                        }) => {
                            return (
                                <Route
                                    key={key}
                                    path={path}
                                    exact
                                    component={component || AppHome}
                                />
                            );
                        }
                    )}
                    <Redirect from="/" to={`/home`} />
                    <Redirect from="" to={`/home`} />
                    <Route component={AppHome} />
                </Switch>
            </AppLayout>
        </Router>
    );
};

export default AppRoutes;
