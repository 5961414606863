import React, { useContext } from "react";
import onHandItemStyles from "../../../on-hand/components/item/OnHandItem.module.css";
import onHandContentStyles from "../../../on-hand/components/content/OnHandContent.module.css";
import OnHandFoodItemTitle from "./OnHandFoodItemTitle";
import OnHandFoodItemDelivery from "./OnHandFoodItemDelivery";
import OnHandFoodItemActions from "./OnHandFoodItemActions";
import OnHandFoodItemMeta from "./OnHandFoodItemMeta";
import { OnHandContext } from "../../../on-hand/context/OnHandContext";
import OnHandUtils from "../../../on-hand/OnHandUtils";
import OnHandItemInput from "../../../on-hand/components/item/OnHandItemInput";

const OnHandFoodItem = (group, item) => {
    const { onHandState } = useContext(OnHandContext);

    if (!OnHandUtils.isSelectedItem(onHandState, item)) {
        return <div />;
    }

    const key = `on-hand-order-item-${item.data.id}`;

    return (
        <div key={key} className={onHandItemStyles.Details}>
            <div className={onHandContentStyles.ContentHeader} style={{ placeContent: "center" }}>
                <OnHandFoodItemTitle />
                <OnHandFoodItemDelivery item={onHandState.restaurant.group.item} />
            </div>
            <OnHandItemInput />
            <OnHandFoodItemActions />
            <OnHandFoodItemMeta />
        </div>
    );
};

export default OnHandFoodItem;
