import OnHandTShirtItemContext from "./OnHandTShirtItemContext";
import {
    AppTShirt,
    AppTShirtInventory,
    AppTShirtInventoryMeta,
    AppTShirtStyle,
} from "../../TShirtsResources";

// TODO: validate this as a way to get item name
const extractItemName = (item) => item.data?.display;

// this instance is re-used => is kept separated
const OnHandTShirtContextConfig = new OnHandTShirtItemContext(
    AppTShirt,
    AppTShirtInventory,
    AppTShirtInventoryMeta,
    AppTShirtStyle,
    { extractItemName }
);

export default OnHandTShirtContextConfig;
