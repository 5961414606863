import React from "react";
import deliveryOrdersStyles from "../../DeliveryOrders.module.css";

const DeliveryOrdersCellPar = ({ order }) => {
    const par = order.data.par;
    const onHandUnit = order.onHandUnit.data.name;

    return (
        <span className={`no-print ${deliveryOrdersStyles.Explained}`}>
            {`${par}`} {onHandUnit}
        </span>
    );
};

export default DeliveryOrdersCellPar;
