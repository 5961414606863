import OnHandItemDisplayModel from "../../on-hand/models/OnHandItemDisplayModel";

class OffSiteOnHandItemDisplayModel extends OnHandItemDisplayModel {
    public views: any;

    constructor({ itemTab, item, views }: { itemTab: any; item: any; views: any }) {
        super(itemTab, item);
        this.views = views;
    }
}

export default OffSiteOnHandItemDisplayModel;
