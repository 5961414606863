import { Button } from "@material-ui/core";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useContext } from "react";
import { Resource } from "../../../../../../resources/Resource";
import { ResourceColumn } from "../../../../../../resources/ResourceColumn";
import { DataEditorContext } from "../../../../context/DataEditorContext";
import DataEditorUtils from "../../../../utils/DataEditorUtils";
import styles from "./DataEditorAdd.module.css";
import DataEditorAddDisplayColumn from "./DataEditorAddDisplayColumn";

export default function DataEditorAddContent() {
    const { dataEditorState, addNewItem, closeAddDialog } = useContext(DataEditorContext);

    const initialValues = DataEditorUtils.getAddMenuInitialValues(dataEditorState);
    const displayColumns = DataEditorUtils.prepareDisplayColumnsForAddMenu(dataEditorState);

    // TODO: find the actual way to get the object; study formik submit (avoid page refresh!!!)
    const handleSubmit = async (fmkProps: FormikProps<any>) => {
        const columnNames: string[] = DataEditorUtils.extractColumnNames(
            dataEditorState.getSelectedResource()
        );
        const columnValues: any[] =
            columnNames.map((columnName) => fmkProps.values[columnName]) || [];

        const items: Resource[] = DataEditorUtils.getSelectedResourceItems(dataEditorState);

        const newItem: Resource = await DataEditorUtils.createNewItem(
            dataEditorState.getSelectedResource(),
            items,
            columnNames,
            columnValues
        );

        addNewItem(newItem);
    };

    const onSubmit = async (values: any, { setSubmitting }: FormikHelpers<any>) => {
        await handleSubmit(values).then(() => setSubmitting(false));
    };

    const validate = (item: any) => {
        const columns = dataEditorState.getSelectedResource().getModel().config.columns;
        return dataEditorState.getSelectedResource().getModel().editor.validator(columns, item);
    };

    return (
        <Formik
            enableReinitialize={true}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            initialValues={initialValues}
            validate={validate}
        >
            {(fmkProps: FormikProps<any>) => {
                return (
                    <form className={styles.AddDialogContent} onSubmit={fmkProps.handleSubmit}>
                        {displayColumns.map((column: ResourceColumn, index: number) => {
                            return (
                                <DataEditorAddDisplayColumn
                                    fmkProps={fmkProps}
                                    column={column}
                                    index={index}
                                />
                            );
                        })}
                        <div className={styles.AddDialogActions}>
                            {/* TODO: use the handleSubmit from Formik (if it would work...) */}
                            <Button
                                onClick={() => handleSubmit(fmkProps)}
                                color="secondary"
                                variant={"contained"}
                            >
                                Submit
                            </Button>
                            <Button
                                autoFocus
                                onClick={() => closeAddDialog()}
                                color="primary"
                                variant={"outlined"}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                );
            }}
        </Formik>
    );
}
