import { FormikErrors, FormikProps, FormikTouched } from "formik";
import React from "react";
import { JsxElement } from "typescript";
import DataEditorCellDisplay from "../containers/data-editor/components/content/item/cell/DataEditorCellDisplay";
import DataEditorCellEditWrp from "../containers/data-editor/components/content/item/cell/DataEditorCellEditWrp";
import {
    DataEditorSortDirection,
    DataEditorState,
} from "../containers/data-editor/types/DataEditorTypes";
import { AppSortCallbackType, AppValidatorType } from "../types/AppTypes";

export type ResourceColumnSortRank = number | null;

export class ResourceColumn {
    public resourceRefKeyAlias: string | null = null;
    public placeholder?: string;
    public disabled?: boolean = true;
    public editable?: boolean = false;
    public hide?: boolean = true;
    public isRequired?: boolean = true;
    public isKey?: boolean = false;
    public defaultSortDirection: DataEditorSortDirection | null = null;
    // unless this is "-1" (which cancels it)
    // the lower it is, the more important the sort criteria
    public defaultSortRank: ResourceColumnSortRank = -1;
    public sortCallback: AppSortCallbackType = (a, b) =>
        JSON.stringify(a).localeCompare(JSON.stringify(b));
    public validator: AppValidatorType = () => false;
    public width?: number = 1;
    public hideOnAdd?: boolean = false;
    public defaultValue?: any;

    constructor(public field: string, public name: string, public inputType: string) {
        // TODO: pass required; remove "extract(Required)Properties" methods
        this.resourceRefKeyAlias = field;
    }

    setOptional(isRequired = false) {
        this.isRequired = isRequired;
        return this;
    }

    setVisible(hide = false) {
        this.hide = hide;
        return this;
    }

    setDefault(defaultValue: any) {
        this.defaultValue = defaultValue;
        return this;
    }

    setEditable(editable = true) {
        this.editable = editable;
        if (this.editable) {
            this.disabled = false;
        }
        return this;
    }

    setDisabled(disabled: boolean) {
        this.disabled = disabled;
        return this;
    }

    setHideOnAdd() {
        this.hideOnAdd = true;
        return this;
    }

    setKey(isKey = true) {
        this.isKey = isKey;
        return this;
    }

    setSortDirection(
        sortDirection: DataEditorSortDirection,
        sortRank: ResourceColumnSortRank = -1
    ) {
        // true => sort ASC; false => sort DESC; else => don't sort
        // the higher the rank, the bigger the sort priority
        // rank = 0 is the last one and is to be used for items that have a .order value, ASC
        this.defaultSortDirection = sortDirection;
        this.defaultSortRank = sortRank;
        return this;
    }

    setSortCallback(sortCallback: AppSortCallbackType) {
        this.sortCallback = sortCallback;
        return this;
    }

    setResourceRefKeyAlias(resourceRefKeyAlias: string) {
        this.resourceRefKeyAlias = resourceRefKeyAlias;
        return this;
    }

    setValidator(validator: AppValidatorType) {
        this.validator = validator;
        return this;
    }

    setWidth(width: string) {
        const widths: any = {
            XS: 1,
            S: 2,
            M: 3,
            L: 4,
            XL: 5,
        };
        this.width = widths[width] || 1;
        return this;
    }

    getDisplay(data: any) {
        return data[this.field];
    }

    renderDisplay(key: string, value: any) {
        return <DataEditorCellDisplay key={key} value={value} />;
    }

    prepRenderEdit(index: number, fmkProps: FormikProps<any>, dataEditorState?: DataEditorState) {
        const { touched, errors, isSubmitting } = fmkProps;
        const content = <div>Invalid!</div>;

        return this.renderEdit(index, this.field, content, errors, touched, isSubmitting);
    }

    renderEdit(
        index: number,
        field: string,
        content: JSX.Element,
        errors: FormikErrors<any>,
        touched: FormikTouched<any>,
        isSubmitting: boolean
    ) {
        const key = `cell-edit-wrp-${field}-${index}`;

        return (
            <DataEditorCellEditWrp
                key={key}
                field={field}
                index={index}
                content={content}
                errors={errors}
                touched={touched}
                isSubmitting={isSubmitting}
            />
        );
    }

    renderFilter() {
        return <div>...Filter...</div>;
    }
}
