import queryString from "query-string";

import AppDayModel from "../components/day/AppDayModel";
import { seriesPromises } from "../resources/ResourcesService";

function groupDataByItem(key) {
    return (acc, item) => {
        acc[item.data[key]] = item;
        return acc;
    };
}

function groupDataByList(key, sortCallback = () => true) {
    return (acc, item) => {
        acc[item.data[key]] = acc[item.data[key]] || [];
        acc[item.data[key]].push(item);
        acc[item.data[key]].sort(sortCallback);
        return acc;
    };
}

const SECTION_RESTAURANT_LOCAL_KEY = "r20{sectionName}Restaurant";

function loadRestaurant(sectionName, pageUrl, restaurants, router) {
    let restaurant = null;
    const localKey = SECTION_RESTAURANT_LOCAL_KEY.replace("{sectionName}", sectionName);

    // key from url
    const urlRestaurantAlias = queryString.parse(router.location.search).restaurant;
    const urlRestaurantsKeys = Object.keys(restaurants).filter(
        (restaurantKey) => restaurants[restaurantKey]?.data?.alias === urlRestaurantAlias
    );
    const urlRestaurantKey = urlRestaurantsKeys[0];
    if (urlRestaurantKey) {
        restaurant = restaurants[urlRestaurantKey];
    }

    // key from local
    if (!restaurant) {
        const localRestaurantAlias = localStorage.getItem(localKey);
        const localRestaurantKeys = Object.keys(restaurants).filter(
            (restaurantKey) => restaurants[restaurantKey]?.data?.alias === localRestaurantAlias
        );
        const localRestaurantKey = localRestaurantKeys[0];
        if (localRestaurantKey) {
            restaurant = restaurants[localRestaurantKey];
        }
    }

    // default
    if (!restaurant) {
        restaurant = restaurants[Object.keys(restaurants)[0]];
    }

    setRestaurant(sectionName, pageUrl, restaurant, router);
    return restaurant;
}

function setRestaurant(sectionName, pageUrl, restaurant, router) {
    const localKey = SECTION_RESTAURANT_LOCAL_KEY.replace("{sectionName}", sectionName);
    localStorage.setItem(localKey, restaurant.data.alias);
    addQuery(router, "restaurant", restaurant?.data?.alias);
    // router.history.push(`/${pageUrl}?restaurant=${restaurant.data.alias}`);
}

function delay(time) {
    return new Promise((res) => setTimeout(res, time));
}

function getDailyDataKey(sectionName, dayLabel, type, restaurantAlias, count) {
    count = typeof count === "number" ? `__${JSON.stringify(count)}` : "";
    return `daily-data/${sectionName}/${dayLabel}__${sectionName}__${type}__${restaurantAlias}${count}.json`;
}

function extractCount(countPart) {
    const countPartPattern = /^__([0-9]*)/;

    if (!countPartPattern.test(countPart)) {
        console.log("No count attached to the key!");
        return -1;
    }

    const countPartNr = countPart.match(countPartPattern)[1];

    if (!countPartNr || countPartNr === "") {
        return -1;
    }

    return parseInt(countPartNr);
}

function getNextDailyDataKey(dailyDataKey) {
    const dailyDataKeyPattern = /^daily-data\/(.*)\/(.*)__(.*)__(.*)__(.*)(__[0-9]*).json/;

    if (!dailyDataKeyPattern.test(dailyDataKey)) {
        console.error("Invalid daily data key provided!", dailyDataKey);
        return [];
    }

    const keyParts = dailyDataKey.match(dailyDataKeyPattern);

    const sectionName = keyParts[1];
    const dayLabel = keyParts[2];
    const saleType = keyParts[4];
    const restaurantAlias = keyParts[5];
    const count = extractCount(keyParts[6]) + 1;

    return getDailyDataKey(sectionName, dayLabel, saleType, restaurantAlias, count);
}

// TODO: transfer thsi inside on-hand
async function buildMissingInventoriesMeta(
    restaurants,
    currentMetaItems,
    metaResource,
    day,
    userName,
    sectionName,
    createPromise
) {
    if (!metaResource) {
        // TODO: test if this is a reliable approach for those that do not use "daily meta"
        return [];
    }

    const idsOfRestaurantsWithMeta = Object.values(currentMetaItems).map(
        (m) => m.data.restaurantId
    );
    const restaurantsWithoutMeta = restaurants.filter(
        ({ data: { id } }) => !idsOfRestaurantsWithMeta.includes(id)
    );
    const createMetaPromises = restaurantsWithoutMeta
        .map(createPromise.bind(null, metaResource, day, userName, sectionName))
        .flat();
    const missingMetaItems = await seriesPromises(createMetaPromises);

    return missingMetaItems.map((inventory) => new metaResource(inventory)) || [];
}

function createMetaInventoryPromise(metaResource, day, userName, sectionName, restaurant) {
    return [
        metaResource.getModel().api.create({
            day: day.data.time,
            restaurantId: restaurant.data.id,
            status: "UNLOCKED",
            storageKey: AppUtilsService.getDailyDataKey(
                sectionName,
                AppDayModel.getDayLabel(day),
                "OnHand",
                restaurant.data.alias
            ),
            completion: 0,
            lastUpdatedAt: AppDayModel.getCurrentUnixTime(),
            lastUpdatedBy: userName,
        }),
    ];
}

function buildSalesStorageKey(sectionName, day, saleType, restaurantAlias, count) {
    return AppUtilsService.getDailyDataKey(
        sectionName,
        AppDayModel.getDayLabel(day),
        `Sales_${saleType}`,
        restaurantAlias,
        count
    );
}

function createMetaSalePromiseType(metaResource, day, userName, sectionName, restaurant, saleType) {
    const storageKey = buildSalesStorageKey(sectionName, day, saleType, restaurant.data.alias, 0);

    return metaResource.getModel().api.create({
        day: day.data.time,
        restaurantId: restaurant.data.id,
        status: "UNLOCKED",
        saleType,
        storageKey,
        completion: 0,
        lastUpdatedAt: AppDayModel.getCurrentUnixTime(),
        lastUpdatedBy: userName,
    });
}

async function updateMetaSalePromiseType(metaResourceModel, item) {
    item.storageKey = getNextDailyDataKey(item.storageKey);
    return await metaResourceModel.api.update(item);
}

function createMetaSalesPromise(metaResource, day, userName, sectionName, restaurant) {
    return [
        createMetaSalePromiseType(metaResource, day, userName, sectionName, restaurant, "SINGLE"),
        createMetaSalePromiseType(metaResource, day, userName, sectionName, restaurant, "MULTIPLE"),
    ];
}

function getEnvName(auth) {
    return auth?._config?.aws_user_files_s3_bucket?.split("-")?.[1];
}

function print(pageIdentifier = "") {
    const appIdentifier = "OCMD App";
    const dateIdentifier = AppDayModel.getTimeDisplay(
        AppDayModel.getTodayUnixTime(),
        "Do of MMM YYYY"
    );
    document.title = `${appIdentifier} _ ${pageIdentifier} _ ${dateIdentifier}`;
    return window.print();
}

function addQuery(router, key, value) {
    let pathname = router.location.pathname;
    // returns path: '/app/books'
    let searchParams = new URLSearchParams(router.location.search);
    // returns the existing query string: '?type=foo&author=bar'
    searchParams.set(key, value);
    router.history.push({
        pathname: pathname,
        search: searchParams.toString(),
    });
}

function removeQuery(router, key) {
    let pathname = router.location.pathname;
    // returns path: '/app/foo'
    let searchParams = new URLSearchParams(router.location.search);
    // returns the existing query string: '?type=foo&author=bar'
    searchParams.delete(key);
    this.props.history.push({
        pathname: pathname,
        search: searchParams.toString(),
    });
}

const AppUtilsService = {
    groupDataByItem,
    groupDataByList,
    loadRestaurant,
    setRestaurant,
    delay,
    getDailyDataKey,
    buildMissingInventoriesMeta,
    createMetaInventoryPromise,
    updateMetaSalePromiseType,
    createMetaSalePromiseType,
    createMetaSalesPromise,
    getEnvName,
    print,
    addQuery,
    removeQuery,
};

export default AppUtilsService;
