function buildSubject({ type, userName, date, priority }) {
    return `New ${type} reported by "${userName}" @${type} ${priority} ${date}`;
}

function buildBody({ details, day, location }) {
    const detailsHTML = `[Details]<br />${details} <br /><br />`;
    const dayHTML = `[Day]<br />${day} <br /><br />`;
    const locationHTML = `[Location]<br /> ${location} <br /><br />`;

    return `${detailsHTML} ${dayHTML} ${locationHTML}`;
}

function buildEmailFeedback(feedback) {
    const { type, priority, date, details, userName, day, sentAt, location } = feedback;

    const subject = buildSubject({ type, userName, date, priority });
    const bodyHTML = buildBody({ details, day, location });

    const email = {
        subject,
        bodyHTML,
        to: ["marian.morosac@gmail.com"],
        bcc: [
            "OCMDApp <add.task.20125503.2280344951.YjMwNTgxYTJWwRZMQ6WLFHfnQbUfcj-9@todoist.net>",
        ],
        sentAt,
        replyTo: ["marian.morosac@gmail.com", "sb86117@gmail.com"],
        sender: "feedback@ocmdapp.com",
    };

    return email;
}

export { buildEmailFeedback };
