import { OffSiteContextStateType } from "./OffSiteContextTypes";

const OffSiteContextInit: OffSiteContextStateType = {
    offSiteTasksByStatusAndIdAndRest: null,
    // Available tasks must have the restaurants in this array (if any)
    destinationRestaurants: [], // TODO: make these "byId"
    // TODO: build these from destinationRestaurants
    restaurantsFilter: {
        0: { isVisible: true, data: { id: 0, alias: "COM" } },
        1: { isVisible: true, data: { id: 1, alias: "LIZ" } },
        2: { isVisible: true, data: { id: 2, alias: "FRD" } },
    },
    taskSelectedKey: undefined,
    showDeliveryTasksDialog: false,
};

export default OffSiteContextInit;
