import React, { useContext } from "react";
import DataEditor from "../../data-editor/DataEditor";
import { DataEditorProvider } from "../../data-editor/context/DataEditorContext";
import {
    AppTShirt,
    AppTShirtInventory,
    AppTShirtInventoryMeta,
    AppTShirtSale,
    AppTShirtSaleMeta,
    AppTShirtSize,
    AppTShirtStyle,
    AppTShirtTransaction,
} from "../TShirtsResources";
import { AppRestaurant } from "../../admin/AdminResources";
import { DataEditorResource } from "../../data-editor/types/DataEditorTypes";
import { AppContext } from "../../../context/AppContext";
import AppUserGroups from "../../../services/AppUserGroups";

const DataEditorTShirts = (props: any) => {
    const { appState } = useContext(AppContext);
    const { history, location } = props;

    let resources: DataEditorResource[];

    const adminResources = [
        new DataEditorResource(AppTShirt, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppTShirtStyle, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppTShirtSize, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppTShirtInventory, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppTShirtInventoryMeta, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppTShirtSale, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppTShirtSaleMeta, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppTShirtTransaction, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppRestaurant, { isVisibleInDataEditor: false }),
    ];

    const nonAdminResources: DataEditorResource[] = [];

    if (AppUserGroups.hasAdminAccess(appState.user.groups)) {
        resources = adminResources;
    } else {
        resources = nonAdminResources;
    }

    return (
        <DataEditorProvider resources={resources} location={location} history={history}>
            <DataEditor location={location} />
        </DataEditorProvider>
    );
};

export default DataEditorTShirts;
