import { useContext, useEffect } from "react";
import { AppContext } from "../../../../context/AppContext";
import { OnHandContext } from "../../../on-hand/context/OnHandContext";
import { OffSiteContext } from "../../context/OffSiteContext";
import OffSiteTasksUtils from "../../tasks/OffSiteTasksUtils";
import OffSiteDeliveryItemInput from "./input/OffSiteDeliveryItemInput";
import styles from "./OffSiteDeliveryItem.module.css";
import OffSiteDeliveryItemActions from "./OffSiteDeliveryItemActions";

export default function OffSiteDeliveryItem() {
    const { appState } = useContext(AppContext);
    const { offSiteState } = useContext(OffSiteContext);
    const { onHandState, onSelectedPendingDeliveryItemQuantityUpdate } = useContext(
        OnHandContext
    ) as any;

    const key = `on-hand-off-site-delivery-item-${onHandState.restaurant?.item?.data?.id}`;

    useEffect(() => {
        if (typeof onHandState.restaurant.group.item.deliveryQuantity !== "number") {
            if (offSiteState.offSiteTasksByStatusAndIdAndRest) {
                const defaultRequiredValue =
                    OffSiteTasksUtils.getDefaultPendingAmountForSelectedItem(
                        offSiteState.offSiteTasksByStatusAndIdAndRest,
                        onHandState.restaurant.group.item
                    );
                onSelectedPendingDeliveryItemQuantityUpdate(
                    defaultRequiredValue,
                    appState.user.name
                );
            }
        }
    }, [
        appState.user.name,
        offSiteState.offSiteTasksByStatusAndIdAndRest,
        onHandState.restaurant.group.item,
        onSelectedPendingDeliveryItemQuantityUpdate,
    ]);

    return (
        <div key={key} className={styles.Main}>
            <OffSiteDeliveryItemInput />
            <OffSiteDeliveryItemActions />
        </div>
    );
}
