import AppDayModel from "../../../../components/day/AppDayModel";
import AppEmailService from "../../../../services/AppEmailService";
import AppEmail from "../../../../types/AppEmail";
import DeliveryOrdersUtils from "../DeliveryOrdersUtils";

class DeliveryOrdersEmail extends AppEmail {
    static getEmailSubject(vendor, restaurantName, day) {
        const vendorName = vendor?.data?.name;
        const dayDisplay = AppDayModel.getTimeDisplay(day.data.time, "ddd, MMM Do");
        return `${restaurantName} Order to ${vendorName} [${dayDisplay}]`;
    }

    static getEmailSubjectPreview(vendor, restaurantName, day) {
        return DeliveryOrdersEmail.getEmailSubject(vendor, restaurantName, day);
    }

    static getHTMLPreview(vendor) {
        const {
            notes,
            deliveryOrders,
            addedOrders,
            data: { name },
        } = vendor;
        const cellStyle = "padding: 4px 8px";

        const buildRow = (name, deliveryDisplay) => {
            const buildCell = (data) => {
                return `
                    <td style="${cellStyle}">${data}</td>
                `;
            };

            return `
                <tr>
                    ${buildCell(name)}
                    ${buildCell(deliveryDisplay)}
                </tr>
            `;
        };

        // TODO: persist the orders that need delivery first time they are computed (use flag)
        const body = DeliveryOrdersUtils.extractNonEmptyOrders(vendor)
            .sort(DeliveryOrdersUtils.sortOrders)
            .map((order) => {
                const orderName = order.data.name;
                const orderDeliveryDisplay = DeliveryOrdersUtils.extractDeliveryDisplay(order);
                return buildRow(orderName, orderDeliveryDisplay);
            })
            .reduce((a, c) => (a += c), "");

        return `
            <html>
                <body>
                    <div>
                        <h3>
                            <strong>Notes to ${name}</strong>
                        </h3>
                        <p>${notes}</p>
                    </div>
                    <br/>
                    <table border="1">
                        <tr>
                            <th style="${cellStyle}"><h4><strong>Item</strong></h4></th>
                            <th style="${cellStyle}"><h4><strong>Delivery</strong></h4></th>
                        </tr>
                        ${body}
                    </table>
                    <br/><br/>
                </body>
            </html>
        `;
    }

    async send(userName, restaurant, vendor) {
        const to = vendor?.data?.email;
        if (!to) {
            alert("Missing delivery address!");
            return;
        }
        // subject is already defined
        this.to = to.split(/,| /).filter(AppEmailService.validateEmail);
        // TODO: insert input validation in Data Editor
        const bcc =
            vendor?.data?.emailBcc?.split(/,| /).filter(AppEmailService.validateEmail) || [];
        // TODO: insert input validation in Data Editor
        this.bcc = ["orders@ocmdapp.com", ...bcc];
        this.sentAt = new Date().getTime();
        this.sender = "orders@ocmdapp.com";
        this.bodyHTML = DeliveryOrdersEmail.getHTMLPreview(vendor);

        let response;

        try {
            response = await AppEmailService.sendEmail(this);
            vendor.emailSent = true;
            response = JSON.parse(response);
            if (response.code !== 200) {
                throw Error(response.body);
            }

            // create transactions for off-site locations
            // TODO: make sure all items are requested accordingly
            // TODO: move the request to create all transactions inside a lambda
            if (DeliveryOrdersUtils.isOffSiteVendor(vendor)) {
                await DeliveryOrdersUtils.createOrdersOffSiteTransactions(
                    userName,
                    restaurant,
                    vendor,
                    DeliveryOrdersUtils.extractNonEmptyOrders(vendor)
                );
            }
        } catch (e) {
            console.log("Error from sending email: ", JSON.stringify(e, null, 2));
            alert("Email sending failed!");
        }

        return response;
    }
}

export { DeliveryOrdersEmail };
