import { AppRestaurantTypes } from "../../../admin/AdminResources";
import DeliveryFoodModeUtils from "../services/DeliveryFoodModeUtils";
import { DeliveryFoodModeRootData } from "../types/DeliveryFoodModeTypes";
import { DeliveryFoodIngredient } from "../types/DeliveryFoodTypes";
import DeliveryFoodMode from "./DeliveryFoodMode";

export class DeliveryFoodPrepMode extends DeliveryFoodMode {
    ingredientsFilter(mode: DeliveryFoodMode, ingredient: DeliveryFoodIngredient, index: number) {
        const { dataForDelivery, results } = ingredient;

        if (!dataForDelivery || !results) {
            return false;
        }

        const hasPrepValues: boolean = (ingredient.results.COM?.prep.total || 0) > 0;

        if (hasPrepValues) {
            console.info(`Selected ${ingredient.data.name} for prep!`);
        }

        return hasPrepValues;
    }

    constructor(rootData: DeliveryFoodModeRootData) {
        super(rootData);
        this.label = "Prep";
        const { restaurants } = rootData;
        this.display = DeliveryFoodModeUtils.buildPrepModeDisplay(restaurants);
    }
}
