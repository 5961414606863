import { useContext, useEffect } from "react";
import { ReactSortable } from "react-sortablejs";
import { Resource } from "../../../../resources/Resource";
import { DataEditorContext } from "../../context/DataEditorContext";
import dataEditorStyles from "../../DataEditor.module.css";
import { DataEditorSortableItem } from "../../types/DataEditorTypes";
import DataEditorUtils from "../../utils/DataEditorUtils";
import DataEditorItemWrp from "./item/DataEditorItemWrp";

function buildSortableItem(item: Resource, index: number): DataEditorSortableItem {
    return {
        id: index,
        name: "" + index,
        item,
    };
}

const DataEditorItems = () => {
    const { dataEditorState, reOrderSelectedItems } = useContext(DataEditorContext);

    useEffect(() => {}, [dataEditorState.store.resources.selected, dataEditorState.store.items]);

    // TODO: study sortable-js
    const handleOrderUpdate = (
        items: { id: string | number }[],
        pageStartIndex: number,
        pageEndIndex: number,
        args: any
    ) => {
        const oldIds = items
            .map(({ id }: { id: string | number }) => +id + pageStartIndex)
            .join(",");
        const newIds = args
            .map(({ id }: { id: string | number }) => +id + pageStartIndex)
            .join(",");

        if (oldIds !== newIds) {
            reOrderSelectedItems(args, pageStartIndex, pageEndIndex);
        }
    };

    // do not sort resources that don't have an order
    const hideHandle = dataEditorState.getSelectedResource()
        ? !DataEditorUtils.containsOrderColumn(
              dataEditorState.getSelectedResource().getModel().getColumns()
          )
        : false;

    const storeKey = dataEditorState.getSelectedResource().getModel().getResourceStoreKey();
    let resourceItems = dataEditorState.getItemsByStoreKey(storeKey) || [];

    if (dataEditorState.display.searchText[storeKey]) {
        resourceItems = DataEditorUtils.searchSelectedResourceItems(dataEditorState);
    }

    const [pageStartIndex, pageEndIndex] = DataEditorUtils.getPageSliceIndexes(
        resourceItems,
        dataEditorState.getSelectedResource()
    );

    resourceItems = DataEditorUtils.getPageSlice(
        resourceItems,
        dataEditorState.getSelectedResource()
    );

    // TODO: disable sort-ability if items are filtered
    const sortableResourceItems = resourceItems.map(buildSortableItem);

    return (
        <ReactSortable
            multiDrag
            className={dataEditorStyles.ContentItems}
            list={sortableResourceItems}
            direction={"vertical"}
            handle={".handle"}
            animation={100}
            setList={handleOrderUpdate.bind(
                null,
                sortableResourceItems,
                pageStartIndex,
                pageEndIndex
            )}
        >
            {resourceItems.map((item: Resource, index) => {
                return <DataEditorItemWrp item={item} index={index} hideHandle={hideHandle} />;
            })}
        </ReactSortable>
    );
};

export default DataEditorItems;
