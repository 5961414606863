import React, { createContext, useContext, useState } from "react";

const GlobalSpinnerContext = createContext();
const GlobalSpinnerActionsContext = createContext();

/* tslint-disable */
/* eslint-disable */
const useContextFactory = (name, context) => {
    return () => {
        const ctx = useContext(context);
        if (ctx === undefined) {
            throw new Error(`use${name}Context must be used withing a ${name}ContextProvider.`);
        }
        return ctx;
    };
};
/* eslint-enable */
/* tslint-enable */

// eslint-disable-next-line react-hooks/rules-of-hooks
export const useGlobalSpinnerContext = useContextFactory(
    "GlobalSpinnerContext",
    GlobalSpinnerContext
);
// eslint-disable-next-line react-hooks/rules-of-hooks
export const useGlobalSpinnerActionsContext = useContextFactory(
    "GlobalSpinnerActionsContext",
    GlobalSpinnerActionsContext
);

const GlobalSpinnerContextProvider = (props) => {
    const [isGlobalSpinnerOn, setGlobalSpinner] = useState(false);

    return (
        <GlobalSpinnerContext.Provider value={isGlobalSpinnerOn}>
            <GlobalSpinnerActionsContext.Provider value={setGlobalSpinner}>
                {props.children}
            </GlobalSpinnerActionsContext.Provider>
        </GlobalSpinnerContext.Provider>
    );
};

export default GlobalSpinnerContextProvider;
