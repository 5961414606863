import { Button } from "@material-ui/core";
import React, { useContext } from "react";
import { useGlobalSpinnerActionsContext } from "../../../../components/global-spinner/GlobalSpinnerContext";
import { DeliveryOrdersContext } from "../context/DeliveryOrdersContext";

const DeliveryOrdersEmailDialogSend = () => {
    const setGlobalSpinner = useGlobalSpinnerActionsContext();
    const { deliveryOrders, sendEmail } = useContext(DeliveryOrdersContext);

    const sendEmailHandler = () => {
        return sendEmail(
            deliveryOrders.restaurant.emailVendor,
            deliveryOrders.restaurant,
            setGlobalSpinner
        );
    };

    const hasOrders =
        deliveryOrders.restaurant.emailVendor?.deliveryOrders?.length > 0 ||
        deliveryOrders.restaurant.emailVendor?.addedOrders?.length > 0;

    return deliveryOrders.restaurant.emailVendor?.emailSent ? (
        <Button onClick={sendEmailHandler} disabled={!hasOrders} color="primary" variant="outlined">
            Re-Send
        </Button>
    ) : (
        <Button
            onClick={sendEmailHandler}
            disabled={!hasOrders}
            color="secondary"
            variant="contained"
        >
            Send
        </Button>
    );
};

export default DeliveryOrdersEmailDialogSend;
