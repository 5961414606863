/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getRestaurant = /* GraphQL */ `
  query GetRestaurant($id: ID!, $name: String!) {
    getRestaurant(id: $id, name: $name) {
      id
      name
      alias
      usersGroup
      ratio
      offSeason
      open
      type
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listRestaurants = /* GraphQL */ `
  query ListRestaurants(
    $id: ID
    $name: ModelStringKeyConditionInput
    $filter: ModelRestaurantFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRestaurants(
      id: $id
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        alias
        usersGroup
        ratio
        offSeason
        open
        type
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDpsEmailRecipient = /* GraphQL */ `
  query GetDpsEmailRecipient($name: String!, $restaurantId: ID!) {
    getDpsEmailRecipient(name: $name, restaurantId: $restaurantId) {
      name
      restaurantId
      email
      isSubscribed
      createdAt
      updatedAt
    }
  }
`;
export const listDpsEmailRecipients = /* GraphQL */ `
  query ListDpsEmailRecipients(
    $name: String
    $restaurantId: ModelIDKeyConditionInput
    $filter: ModelDpsEmailRecipientFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDpsEmailRecipients(
      name: $name
      restaurantId: $restaurantId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        restaurantId
        email
        isSubscribed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDpsEmail = /* GraphQL */ `
  query GetDpsEmail($sentAt: Int!, $restaurantId: ID!, $sentBy: String!) {
    getDpsEmail(sentAt: $sentAt, restaurantId: $restaurantId, sentBy: $sentBy) {
      sentAt
      restaurantId
      sentBy
      recipients
      subject
      body
      configs
      createdAt
      updatedAt
    }
  }
`;
export const listDpsEmails = /* GraphQL */ `
  query ListDpsEmails(
    $sentAt: Int
    $restaurantIdSentBy: ModelDpsEmailPrimaryCompositeKeyConditionInput
    $filter: ModelDpsEmailFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDpsEmails(
      sentAt: $sentAt
      restaurantIdSentBy: $restaurantIdSentBy
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        sentAt
        restaurantId
        sentBy
        recipients
        subject
        body
        configs
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDpsPack = /* GraphQL */ `
  query GetDpsPack($id: ID!, $name: String!) {
    getDpsPack(id: $id, name: $name) {
      id
      name
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listDpsPacks = /* GraphQL */ `
  query ListDpsPacks(
    $id: ID
    $name: ModelStringKeyConditionInput
    $filter: ModelDpsPackFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDpsPacks(
      id: $id
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        order
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDpsIngredient = /* GraphQL */ `
  query GetDpsIngredient($id: ID!, $name: String!) {
    getDpsIngredient(id: $id, name: $name) {
      id
      name
      dpsPackId
      dpsPackSize
      subDpsPackId
      subDpsPackSize
      subSubDpsPackId
      useSubPack
      ratio
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listDpsIngredients = /* GraphQL */ `
  query ListDpsIngredients(
    $id: ID
    $name: ModelStringKeyConditionInput
    $filter: ModelDpsIngredientFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDpsIngredients(
      id: $id
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        dpsPackId
        dpsPackSize
        subDpsPackId
        subDpsPackSize
        subSubDpsPackId
        useSubPack
        ratio
        order
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDpsMenuItem = /* GraphQL */ `
  query GetDpsMenuItem($id: ID!, $name: String!, $restaurantId: ID!) {
    getDpsMenuItem(id: $id, name: $name, restaurantId: $restaurantId) {
      id
      name
      restaurantId
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listDpsMenuItems = /* GraphQL */ `
  query ListDpsMenuItems(
    $id: ID
    $nameRestaurantId: ModelDpsMenuItemPrimaryCompositeKeyConditionInput
    $filter: ModelDpsMenuItemFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDpsMenuItems(
      id: $id
      nameRestaurantId: $nameRestaurantId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        restaurantId
        order
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDpsMenuItemSale = /* GraphQL */ `
  query GetDpsMenuItemSale(
    $day: Int!
    $dpsMenuItemId: ID!
    $type: DpsSaleType!
  ) {
    getDpsMenuItemSale(day: $day, dpsMenuItemId: $dpsMenuItemId, type: $type) {
      dpsMenuItemId
      day
      type
      quantity
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listDpsMenuItemSales = /* GraphQL */ `
  query ListDpsMenuItemSales(
    $day: Int
    $dpsMenuItemIdType: ModelDpsMenuItemSalePrimaryCompositeKeyConditionInput
    $filter: ModelDpsMenuItemSaleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDpsMenuItemSales(
      day: $day
      dpsMenuItemIdType: $dpsMenuItemIdType
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        dpsMenuItemId
        day
        type
        quantity
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDpsMenuItemSaleMeta = /* GraphQL */ `
  query GetDpsMenuItemSaleMeta(
    $day: Int!
    $restaurantId: ID!
    $saleType: String!
  ) {
    getDpsMenuItemSaleMeta(
      day: $day
      restaurantId: $restaurantId
      saleType: $saleType
    ) {
      day
      restaurantId
      saleType
      storageKey
      status
      completion
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listDpsMenuItemSaleMetas = /* GraphQL */ `
  query ListDpsMenuItemSaleMetas(
    $day: Int
    $restaurantIdSaleType: ModelDpsMenuItemSaleMetaPrimaryCompositeKeyConditionInput
    $filter: ModelDpsMenuItemSaleMetaFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDpsMenuItemSaleMetas(
      day: $day
      restaurantIdSaleType: $restaurantIdSaleType
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        day
        restaurantId
        saleType
        storageKey
        status
        completion
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDpsRecipeItem = /* GraphQL */ `
  query GetDpsRecipeItem($dpsMenuItemId: ID!, $dpsIngredientId: ID!) {
    getDpsRecipeItem(
      dpsMenuItemId: $dpsMenuItemId
      dpsIngredientId: $dpsIngredientId
    ) {
      dpsMenuItemId
      dpsIngredientId
      quantity
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listDpsRecipeItems = /* GraphQL */ `
  query ListDpsRecipeItems(
    $dpsMenuItemId: ID
    $dpsIngredientId: ModelIDKeyConditionInput
    $filter: ModelDpsRecipeItemFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDpsRecipeItems(
      dpsMenuItemId: $dpsMenuItemId
      dpsIngredientId: $dpsIngredientId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        dpsMenuItemId
        dpsIngredientId
        quantity
        order
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getIngredientPack = /* GraphQL */ `
  query GetIngredientPack($id: ID!, $display: String!) {
    getIngredientPack(id: $id, display: $display) {
      id
      display
      name
      alias
      size
      order
      portionRatio
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listIngredientPacks = /* GraphQL */ `
  query ListIngredientPacks(
    $id: ID
    $display: ModelStringKeyConditionInput
    $filter: ModelIngredientPackFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listIngredientPacks(
      id: $id
      display: $display
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        display
        name
        alias
        size
        order
        portionRatio
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getIngredient = /* GraphQL */ `
  query GetIngredient($id: ID!, $name: String!) {
    getIngredient(id: $id, name: $name) {
      id
      name
      ingredientPackId
      salesRatio
      laborTime
      batchSize
      portionTime
      order
      onHandAlias
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listIngredients = /* GraphQL */ `
  query ListIngredients(
    $id: ID
    $name: ModelStringKeyConditionInput
    $filter: ModelIngredientFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listIngredients(
      id: $id
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        ingredientPackId
        salesRatio
        laborTime
        batchSize
        portionTime
        order
        onHandAlias
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getIngredientInventory = /* GraphQL */ `
  query GetIngredientInventory($day: Int!, $ingredientOnHandAreaId: ID!) {
    getIngredientInventory(
      day: $day
      ingredientOnHandAreaId: $ingredientOnHandAreaId
    ) {
      ingredientOnHandAreaId
      day
      quantity
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listIngredientInventorys = /* GraphQL */ `
  query ListIngredientInventorys(
    $day: Int
    $ingredientOnHandAreaId: ModelIDKeyConditionInput
    $filter: ModelIngredientInventoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listIngredientInventorys(
      day: $day
      ingredientOnHandAreaId: $ingredientOnHandAreaId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ingredientOnHandAreaId
        day
        quantity
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getIngredientInventoryMeta = /* GraphQL */ `
  query GetIngredientInventoryMeta($day: Int!, $restaurantId: ID!) {
    getIngredientInventoryMeta(day: $day, restaurantId: $restaurantId) {
      day
      restaurantId
      storageKey
      status
      completion
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listIngredientInventoryMetas = /* GraphQL */ `
  query ListIngredientInventoryMetas(
    $day: Int
    $restaurantId: ModelIDKeyConditionInput
    $filter: ModelIngredientInventoryMetaFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listIngredientInventoryMetas(
      day: $day
      restaurantId: $restaurantId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        day
        restaurantId
        storageKey
        status
        completion
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getIngredientOnHandArea = /* GraphQL */ `
  query GetIngredientOnHandArea(
    $id: ID!
    $ingredientId: ID!
    $onHandAreaId: ID!
  ) {
    getIngredientOnHandArea(
      id: $id
      ingredientId: $ingredientId
      onHandAreaId: $onHandAreaId
    ) {
      id
      ingredientId
      onHandAreaId
      bulk
      portionRatio
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listIngredientOnHandAreas = /* GraphQL */ `
  query ListIngredientOnHandAreas(
    $id: ID
    $ingredientIdOnHandAreaId: ModelIngredientOnHandAreaPrimaryCompositeKeyConditionInput
    $filter: ModelIngredientOnHandAreaFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listIngredientOnHandAreas(
      id: $id
      ingredientIdOnHandAreaId: $ingredientIdOnHandAreaId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        ingredientId
        onHandAreaId
        bulk
        portionRatio
        order
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getIngredientDeliveryArea = /* GraphQL */ `
  query GetIngredientDeliveryArea(
    $id: ID!
    $ingredientId: ID!
    $deliveryAreaId: ID!
  ) {
    getIngredientDeliveryArea(
      id: $id
      ingredientId: $ingredientId
      deliveryAreaId: $deliveryAreaId
    ) {
      id
      ingredientId
      deliveryAreaId
      fixedNeed
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listIngredientDeliveryAreas = /* GraphQL */ `
  query ListIngredientDeliveryAreas(
    $id: ID
    $ingredientIdDeliveryAreaId: ModelIngredientDeliveryAreaPrimaryCompositeKeyConditionInput
    $filter: ModelIngredientDeliveryAreaFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listIngredientDeliveryAreas(
      id: $id
      ingredientIdDeliveryAreaId: $ingredientIdDeliveryAreaId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        ingredientId
        deliveryAreaId
        fixedNeed
        order
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getIngredientTransaction = /* GraphQL */ `
  query GetIngredientTransaction($ingredientId: ID!, $createdAt: Int!) {
    getIngredientTransaction(
      ingredientId: $ingredientId
      createdAt: $createdAt
    ) {
      ingredientId
      createdAt
      type
      restaurantId
      quantity
      createdBy
      updatedAt
    }
  }
`;
export const listIngredientTransactions = /* GraphQL */ `
  query ListIngredientTransactions(
    $ingredientId: ID
    $createdAt: ModelIntKeyConditionInput
    $filter: ModelIngredientTransactionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listIngredientTransactions(
      ingredientId: $ingredientId
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ingredientId
        createdAt
        type
        restaurantId
        quantity
        createdBy
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMenuItem = /* GraphQL */ `
  query GetMenuItem($id: ID!, $name: String!, $restaurantId: ID!) {
    getMenuItem(id: $id, name: $name, restaurantId: $restaurantId) {
      id
      name
      restaurantId
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listMenuItems = /* GraphQL */ `
  query ListMenuItems(
    $id: ID
    $nameRestaurantId: ModelMenuItemPrimaryCompositeKeyConditionInput
    $filter: ModelMenuItemFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMenuItems(
      id: $id
      nameRestaurantId: $nameRestaurantId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        restaurantId
        order
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRecipeItem = /* GraphQL */ `
  query GetRecipeItem($menuItemId: ID!, $ingredientId: ID!) {
    getRecipeItem(menuItemId: $menuItemId, ingredientId: $ingredientId) {
      menuItemId
      ingredientId
      quantity
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listRecipeItems = /* GraphQL */ `
  query ListRecipeItems(
    $menuItemId: ID
    $ingredientId: ModelIDKeyConditionInput
    $filter: ModelRecipeItemFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRecipeItems(
      menuItemId: $menuItemId
      ingredientId: $ingredientId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        menuItemId
        ingredientId
        quantity
        order
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMenuItemSale = /* GraphQL */ `
  query GetMenuItemSale($day: Int!, $menuItemId: ID!, $type: FoodSaleType!) {
    getMenuItemSale(day: $day, menuItemId: $menuItemId, type: $type) {
      menuItemId
      day
      type
      quantity
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listMenuItemSales = /* GraphQL */ `
  query ListMenuItemSales(
    $day: Int
    $menuItemIdType: ModelMenuItemSalePrimaryCompositeKeyConditionInput
    $filter: ModelMenuItemSaleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMenuItemSales(
      day: $day
      menuItemIdType: $menuItemIdType
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        menuItemId
        day
        type
        quantity
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMenuItemSaleMeta = /* GraphQL */ `
  query GetMenuItemSaleMeta(
    $day: Int!
    $restaurantId: ID!
    $saleType: String!
  ) {
    getMenuItemSaleMeta(
      day: $day
      restaurantId: $restaurantId
      saleType: $saleType
    ) {
      day
      restaurantId
      saleType
      storageKey
      status
      completion
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listMenuItemSaleMetas = /* GraphQL */ `
  query ListMenuItemSaleMetas(
    $day: Int
    $restaurantIdSaleType: ModelMenuItemSaleMetaPrimaryCompositeKeyConditionInput
    $filter: ModelMenuItemSaleMetaFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMenuItemSaleMetas(
      day: $day
      restaurantIdSaleType: $restaurantIdSaleType
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        day
        restaurantId
        saleType
        storageKey
        status
        completion
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDeliveryArea = /* GraphQL */ `
  query GetDeliveryArea($id: ID!, $restaurantId: ID!) {
    getDeliveryArea(id: $id, restaurantId: $restaurantId) {
      id
      name
      restaurantId
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listDeliveryAreas = /* GraphQL */ `
  query ListDeliveryAreas(
    $id: ID
    $restaurantId: ModelIDKeyConditionInput
    $filter: ModelDeliveryAreaFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDeliveryAreas(
      id: $id
      restaurantId: $restaurantId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        restaurantId
        order
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOnHandArea = /* GraphQL */ `
  query GetOnHandArea($id: ID!, $restaurantId: ID!) {
    getOnHandArea(id: $id, restaurantId: $restaurantId) {
      id
      name
      restaurantId
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listOnHandAreas = /* GraphQL */ `
  query ListOnHandAreas(
    $id: ID
    $restaurantId: ModelIDKeyConditionInput
    $filter: ModelOnHandAreaFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOnHandAreas(
      id: $id
      restaurantId: $restaurantId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        restaurantId
        order
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOffSiteItem = /* GraphQL */ `
  query GetOffSiteItem($id: ID!, $name: String!, $offSiteAreaId: ID!) {
    getOffSiteItem(id: $id, name: $name, offSiteAreaId: $offSiteAreaId) {
      id
      name
      offSiteAreaId
      offSiteUnitId
      conversion
      offSiteSubUnitId
      alertThreshold
      par
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listOffSiteItems = /* GraphQL */ `
  query ListOffSiteItems(
    $id: ID
    $nameOffSiteAreaId: ModelOffSiteItemPrimaryCompositeKeyConditionInput
    $filter: ModelOffSiteItemFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOffSiteItems(
      id: $id
      nameOffSiteAreaId: $nameOffSiteAreaId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        offSiteAreaId
        offSiteUnitId
        conversion
        offSiteSubUnitId
        alertThreshold
        par
        order
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOffSiteArea = /* GraphQL */ `
  query GetOffSiteArea($id: ID!, $restaurantId: ID!) {
    getOffSiteArea(id: $id, restaurantId: $restaurantId) {
      id
      restaurantId
      name
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listOffSiteAreas = /* GraphQL */ `
  query ListOffSiteAreas(
    $id: ID
    $restaurantId: ModelIDKeyConditionInput
    $filter: ModelOffSiteAreaFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOffSiteAreas(
      id: $id
      restaurantId: $restaurantId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        restaurantId
        name
        order
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOffSiteUnit = /* GraphQL */ `
  query GetOffSiteUnit($id: ID!, $name: String!) {
    getOffSiteUnit(id: $id, name: $name) {
      id
      name
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listOffSiteUnits = /* GraphQL */ `
  query ListOffSiteUnits(
    $id: ID
    $name: ModelStringKeyConditionInput
    $filter: ModelOffSiteUnitFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOffSiteUnits(
      id: $id
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        order
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOffSiteTransaction = /* GraphQL */ `
  query GetOffSiteTransaction(
    $offSiteItemId: ID!
    $type: OffSiteTransactionType!
    $createdAt: AWSDateTime!
  ) {
    getOffSiteTransaction(
      offSiteItemId: $offSiteItemId
      type: $type
      createdAt: $createdAt
    ) {
      offSiteItemId
      type
      createdAt
      status
      restaurantId
      quantity
      createdBy
      updatedAt
    }
  }
`;
export const listOffSiteTransactions = /* GraphQL */ `
  query ListOffSiteTransactions(
    $offSiteItemId: ID
    $typeCreatedAt: ModelOffSiteTransactionPrimaryCompositeKeyConditionInput
    $filter: ModelOffSiteTransactionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOffSiteTransactions(
      offSiteItemId: $offSiteItemId
      typeCreatedAt: $typeCreatedAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        offSiteItemId
        type
        createdAt
        status
        restaurantId
        quantity
        createdBy
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOffSiteAlert = /* GraphQL */ `
  query GetOffSiteAlert($offSiteItemId: ID!, $createdAt: AWSDateTime!) {
    getOffSiteAlert(offSiteItemId: $offSiteItemId, createdAt: $createdAt) {
      offSiteItemId
      createdAt
      status
      snoozedUntil
      message
      createdBy
      lastUpdatedBy
      lastUpdatedAt
      updatedAt
    }
  }
`;
export const listOffSiteAlerts = /* GraphQL */ `
  query ListOffSiteAlerts(
    $offSiteItemId: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelOffSiteAlertFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOffSiteAlerts(
      offSiteItemId: $offSiteItemId
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        offSiteItemId
        createdAt
        status
        snoozedUntil
        message
        createdBy
        lastUpdatedBy
        lastUpdatedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!, $orderAreaId: ID!) {
    getOrder(id: $id, orderAreaId: $orderAreaId) {
      id
      orderAreaId
      name
      orderOnHandUnitId
      orderDeliveryUnitId
      orderVendorId
      discount
      par
      conversion
      alertThreshold
      offSiteItemId
      stockLimit
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $id: ID
    $orderAreaId: ModelIDKeyConditionInput
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrders(
      id: $id
      orderAreaId: $orderAreaId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        orderAreaId
        name
        orderOnHandUnitId
        orderDeliveryUnitId
        orderVendorId
        discount
        par
        conversion
        alertThreshold
        offSiteItemId
        stockLimit
        order
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrderArea = /* GraphQL */ `
  query GetOrderArea($id: ID!, $restaurantId: ID!) {
    getOrderArea(id: $id, restaurantId: $restaurantId) {
      id
      restaurantId
      name
      order
      type
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listOrderAreas = /* GraphQL */ `
  query ListOrderAreas(
    $id: ID
    $restaurantId: ModelIDKeyConditionInput
    $filter: ModelOrderAreaFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrderAreas(
      id: $id
      restaurantId: $restaurantId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        restaurantId
        name
        order
        type
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrderUnit = /* GraphQL */ `
  query GetOrderUnit($id: ID!, $name: String!) {
    getOrderUnit(id: $id, name: $name) {
      id
      name
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listOrderUnits = /* GraphQL */ `
  query ListOrderUnits(
    $id: ID
    $name: ModelStringKeyConditionInput
    $filter: ModelOrderUnitFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrderUnits(
      id: $id
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        order
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrderVendor = /* GraphQL */ `
  query GetOrderVendor($id: ID!, $name: String!) {
    getOrderVendor(id: $id, name: $name) {
      id
      name
      email
      emailBcc
      isOffSite
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listOrderVendors = /* GraphQL */ `
  query ListOrderVendors(
    $id: ID
    $name: ModelStringKeyConditionInput
    $filter: ModelOrderVendorFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrderVendors(
      id: $id
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        email
        emailBcc
        isOffSite
        order
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrderInventory = /* GraphQL */ `
  query GetOrderInventory($day: Int!, $orderId: ID!) {
    getOrderInventory(day: $day, orderId: $orderId) {
      orderId
      day
      quantity
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listOrderInventorys = /* GraphQL */ `
  query ListOrderInventorys(
    $day: Int
    $orderId: ModelIDKeyConditionInput
    $filter: ModelOrderInventoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrderInventorys(
      day: $day
      orderId: $orderId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        orderId
        day
        quantity
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrderInventoryMeta = /* GraphQL */ `
  query GetOrderInventoryMeta($day: Int!, $restaurantId: ID!) {
    getOrderInventoryMeta(day: $day, restaurantId: $restaurantId) {
      day
      restaurantId
      storageKey
      status
      completion
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listOrderInventoryMetas = /* GraphQL */ `
  query ListOrderInventoryMetas(
    $day: Int
    $restaurantId: ModelIDKeyConditionInput
    $filter: ModelOrderInventoryMetaFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrderInventoryMetas(
      day: $day
      restaurantId: $restaurantId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        day
        restaurantId
        storageKey
        status
        completion
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTShirt = /* GraphQL */ `
  query GetTShirt($id: ID!, $restaurantId: ID!, $display: String!) {
    getTShirt(id: $id, restaurantId: $restaurantId, display: $display) {
      id
      restaurantId
      display
      name
      sizeId
      stockCapacity
      par
      packSize
      discount
      order
      styleId
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listTShirts = /* GraphQL */ `
  query ListTShirts(
    $id: ID
    $restaurantIdDisplay: ModelTShirtPrimaryCompositeKeyConditionInput
    $filter: ModelTShirtFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTShirts(
      id: $id
      restaurantIdDisplay: $restaurantIdDisplay
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        restaurantId
        display
        name
        sizeId
        stockCapacity
        par
        packSize
        discount
        order
        styleId
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTShirtSize = /* GraphQL */ `
  query GetTShirtSize($id: ID!, $name: String!) {
    getTShirtSize(id: $id, name: $name) {
      id
      name
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listTShirtSizes = /* GraphQL */ `
  query ListTShirtSizes(
    $id: ID
    $name: ModelStringKeyConditionInput
    $filter: ModelTShirtSizeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTShirtSizes(
      id: $id
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        order
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTShirtStyle = /* GraphQL */ `
  query GetTShirtStyle($id: ID!, $name: String!) {
    getTShirtStyle(id: $id, name: $name) {
      id
      name
      restaurantId
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listTShirtStyles = /* GraphQL */ `
  query ListTShirtStyles(
    $id: ID
    $name: ModelStringKeyConditionInput
    $filter: ModelTShirtStyleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTShirtStyles(
      id: $id
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        restaurantId
        order
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTShirtInventory = /* GraphQL */ `
  query GetTShirtInventory($day: Int!, $tShirtId: ID!) {
    getTShirtInventory(day: $day, tShirtId: $tShirtId) {
      tShirtId
      day
      quantity
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listTShirtInventorys = /* GraphQL */ `
  query ListTShirtInventorys(
    $day: Int
    $tShirtId: ModelIDKeyConditionInput
    $filter: ModelTShirtInventoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTShirtInventorys(
      day: $day
      tShirtId: $tShirtId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tShirtId
        day
        quantity
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTShirtInventoryMeta = /* GraphQL */ `
  query GetTShirtInventoryMeta($day: Int!, $restaurantId: ID!) {
    getTShirtInventoryMeta(day: $day, restaurantId: $restaurantId) {
      day
      restaurantId
      storageKey
      status
      completion
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listTShirtInventoryMetas = /* GraphQL */ `
  query ListTShirtInventoryMetas(
    $day: Int
    $restaurantId: ModelIDKeyConditionInput
    $filter: ModelTShirtInventoryMetaFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTShirtInventoryMetas(
      day: $day
      restaurantId: $restaurantId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        day
        restaurantId
        storageKey
        status
        completion
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTShirtSale = /* GraphQL */ `
  query GetTShirtSale($day: Int!, $tShirtId: ID!) {
    getTShirtSale(day: $day, tShirtId: $tShirtId) {
      tShirtId
      day
      quantity
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listTShirtSales = /* GraphQL */ `
  query ListTShirtSales(
    $day: Int
    $tShirtId: ModelIDKeyConditionInput
    $filter: ModelTShirtSaleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTShirtSales(
      day: $day
      tShirtId: $tShirtId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tShirtId
        day
        quantity
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTShirtSaleMeta = /* GraphQL */ `
  query GetTShirtSaleMeta($day: Int!, $restaurantId: ID!) {
    getTShirtSaleMeta(day: $day, restaurantId: $restaurantId) {
      day
      restaurantId
      storageKey
      status
      completion
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listTShirtSaleMetas = /* GraphQL */ `
  query ListTShirtSaleMetas(
    $day: Int
    $restaurantId: ModelIDKeyConditionInput
    $filter: ModelTShirtSaleMetaFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTShirtSaleMetas(
      day: $day
      restaurantId: $restaurantId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        day
        restaurantId
        storageKey
        status
        completion
        lastUpdatedBy
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTShirtTransaction = /* GraphQL */ `
  query GetTShirtTransaction($tShirtId: ID!, $createdAt: Int!) {
    getTShirtTransaction(tShirtId: $tShirtId, createdAt: $createdAt) {
      tShirtId
      createdAt
      type
      quantity
      createdBy
      updatedAt
    }
  }
`;
export const listTShirtTransactions = /* GraphQL */ `
  query ListTShirtTransactions(
    $tShirtId: ID
    $createdAt: ModelIntKeyConditionInput
    $filter: ModelTShirtTransactionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTShirtTransactions(
      tShirtId: $tShirtId
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tShirtId
        createdAt
        type
        quantity
        createdBy
        updatedAt
      }
      nextToken
    }
  }
`;
