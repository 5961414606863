import React, { useContext } from "react";
import { DeliveryOrdersContext } from "../context/DeliveryOrdersContext";
import deliveryOrdersStyles from "../DeliveryOrders.module.css";

export default function DeliveryOrdersEmailDialogSubject() {
    const { deliveryOrders } = useContext(DeliveryOrdersContext);

    return (
        <span className={deliveryOrdersStyles.DialogTitleText}>
            <strong>{deliveryOrders.restaurant.emailVendor.email.subject}</strong>
        </span>
    );
}
