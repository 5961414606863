import { ADMINS, DEVS, STORE_USERS } from "../../services/AppUserGroupsData";
import { SectionNames } from "../sections/SectionNames";
import { UserGroupType } from "./../../services/AppUserGroupsData";
import { ContainerConfig } from "./../ContainerConfig";
import { TShirtsContainerConfig } from "./TShirtsContainerConfig";
import { TShirtsSection } from "./TShirtsSection";

const tShirtsSectionPages: ContainerConfig[] = [
    TShirtsContainerConfig.OnHand,
    TShirtsContainerConfig.Delivery,
    TShirtsContainerConfig.DataEditor,
];

const tShirtsSectionAllowedUserGroups: UserGroupType[] = [STORE_USERS, ADMINS, DEVS];

const isDisabledTShirtsSection = false;

export const TShirtsSectionConfig = new TShirtsSection(
    tShirtsSectionPages,
    SectionNames.T_SHIRTS,
    tShirtsSectionAllowedUserGroups,
    isDisabledTShirtsSection
);
