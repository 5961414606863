import { retry, updateDailyData } from "../../../resources/ResourcesService";
import OnHandUtils from "../OnHandUtils";
import OnHandItemModel from "./OnHandItemModel";

export default class OnHandRestaurantModel {
    updateStatus(newStatus) {
        this.status = newStatus;
        return this;
    }

    selectGroup(newGroup) {
        const newGroupIndex = this.groups.findIndex(
            ({ data: { name } }) => name === newGroup.data.name
        );
        return this.selectGroupByIndex(newGroupIndex);
    }

    selectGroupByIndex(newGroupIndex) {
        if (
            typeof newGroupIndex !== "number" ||
            newGroupIndex < 0 ||
            newGroupIndex > this.groups.length
        ) {
            return;
        }
        this.selectedGroupIndex = newGroupIndex;
        this.group = this.groups[this.selectedGroupIndex];
        return this;
    }

    deselectGroup() {
        this.selectedGroupIndex = null;
        this.group = null;
        return this;
    }

    selectFirstIncompleteGroup() {
        const firstIncompleteGroupIndex = this.groups.findIndex((a) => a.status < 2);
        if (firstIncompleteGroupIndex === -1) {
            return this.deselectGroup();
        }
        // TODO: FETCH/CREATE GROUP's MISSING INVENTORY DATA
        return this.selectGroupByIndex(firstIncompleteGroupIndex);
    }

    goToPrevItem() {
        const isFirstItemInGroup = this.group.selectedItemIndex === 0;
        const isFirstGroup = this.selectedGroupIndex === 0;

        if (isFirstItemInGroup) {
            if (isFirstGroup) {
                return this.deselectGroup();
            }
            this.selectGroupByIndex(this.selectedGroupIndex - 1);
            this.group.selectItemByIndex(this.group.items.length - 1);
            return this;
        }
        this.group.selectItemByIndex(this.group.selectedItemIndex - 1);
        return this;
    }

    async confirmGroupItems(setGlobalSpinner) {
        setGlobalSpinner(true);

        // submit to S3 all inventory items, from all groups, that are not mocks
        const completedInventories = OnHandUtils.extractCompletedInventories(this.groups);

        try {
            await retry(async () => {
                await updateDailyData(completedInventories, this.inventoryMeta.data.storageKey);
            }, 1);

            // upon successful confirmation, clean the data
            this.group.showConfirmation = false;

            // clear the "dirty" status of "unconfirmed" items
            this.group.items = this.group.items.map((item) => {
                delete item.inventory.dirty;
                return item;
            });
        } catch (e) {
            console.error(e.message);
            alert("Please check your internet connection!");
        }
        setGlobalSpinner(false);

        return this;
    }

    rejectGroupItems() {
        this.group = this.group.rejectItems();
        return this;
    }

    checkGroupIsCompleted() {
        return this.group.items.every(OnHandItemModel.isCounted);
    }

    getNextItemIndex() {
        const selectedItemIndex = this.group.selectedItemIndex;
        const itemsBeforeSelectedItem = this.group.items.slice(0, selectedItemIndex);
        const itemsAfterSelectedItem = this.group.items.slice(selectedItemIndex + 1);
        const notSelectedItems = itemsAfterSelectedItem.concat(itemsBeforeSelectedItem);

        const itemsCount = this.group.items.length;
        const nextItemIndex =
            (selectedItemIndex +
                1 +
                notSelectedItems.findIndex((item) => !OnHandItemModel.isCounted(item))) %
            itemsCount;

        console.log(`Current item index: ${selectedItemIndex}, Next item index: ${nextItemIndex}`);

        return nextItemIndex;
    }

    goToNextItem() {
        if (!this.checkGroupIsCompleted()) {
            this.group.selectItemByIndex(this.getNextItemIndex());
            return this;
        }

        // if it is fully completed, ask for confirmation if it has dirty items (something changed)
        if (this.group.needsConfirmation()) {
            this.group.showConfirmation = true;
            return this;
        }

        // advance to next group, if possible
        if (this.selectedGroupIndex < this.groups.length - 1) {
            this.selectGroupByIndex(this.selectedGroupIndex + 1);
            this.group.selectItemByIndex(0);
            return this;
        }

        return this.deselectGroup();
    }

    constructor(appRestaurant, groups = [], sectionName) {
        Object.assign(this, appRestaurant);
        this.sectionName = sectionName;
        this.groups = groups.sort((a, b) => parseInt(a.data.order) - parseInt(b.data.order));
    }
}
