import React, { useContext } from "react";
import DataEditor from "../../data-editor/DataEditor";
import { DataEditorProvider } from "../../data-editor/context/DataEditorContext";
import {
    AppDeliveryArea,
    AppIngredient,
    AppIngredientDeliveryArea,
    AppIngredientInventory,
    AppIngredientInventoryMeta,
    AppIngredientOnHandArea,
    AppIngredientPack,
    AppMenuItem,
    AppMenuItemSale,
    AppMenuItemSaleMeta,
    AppOnHandArea,
    AppRecipeItem,
} from "../FoodResources";
import { AppRestaurant } from "../../admin/AdminResources";
import { DataEditorResource } from "../../data-editor/types/DataEditorTypes";
import { AppContext } from "../../../context/AppContext";
import AppUserGroups from "../../../services/AppUserGroups";

const DataEditorFood = (props: any) => {
    const { appState } = useContext(AppContext);
    const { history, location } = props;

    let resources: DataEditorResource[];

    const adminResources = [
        new DataEditorResource(AppIngredient, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppIngredientPack, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppIngredientDeliveryArea, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppIngredientOnHandArea, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppMenuItem, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppRecipeItem, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppIngredientInventory, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppIngredientInventoryMeta, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppMenuItemSale, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppMenuItemSaleMeta, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppDeliveryArea, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppOnHandArea, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppRestaurant, { isVisibleInDataEditor: false }),
    ];

    const nonAdminResources: DataEditorResource[] = [
        new DataEditorResource(AppIngredient, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppIngredientDeliveryArea, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppIngredientOnHandArea, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppDeliveryArea, { isVisibleInDataEditor: false }),
        new DataEditorResource(AppOnHandArea, { isVisibleInDataEditor: false }),
        new DataEditorResource(AppRestaurant, { isVisibleInDataEditor: false }),
    ];

    if (AppUserGroups.hasAdminAccess(appState.user.groups)) {
        resources = adminResources;
    } else {
        resources = nonAdminResources;
    }

    return (
        <DataEditorProvider resources={resources} location={location} history={history}>
            <DataEditor location={location} />
        </DataEditorProvider>
    );
};

export default DataEditorFood;
