import React from "react";
import menuStyles from "../Menus.module.css";

const MenusContentHeader = ({ displayConfigs: { hideMultiple } }) => {
    const hideMultipleContent = (
        <div className={menuStyles.menuItemSaleSingle} key={`MenuItemSalesSingle`}>
            Sold
        </div>
    );

    const showMultipleContent = [
        <div className={menuStyles.menuItemSaleSingle} key={`MenuItemSalesSingle`}>
            Single
        </div>,
        <div className={menuStyles.menuItemSaleMultiple} key={`MenuItemSalesMultiple`}>
            Multiple
        </div>,
    ];

    const content = hideMultiple ? hideMultipleContent : showMultipleContent;

    return (
        <div className={`${menuStyles.menuContentHeader} ${menuStyles.menuContentItem}`}>
            <div className={menuStyles.menuItem} key={`MenuItemName`}>
                Item
            </div>
            {content}
            <div className={menuStyles.menuItemRecipe} key={`Recipe`}>
                Recipe
            </div>
        </div>
    );
};

export default MenusContentHeader;
