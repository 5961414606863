import dataEditorStyles from "../../DataEditor.module.css";
import DataEditorResourceSelect from "./DataEditorResourceSelect";
import DataEditorSearch from "./DataEditorSearch";

/**
 * Holds data type selection
 */

const DataEditorToolbar = () => {
    return (
        <div className={dataEditorStyles.Toolbar}>
            <DataEditorResourceSelect />
            <DataEditorSearch />
            {/*<DataEditorFilter />*/}
        </div>
    );
};

export default DataEditorToolbar;
