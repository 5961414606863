import React, { useContext, useEffect } from "react";
import menusStyles from "../Menus.module.css";
import { MenusContext } from "../context/MenusContext";
import MenusContentHeader from "./MenusContentHeader";
import MenusCompletionStatus from "./MenusCompletionStatus";
import MenusItem from "./MenusItem";

const MenusContent = () => {
    const { menusState } = useContext(MenusContext);

    useEffect(() => {
        console.log("MenusContent: USE EFFECT!");
    }, [menusState.restaurant, menusState.completionScore, menusState.config]);

    if (!menusState.restaurant) {
        console.info("MenusContent: Invalid restaurant!");
        return <div />;
    }

    if (!menusState.config) {
        console.info("MenusContent: Invalid config!");
        return <div />;
    }

    return (
        <div className={menusStyles.menusContent}>
            <MenusContentHeader displayConfigs={menusState.config.displayConfigs} />
            <MenusCompletionStatus />
            <div className={menusStyles.menuContentItems}>
                {menusState.restaurant.menu
                    .sort((a, b) => a.data.name.localeCompare(b.data.name))
                    .map((menuItem, index) => {
                        return (
                            <MenusItem
                                displayConfigs={menusState.config.displayConfigs}
                                item={menuItem}
                                index={index}
                            />
                        );
                    })}
            </div>
        </div>
    );
};

export default MenusContent;
