import AppUserGroups from "../../../../services/AppUserGroups";
import menusToolbarStyles from "./MenusToolbar.module.css";
import { Button } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../context/AppContext";
import AppContextValidator from "../../../../context/AppContextValidator";
import { MenusContext } from "../../context/MenusContext";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

const MenusToolbarInvalidQueue = () => {
    const { appState } = useContext(AppContext);
    const { menusState } = useContext(MenusContext);
    const [state, setState] = useState({
        showInvalidQueue: false,
    });

    useEffect(() => {}, [menusState.restaurant]);

    if (!AppContextValidator.isValid(appState)) {
        return <div />;
    }

    if (
        !AppUserGroups.hasAdminAccess(appState.user.groups) ||
        !menusState.restaurant.invalidQueue ||
        menusState.restaurant.invalidQueue.length === 0
    ) {
        return <div />;
    }

    const handleShowInvalidQueue = () => {
        return setState((prevState) => {
            return { ...prevState, showInvalidQueue: true };
        });
    };

    const handleInvalidQueueDialogClose = () => {
        return setState((prevState) => {
            return { ...prevState, showInvalidQueue: false };
        });
    };

    if (state.showInvalidQueue) {
        return (
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                onClose={handleInvalidQueueDialogClose}
                aria-labelledby="customized-dialog-title"
                open={state.showInvalidQueue}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleInvalidQueueDialogClose}>
                    Failed to upload these values
                </DialogTitle>
                <DialogContent dividers>
                    <div className={menusToolbarStyles.menusToolbarReadFailuresContent}>
                        {menusState.restaurant.invalidQueue.map(([name, quantity]) => {
                            return (
                                <div className={menusToolbarStyles.menusToolbarReadFailure}>
                                    <div>{name}</div>
                                    <div>{quantity}</div>
                                </div>
                            );
                        })}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleInvalidQueueDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <div className={menusToolbarStyles.menusToolbarReadFailures}>
            <Button color={"secondary"} variant={"outlined"} onClick={handleShowInvalidQueue}>
                Unknown ({menusState.restaurant.invalidQueue.length})
            </Button>
        </div>
    );
};

export default MenusToolbarInvalidQueue;
