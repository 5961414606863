import { useContext, useEffect } from "react";
import { DataEditorContext } from "../../context/DataEditorContext";
import dataEditorStyles from "../../DataEditor.module.css";
import DataEditorAddAllBtn from "./DataEditorAddAllBtn";
import DataEditorDeleteAllBtn from "./DataEditorDeleteAllBtn";
import DataEditorItemsCount from "./DataEditorItemsCount";
import DataEditorPagination from "./DataEditorPagination";
import DataEditorRefreshBtn from "./DataEditorRefreshBtn";

/**
 * Holds general actions applicable to multiple (types of) data
 * TODO:
 *  - "edit all"
 *  - "delete everything"
 *  - "add everything"
 *  - disable "add all" if items are not empty (or avoid adding items that already exist)
 *  - check data health (valid references to other types ids)
 */

const DataEditorFooter = () => {
    const { dataEditorState } = useContext(DataEditorContext);

    useEffect(() => {}, [dataEditorState.store]);

    if (!dataEditorState?.getSelectedResource() || !dataEditorState.store) {
        console.info("Invalid dataEditorState selectedResource or store");
        return <div />;
    }

    return (
        <div className={dataEditorStyles.Footer}>
            <div>
                <DataEditorDeleteAllBtn />
                <DataEditorAddAllBtn />
            </div>
            <div>
                <DataEditorPagination />
            </div>
            <div>
                <DataEditorItemsCount />
                <DataEditorRefreshBtn />
            </div>
        </div>
    );
};

export default DataEditorFooter;
