import { TRANSACTIONAL } from "../models/OnHandTypes";

export default async function OnHandResolver(
    day,
    userName,
    sectionName,
    userGroups,
    context,
    useMocks = false
) {
    if (context.type === TRANSACTIONAL) {
        await context.loadDataTransactional(userGroups);
    } else {
        await context.loadDataInventory(
            day,
            userName,
            sectionName,
            userGroups,
            context.inventoryMetaResource,
            useMocks
        );
    }

    await context.loadDependencies(day, userName, sectionName, useMocks);

    context.buildDataHierarchy(sectionName);

    return context.restaurantsById;
}
