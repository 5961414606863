import _ from "lodash";
import { createContext, useCallback, useReducer } from "react";
import AppDayModel from "../../../components/day/AppDayModel";
import { AppRestaurant } from "../../admin/AdminResources";
import DeliveryFoodMode from "./modes/DeliveryFoodMode";
import { extractIngredientResults } from "./services/DeliveryFoodExtract";
import {
    DeliveryFoodAction,
    DeliveryFoodContextType,
    DeliveryFoodIngredient,
    DeliveryFoodState,
    DeliveryFoodSummary,
    DELIVERY_FOOD_ACTIONS,
} from "./types/DeliveryFoodTypes";

const DeliveryFoodContext = createContext({} as DeliveryFoodContextType);

function DeliveryFoodReducer(
    deliveryFoodState: DeliveryFoodState,
    action: DeliveryFoodAction
): DeliveryFoodState {
    switch (action.type as DELIVERY_FOOD_ACTIONS) {
        case "UPDATE_DELIVERY_FOOD":
            deliveryFoodState = action.payload.updatedDeliveryFoodState as DeliveryFoodState;
            break;

        case "SET_SUMMARY":
            deliveryFoodState.summary = action.payload.summary as DeliveryFoodSummary;
            break;

        case "CLEAR_SUMMARY":
            deliveryFoodState.summary = {
                key: "",
                total: 0,
                totalDisplay: "",
                data: [],
            };
            break;

        case "SET_MODE":
            deliveryFoodState.mode = action.payload.mode as DeliveryFoodMode;
            break;

        case "UPDATE_FIXED_NEED":
            // re-compute results
            const ingredient: DeliveryFoodIngredient = action.payload
                .updatedIngredient as DeliveryFoodIngredient;
            const restaurantsList: AppRestaurant[] = Object.values(
                deliveryFoodState.restaurantsById
            );
            const results = extractIngredientResults(ingredient, restaurantsList);
            ingredient.results = results;

            // update ingredient
            const ingredientIndex = deliveryFoodState.ingredients.findIndex(
                (oldIngredient) => oldIngredient.data.id === ingredient.data.id
            );

            if (ingredientIndex === -1) {
                break;
            }

            deliveryFoodState.ingredients[ingredientIndex] = ingredient;

            break;

        default:
            break;
    }

    return _.clone(deliveryFoodState);
}

const deliveryFoodStateInit: DeliveryFoodState = {
    config: { router: { history: null, location: null } },
    ingredients: [],
    restaurantsById: {},
    deliverySections: {},
    summary: null,
    modes: [],
    mode: new DeliveryFoodMode({
        restaurants: [],
        restaurant: new AppRestaurant({ __typename: "Restaurant" }),
    }),
    locked: null,
    days: {
        salesDay: new AppDayModel(),
        onHandDay: new AppDayModel(),
    },
};

const DeliveryFoodProvider = ({ children }: any) => {
    const [deliveryFoodState, dispatch] = useReducer(DeliveryFoodReducer, deliveryFoodStateInit);

    const updateDeliveryFood = useCallback(
        async (updatedDeliveryFoodState: DeliveryFoodState) => {
            const action: DeliveryFoodAction = {
                type: "UPDATE_DELIVERY_FOOD",
                payload: { updatedDeliveryFoodState },
            };

            return dispatch(action);
        },
        [dispatch]
    );

    const setSummary = useCallback(
        (summary, key) => {
            const action: DeliveryFoodAction = { type: "SET_SUMMARY", payload: { summary, key } };

            return dispatch(action);
        },
        [dispatch]
    );

    const clearSummary = useCallback(() => {
        const action: DeliveryFoodAction = { type: "CLEAR_SUMMARY", payload: {} };

        return dispatch(action);
    }, [dispatch]);

    const setMode = useCallback(
        (mode) => {
            const action: DeliveryFoodAction = { type: "SET_MODE", payload: { mode } };

            return dispatch(action);
        },
        [dispatch]
    );

    const updateFixedNeed = useCallback(
        (updatedIngredient) => {
            const action: DeliveryFoodAction = {
                type: "UPDATE_FIXED_NEED",
                payload: { updatedIngredient },
            };

            return dispatch(action);
        },
        [dispatch]
    );

    const value: DeliveryFoodContextType = {
        deliveryFoodState,
        updateDeliveryFood,
        setSummary,
        clearSummary,
        setMode,
        updateFixedNeed,
    };

    return <DeliveryFoodContext.Provider value={value}>{children}</DeliveryFoodContext.Provider>;
};

export { DeliveryFoodProvider, DeliveryFoodContext };
