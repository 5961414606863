import AppDayModel from "../../../components/day/AppDayModel";
import AppUtilsService from "../../../services/AppUtilsService";
import OnHandStatus from "../components/status/OnHandStatus";
import OnHandActions from "./OnHandActions";
import OnHandContextValidator from "./OnHandContextValidator";
import OnHandUtils from "../OnHandUtils";
import OffSiteTasksUtils from "../../off-site/tasks/OffSiteTasksUtils";

function updateState(state, action) {
    console.info(`CONTEXT: ON_HAND: ${action.type}`);
    return { ...state };
}

export default function OnHandReducer(state, action) {
    if (action.type === OnHandActions.CHANGE_RESTAURANT) {
        const restaurant = state.restaurants[action.payload.restaurantId];
        const pageUrl = state?.config?.context?.utils?.pageUrl || "on-hand";
        AppUtilsService.setRestaurant(state.sectionName, pageUrl, restaurant, state.config.router);
        state.restaurant = restaurant;
        return updateState(state, action);
    }

    if (action.type === OnHandActions.CHANGE_VIEW) {
        OnHandUtils.persistView(
            action.payload.view,
            state.config.router,
            state.config.display?.views
        );
        state.view = action.payload.view;
        return updateState(state, action);
    }

    if (action.type === OnHandActions.CHANGE_ITEM) {
        const group = state.restaurant.group.selectItemByIndex(action.payload.index);
        state.restaurant.selectGroup(group);
        return updateState(state, action);
    }

    if (action.type === OnHandActions.GO_TO_PREV_ITEM_TASKS) {
        if (action.payload?.taskSelectedKey) {
            action.payload.goToPrevTask();
            delete action.payload;
            return updateState(state, action);
        } else {
            action.type = OnHandActions.GO_TO_PREV_ITEM;
        }
    }

    if (
        action.type === OnHandActions.GO_TO_NEXT_ITEM_TASKS ||
        action.type === OnHandActions.SUBMIT_SELECTED_DELIVERY_ITEM
    ) {
        if (action.payload?.taskSelectedKey) {
            action.payload.goToNextTask();
            delete action.payload;
            return updateState(state, action);
        } else {
            action.type = OnHandActions.GO_TO_NEXT_ITEM;
        }
    }

    if (action.type === OnHandActions.GO_TO_PREV_ITEM) {
        state.restaurant.goToPrevItem();
        // TODO: test if change is performed; test if the newly created state is required
        return updateState(state, action);
    }

    if (action.type === OnHandActions.GO_TO_NEXT_ITEM) {
        state.restaurant.goToNextItem();
        // TODO: test if change is performed; test if the newly created state is required
        return updateState(state, action);
    }

    if (action.type === OnHandActions.INVENTORY_ATTACH) {
        state.restaurant.group.item = Object.assign(state.restaurant.group.item, {
            inventory: action.payload.inventory,
        });
        state.reload = !state.reload;
        return updateState(state, action);
    }

    if (
        action.type === OnHandActions.INVENTORY_UPDATE &&
        OnHandContextValidator.hasValidItemInventory(state)
    ) {
        const inventory = state.restaurant.group.item.inventory;
        if (inventory && inventory.data) {
            if (typeof action.payload.quantity !== "number") {
                return;
            }
            inventory.data.quantity = parseFloat(action.payload.quantity);
            inventory.data.lastUpdatedBy = action.payload.userName;
            inventory.data.lastUpdatedAt = AppDayModel.getCurrentUnixTime();
            return updateState(state, action);
        }
    }

    // TODO: is there really a difference between a delivery and an inventory transaction here?
    if (action.type === OnHandActions.DELIVERY_TRANSACTION_UPDATE) {
        // must have a valid quantity
        if (typeof action.payload.addedQuantity !== "number") {
            return updateState(state, action);
        }

        state.restaurant.group.item.deliveryQuantity =
            (state.restaurant.group.item.deliveryQuantity || 0) + action.payload.addedQuantity;
        state.restaurant.group.item.deliveryQuantity = Math.max(
            0,
            state.restaurant.group.item.deliveryQuantity
        );

        delete action.payload.addedQuantity;

        return updateState(state, action);
    }

    // TODO: is there really a difference between a delivery and an inventory transaction here?
    if (action.type === OnHandActions.STOCKING_TRANSACTION_UPDATE) {
        // must have a valid quantity
        if (typeof action.payload.addedQuantity !== "number") {
            return updateState(state, action);
        }

        if (!state.restaurant.group.item) {
            return updateState(state, action);
        }

        if (!state.restaurant.group.item.stockingQuantity) {
            state.restaurant.group.item.stockingQuantity = 0;
        }

        state.restaurant.group.item.stockingQuantity =
            state.restaurant.group.item.stockingQuantity + action.payload.addedQuantity;

        return updateState(state, action);
    }

    if (action.type === OnHandActions.DELIVERY_TRANSACTION_RESET) {
        state.restaurant.group.item.deliveryQuantity = 0;
        return updateState(state, action);
    }

    if (action.type === OnHandActions.STOCKING_TRANSACTION_RESET) {
        state.restaurant.group.item.stockingQuantity = 0;
        return updateState(state, action);
    }

    if (action.type === OnHandActions.LOCK_SUBMISSION) {
        state.lockedSubmit = true;
    }

    if (action.type === OnHandActions.UNLOCK_SUBMISSION) {
        state.lockedSubmit = false;
    }

    if (action.type === OnHandActions.SUBMIT_SELECTED_ITEM) {
        const restaurants = action.payload.restaurants;
        const restaurant = action.payload.restaurant;
        const status = action.payload.status;
        state.restaurants = restaurants;
        state.restaurant = restaurant;
        state.status = status;
        state.lockedSubmit = false;
        // TODO: go to next task
        return updateState(state, action);
    }

    if (action.type === OnHandActions.SUBMIT_SELECTED_ITEM_TRANSACTION) {
        state.status = OnHandStatus.compute(state.restaurants);
        delete state.restaurant.group.item.transaction;
        state.lockedSubmit = false;
        state.restaurant.goToNextItem();
        // TODO: go to next task
        return updateState(state, action);
    }

    if (action.type === OnHandActions.CONFIRM_CHANGES) {
        state.restaurant = action.payload.restaurant;
        state.restaurant.goToNextItem();
        return updateState(state, action);
    }

    if (action.type === OnHandActions.REJECT_CHANGES) {
        state.restaurant = state.restaurant.rejectGroupItems();
        return updateState(state, action);
    }

    if (action.type === OnHandActions.TOGGLE_LOCK) {
        state.restaurant = action.payload.restaurant;
        return updateState(state, action);
    }

    if (action.type === OnHandActions.SYNC) {
        state.restaurant = action.payload.restaurant;
        return updateState(state, action);
    }

    if (
        action.type === OnHandActions.SELECT_ITEM_FROM_TASK ||
        action.type === OnHandActions.LOAD_DATA_TASK
    ) {
        const { restaurant, group, item } = OffSiteTasksUtils.findLocationOfTaskByKey(
            state.restaurants || action.payload.restaurants,
            action.payload.taskSelectedKey
        );

        if (restaurant && group && item) {
            group.item = item;
            restaurant.group = group;
            action.payload.restaurant = restaurant;
        }

        delete action.payload.taskSelectedKey;

        state = { ...state, ...action.payload };

        return updateState(state, action);
    }

    if (action.type === OnHandActions.SUBMIT_SELECTED_STOCKING_ITEM) {
        state.restaurant.goToNextItem();
        return updateState(state, action);
    }

    console.info("CONTEXT: ON_HAND: OTHER");
    return { ...state, ...action.payload };
}
