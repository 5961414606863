import _ from "lodash";
import OffSiteTasksUtils from "../tasks/OffSiteTasksUtils";
import {
    OffSiteContextAction,
    OffSiteContextStateType,
    OffSiteInitDataType,
} from "./OffSiteContextTypes";

export function OffSiteContextReducer(
    offSiteState: OffSiteContextStateType,
    action: OffSiteContextAction
): OffSiteContextStateType {
    switch (action.type) {
        case "LOAD_DELIVERY_TASKS":
            const { offSiteTasks, destinationRestaurants } = action.payload
                .offSiteInitData as OffSiteInitDataType;
            offSiteState.offSiteTasksByStatusAndIdAndRest =
                OffSiteTasksUtils.organizeOffSiteTasksByStatusAndId(offSiteTasks);
            offSiteState.destinationRestaurants = destinationRestaurants;
            break;
        case "TOGGLE_DELIVERY_TASKS_DIALOG":
            offSiteState.showDeliveryTasksDialog = action.payload.showDeliveryTasksDialog;
            break;
        case "GO_TO_PREV_TASK":
            if (!offSiteState.offSiteTasksByStatusAndIdAndRest) {
                break;
            }
            const prevTaskKey = OffSiteTasksUtils.getPrevPendingTaskKey(
                offSiteState.offSiteTasksByStatusAndIdAndRest,
                offSiteState.taskSelectedKey
            );

            if (prevTaskKey) {
                offSiteState.taskSelectedKey = prevTaskKey;
                action.payload.syncOffSiteTaskSelectedKeyCallback(offSiteState.taskSelectedKey);
            }

            break;
        case "GO_TO_NEXT_TASK":
            if (!offSiteState.offSiteTasksByStatusAndIdAndRest) {
                break;
            }
            const nextTaskKey = OffSiteTasksUtils.getNextPendingTaskKey(
                offSiteState.offSiteTasksByStatusAndIdAndRest,
                offSiteState.taskSelectedKey
            );

            if (nextTaskKey) {
                offSiteState.taskSelectedKey = nextTaskKey;
                action.payload.syncOffSiteTaskSelectedKeyCallback(offSiteState.taskSelectedKey);
            }

            break;
        case "SEND_LOADED_TASKS":
            offSiteState.offSiteTasksByStatusAndIdAndRest = action.payload.offSiteTasks;
            break;
        default:
            break;
    }

    return _.clone(offSiteState);
}
