import { Amplify } from "aws-amplify";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import awsconfig from "./aws-exports";
import GlobalSpinner from "./components/global-spinner/GlobalSpinner";
import GlobalSpinnerContextProvider from "./components/global-spinner/GlobalSpinnerContext";
import { AppContextProvider } from "./context/AppContext";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

Amplify.configure(awsconfig);

window.errorsReport = [];
window.addEventListener("error", (ev) => {
    window.errorsReport.push(ev);
});

ReactDOM.render(
    <React.StrictMode>
        <AppContextProvider>
            <GlobalSpinnerContextProvider>
                <GlobalSpinner />
                <App />
            </GlobalSpinnerContextProvider>
        </AppContextProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
