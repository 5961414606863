import { Button, FormControl, MenuItem, Select } from "@material-ui/core";
import { useContext, useEffect } from "react";
import { DeliveryOrdersContext } from "../context/DeliveryOrdersContext";
import deliveryOrdersStyles from "../DeliveryOrders.module.css";
import DeliveryOrdersUtils from "../DeliveryOrdersUtils";

export default function DeliveryOrdersEmailOrderEditorAdd() {
    const { deliveryOrders, updateEmailVendorOrders, selectEmailVendorOrders } =
        useContext(DeliveryOrdersContext);

    useEffect(() => {}, [deliveryOrders.restaurant.emailVendor.addedOrders]);

    const handleOrdersChange = (event) => {
        selectEmailVendorOrders(event.target.value);
    };

    const ordersThatCanBeAdded = DeliveryOrdersUtils.getOrdersThatCanBeAdded(
        deliveryOrders.restaurant.emailVendor
    );

    if (ordersThatCanBeAdded.length === 0) {
        return null;
    }

    if (!deliveryOrders.restaurant.emailVendor.selectedOrderToBeAdded) {
        selectEmailVendorOrders(ordersThatCanBeAdded[0]);
        return null;
    }

    return (
        <div className={deliveryOrdersStyles.DialogEditorAdd}>
            <FormControl styles={{ display: "grid" }}>
                <Select
                    labelId="orders-add-select-label"
                    id="orders-add-select"
                    value={deliveryOrders.restaurant.emailVendor.selectedOrderToBeAdded}
                    onChange={handleOrdersChange}
                >
                    {ordersThatCanBeAdded.map((order, index) => {
                        const orderQuantity = DeliveryOrdersUtils.getOrderQuantity(order);
                        return (
                            <MenuItem key={`orders-add-${index}`} value={order}>
                                {order?.data?.name} ({orderQuantity})
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <Button
                onClick={updateEmailVendorOrders}
                size={"small"}
                variant={"outlined"}
                disabled={!deliveryOrders.restaurant.emailVendor.selectedOrderToBeAdded}
                className={deliveryOrdersStyles.DialogOrderEditorButton}
            >
                Add
            </Button>
        </div>
    );
}
