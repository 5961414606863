import { AdminSectionConfig } from "../admin/AdminSectionConfig";
import { DpsSectionConfig } from "../dps/DpsSectionConfig";
import { FoodSectionConfig } from "../food/FoodSectionConfig";
import { OffSiteSectionConfig } from "../off-site/OffSiteSectionConfig";
import { OrdersSectionConfig } from "../orders/OrdersSectionConfig";
import { TShirtsSectionConfig } from "../t-shirts/TShirtsSectionConfig";
import { Section } from "./Section";

const Sections: Section[] = [
    FoodSectionConfig,
    OrdersSectionConfig,
    OffSiteSectionConfig,
    TShirtsSectionConfig,
    DpsSectionConfig,
    AdminSectionConfig,
];

export default Sections;
