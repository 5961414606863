import React from "react";
import OffSiteDeliveryItem from "../delivery/item/OffSiteDeliveryItem";
import OffSiteStockingItem from "../stocking/item/OffSiteStockingItem";
import OffSiteHistoryItem from "../history/item/OffSiteHistoryItem";
import OffSiteReordersItemAlert from "../reorders/OffSiteReordersItemAlert";

const DELIVERY = { name: "Delivery", disabled: false, component: <OffSiteDeliveryItem /> };
const HISTORY = { name: "History", disabled: false, component: <OffSiteHistoryItem /> };
const STOCKING = { name: "Stocking", disabled: false, component: <OffSiteStockingItem /> };
const ALERT = { name: "Alert", disabled: false, component: <OffSiteReordersItemAlert /> };

const VIEWS = {
    DELIVERY: {
        default: DELIVERY,
        all: [DELIVERY, HISTORY, ALERT],
        localKey: "OffSiteOnHandViewDelivery",
    },
    STOCKING: {
        default: STOCKING,
        all: [STOCKING, HISTORY, ALERT],
        localKey: "OffSiteOnHandViewStocking",
    },
};

export { VIEWS };
