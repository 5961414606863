import { AppRestaurant, AppRestaurantTypes } from "../../../admin/AdminResources";
import {
    DeliveryFoodIngredient,
    DeliveryFoodIngredientResultCom,
    DeliveryFoodIngredientResultRst,
    DeliveryFoodIngredientResults,
    DeliveryFoodIngredientResultsRst,
    DeliveryFoodOnHandSummary,
    DeliveryFoodSalesSummary,
    DeliveryFoodSummary,
} from "../types/DeliveryFoodTypes";
import DeliveryFoodUtils from "./DeliveryFoodUtils";

function extractOnHand(
    ingredient: DeliveryFoodIngredient,
    restaurant: AppRestaurant
): DeliveryFoodOnHandSummary {
    const onHand = ingredient.dataForDelivery?.onHand;
    const size = ingredient.pack.data.size;
    const id = restaurant.data.id;

    const data = onHand?.filter(({ restaurantId }: any) => restaurantId === id) || [];

    if (data.length === 0) {
        return {
            data,
            total: null,
            totalDisplay: null,
        };
    }

    const total = data.reduce((acc: any, { bulk, portionRatio, quantity }: any) => {
        acc += bulk === "BULK" ? quantity : (quantity / size) * (portionRatio || 1);
        return acc;
    }, 0);
    const totalDisplay = Math.ceil(total * 100) / 100;

    return { data, total, totalDisplay };
}

function extractSales(
    ingredient: DeliveryFoodIngredient,
    restaurant: AppRestaurant,
    salesType: any
): DeliveryFoodSalesSummary {
    const ingredientSales = ingredient.dataForDelivery?.sales;
    const packSize = ingredient.pack.data.size;
    const ingredientSalesRatio = ingredient.data.salesRatio;
    const id = restaurant.data.id;
    const restaurantRatio = restaurant.data.ratio;

    const data = ingredientSales?.filter(
        ({ restaurantId, type }: any) => restaurantId === id && type === salesType
    ) || [];

    if (data.length === 0) {
        return {
            data,
            total: null,
            totalWithoutRatio: null,
            totalPortions: null,
            totalDisplay: null,
        };
    }

    const totalPortions = data.reduce((acc: any, { quantitySold, quantityPerItem }: any) => {
        acc += quantitySold * quantityPerItem;
        return acc;
    }, 0);
    const totalWithoutRatio = Math.ceil((totalPortions / packSize) * 100) / 100;
    const totalWithoutSalesRatio = Math.ceil(totalWithoutRatio * (restaurantRatio || 1) * 100) / 100;
    const total = Math.ceil(totalWithoutSalesRatio * (ingredientSalesRatio || 1) * 100) / 100;
    const totalDisplay = Math.ceil(total * 100) / 100;

    return { data, total, totalWithoutSalesRatio, totalWithoutRatio, totalPortions, totalDisplay };
}

function extractDelivery(
    ingredient: DeliveryFoodIngredient,
    restaurant: AppRestaurant
): DeliveryFoodSummary {
    if (!restaurant?.data?.id) {
        return { total: null, totalDisplay: null };
    }

    const DELIVERY = ingredient?.deliveryConfigsByRestaurantId?.[restaurant.data.id]?.data;
    const MULTIPLE = extractSales(ingredient, restaurant, "MULTIPLE");
    const ON_HAND = extractOnHand(ingredient, restaurant);
    const SINGLE = extractSales(ingredient, restaurant, "SINGLE");

    const data = { DELIVERY, MULTIPLE, ON_HAND, SINGLE };
    // todo: validate the extra .ceil on multipleSales
    const totalWithoutFixedNeeds = Math.max(
        0,
        (MULTIPLE?.totalDisplay || 0) - (ON_HAND?.total || 0)
    );
    const totalFixedNeed = Math.ceil(DELIVERY?.fixedNeed - (ON_HAND?.total || 0));
    let total = Math.max(totalFixedNeed, totalWithoutFixedNeeds);
    let totalDisplay: string | number = Math.ceil(total * 10) / 10; // TODO: vary with seasonal strategy
    if (!totalDisplay && totalDisplay !== 0) {
        total = 0;
        totalDisplay = "-";
    }

    return {
        data,
        total,
        totalFixedNeed: DELIVERY?.fixedNeed,
        totalFixedNeedWithoutOnHand: totalFixedNeed,
        totalSalesNeed: totalWithoutFixedNeeds,
        totalDisplay,
    };
}

function extractPrepForDelivery(
    ingredient: DeliveryFoodIngredient,
    commissary: any,
    restaurants: any
): DeliveryFoodSummary {
    const COMM_ON_HAND = extractOnHand(ingredient, commissary);
    const DELIVERY = restaurants.reduce((acc: any, restaurant: AppRestaurant) => {
        if (!restaurant?.data?.id) {
            return acc;
        }

        acc[restaurant?.data?.id] = {
            data: restaurant?.data,
            DELIVERY: extractDelivery(ingredient, restaurant),
        };

        return acc;
    }, {});

    const data = {
        COMM_ON_HAND,
        DELIVERY,
        restaurants,
    };
    const totalDelivery: any = Object.values(DELIVERY).reduce(
        (acc: any, restaurantDelivery: any) => {
            // here should be used totalDisplay, and not total, since the values seen
            // by the delivery guy is the one that should be prep-ed
            if (typeof restaurantDelivery?.DELIVERY?.totalDisplay !== "number") {
                return acc;
            }
            acc += restaurantDelivery?.DELIVERY?.totalDisplay;
            return acc;
        },
        0
    );
    const totalCommOnHand = COMM_ON_HAND?.total;

    const total = totalDelivery - (totalCommOnHand || 0);
    const totalDisplay = Math.ceil(Math.max(0, total));

    return { data, total, totalDisplay };
}

function extractPrep(
    ingredient: DeliveryFoodIngredient,
    commissary: any,
    restaurants: any
): DeliveryFoodSummary {
    const COMM_ON_HAND = extractOnHand(ingredient, commissary);
    const SINGLE_SALES = restaurants.reduce((acc: any, restaurant: AppRestaurant) => {
        if (!restaurant?.data?.id) {
            return acc;
        }

        acc[restaurant?.data?.id] = {
            data: restaurant?.data,
            SINGLE: extractSales(ingredient, restaurant, "SINGLE"),
        };
        return acc;
    }, {});
    const MULTIPLE_SALES = restaurants.reduce((acc: any, restaurant: AppRestaurant) => {
        if (!restaurant?.data?.id) {
            return acc;
        }

        acc[restaurant?.data?.id] = {
            data: restaurant?.data,
            MULTIPLE: extractSales(ingredient, restaurant, "MULTIPLE"),
        };
        return acc;
    }, {});

    const data = {
        COMM_ON_HAND,
        SINGLE_SALES,
        MULTIPLE_SALES,
    };
    const totalCommOnHand = COMM_ON_HAND?.total;
    const totalSingle = restaurants.reduce(
        (acc: any, r: any) => acc + SINGLE_SALES[r?.data?.id]?.SINGLE?.total,
        0
    );
    const totalDelivery = restaurants
        .filter(
            (restaurant: AppRestaurant) => restaurant?.data?.type === AppRestaurantTypes.RESTAURANT
        )
        .reduce((acc: any, restaurant: AppRestaurant) => {
            const DELIVERY = extractDelivery(ingredient, restaurant);
            if (typeof DELIVERY?.totalDisplay !== "number") {
                return acc;
            }
            acc += DELIVERY?.totalDisplay;
            return acc;
        }, 0);

    const total = totalSingle - Math.max(0, (totalCommOnHand || 0) - totalDelivery);
    const totalDisplay = Math.ceil(Math.max(0, total));

    return { data, total, totalDisplay };
}

function extractPrepTime(prep: any, ingredient: DeliveryFoodIngredient): DeliveryFoodSummary {
    const { laborTime, batchSize, portionTime } = ingredient?.data;
    const quantity = prep?.totalDisplay;
    const batches = Math.ceil(quantity / (batchSize || 1));
    const total = laborTime * batches + portionTime * quantity;
    const totalDisplay = DeliveryFoodUtils.getHoursDisplay(total);
    const data = { laborTime, portionTime, prepTotal: prep?.totalDisplay };
    return { data, total, totalDisplay };
}

function extractPrepForDeliveryTime(
    prepForDelivery: any,
    ingredient: DeliveryFoodIngredient
): DeliveryFoodSummary {
    const { laborTime, batchSize, portionTime } = ingredient?.data;
    const quantity = prepForDelivery?.totalDisplay;
    const batches = Math.ceil(quantity / (batchSize || 1));
    const total = laborTime * batches + portionTime * quantity;
    const totalDisplay = DeliveryFoodUtils.getHoursDisplay(total);
    const data = { laborTime, portionTime, prepForDeliveryTotal: prepForDelivery?.totalDisplay };
    return { data, total, totalDisplay };
}

function extractIngredientResults(
    ingredient: DeliveryFoodIngredient,
    allRestaurants: AppRestaurant[]
): DeliveryFoodIngredientResults {
    let result: DeliveryFoodIngredientResults = {
        COM: null,
        RST: null,
    };

    const [commissary] = allRestaurants.filter(
        ({ data: { type } }: any) => type === AppRestaurantTypes.COMMISSARY
    );
    const restaurants = allRestaurants.filter(
        ({ data: { type } }: any) => type === AppRestaurantTypes.RESTAURANT
    );

    const prep = extractPrep(ingredient, commissary, restaurants);
    const prepForDelivery = extractPrepForDelivery(ingredient, commissary, restaurants);

    const COM: DeliveryFoodIngredientResultCom = {
        type: AppRestaurantTypes.COMMISSARY,
        prepForDelivery: extractPrepForDelivery(ingredient, commissary, restaurants),
        prep,
        prepTime: extractPrepTime(prep, ingredient),
        prepForDeliveryTime: extractPrepForDeliveryTime(prepForDelivery, ingredient),
        onHand: extractOnHand(ingredient, commissary),
    };

    const RST: DeliveryFoodIngredientResultsRst = restaurants.reduce(
        (acc: DeliveryFoodIngredientResultsRst, restaurant: AppRestaurant) => {
            if (!restaurant?.data?.id) {
                return acc;
            }

            const restaurantResult: DeliveryFoodIngredientResultRst = {
                type: AppRestaurantTypes.RESTAURANT,
                delivery: extractDelivery(ingredient, restaurant),
                onHand: extractOnHand(ingredient, restaurant),
                sales: {
                    SINGLE: extractSales(ingredient, restaurant, "SINGLE"),
                    MULTIPLE: extractSales(ingredient, restaurant, "MULTIPLE"),
                },
            };

            acc[restaurant.data.id] = restaurantResult;

            return acc;
        },
        {}
    );

    result = {
        COM,
        RST,
    };

    return result;
}

export {
    extractOnHand,
    extractSales,
    extractDelivery,
    extractPrepForDelivery,
    extractPrep,
    extractPrepTime,
    extractPrepForDeliveryTime,
    extractIngredientResults,
};
