import { TextField } from "@material-ui/core";
import React, { useContext } from "react";
import { DeliveryOrdersContext } from "../context/DeliveryOrdersContext";

export default function DeliveryOrdersEmailDialogNotes() {
    const { deliveryOrders, updateEmailVendorNotes } = useContext(DeliveryOrdersContext);

    const label = `Notes to ${deliveryOrders.restaurant.emailVendor?.data?.name}`;
    return (
        <TextField
            id="outlined-basic"
            label={label}
            multiline
            rows={1}
            onChange={(event) => updateEmailVendorNotes(event.target.value)}
            value={deliveryOrders.restaurant.emailVendor.notes}
        />
    );
}
