import React from "react";
import OnHandConfigModel from "../../on-hand/models/OnHandConfigModel";
import OnHandItemDisplayModel from "../../on-hand/models/OnHandItemDisplayModel";
import OnHandTShirtItemTab from "./display/OnHandTShirtItemTab";
import OnHandWrp from "../../on-hand/OnHandWrp";
import OnHandTShirtItem from "./display/OnHandTShirtItem";
import OnHandTShirtContextConfig from "./context/OnHandTShirtContextConfig";
import OnHandTShirtItemConfirm from "./display/OnHandTShirtItemConfirm";

const OnHandTShirts = ({ history, location }) => {
    const config = new OnHandConfigModel(
        OnHandTShirtContextConfig,
        new OnHandItemDisplayModel(OnHandTShirtItemTab, OnHandTShirtItem, OnHandTShirtItemConfirm),
        { history, location }
    );

    return <OnHandWrp config={config}>{console.info("RENDER: OnHand TShirts")}</OnHandWrp>;
};

export default OnHandTShirts;
