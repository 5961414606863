import { AppRestaurant } from "../../../../../admin/AdminResources";
import {
    DeliveryFoodIngredient,
    DeliveryFoodSummaryConfigsSubType,
} from "../../../types/DeliveryFoodTypes";

export const DeliveryFoodSummarySalesConfigs: DeliveryFoodSummaryConfigsSubType = {
    title: ({
        ingredient,
        restaurant,
        type,
    }: {
        ingredient: DeliveryFoodIngredient;
        restaurant: AppRestaurant;
        type: string;
    }) => {
        return (
            <div>
                {type} Sales for <strong>{ingredient.data.name}</strong> at{" "}
                <strong>{restaurant.data.name}</strong>
            </div>
        );
    },
    content: ({
        data,
        total,
        totalWithoutSalesRatio,
        totalWithoutRatio,
        totalPortions,
        ingredient,
        restaurant,
    }: any) => {
        const DeliveryFoodSummarySaleItem = ({ ingredientName, saleItem, index }: any) => {
            const {
                menuItem: { name },
                quantitySold,
                quantityPerItem,
            } = saleItem;
            return (
                <li key={`food-sales-item-${index}`}>
                    Sold <span>{quantitySold}</span> x <strong>{name}</strong>, each had{" "}
                    <strong>{quantityPerItem}</strong> portions of <strong>{ingredientName}</strong>
                </li>
            );
        };

        return (
            <div>
                <ul>
                    {data.map((item: any, i: any) => {
                        return (
                            <DeliveryFoodSummarySaleItem
                                ingredientName={ingredient.data.name}
                                saleItem={item}
                                index={i}
                            />
                        );
                    })}
                </ul>
                <div>
                    <strong>Total Portions sold</strong>: {totalPortions}
                </div>
                <div>
                    <strong>Bulk size</strong>: {ingredient.pack.data.size}
                </div>
                <div>
                    <strong>Total bulks sold</strong>: {totalWithoutRatio}
                </div>
                <div>
                    <strong>Restaurant Ratio</strong>: {restaurant.data.ratio}
                </div>
                <div>
                    <strong>Total bulks sold * restaurant ratio</strong>: {totalWithoutSalesRatio}
                </div>
                <div>
                    <strong>Ingredient Ratio</strong>: {ingredient.data.salesRatio || 1}
                </div>
                <div>
                    <strong>Total bulks sold * restaurant ratio * ingredient ratios</strong>:{" "}
                    {total}
                </div>
            </div>
        );
    },
};
