import { ContainerConfigSection } from "./../ContainerConfigSection";
import ContainerConfigBuilder from "../ContainerConfigBuilder";
import { ContainerPaths } from "../ContainerPaths";
import {
    ADMINS,
    DATA_EDITOR_USERS,
    KITCHEN_USERS,
    MANAGERS,
    ORDERS_ADMIN,
    ORDERS_DELIVERY_USERS,
    ORDERS_USERS,
} from "../../services/AppUserGroupsData";
import OnHandOrders from "./on-hand/OnHandOrders";
import OnHandOrdersMenuItem from "./on-hand/OnHandOrdersMenuItem";
import DeliveryOrdersMenuItem from "./delivery/DeliveryOrdersMenuItem";
import DataEditorOrders from "./data-editor/DataEditorOrders";
import DataEditorOrdersMenuItem from "./data-editor/DataEditorOrdersMenuItem";
import DeliveryOrdersWrp from "./delivery/DeliveryOrdersWrp";

export const OrdersContainerConfig: ContainerConfigSection = {
    OnHand: new ContainerConfigBuilder("On Hand")
        .setPath(ContainerPaths.ON_HAND)
        .setUserAccess([ORDERS_ADMIN, KITCHEN_USERS, ORDERS_USERS, MANAGERS, ADMINS])
        .setComponent(OnHandOrders)
        .setMenuItem(OnHandOrdersMenuItem)
        .build(),
    Delivery: new ContainerConfigBuilder("Delivery")
        .setPath(ContainerPaths.DELIVERY)
        .setUserAccess([ORDERS_ADMIN, ORDERS_DELIVERY_USERS, ADMINS])
        .setComponent(DeliveryOrdersWrp)
        .setMenuItem(DeliveryOrdersMenuItem)
        .build(),
    DataEditor: new ContainerConfigBuilder("Editor")
        .setPath(ContainerPaths.EDITOR)
        .setUserAccess([ORDERS_ADMIN, DATA_EDITOR_USERS, ADMINS])
        .setComponent(DataEditorOrders)
        .setMenuItem(DataEditorOrdersMenuItem)
        .build(),
};
