import React, { useContext } from "react";
import deliveryFoodStyles from "../../DeliveryFood.module.css";
import {
    delivery,
    deliveryFixed,
    deliverySales,
    onHand,
    salesMultiple,
    salesSingle,
} from "./configs/DeliveryFoodHeaderCellConfigs";
import DeliveryFoodHeaderCell from "./DeliveryFoodHeaderCell";
import { DeliveryFoodContext } from "../../DeliveryFoodContext";
import { Resource } from "../../../../../resources/Resource";

const DeliveryFoodHeaderRestaurant = (restaurant: Resource, index: number) => {
    const { deliveryFoodState } = useContext(DeliveryFoodContext);

    const key = `header-restaurant-${index}`;

    const DeliveryCell = () => {
        if (!deliveryFoodState.mode.display.RST[restaurant.data.id]?.delivery) {
            return null;
        }

        return <DeliveryFoodHeaderCell config={delivery} />;
    };

    const DeliverySalesCell = () => {
        if (!deliveryFoodState.mode.display.RST[restaurant.data.id]?.deliverySales) {
            return null;
        }

        return <DeliveryFoodHeaderCell config={deliverySales} />;
    };

    const DeliveryFixedCell = () => {
        if (!deliveryFoodState.mode.display.RST[restaurant.data.id]?.deliveryFixed) {
            return null;
        }

        return <DeliveryFoodHeaderCell config={deliveryFixed} />;
    };

    const SalesMultipleCell = () => {
        if (!deliveryFoodState.mode.display.RST[restaurant.data.id]?.sales?.MULTIPLE) {
            return null;
        }

        return <DeliveryFoodHeaderCell config={salesMultiple} />;
    };

    const OnHandCell = () => {
        if (!deliveryFoodState.mode.display.RST[restaurant.data.id]?.onHand) {
            return null;
        }

        return <DeliveryFoodHeaderCell config={onHand} />;
    };

    const SalesSingleCell = () => {
        if (!deliveryFoodState.mode.display.RST[restaurant.data.id]?.sales?.SINGLE) {
            return null;
        }

        return <DeliveryFoodHeaderCell config={salesSingle} />;
    };

    return (
        <div key={key} className={deliveryFoodStyles.ItemRestaurantHeader}>
            <div className={`${deliveryFoodStyles.CellRestaurantName} truncate`}>
                {restaurant.data.name}
            </div>
            <div className={deliveryFoodStyles.CellsRestaurantSections}>
                <DeliveryCell />
                <DeliverySalesCell />
                <DeliveryFixedCell />
                <SalesMultipleCell />
                <OnHandCell />
                <SalesSingleCell />
            </div>
        </div>
    );
};

export default DeliveryFoodHeaderRestaurant;
