import React from "react";
import { FiLayers } from "react-icons/fi";

export default function OffSiteStockingMenuItem(name: string) {
    return [
        <FiLayers key={`${name}-icon`} size={"1.5em"} />,
        <div key={`${name}-text`}>
            <span>{name}</span>
        </div>,
    ];
}
