import React, { useContext } from "react";
import onHandItemStyles from "../../../on-hand/components/item/OnHandItem.module.css";
import onHandContentStyles from "../../../on-hand/components/content/OnHandContent.module.css";
import { OnHandContext } from "../../../on-hand/context/OnHandContext";
import OnHandUtils from "../../../on-hand/OnHandUtils";
import OnHandTShirtItemInput from "./OnHandTShirtItemInput";
import OnHandTShirtItemActions from "./OnHandTShirtItemActions";
import OnHandTShirtItemTitle from "./OnHandTShirtItemTitle";
import OnHandTShirtItemMeta from "./OnHandTShirtItemMeta";

const OnHandTShirtItem = (group, item) => {
    const { onHandState } = useContext(OnHandContext);

    if (!OnHandUtils.isSelectedItem(onHandState, item)) {
        return <div />;
    }

    const key = `on-hand-order-item-${item.data.id}`;

    return (
        <div key={key} className={onHandItemStyles.Details}>
            <div className={onHandContentStyles.ContentHeader} style={{ placeContent: "center" }}>
                {/*TODO: check if title is compatible*/}
                <OnHandTShirtItemTitle item={item} />
            </div>
            {/*TODO: check if this type of input is compatible*/}
            <OnHandTShirtItemInput item={item} />
            <OnHandTShirtItemActions />
            <OnHandTShirtItemMeta item={item} />
        </div>
    );
};

export default OnHandTShirtItem;
