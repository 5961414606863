import React from "react";
import onHandItemStyles from "../../../on-hand/components/item/OnHandItem.module.css";
import OffSiteStockingItemPrev from "./OffSiteStockingItemPrev";
import OffSiteStockingItemSkip from "./OffSiteStockingItemSkip";
import OffSiteStockingItemSubmit from "./OffSiteStockingItemSubmit";

export default function OffSiteStockingItemActions() {
    return (
        <div className={onHandItemStyles.Actions}>
            <OffSiteStockingItemPrev />
            <OffSiteStockingItemSubmit />
            <OffSiteStockingItemSkip />
        </div>
    );
}
