import { useState } from "react";
import { Resource } from "../../../../../resources/Resource";
import DataEditorItemDisplay from "./DataEditorItemDisplay";
import DataEditorItemEdit from "./DataEditorItemEdit";

export type DataEditorItemParams = { item: Resource; index: number };
export type DataEditorItemModeParams = DataEditorItemParams & {
    setIsEditing: Function;
};

function DataEditorItem({ item, index }: DataEditorItemParams) {
    const [isEditing, setIsEditing] = useState(false);

    if (isEditing) {
        return <DataEditorItemEdit item={item} index={index} setIsEditing={setIsEditing} />;
    }

    return <DataEditorItemDisplay item={item} index={index} setIsEditing={setIsEditing} />;
}

export default DataEditorItem;
