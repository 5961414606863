import { API, graphqlOperation } from "aws-amplify";
import { ModelConfig } from "./ModelConfig";
import * as graphQlOperations from "../graphql/graph-ql-operations";
import { Resource } from "../resources/Resource";

const queries: { [key: string]: any } = graphQlOperations.queries;
const mutations: { [key: string]: any } = graphQlOperations.mutations;

class ModelApi {
    public typeName: string;
    public limit = 100000;

    static async customMutation(input: any, mutationName: string): Promise<any> {
        const operation = graphqlOperation(mutations[mutationName], input);
        const response = (await API.graphql(operation)) as {
            data: { [key: string]: any };
        };
        return response.data[mutationName];
    }

    async list(filter?: any): Promise<any> {
        const queryName = `list${this.typeName}s`;
        const operation = queries[queryName];

        const variables = { limit: this.limit, filter: filter || null };
        const response = (await API.graphql(graphqlOperation(operation, variables))) as {
            data: { [key: string]: any };
        };
        return response.data[queryName].items;
    }

    async get(input: any): Promise<any> {
        const operationName = `get${this.typeName}`;
        const operation = queries[operationName];

        const response = (await API.graphql(graphqlOperation(operation, input))) as {
            data: { [key: string]: any };
        };
        return response.data[operationName].item;
    }

    async restore(item: any): Promise<any> {
        const operationName = `get${this.typeName}`;
        const operation = queries[operationName];

        const input = item.getModel().extractKeyObject(item);
        const variables = { limit: this.limit, input };

        const response = (await API.graphql(graphqlOperation(operation, variables))) as {
            data: { [key: string]: any };
        };
        // TODO: check the result holds only one item; throw error otherwise
        item.data = response.data[operationName];
        return item;
    }

    async update(input: any, keepCreatedAt = false, keepUpdatedAt = false): Promise<any> {
        const mutationName = `update${this.typeName}`;
        const mutation = mutations[mutationName];

        if (!keepCreatedAt) {
            delete input.createdAt;
        }
        if (!keepUpdatedAt) {
            delete input.updatedAt;
        }

        const response = (await API.graphql(graphqlOperation(mutation, { input }))) as {
            data: { [key: string]: any };
        };
        return response.data[mutationName];
    }

    async create(input: any): Promise<any> {
        const mutationName = `create${this.typeName}`;
        const mutation = mutations[mutationName];

        console.log(`Creating ${this.typeName}, from data: ${JSON.stringify(input)}`);

        const response = (await API.graphql(graphqlOperation(mutation, { input }))) as {
            data: { [key: string]: any };
        };
        return response.data[mutationName];
    }

    async delete(item: Resource, shouldConfirm = true): Promise<any> {
        if (shouldConfirm) {
            if (!window.confirm("Are you sure you want to delete this item?")) {
                return false;
            }
        }

        const mutationName = `delete${this.typeName}`;
        const mutation = mutations[mutationName];

        const input = item.getModel().extractKeyObject(item);
        const response = (await API.graphql(graphqlOperation(mutation, { input }))) as {
            data: { [key: string]: any };
        };
        return response.data[mutationName];
    }

    constructor(config: ModelConfig) {
        this.typeName = config.typeName;
        this.limit = config.limit;
    }
}

export default ModelApi;
