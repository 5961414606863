import { Resource } from "../../../../../resources/Resource";
import dataEditorStyles from "../../../DataEditor.module.css";
import DataEditorHandle from "./DataEditorHandle";
import DataEditorItem from "./DataEditorItem";

export default function DataEditorItemWrp({
    item,
    index,
    hideHandle,
}: {
    item: Resource;
    index: number;
    hideHandle: boolean;
}) {
    const name = item.getModel().config.typeName;
    const key = `item-${name}-${index}`;

    return (
        <div key={key} className={dataEditorStyles.RowWrp}>
            <DataEditorItem item={item} index={index} />
            {hideHandle ? <div /> : <DataEditorHandle />}
        </div>
    );
}
