import { IconButton } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { useContext } from "react";
import { FcDeleteDatabase } from "react-icons/fc";
import { useGlobalSpinnerActionsContext } from "../../../../components/global-spinner/GlobalSpinnerContext";
import { AppContext } from "../../../../context/AppContext";
import { Resource } from "../../../../resources/Resource";
import { seriesPromises } from "../../../../resources/ResourcesService";
import AppUserGroups from "../../../../services/AppUserGroups";
import { DataEditorContext } from "../../context/DataEditorContext";

const DataEditorDeleteAllBtn = () => {
    const setGlobalSpinner = useGlobalSpinnerActionsContext();
    const { appState } = useContext(AppContext);
    const { dataEditorState, clearAllItemsFromSelectedResource } = useContext(DataEditorContext);

    const deleteAll = async () => {
        if (!window.confirm("Are you sure you want to delete all items?")) {
            return false;
        }

        setGlobalSpinner(true);

        const storeKey = dataEditorState.getSelectedResource().getModel().getResourceStoreKey();
        const selectedResourceItems = dataEditorState.getItemsByStoreKey(storeKey);

        // no items to delete
        if (!selectedResourceItems) {
            setGlobalSpinner(false);
            return false;
        }

        try {
            const itemsDeletionPromises = selectedResourceItems.map(
                (selectedResourceItem: Resource) => {
                    return dataEditorState
                        .getSelectedResource()
                        .getModel()
                        .api.delete(selectedResourceItem, false);
                }
            );

            await seriesPromises(itemsDeletionPromises);

            return clearAllItemsFromSelectedResource();
        } catch (e: any) {
            throw Error(e.message);
        } finally {
            setGlobalSpinner(false);
        }
    };

    if (!AppUserGroups.hasDevAccess(appState.user.groups)) {
        return null;
    }

    return (
        <Tooltip title={"Delete All"}>
            <IconButton onClick={deleteAll}>
                <FcDeleteDatabase size={16} />
            </IconButton>
        </Tooltip>
    );
};

export default DataEditorDeleteAllBtn;
