import React, { useContext } from "react";
import deliveryFoodStyles from "../../DeliveryFood.module.css";
import {
    onHand,
    prep,
    prepTime,
    prepForDeliveryTime,
    prepForDelivery,
} from "./configs/DeliveryFoodHeaderCellConfigs";
import DeliveryFoodHeaderCell from "./DeliveryFoodHeaderCell";
import { DeliveryFoodContext } from "../../DeliveryFoodContext";

const DeliveryFoodHeaderCommissary = (restaurant: any, index: number) => {
    const { deliveryFoodState } = useContext(DeliveryFoodContext);

    const key = `header-commissary-${index}`;

    const PrepForDeliveryComCell = () => {
        if (!deliveryFoodState.mode.display.COM.prepForDelivery) {
            return null;
        }

        return <DeliveryFoodHeaderCell config={prepForDelivery} />;
    };

    const OnHandComCell = () => {
        if (!deliveryFoodState.mode.display.COM.onHand) {
            return null;
        }

        return <DeliveryFoodHeaderCell config={onHand} />;
    };

    const PrepComCell = () => {
        if (!deliveryFoodState.mode.display.COM.prep) {
            return null;
        }

        return <DeliveryFoodHeaderCell config={prep} />;
    };

    const PrepTimeComCell = () => {
        if (!deliveryFoodState.mode.display.COM.prepTime) {
            return null;
        }

        return <DeliveryFoodHeaderCell config={prepTime} />;
    };

    const PrepForDeliveryTimeComCell = () => {
        if (!deliveryFoodState.mode.display.COM.prepForDeliveryTime) {
            return null;
        }

        return <DeliveryFoodHeaderCell config={prepForDeliveryTime} />;
    };

    return (
        <div key={key} className={deliveryFoodStyles.ItemRestaurantHeader}>
            <div className={`${deliveryFoodStyles.CellRestaurantName} truncate`}>
                {restaurant.data.name}
            </div>
            <div className={deliveryFoodStyles.CellsRestaurantSections}>
                <PrepForDeliveryComCell />
                <OnHandComCell />
                <PrepComCell />
                <PrepTimeComCell />
                <PrepForDeliveryTimeComCell />
            </div>
        </div>
    );
};

export default DeliveryFoodHeaderCommissary;
