import deliveryFoodStyles from "../../DeliveryFood.module.css";

export const DeliveryFoodSignature = () => {
    const key = "delivery-food-signature";
    const className = `${deliveryFoodStyles.Signature}`;
    return (
        <div key={key} className={className}>
            <div>Delivery Signature</div>
            <div />
            <div>Receiving Signature</div>
        </div>
    );
};
