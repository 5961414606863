import { FormControl, Input, InputAdornment } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { OnHandContext } from "../../../on-hand/context/OnHandContext";
import OffSiteTasksUtils from "../../tasks/OffSiteTasksUtils";
import OffSiteStockingItemClearInput from "./OffSiteStockingItemClearInput";
import styles from "./OffSiteStockingItemTotal.module.css";

export default function OffSiteStockingItemTotal() {
    const { onHandState, onSelectedItemStockingTransactionUpdate } = useContext(
        OnHandContext
    ) as any;
    const [totalAmount, setTotalAmount] = useState(null);

    useEffect(() => {
        if (totalAmount === null) {
            OffSiteTasksUtils.getOffSiteItemInStockAmount(
                onHandState.restaurant?.group?.item?.data?.id
            ).then((totalAmount: any) => setTotalAmount(totalAmount));
        }
    }, []);

    const handleQuantityChange = (event: any) => {
        const addedQuantity = +event.target.value || 0;
        return addedQuantity === 0 ? null : onSelectedItemStockingTransactionUpdate(addedQuantity);
    };

    // TODO: until a solution is found, do not allow values below the sum of required values
    // TODO: propose solution for partial completion
    const itemUnitDisplay = OffSiteTasksUtils.getUnitDisplay(onHandState.restaurant?.group?.item);

    return (
        <div key={""} className={styles.Middle}>
            <div className={styles.TotalAmountWrp}>
                <span className={styles.TotalAmount}>{totalAmount}</span>
                <span className={styles.TextEllipsis}>in stock</span>
            </div>
            <div className={styles.InputWrp} style={{ margin: "0.25em 0", width: "unset" }}>
                <FormControl>
                    <Input
                        id="taking-amount"
                        value={onHandState.restaurant?.group?.item?.stockingQuantity || 0}
                        onChange={handleQuantityChange}
                        endAdornment={
                            <InputAdornment position="end">{itemUnitDisplay}</InputAdornment>
                        }
                        aria-describedby="taking-amount-helper-text"
                        inputProps={{
                            "aria-label": "taking-amount",
                        }}
                    />
                </FormControl>
            </div>
            <OffSiteStockingItemClearInput />
        </div>
    );
}
