import React from "react";
import deliveryDpsStyles from "./DeliveryDps.module.css";

function DeliveryDpsContent({ ingredients }) {
    return (
        <div className={deliveryDpsStyles.Content}>
            <div className={deliveryDpsStyles.Item}>
                <strong>ITEM</strong>
                <strong className={"no-print"}>PROJECTED</strong>
                <strong>PULL</strong>
            </div>
            {ingredients
                .filter(({ sold }) => !!sold)
                .map(({ data: { name }, soldDisplay, deliveryDisplay }) => (
                    <div className={deliveryDpsStyles.Item}>
                        <span>{name}</span>
                        <span className={"no-print"}>{soldDisplay}</span>
                        <span>{deliveryDisplay}</span>
                    </div>
                ))}
        </div>
    );
}

export default DeliveryDpsContent;
