import React, { useContext, useEffect } from "react";
import deliveryFoodStyles from "../../DeliveryFood.module.css";
import { DeliveryFoodContext } from "../../DeliveryFoodContext";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import AppUtilsService from "../../../../../services/AppUtilsService";

const DeliveryFoodToolbar = () => {
    const { deliveryFoodState, setMode } = useContext(DeliveryFoodContext);

    useEffect(() => {}, [deliveryFoodState.modes]);

    const handleModeChange = (event: any) => setMode(event.target.value);

    if (!deliveryFoodState.mode) {
        return <div />;
    }

    const handlePrint = () => {
        const locationIdentifier = deliveryFoodState?.mode?.label || "";
        return AppUtilsService.print(locationIdentifier);
    };

    // use this part to share some info on the toolbar, depending on the mode
    const toolbarInfo = deliveryFoodState.mode.getToolbarInfo(deliveryFoodState);

    return (
        <div className={`no-print ${deliveryFoodStyles.Toolbar}`}>
            <FormControl>
                <Select
                    labelId="mode-selection"
                    id="mode-selection"
                    value={deliveryFoodState.mode}
                    onChange={handleModeChange}
                >
                    {deliveryFoodState.modes.map((mode: any, index: number) => {
                        return (
                            <MenuItem key={`mode-${index}`} value={mode}>
                                {mode.label}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <div className={deliveryFoodStyles.ToolbarInfo}>{toolbarInfo}</div>
            <div className={"no-print"}>
                <Button onClick={handlePrint} color={"secondary"} variant={"contained"}>
                    Print
                </Button>
            </div>
        </div>
    );
};

export default DeliveryFoodToolbar;
