export type UserGroupType = string;

export const DEVS: UserGroupType = "Devs";
export const ADMINS: UserGroupType = "Admins";
export const DATA_EDITOR_USERS: UserGroupType = "DataEditorUsers";
export const MANAGERS: UserGroupType = "Managers";
export const ORDERS_ADMIN: UserGroupType = "OrdersAdmin";
export const KITCHEN_DELIVERY_USERS: UserGroupType = "KitchenDeliveryUsers";
export const ORDERS_DELIVERY_USERS: UserGroupType = "OrdersDeliveryUsers";
export const KITCHEN_USERS: UserGroupType = "KitchenUsers";
export const ORDERS_USERS: UserGroupType = "OrdersUsers";
export const STORE_USERS: UserGroupType = "StoreUsers";
export const WAREHOUSE_USERS: UserGroupType = "WarehouseUsers";
export const OFFICE_USERS: UserGroupType = "OfficeUsers";
export const BAR_USERS: UserGroupType = "BarUsers";
export const ON_HAND_UNLOCK: UserGroupType = "OnHandUnlock";
export const COMMISSARY_USERS: UserGroupType = "CommissaryUsers";
export const LIZARD_USERS: UserGroupType = "LizardUsers";
export const FREDDIES_USERS: UserGroupType = "FreddiesUsers";
export const TEST: UserGroupType = "Test";
export const GUESTS: UserGroupType = "Guests";
