import React from "react";
import { RiAdminLine } from "react-icons/ri";
import { ADMINS, DEVS, UserGroupType } from "../../services/AppUserGroupsData";
import ContainerBasePaths from "../ContainerBasePaths";
import { ContainerConfig } from "../ContainerConfig";
import { Section } from "../sections/Section";
import { AdminContainerConfig } from "./AdminContainerConfig";

export class AdminSection extends Section {
    renderIcon(disabled: boolean): JSX.Element {
        return <RiAdminLine size={this.iconSize} color={"inherit"} />;
    }

    renderHeaderIcon(): JSX.Element {
        return <RiAdminLine size={this.iconHeaderSize} color={this.iconHeaderColor} />;
    }

    renderHomeIcon() {
        return <RiAdminLine size={this.iconHomeSize} color={this.iconHomeColor} />;
    }

    getDefaultPath(userGroups: UserGroupType[]) {
        if (userGroups.includes(ADMINS) || userGroups.includes(DEVS)) {
            return AdminContainerConfig.DataEditor.url;
        }
        console.error("Couldn't match the user to any known section pages", userGroups);
        return super.getDefaultPath(userGroups);
    }

    constructor(
        pages: ContainerConfig[],
        name: string,
        allowedGroups: UserGroupType[],
        disabled: boolean
    ) {
        super(pages, name, allowedGroups, disabled, ContainerBasePaths.ADMIN);
    }
}
