import MenusActions from "./MenusActions";
import MenusUtils from "../services/MenusUtils";
import AppUtilsService from "../../../services/AppUtilsService";

export default function MenusReducer(state, action) {
    if (action.type === MenusActions.LOAD_MENUS) {
        console.info("CONTEXT: MENUS: LOAD_MENUS");
        state.restaurants = action.payload.restaurants;
        state.restaurant = action.payload.restaurant;
        state.completionScore = action.payload.completionScore;
        state.config = action.payload.config;
        state.sectionName = action.payload.sectionName;
        return { ...state };
    }

    if (action.type === MenusActions.CHANGE_RESTAURANT) {
        console.info("CONTEXT: MENUS: CHANGE_RESTAURANT");
        const restaurant = state.restaurants[action.payload.restaurantId];
        AppUtilsService.setRestaurant(state.sectionName, "sales", restaurant, state.config.router);
        state.restaurant = restaurant;
        return { ...state };
    }

    if (action.type === MenusActions.UPLOAD_SALES) {
        console.info("CONTEXT: MENUS: UPLOAD_SALES");
        state.restaurant = action.payload.restaurant;
        state.restaurants[state.restaurant.data.id] = state.restaurant;
        state.completionScore = MenusUtils.getTotalCompletionScore(
            Object.values(state.restaurants)
        );
        return { ...state };
    }

    if (action.type === MenusActions.OPEN_ADD_DIALOG) {
        console.info("CONTEXT: MENUS: OPEN_ADD_DIALOG");
        state.openAddDialog = true;
        return { ...state };
    }

    if (action.type === MenusActions.CLOSE_ADD_DIALOG) {
        console.info("CONTEXT: MENUS: CLOSE_ADD_DIALOG");
        state.openAddDialog = false;
        return { ...state };
    }

    return { ...state };
}
