import { Button } from "@material-ui/core";
import { useContext } from "react";
import { AppRestaurant } from "../../../../admin/AdminResources";
import { OnHandContext } from "../../../../on-hand/context/OnHandContext";
import { OffSiteContext } from "../../../context/OffSiteContext";
import { AppOffSiteTransactionStatuses } from "../../../OffSiteResources";
import styles from "./OffSiteDeliveryItemRestaurants.module.css";

export default function OffSiteDeliveryItemRestaurants() {
    const { offSiteState } = useContext(OffSiteContext);
    const { onHandState } = useContext(OnHandContext) as any;

    const getSelectedItemTasks = () => {
        const itemId = onHandState?.restaurant?.group?.item?.data?.id;

        if (!itemId) {
            return null;
        }

        return offSiteState.offSiteTasksByStatusAndIdAndRest?.[
            AppOffSiteTransactionStatuses.PENDING
        ][itemId];
    };

    const selectedItemTasks = getSelectedItemTasks();

    return (
        <div className={styles.Group}>
            {offSiteState.destinationRestaurants.map(
                (tasksDestinationRestaurant: AppRestaurant) => {
                    const tasksDestinationRestaurantId = tasksDestinationRestaurant.data.id;
                    if (!tasksDestinationRestaurantId) {
                        return null;
                    }
                    const tasksDestinationRestaurantName = tasksDestinationRestaurant.data.name;
                    const selectedItemDestinationRestaurantTasks =
                        selectedItemTasks?.[tasksDestinationRestaurantId];
                    const quantity = selectedItemDestinationRestaurantTasks?.[0].data.quantity || 0;

                    const quantityDisplay = quantity === 0 ? "" : ` (${quantity})`;
                    const variant = selectedItemDestinationRestaurantTasks
                        ? "contained"
                        : "outlined";
                    // TODO: APPLY FILTER TOO AS A DISABLED CONDITION
                    const disabled = !selectedItemDestinationRestaurantTasks;

                    return (
                        <Button
                            className={styles.Button}
                            color={"secondary"}
                            variant={variant}
                            disabled={disabled}
                        >
                            <div className={styles.ButtonContent}>
                                <span className={styles.TextEllipsis}>
                                    {tasksDestinationRestaurantName}
                                </span>
                                <span>{quantityDisplay}</span>
                            </div>
                        </Button>
                    );
                }
            )}
        </div>
    );
}
