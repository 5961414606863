const OPEN_EMAIL_DIALOG = "OPEN_EMAIL_DIALOG";
const CLOSE_EMAIL_DIALOG = "CLOSE_EMAIL_DIALOG";
const LOAD_DATA = "LOAD_DATA";
const CHANGE_RESTAURANT = "CHANGE_RESTAURANT";
const SEND_EMAIL = "SEND_EMAIL";
const CHANGE_VENDOR = "CHANGE_VENDOR";
const SELECT_PREV_EMAIL_VENDOR = "SELECT_PREV_EMAIL_VENDOR";
const SELECT_NEXT_EMAIL_VENDOR = "SELECT_NEXT_EMAIL_VENDOR";
const UPDATE_EMAIL_VENDOR_NOTES = "UPDATE_EMAIL_VENDOR_NOTES";
const UPDATE_DELIVERY_ORDER = "UPDATE_DELIVERY_ORDER";
const SELECT_EMAIL_VENDOR_ORDERS = "SELECT_EMAIL_VENDOR_ORDERS";
const UPDATE_EMAIL_VENDOR_ORDERS = "UPDATE_EMAIL_VENDOR_ORDERS";
const ADD_NEW_VENDOR = "ADD_NEW_VENDOR";
const ENABLE_ADD_NEW_VENDOR = "ENABLE_ADD_NEW_VENDOR";

export {
    LOAD_DATA,
    OPEN_EMAIL_DIALOG,
    CLOSE_EMAIL_DIALOG,
    CHANGE_RESTAURANT,
    SEND_EMAIL,
    CHANGE_VENDOR,
    SELECT_PREV_EMAIL_VENDOR,
    SELECT_NEXT_EMAIL_VENDOR,
    UPDATE_EMAIL_VENDOR_NOTES,
    UPDATE_DELIVERY_ORDER,
    SELECT_EMAIL_VENDOR_ORDERS,
    UPDATE_EMAIL_VENDOR_ORDERS,
    ADD_NEW_VENDOR,
    ENABLE_ADD_NEW_VENDOR,
};
