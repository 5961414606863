import { ADMINS, DEVS, OFFICE_USERS, WAREHOUSE_USERS } from "../../services/AppUserGroupsData";
import { SectionNames } from "../sections/SectionNames";
import { UserGroupType } from "./../../services/AppUserGroupsData";
import { ContainerConfig } from "./../ContainerConfig";
import OffSiteContextProvider from "./context/OffSiteContext";
import { OffSiteContainerConfig } from "./OffSiteContainerConfig";
import { OffSiteSection } from "./OffSiteSection";
import OffSiteTasks from "./tasks/OffSiteTasks";

const offSiteSectionPages: ContainerConfig[] = [
    OffSiteContainerConfig.Delivery,
    OffSiteContainerConfig.Reception,
    OffSiteContainerConfig.Stocking,
    OffSiteContainerConfig.Reorders,
    OffSiteContainerConfig.DataEditor,
];

const offSiteSectionAllowedUserGroups: UserGroupType[] = [
    WAREHOUSE_USERS,
    OFFICE_USERS,
    ADMINS,
    DEVS,
];

const isOffSiteSectionDisabled = false;

const offSiteSectionTasksComponent = OffSiteTasks;

const offSiteSectionWrapperComponent = OffSiteContextProvider;

export const OffSiteSectionConfig = new OffSiteSection(
    offSiteSectionPages,
    SectionNames.OFF_SITE,
    offSiteSectionAllowedUserGroups,
    isOffSiteSectionDisabled,
    offSiteSectionTasksComponent,
    offSiteSectionWrapperComponent
);
