import React from "react";
import { AppRestaurant } from "../AdminResources";
import DataEditor from "../../data-editor/DataEditor";
import { DataEditorProvider } from "../../data-editor/context/DataEditorContext";
import { DataEditorResource } from "../../data-editor/types/DataEditorTypes";

const DataEditorAdmin = (props: any) => {
    const { history, location } = props;

    const pageResources = [new DataEditorResource(AppRestaurant, { isVisibleInDataEditor: true })];

    console.log("Rendering DataEditorAdmin");

    return (
        <DataEditorProvider resources={pageResources} location={location} history={history}>
            <DataEditor location={location} />
        </DataEditorProvider>
    );
};

export default DataEditorAdmin;
