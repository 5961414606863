import { IconButton } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { useContext } from "react";
import { MdRefresh } from "react-icons/md";
import { useGlobalSpinnerActionsContext } from "../../../../components/global-spinner/GlobalSpinnerContext";
import { AppContext } from "../../../../context/AppContext";
import { fetchResources } from "../../../../resources/ResourcesService";
import { DataEditorContext } from "../../context/DataEditorContext";

const DataEditorRefreshBtn = () => {
    const setGlobalSpinner = useGlobalSpinnerActionsContext();
    const { appState } = useContext(AppContext);
    const { dataEditorState, updateResourceItems } = useContext(DataEditorContext);

    const onRefreshData = async () => {
        try {
            setGlobalSpinner(true);
            const resource = dataEditorState.getSelectedResource();
            const filter = resource.getModel().getFilter(appState);

            const [resourceItems] = await fetchResources([[resource, filter]]);

            updateResourceItems(resourceItems);
        } catch (e: any) {
            throw Error(e.message);
        } finally {
            setGlobalSpinner(false);
        }
    };

    return (
        <Tooltip title={"Refresh Data"}>
            <IconButton onClick={onRefreshData} style={{ padding: "0 2px" }}>
                <MdRefresh size={20} />
            </IconButton>
        </Tooltip>
    );
};

export default DataEditorRefreshBtn;
