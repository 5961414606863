import Typography from "@material-ui/core/Typography";
import React from "react";
import AppDayModel from "../../../../components/day/AppDayModel";
import onHandItemMetaDataStyles from "./OnHandItemMetaData.module.css";

const OnHandItemMetaData = ({ lastUpdatedAt, lastUpdatedBy }) => {
    if (!lastUpdatedBy || !lastUpdatedAt) {
        return (
            <Typography
                variant="caption"
                display="block"
                gutterBottom
                className={onHandItemMetaDataStyles.Main}
            >
                Item was not yet updated!
            </Typography>
        );
    }

    lastUpdatedAt = AppDayModel.getTimeDisplay(lastUpdatedAt);

    return (
        <Typography
            variant="caption"
            display="block"
            gutterBottom
            className={onHandItemMetaDataStyles.Main}
        >
            Updated by <strong>{lastUpdatedBy}</strong> on <strong>{lastUpdatedAt}</strong>
        </Typography>
    );
};

export default OnHandItemMetaData;
