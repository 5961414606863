import React, { useContext } from "react";
import { IconButton } from "@material-ui/core";
import { MdOutlineSmsFailed } from "react-icons/md";
import { AppFeedbackContext } from "./AppFeedbackContext";

export default function AppFeedbackButton() {
    const { openDialog } = useContext(AppFeedbackContext);

    return (
        <IconButton onClick={openDialog} color="primary">
            <MdOutlineSmsFailed size="20" color="white" />
        </IconButton>
    );
}
