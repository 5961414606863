import MocksApi from "../mocks/MocksApi";
import { buildS3GetRequest, buildS3PutRequest, sendS3Requests } from "../services/AppS3Service";
import { AppS3ClientResponseBody } from "../services/AppS3Types";
import AppUtilsService from "../services/AppUtilsService";
import { Resource } from "./Resource";
import { ResourceRequests } from "./ResourceTypes";

export async function seriesPromises(promises: (Promise<any> | undefined)[]): Promise<any> {
    /*
     * serial executes Promises sequentially.
     * @param {funcs} An array of funcs that return promises.
     * @example
     * const urls = ['/url1', '/url2', '/url3']
     * serial(urls.map(url => () => $.ajax(url)))
     *     .then(console.log.bind(console))
     */
    const serial = (funcs: any[]) =>
        funcs.reduce(
            (promise, func) =>
                promise.then(
                    (result: any) =>
                        func().then(
                            (response: any) => {
                                result.push(response);
                                return result;
                            },
                            (err: any) => console.error(JSON.stringify(err))
                        ),
                    (err: any) => console.error(JSON.stringify(err))
                ),
            Promise.resolve([])
        );

    return await serial(promises.map((promise) => () => promise));
}

// TODO: use a global cache option as the third parameter
// TODO: when using cache, check if the data is older than today
// TODO: if data is from today, use it and update inner DB
// TODO: if data is not older than today, take it from innerDB
// TODO: make sure that a full-page reload gets all of the data fresh
export async function fetchResources(
    resources: ResourceRequests,
    useMock?: boolean
): Promise<any[]> {
    let responses = [];
    try {
        const promises = resources
            .map(([resourceType, filter]) => {
                if (MocksApi.canUseMock(useMock, resourceType, filter)) {
                    return MocksApi.list(resourceType);
                }
                return resourceType.getModel().api.list(filter);
            })
            .filter((promise) => !!promise);

        responses = await seriesPromises(promises);
    } catch (e) {
        throw new Error(JSON.stringify(e, null, 2));
    }

    return responses.map((apiDataItems: any[], i: number) => {
        let items = apiDataItems.map((dbItem: any) => {
            return new resources[i][0](dbItem);
        });

        items = resources[i][0].getModel().editor.sort(items);

        return items;
    });
}

export async function updateDailyData(data: any, key: string, keyData: any): Promise<void> {
    if (!key && keyData) {
        const { sectionName, day, type, restaurantAlias } = keyData;
        // STEP 2: build the daily record key
        key = AppUtilsService.getDailyDataKey(sectionName, day, type, restaurantAlias);
    }

    // STEP 3: get the latest stored data and append the new values to it, with de-duplication
    let existingData: any[] = [];
    try {
        const result = await readS3File(key);
        if (result !== null) {
            existingData = result.data || [];
        }
    } catch (e: any) {
        console.error(e.message);
    }

    const mergedData = [existingData, data].flat().filter((x: any) => x);
    const serializedData = mergedData.map((x) => JSON.stringify(x));
    const deDupedData = Array.from(new Set(serializedData));
    const deSerializedData = deDupedData.map((x) => JSON.parse(x));

    // STEP 4: persist data to S3
    try {
        const requests = [buildS3PutRequest(key, deSerializedData)];

        await sendS3Requests(requests);

        console.log("Updated!");
    } catch (e: any) {
        alert("Unable to save your changes!");

        console.error(e);
    }
}

export async function readS3File(key: string): Promise<AppS3ClientResponseBody | null> {
    let result;

    const request = buildS3GetRequest(key);
    const results = await sendS3Requests([request]);
    result = Array.isArray(results) ? results[0] : null;

    return result;
}

// grabs resource items from multiple files and build resource instances out of them
export async function readSectionS3Files(
    keys: string[],
    resource: typeof Resource
): Promise<Resource[]> {
    let result;

    let results = await sendS3Requests(keys.map(buildS3GetRequest));

    if (results === null) {
        return [];
    }

    result = buildResourcesFromResults(results, resource);

    return result;

    //----------------------------------------------------------------
    function buildResourcesFromResults(
        results: AppS3ClientResponseBody[],
        resource: typeof Resource
    ) {
        return results
            .map((result) => result.data || [])
            .flat()
            .map((item: any) => new resource(item));
    }
}

export async function retry(asyncCallback: any, retriesLeft = 2, delay = 100): Promise<void> {
    let success = false;

    while (!success && retriesLeft > 0) {
        try {
            await asyncCallback();
            retriesLeft = 0;
            success = true;
        } catch (e: any) {
            console.error(e.message);
            retriesLeft -= 1;
            await AppUtilsService.delay(delay);
        }
    }

    if (!success) {
        throw Error("Failed all retry attempts!");
    }
}
