import { DataEditorState } from "../data-editor/types/DataEditorTypes";
import {
    OffSiteAlert,
    OffSiteArea,
    OffSiteItem,
    OffSiteTransaction,
    OffSiteUnit,
} from "../../graphql/API";
import { Model } from "../../model/Model";
import { ModelConfig } from "../../model/ModelConfig";
import { ResourceColumnNumber } from "../../resources/columns/ResourceColumnNumber";
import { ResourceColumnSelect } from "../../resources/columns/select/ResourceColumnSelect";
import { ResourceColumnText } from "../../resources/columns/ResourceColumnText";
import { Resource } from "../../resources/Resource";

/* ---------- AppOffSiteTransactionTypes ---------- */
const AppOffSiteTransactionTypes = {
    DELIVERY: "DELIVERY",
    STOCKING: "STOCKING",
    CORRECTION: "CORRECTION",
};

/* ---------- AppOffSiteTransactionStatuses ---------- */
export type OffSiteTransactionStatuses = {
    PENDING: "PENDING";
    CANCELLED: "CANCELLED";
    LOADED: "LOADED";
    TRANSIT: "TRANSIT";
    COMPLETED: "COMPLETED";
};

const AppOffSiteTransactionStatuses: { [key: string]: keyof OffSiteTransactionStatuses } = {
    PENDING: "PENDING",
    CANCELLED: "CANCELLED",
    LOADED: "LOADED",
    TRANSIT: "TRANSIT",
    COMPLETED: "COMPLETED",
};

/* ---------- AppOffSiteAlertStatuses ---------- */
const AppOffSiteAlertStatuses = {
    PENDING: "PENDING",
    CANCELLED: "CANCELLED",
    COMPLETED: "COMPLETED",
};

/* ---------- AppOffSiteAlertLocalTypes ---------- */
const AppOffSiteAlertLocalTypes = {
    OPEN: "OPEN",
    SNOOZED: "SNOOZED",
    COMPLETED: "COMPLETED",
    OTHER: "OTHER",
};

/* ---------- AppOffSiteItem ---------- */
const appOffSiteItem = new Model(
    new ModelConfig(
        "OffSiteItem",
        10000,
        200,
        "offSiteItem",
        "Items",
        "OffSiteItem",
        "offSiteItemId",
        [
            new ResourceColumnText("id", "Id").setHideOnAdd().setKey(),
            new ResourceColumnText("name", "Name")
                .setVisible()
                .setEditable()
                .setWidth("M")
                .setKey(),
            new ResourceColumnSelect("offSiteAreaId", "Area")
                .setKey()
                .setVisible()
                .setSortCallback((a: any, b: any) => parseInt(a) - parseInt(b))
                .setWidth("M"),
            new ResourceColumnSelect("offSiteSubUnitId", "Sub-Unit")
                .setVisible()
                .setResourceRefKeyAlias("offSiteUnitId")
                .setEditable()
                .setWidth("S")
                .setOptional(),
            new ResourceColumnNumber("conversion", "conv.")
                .setVisible()
                .setEditable()
                .setWidth("S"),
            new ResourceColumnSelect("offSiteUnitId", "Unit")
                .setVisible()
                .setResourceRefKeyAlias("offSiteUnitId")
                .setEditable()
                .setWidth("S"),
            new ResourceColumnNumber("alertThreshold", "Alert")
                .setVisible()
                .setOptional()
                .setEditable()
                .setWidth("S"),
            new ResourceColumnNumber("par", "Par").setVisible().setEditable().setWidth("S"),
            new ResourceColumnNumber("order", "#")
                .setVisible()
                .setHideOnAdd()
                .setEditable()
                .setWidth("XS")
                .setSortDirection("ASC", 0)
                .setSortCallback((a: any, b: any) => parseInt(a) - parseInt(b)),
            new ResourceColumnText("lastUpdatedBy", "Updated By").setOptional(),
            new ResourceColumnNumber("lastUpdatedAt", "Update Time").setOptional(),
        ]
    )
);

class AppOffSiteItem extends Resource {
    static getModel(): Model {
        return appOffSiteItem;
    }

    getModel(): Model {
        return appOffSiteItem;
    }

    constructor(data: OffSiteItem) {
        super(data, appOffSiteItem);
    }
}

/* ---------- OffSiteArea ---------- */
class AppOffSiteAreaModel extends Model {
    refDisplay(data: any, dataEditorState?: DataEditorState) {
        if (dataEditorState) {
            return super.refChainDisplay(data, dataEditorState, "restaurantId");
        }
        return super.refDisplay(data);
    }
}

const appOffSiteAreaModel = new AppOffSiteAreaModel(
    new ModelConfig(
        "OffSiteArea",
        1000,
        200,
        "offSiteAreas",
        "Areas",
        "OffSiteArea",
        "offSiteAreaId",
        [
            new ResourceColumnText("id", "Id").setHideOnAdd().setKey(),
            new ResourceColumnSelect("restaurantId", "Location")
                .setKey()
                .setSortCallback((a: any, b: any) => parseInt(a) - parseInt(b))
                .setVisible()
                .setWidth("S"),
            new ResourceColumnText("name", "Name").setVisible().setEditable().setWidth("M"),
            new ResourceColumnNumber("order", "#")
                .setHideOnAdd()
                .setEditable()
                .setVisible()
                .setWidth("XS")
                .setSortDirection("ASC", 0)
                .setSortCallback((a: any, b: any) => parseInt(a) - parseInt(b)),
            new ResourceColumnText("lastUpdatedBy", "Updated By").setOptional(),
            new ResourceColumnNumber("lastUpdatedAt", "Update Time").setOptional(),
        ]
    )
);

class AppOffSiteArea extends Resource {
    static getModel(): AppOffSiteAreaModel {
        return appOffSiteAreaModel;
    }

    getModel(): AppOffSiteAreaModel {
        return appOffSiteAreaModel;
    }

    constructor(data: OffSiteArea) {
        super(data, appOffSiteAreaModel);
    }
}

/* ---------- AppOffSiteUnit ---------- */
const appOffSiteUnit = new Model(
    new ModelConfig(
        "OffSiteUnit",
        1000,
        200,
        "offSiteUnit",
        "Units",
        "OffSiteUnit",
        "offSiteUnitId",
        [
            new ResourceColumnText("id", "Id").setHideOnAdd().setKey(),
            new ResourceColumnText("name", "Name")
                .setVisible()
                .setEditable()
                .setWidth("M")
                .setKey(),
            new ResourceColumnNumber("order", "#")
                .setHideOnAdd()
                .setEditable()
                .setVisible()
                .setWidth("XS")
                .setSortDirection("ASC", 0)
                .setSortCallback((a: any, b: any) => parseInt(a) - parseInt(b)),
            new ResourceColumnText("lastUpdatedBy", "Updated By").setOptional(),
            new ResourceColumnNumber("lastUpdatedAt", "Update Time").setOptional(),
        ]
    )
);

class AppOffSiteUnit extends Resource {
    static getModel(): Model {
        return appOffSiteUnit;
    }

    getModel(): Model {
        return appOffSiteUnit;
    }

    constructor(data: OffSiteUnit) {
        super(data, appOffSiteUnit);
    }
}

/* ---------- AppOffSiteTransaction ---------- */
const appOffSiteTransaction = new Model(
    new ModelConfig(
        "OffSiteTransaction",
        100000,
        400,
        "offSiteTransaction",
        "Transactions",
        "OffSiteTransaction",
        "offSiteTransactionId",
        [
            new ResourceColumnSelect(
                "status",
                "Status",
                Object.values(AppOffSiteTransactionStatuses)
            )
                .setVisible()
                .setEditable()
                .setWidth("S"),
            new ResourceColumnSelect("type", "Type", Object.values(AppOffSiteTransactionTypes))
                .setVisible()
                .setWidth("S")
                .setKey(),
            new ResourceColumnText("offSiteItemId", "Item").setVisible().setKey().setWidth("M"),
            new ResourceColumnNumber("quantity", "Qty").setVisible().setEditable().setWidth("XS"),
            new ResourceColumnSelect("restaurantId", "Rest.").setVisible().setWidth("XS"),
            new ResourceColumnText("createdAt", "Created")
                .setVisible()
                .setKey()
                .setWidth("M")
                .setSortDirection("DESC", 0),
            new ResourceColumnText("createdBy", "By").setVisible().setWidth("XS"),
        ]
    )
);

class AppOffSiteTransaction extends Resource {
    static getModel(): Model {
        return appOffSiteTransaction;
    }

    getModel(): Model {
        return appOffSiteTransaction;
    }

    constructor(data: OffSiteTransaction) {
        super(data, appOffSiteTransaction);
    }
}

/* ---------- AppOffSiteAlert ---------- */
const appOffSiteAlert = new Model(
    new ModelConfig(
        "OffSiteAlert",
        100000,
        400,
        "offSiteAlert",
        "Alerts",
        "OffSiteAlert",
        "offSiteAlertId",
        [
            new ResourceColumnText("offSiteItemId", "Item").setVisible().setKey().setWidth("M"),
            new ResourceColumnSelect("status", "Status", Object.values(AppOffSiteAlertStatuses))
                .setVisible()
                .setWidth("S"),
            new ResourceColumnText("createdAt", "Created At")
                .setVisible()
                .setKey()
                .setWidth("M")
                .setSortDirection("DESC", 0),
            new ResourceColumnText("snoozedUntil", "Snoozed Until").setVisible().setWidth("M"),
            new ResourceColumnText("message", "Message").setVisible().setWidth("S"),
            new ResourceColumnText("createdBy", "Created By").setVisible().setWidth("S"),
        ]
    )
);

class AppOffSiteAlert extends Resource {
    static getModel(): Model {
        return appOffSiteAlert;
    }

    getModel(): Model {
        return appOffSiteAlert;
    }

    constructor(data: OffSiteAlert) {
        super(data, appOffSiteAlert);
    }
}

export {
    AppOffSiteItem,
    AppOffSiteArea,
    AppOffSiteUnit,
    AppOffSiteTransaction,
    AppOffSiteAlert,
    AppOffSiteTransactionTypes,
    AppOffSiteTransactionStatuses,
    AppOffSiteAlertStatuses,
    AppOffSiteAlertLocalTypes,
};
