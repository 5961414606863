import React, { useContext, useEffect } from "react";
import onHandItemStyles from "../../../on-hand/components/item/OnHandItem.module.css";
import { Button } from "@material-ui/core";
import { OnHandContext } from "../../../on-hand/context/OnHandContext";
import OnHandUtils from "../../../on-hand/OnHandUtils";
import { AppContext } from "../../../../context/AppContext";
// import AppEmailService from "../../../../services/AppEmailService";
import { useGlobalSpinnerActionsContext } from "../../../../components/global-spinner/GlobalSpinnerContext";

const OnHandOrderItemActions = () => {
    const { appState } = useContext(AppContext);
    const setGlobalSpinner = useGlobalSpinnerActionsContext();
    const { onHandState, goToPrevItem, goToNextItem, lockSubmission, submitSelectedItem } =
        useContext(OnHandContext);

    useEffect(() => {}, [onHandState.restaurant.group.item]);

    const handleSubmit = async () => {
        lockSubmission();
        try {
            console.log("Not the ideal place, but one place to put on hand check");
            const inventory = OnHandUtils.buildInventory(appState, onHandState);

            // TODO: make this properly...
            // const alertThreshold = onHandState.restaurant.group.item?.data?.alertThreshold;
            // const inventoryQuantity = inventory?.data?.quantity;

            // TODO: re-enable this when we actually use alert transactions
            // if (OnHandUtils.isOnHandBelowAlert(alertThreshold, inventoryQuantity)) {
            //     const email = {
            //         subject: `[Alert][${onHandState.restaurant.data.name}] "${onHandState.restaurant.group.item.data.name}" is low`,
            //         bodyHTML: `At "${onHandState.restaurant.group.data.name}", item "${onHandState.restaurant.group.item.data.name}" reached the minimum value of ${onHandState.restaurant.group.item.data.alertThreshold}`,
            //         to: ["orders@ocmdapp.com"],
            //         bcc: [],
            //         sentAt: new Date().getTime(),
            //         sender: "orders@ocmdapp.com",
            //     };
            //     setGlobalSpinner(true);
            //     await AppEmailService.sendEmail(email);
            //     setGlobalSpinner(false);
            //     console.log("Sending email for alert");
            // }
            await OnHandUtils.handleSubmit(onHandState, submitSelectedItem, inventory);
        } catch (e) {
            throw Error("Failed to submit on hand");
        } finally {
            setGlobalSpinner(false);
        }
    };

    const disabledSubmit =
        !onHandState.restaurant.group.item ||
        !onHandState.restaurant.group.item.inventory ||
        onHandState.lockedSubmit;

    return (
        <div className={onHandItemStyles.Actions}>
            <Button variant="contained" onClick={goToPrevItem}>
                Prev
            </Button>
            <Button
                variant="contained"
                color="secondary"
                onClick={handleSubmit}
                disabled={disabledSubmit}
            >
                Submit
            </Button>
            <Button variant="contained" onClick={goToNextItem}>
                Skip
            </Button>
        </div>
    );
};

export default OnHandOrderItemActions;
