import OnHandFoodItemContext from "./OnHandFoodItemContext";
import {
    AppIngredientInventory,
    AppIngredientInventoryMeta,
    AppIngredientOnHandArea,
    AppOnHandArea,
} from "../../FoodResources";

const extractItemName = (item) => item.ingredient?.data?.alias || item.ingredient?.data?.name;

// this instance is re-used => is kept separated
const OnHandFoodContextConfig = new OnHandFoodItemContext(
    AppIngredientOnHandArea,
    AppIngredientInventory,
    AppIngredientInventoryMeta,
    AppOnHandArea,
    { extractItemName }
);

export default OnHandFoodContextConfig;
