import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import { useContext, useEffect } from "react";
import { MdSearch } from "react-icons/md";
import { DataEditorContext } from "../../context/DataEditorContext";
import dataEditorStyles from "../../DataEditor.module.css";

const DataEditorSearch = () => {
    const { dataEditorState, setSearchText } = useContext(DataEditorContext);

    useEffect(() => {}, [
        dataEditorState.store.resources.selected,
        dataEditorState.display.searchText,
    ]);

    const handleSearch = (searchText: string) => {
        // return _.debounce(() => setSearchText(searchText), 1000);
        setSearchText(searchText);
    };

    const storeKey = dataEditorState.getSelectedResource().getModel().getResourceStoreKey();

    return (
        <div key={"search-text-input"} className={dataEditorStyles.ToolbarSearch}>
            <InputBase
                className={dataEditorStyles.ToolbarSearchInput}
                placeholder="Search..."
                value={dataEditorState.display.searchText[storeKey]}
                onChange={(event) => handleSearch(event?.target?.value as string)}
                inputProps={{ "aria-label": "search" }}
            />
            <IconButton
                type="submit"
                className={dataEditorStyles.ToolbarSearchBtn}
                aria-label="search"
            >
                <MdSearch size={20} />
            </IconButton>
        </div>
    );
};

export default DataEditorSearch;
