import React from "react";
import { GiHotMeal } from "react-icons/gi";
import {
    ADMINS,
    DEVS,
    KITCHEN_USERS,
    MANAGERS,
    UserGroupType,
} from "../../services/AppUserGroupsData";
import ContainerBasePaths from "../ContainerBasePaths";
import { ContainerConfig } from "../ContainerConfig";
import { MenusContainerConfig } from "../menus-sales/MenusContainerConfig";
import { Section } from "../sections/Section";
import { FoodContainerConfig } from "./FoodContainerConfig";

export class FoodSection extends Section {
    renderIcon(disabled: boolean) {
        return <GiHotMeal size={this.iconSize} color={"inherit"} />;
    }

    renderHeaderIcon() {
        return <GiHotMeal size={this.iconHeaderSize} color={this.iconHeaderColor} />;
    }

    renderHomeIcon() {
        return <GiHotMeal size={this.iconHomeSize} color={this.iconHomeColor} />;
    }

    getDefaultPath(userGroups: UserGroupType[]) {
        if (userGroups.includes(DEVS)) {
            return FoodContainerConfig.DataEditor.url;
        }

        if (userGroups.includes(ADMINS)) {
            return MenusContainerConfig.url;
        }

        if (userGroups.includes(MANAGERS)) {
            return FoodContainerConfig.Delivery.url;
        }

        if (userGroups.includes(KITCHEN_USERS)) {
            return FoodContainerConfig.OnHand.url;
        }

        console.error("Couldn't match the user to any known section pages", userGroups);
        return super.getDefaultPath(userGroups);
    }

    constructor(
        pages: ContainerConfig[],
        name: string,
        allowedGroups: UserGroupType[],
        disabled: boolean
    ) {
        super(pages, name, allowedGroups, disabled, ContainerBasePaths.FOOD);
    }
}
