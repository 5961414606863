import { seriesPromises } from "../../resources/ResourcesService";
import {
    OffSiteTasksByIdAndRestType,
    OffSiteTasksByRestType,
    OffSiteTasksByStatusAndIdAndRestType,
} from "./context/OffSiteContextTypes";
import {
    AppOffSiteTransaction,
    AppOffSiteTransactionStatuses,
    AppOffSiteTransactionTypes,
    OffSiteTransactionStatuses,
} from "./OffSiteResources";

function decorateOffSiteItem(transactionsByItem: any, item: any) {
    item.transactions = transactionsByItem[item?.data?.id] || [];
    item.transactionsTotal = item.transactions.reduce((a: any, c: any) => {
        a += c?.data?.quantity;
        return a;
    }, 0);
    return item;
}

function decorateOffSiteRestaurants(
    restaurants: any[],
    itemsByRestaurant: any,
    transactionsByItem: any
) {
    return restaurants.map((restaurant: any) => {
        const restaurantItems = itemsByRestaurant[restaurant?.data?.id] || [];
        restaurant.items = restaurantItems.map(decorateOffSiteItem.bind(null, transactionsByItem));
        restaurant.selectedItem = restaurant?.items[0] || null;
        return restaurant;
    });
}

function updateTransactionPromise(data: any) {
    return AppOffSiteTransaction.getModel().api.update(data, true);
}

function makePendingTaskLoaded(
    offSiteTasks: OffSiteTasksByStatusAndIdAndRestType,
    pendingItemTask: OffSiteTasksByRestType,
    offSiteItemId: string | number
) {
    // update status
    Object.keys(pendingItemTask).forEach((restaurantId) => {
        pendingItemTask[restaurantId].forEach((item) => {
            item.data.status = AppOffSiteTransactionStatuses.LOADED;
        });
    });
    // move item task
    offSiteTasks[AppOffSiteTransactionStatuses.LOADED][offSiteItemId] = pendingItemTask;
    // delete it from p.ending
    delete offSiteTasks[AppOffSiteTransactionStatuses.PENDING][offSiteItemId];
}

async function submitPendingDeliveryTask(
    offSiteTasks: OffSiteTasksByStatusAndIdAndRestType,
    item: any
) {
    if (!offSiteTasks[AppOffSiteTransactionStatuses.PENDING]?.[item?.data?.id]) {
        // TODO: if there are no items pending, create a new one, loaded directly
        // TODO: create a transaction
        return null;
    }

    // TODO: extract tasks to be updated
    const itemTask: OffSiteTasksByRestType =
        offSiteTasks[AppOffSiteTransactionStatuses.PENDING][item.data.id];
    const tasks = Object.values(itemTask);
    const taskDuplicates = tasks
        .flat()
        .map(({ duplicates }) => duplicates)
        .flat();

    try {
        const tasksToBeUpdatedAsLoaded = tasks.flat().map(({ data }) => ({
            ...data,
            status: AppOffSiteTransactionStatuses.LOADED,
        }));

        await seriesPromises(tasksToBeUpdatedAsLoaded.map(updateTransactionPromise));
    } catch (e) {
        throw new Error("Could not update loaded!");
    }

    makePendingTaskLoaded(offSiteTasks, itemTask, item.data.id);

    try {
        const tasksToBeUpdatedAsCancelled = taskDuplicates.map(({ data }) => ({
            ...data,
            status: AppOffSiteTransactionStatuses.CANCELLED,
        }));

        await seriesPromises(tasksToBeUpdatedAsCancelled.map(updateTransactionPromise));
    } catch (e) {
        throw new Error("Could not update cancelled!");
    }

    return offSiteTasks;
}

// return Array of tasks from [id][restaurantId]?[] structure
function extractTasksFromTasksByStatus(statusTasks: OffSiteTasksByIdAndRestType) {
    return Object.values(statusTasks)
        .map((restaurantsTasks) => Object.values(restaurantsTasks))
        .flat();
}

async function sendLoadedTasksToTransit(loadedTasks: OffSiteTasksByIdAndRestType) {
    try {
        const extractedTasks = extractTasksFromTasksByStatus(loadedTasks);
        const updatedTasksData = extractedTasks.flat().map(({ data }) => ({
            ...data,
            status: AppOffSiteTransactionStatuses.TRANSIT,
        }));
        const updatePromises = updatedTasksData.map(updateTransactionPromise);

        await seriesPromises(updatePromises);
    } catch (e) {
        throw new Error("Could not update loaded!");
    }
}

function updateTasksStatus(
    tasks: OffSiteTasksByIdAndRestType,
    newStatus: keyof OffSiteTransactionStatuses
) {
    Object.keys(tasks).forEach((offSiteItemId) => {
        Object.keys(tasks[offSiteItemId]).forEach((restaurantId) => {
            tasks[offSiteItemId][restaurantId].forEach((task) => {
                task.data.status = newStatus;
            });
        });
    });
    return tasks;
}

async function submitCompletedStockingTransaction(
    userName: string | number,
    item: any,
    restaurantId: string | number
) {
    try {
        await AppOffSiteTransaction.getModel().api.create({
            offSiteItemId: item?.data?.id,
            type: AppOffSiteTransactionTypes.STOCKING,
            status: AppOffSiteTransactionStatuses.COMPLETED,
            restaurantId,
            quantity: item.stockingQuantity,
            createdBy: userName,
        });
    } catch (e) {
        throw new Error("Cannot submit Stock transaction!");
    }
}

// TODO: Merge this and OffSiteTasksUtils
export {
    decorateOffSiteRestaurants,
    submitPendingDeliveryTask,
    sendLoadedTasksToTransit,
    updateTasksStatus,
    submitCompletedStockingTransaction,
};
