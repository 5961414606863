import { DEVS } from "../services/AppUserGroupsData";
import { ContainerConfig } from "./ContainerConfig";
export default class ContainerConfigBuilder {
    constructor(name, key) {
        this.name = name;
        this.key = key || name;
    }

    setKey(key) {
        this.key = key;
        return this;
    }

    setPath(url) {
        this.url = url;
        this.path = url;
        return this;
    }

    setUserAccess(allowedGroups) {
        if (!Array.isArray(allowedGroups)) {
            // defaults to no access at all (not even for DEVS)
            this.userAccess = () => false;
            return this;
        }

        if (allowedGroups.length === 0) {
            // if no group-restriction is set, access is PUBLIC
            this.userAccess = () => true;
            return this;
        }

        // "DEVS" is included by default
        this.userAccess = ({ groups }) => {
            return userCanAccess(allowedGroups, groups);
        };

        return this;

        /* ================== */
        function userCanAccess(allowedGroups, allGroups) {
            allowedGroups = [...allowedGroups, DEVS];
            return allowedGroups.reduce((acc, validGroup) => {
                acc = acc || allGroups.includes(validGroup);
                return acc;
            }, false);
        }
    }

    setComponent(component) {
        this.component = component;
        return this;
    }

    setMenuItem(menuItem) {
        this.menuItem = menuItem;
        return this;
    }

    build() {
        if (!("url" in this)) {
            throw new Error("URL is missing");
        }

        if (!("userAccess" in this)) {
            throw new Error("User Access is missing");
        }

        if (!("component" in this)) {
            throw new Error("Component is missing");
        }

        if (!("menuItem" in this)) {
            throw new Error("Menu Item is missing");
        }

        return new ContainerConfig(
            this.name,
            this.key,
            this.url,
            this.userAccess,
            this.component,
            this.menuItem
        );
    }
}
