import React, { createContext, useCallback, useReducer } from "react";
import MenusReducer from "./MenusReducer";
import MenusActions from "./MenusActions";
import { seriesPromises, updateDailyData } from "../../../resources/ResourcesService";
import MenusUtils from "../services/MenusUtils";
import AppDayModel from "../../../components/day/AppDayModel";
import AppUtilsService from "../../../services/AppUtilsService";
import MenusResolver from "../services/MenusResolver";

const MenusContext = createContext({});

const MenusProvider = ({ children }) => {
    const [menusState, dispatch] = useReducer(MenusReducer, {
        restaurants: null,
        restaurant: null,
        completionScore: 0,
        config: null,
        sectionName: null,
    });

    const loadMenus = useCallback(
        async (config, sectionName, day, userName, userGroups, setGlobalSpinner) => {
            setGlobalSpinner(true);

            const restaurants = await MenusResolver(userGroups, day, userName, sectionName);
            const restaurant = AppUtilsService.loadRestaurant(
                sectionName,
                "sales",
                restaurants,
                config.router
            );
            const completionScore = MenusUtils.getMenuCompletionScore(restaurant.menu);

            setGlobalSpinner(false);
            return dispatch({
                type: MenusActions.LOAD_MENUS,
                payload: { config, sectionName, restaurants, restaurant, completionScore },
            });
        },
        [dispatch]
    );

    const salesUpload = useCallback(
        async (sectionName, data, restaurant, saleType, day, userName, setGlobalSpinner) => {
            setGlobalSpinner(true);

            const lastUpdatedBy = userName;
            const lastUpdatedAt = AppDayModel.getCurrentUnixTime();

            const { updatedMenu, updateQueue, createQueue, invalidQueue } =
                MenusUtils.digestUploadedSales(
                    sectionName,
                    data,
                    restaurant.menu,
                    saleType,
                    day,
                    lastUpdatedBy,
                    lastUpdatedAt
                );

            const updatePromises = updateQueue.map((sale) => sale.model.api.update(sale.data));
            await seriesPromises(updatePromises);

            const createPromises = createQueue.map((sale) => sale.model.api.create(sale.data));
            await seriesPromises(createPromises);

            const uploadedSales = updateQueue.concat(createQueue).map(({ data }) => data);

            // increase the count of the storageKey
            restaurant.meta[saleType].data = await AppUtilsService.updateMetaSalePromiseType(
                restaurant.meta[saleType].model,
                restaurant.meta[saleType].data
            );

            const saleTypeStorageKey = restaurant.meta[saleType].data.storageKey;
            await updateDailyData(uploadedSales, saleTypeStorageKey);

            restaurant.menu = updatedMenu;
            restaurant.invalidQueue = invalidQueue.filter((x) => !!x);

            setGlobalSpinner(false);
            return dispatch({ type: MenusActions.UPLOAD_SALES, payload: { restaurant } });
        },
        [dispatch]
    );

    const changeRestaurant = useCallback(
        (restaurantId) => {
            return dispatch({ type: MenusActions.CHANGE_RESTAURANT, payload: { restaurantId } });
        },
        [dispatch]
    );

    const openAddDialog = useCallback(() => {
        return dispatch({ type: MenusActions.OPEN_ADD_DIALOG, payload: {} });
    }, [dispatch]);

    const closeAddDialog = useCallback(() => {
        return dispatch({ type: MenusActions.CLOSE_ADD_DIALOG, payload: {} });
    }, [dispatch]);

    const value = {
        menusState,
        loadMenus,
        salesUpload,
        changeRestaurant,
        openAddDialog,
        closeAddDialog,
    };

    return <MenusContext.Provider value={value}>{children}</MenusContext.Provider>;
};

export { MenusContext, MenusProvider };
