import React, { useContext, useState } from "react";
import deliveryDpsStyles from "./DeliveryDps.module.css";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DeliveryDpsRestaurantSelector from "./DeliveryDpsRestaurantSelector";
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import DeliveryDpsEmailDataPreview from "./email/DeliveryDpsEmailDataPreview";
import DeliveryDpsUtils from "./DeliveryDpsUtils";
import { useGlobalSpinnerActionsContext } from "../../../components/global-spinner/GlobalSpinnerContext";
import { AppContext } from "../../../context/AppContext";
import AppDayModel from "../../../components/day/AppDayModel";
import AppEmailService from "../../../services/AppEmailService";

function DeliveryDpsHeader({ state, setState, ingredients }) {
    const { appState } = useContext(AppContext);
    const [notesToKitchenManager, setNotesToKitchenManager] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const setGlobalSpinner = useGlobalSpinnerActionsContext();

    let recipients = DeliveryDpsUtils.getRestaurantRecipients(state.restaurant, state.recipients);
    const date = AppDayModel.getTimeDisplay(appState.day.data.time, "ddd, MMM Do");

    const email = {
        to: [],
        cc: [],
        bcc: ["dps@ocmdapp.com"],
        replyTo: ["dps@ocmdapp.com", "commissary@oceancityoffice.com"],
        sentAt: undefined,
        sentBy: appState.user.name,
        subject: `[${state.restaurant.data.name}] Daily Pull Sheet for ${date}`,
        bodyHTML: DeliveryDpsEmailDataPreview(notesToKitchenManager, ingredients),
    };

    const handleEmail = async () => {
        const filterSelectedRecipients = ({ isSelected }) => isSelected;
        const splitValidEmails = ({ data: { email } }) =>
            email.split(/,| /).filter(AppEmailService.validateEmail);

        // TODO: insert input validation in Data Editor
        const selectedRecipients = recipients
            .filter(filterSelectedRecipients)
            .map(splitValidEmails)
            .flat();
        email.to = email.to.concat(selectedRecipients).flat();
        email.sentAt = new Date().getTime();
        email.sender = "dps@ocmdapp.com";

        let response;
        try {
            setGlobalSpinner(true);
            response = await AppEmailService.sendEmail(email);
            response = JSON.parse(response);
            if (response.code !== 200) {
                throw Error(response.body);
            }
            closeSendEmailDialog();
        } catch (e) {
            console.log("Error from sending email: ", JSON.stringify(e, null, 2));
            alert("Email failed! Please try again!");
        }

        setGlobalSpinner(false);

        return response;
    };

    const sendEmailDialog = () => setIsDialogOpen(true);

    const closeSendEmailDialog = () => setIsDialogOpen(false);

    const updateNotesToKitchenManager = (event) => setNotesToKitchenManager(event.target.value);

    const onRecipientsChange = (event, value) => {
        // only those recipients that are in "value" are "isSelected"
        recipients = recipients.map((recipient) => {
            recipient.isSelected = !!value.find(
                (element) => element?.data?.name === recipient?.data?.name
            );
            return recipient;
        });
        return value;
    };

    return (
        <div className={deliveryDpsStyles.Header}>
            <div>
                <DeliveryDpsRestaurantSelector state={state} setState={setState} />
            </div>
            <div className={"no-print"}>
                <Button onClick={sendEmailDialog} color={"secondary"} variant={"contained"}>
                    Email
                </Button>
            </div>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                onClose={() => {}}
                aria-labelledby="customized-dialog-title"
                open={isDialogOpen}
            >
                <DialogTitle id="customized-dialog-title" onClose={() => {}}>
                    <div
                        style={{
                            display: "grid",
                            gridAutoFlow: "column",
                            placeContent: "center space-between",
                            placeItems: "center",
                        }}
                    >
                        <strong>{`${email.subject}`}</strong>
                        <Button onClick={handleEmail} color="secondary" variant="contained">
                            Send
                        </Button>
                    </div>
                </DialogTitle>
                <DialogContent
                    dividers
                    style={{ display: "grid", placeContent: "stretch", gridGap: "1em" }}
                >
                    <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={recipients}
                        getOptionLabel={(option) => option.data.name}
                        defaultValue={recipients.filter((r) => r?.data.isSubscribed)}
                        filterSelectedOptions
                        onChange={onRecipientsChange}
                        renderInput={(params) => (
                            <TextField {...params} label="Recipients" placeholder="Add..." />
                        )}
                    />
                    <TextField
                        id="outlined-basic"
                        label="Notes to Kitchen Manager"
                        multiline
                        rows={1}
                        onChange={updateNotesToKitchenManager}
                        value={notesToKitchenManager || ""}
                    />
                    <div
                        class={deliveryDpsStyles.EmailPreviewWrp}
                        dangerouslySetInnerHTML={{ __html: email.bodyHTML }}
                    ></div>
                </DialogContent>
                <DialogActions style={{ display: "grid", placeContent: "center" }}>
                    <Button autoFocus onClick={closeSendEmailDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DeliveryDpsHeader;
