import { useContext } from "react";
import { DeliveryFoodContext } from "../../../../DeliveryFoodContext";
import { DeliveryFoodItemRestaurantCell } from "../../../../types/DeliveryFoodTypes";
import DeliveryFoodCell from "../../DeliveryFoodCell";

export const DeliveryFoodItemRestaurantSalesSingleCell = ({
    config,
}: {
    config: DeliveryFoodItemRestaurantCell;
}) => {
    const { deliveryFoodState } = useContext(DeliveryFoodContext);
    const { ingredient, restaurant, restaurantId, index } = config;

    if (deliveryFoodState.mode.display.RST[restaurantId].sales.SINGLE === false) {
        return null;
    }

    const key = `sales-single-cell-${index}`;
    const summary = ingredient.results.RST?.[restaurantId].sales?.SINGLE;

    if (!summary) {
        return null;
    }

    return (
        <DeliveryFoodCell
            key={key}
            fieldName={"sales"}
            styleName={"SINGLE"}
            salesType={"SINGLE"}
            summary={summary}
            index={index}
            ingredient={ingredient}
            restaurant={restaurant}
        />
    );
};
