import dataEditorStyles from "../../../DataEditor.module.css";
import DataEditorUtils from "../../../utils/DataEditorUtils";
import { DataEditorItemModeParams } from "./DataEditorItem";
import DataEditorItemDeleteButton from "./DataEditorItemDeleteButton";
import DataEditorItemEditButton from "./DataEditorItemEditButton";

const DataEditorItemDisplay = ({ item, index, setIsEditing }: DataEditorItemModeParams) => {
    const displayColumns = item
        .getModel()
        .getColumns()
        .filter(({ hide }) => !hide);
    const columnStyles = DataEditorUtils.getColumnStyles(displayColumns);

    return (
        <div key={`item-display-${index}`} className={dataEditorStyles.Row}>
            <div className={dataEditorStyles.ItemActions}>
                <DataEditorItemDeleteButton item={item} />
                <DataEditorItemEditButton item={item} index={index} setIsEditing={setIsEditing} />
            </div>

            <div className={dataEditorStyles.ItemContent} style={columnStyles}>
                {displayColumns.map((column) => {
                    const key = `${column.name}-${index}`;
                    const value = item.getDisplay()[column.field];

                    return column.renderDisplay(key, value);
                })}
            </div>
        </div>
    );
};

export default DataEditorItemDisplay;
