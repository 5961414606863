import React, { createContext, useCallback, useContext, useEffect, useReducer } from "react";
import { useGlobalSpinnerActionsContext } from "../../../../components/global-spinner/GlobalSpinnerContext";
import { fetchResources } from "../../../../resources/ResourcesService";

import AppUserGroups from "../../../../services/AppUserGroups";
import { AppContext } from "../../../../context/AppContext";
import { STOCKING } from "../mode/TShirtsStockingModes";
import { NEEDS_STOCKING } from "../t-shirt/TShirtsStockingStatus";
import TShirtsStockingUtils from "../TShirtsStockingUtils";
import AppContextValidator from "../../../../context/AppContextValidator";
import AppDayModel from "../../../../components/day/AppDayModel";
import AppUtilsService from "../../../../services/AppUtilsService";
import { AppRestaurant } from "../../../admin/AdminResources";
import {
    AppTShirt,
    AppTShirtInventory,
    AppTShirtInventoryMeta,
    AppTShirtSize,
    AppTShirtStyle,
    AppTShirtTransaction,
} from "../../TShirtsResources";
import OnHandDailyGenerator from "../../../on-hand/services/OnHandDailyGenerator";
import OnHandItemContext from "../../../on-hand/context/OnHandItemContext";

const TShirtsStockingContext = createContext({});

const INIT = "INIT";
const CHANGE_RESTAURANT = "CHANGE_RESTAURANT";
const CHANGE_MODE = "CHANGE_MODE";
const SELECT_T_SHIRT = "SELECT_T_SHIRT";
const STOCK_T_SHIRT = "STOCK_T_SHIRT";
const MASTER_INVENTORY_T_SHIRT = "MASTER_INVENTORY_T_SHIRT";

const reducer = (state, action) => {
    if (action.type === SELECT_T_SHIRT) {
        state.restaurant = Object.assign(state.restaurant, action.payload);
        state.restaurants[state.restaurant.index] = state.restaurant;
        return { ...state };
    }
    return { ...state, ...action.payload };
};

const TShirtsStockingProvider = ({ children }) => {
    const setGlobalSpinner = useGlobalSpinnerActionsContext();
    const { appState } = useContext(AppContext);
    const [tShirtsStocking, dispatch] = useReducer(reducer, {
        restaurants: [],
        restaurant: null,
        mode: STOCKING,
    });

    // methods
    const changeRestaurant = useCallback(
        (restaurant) => {
            return dispatch({ type: CHANGE_RESTAURANT, payload: { restaurant } });
        },
        [dispatch]
    );

    const changeMode = useCallback(
        (mode) => {
            return dispatch({ type: CHANGE_MODE, payload: { mode } });
        },
        [dispatch]
    );

    const selectTShirt = useCallback(
        (selectedTShirt) => {
            return dispatch({ type: SELECT_T_SHIRT, payload: { selectedTShirt } });
        },
        [dispatch]
    );

    // submit stock transaction
    // update t-Shirt metadata
    // load next t-Shirt
    const stockTShirt = useCallback(
        async (restaurant, tShirt, stockQuantity) => {
            setGlobalSpinner(true);
            await TShirtsStockingUtils.submitStockTShirtTransaction(
                tShirt,
                stockQuantity,
                appState
            );
            tShirt.masterInventory -= stockQuantity;
            // locally increase the on hand quantity
            tShirt.inventory.data.quantity += stockQuantity;

            // update tShirt local metadata
            updateLocalTShirtMetadata(restaurant, tShirt);
            navigateToNextTShirt(restaurant, tShirt);
            setGlobalSpinner(false);

            return dispatch({ type: STOCK_T_SHIRT, payload: { restaurant } });

            /* ----------- */
            function updateLocalTShirtMetadata(restaurant, tShirt) {
                const tShirtIndex = getTShirtByIndex(restaurant, tShirt);
                const tShirtStyleId = tShirt.data.styleId;
                restaurant.tShirtStyles[tShirtStyleId][tShirtIndex] =
                    TShirtsStockingUtils.updateTShirtStockMetadata(tShirt);

                /* ----------- */
                function getTShirtByIndex(restaurant, tShirt) {
                    return restaurant.tShirtStyles[tShirt.data.styleId].findIndex(
                        ({ data: { id } }) => id === tShirt.data.id
                    );
                }
            }

            function navigateToNextTShirt(restaurant, tShirt) {
                restaurant.selectedTShirt = TShirtsStockingUtils.findNextTShirt(
                    restaurant.tShirtStyles,
                    tShirt,
                    NEEDS_STOCKING
                );
            }
        },
        [dispatch]
    );

    // todo: move to lambda
    const loadData = useCallback(
        async (userGroups, day, setGlobalSpinner, userName, sectionName) => {
            try {
                setGlobalSpinner(true);
                // 1. Gather data
                const appRestaurantsFilter = { type: { eq: "RESTAURANT" } };
                const [appRestaurants] = await fetchResources([
                    [AppRestaurant, appRestaurantsFilter],
                ]);
                let { restaurants, restaurantFilter } = AppUserGroups.getUserRestaurantWithFilter(
                    appRestaurants,
                    userGroups
                );

                const createdUntilNextDayFilter = {
                    createdAt: { lt: AppDayModel.getNextDayUnixTime(day.data.time) },
                };
                let [tShirts, sizes, styles, tShirtTransactions] = await fetchResources([
                    [AppTShirt, restaurantFilter],
                    [AppTShirtSize],
                    [AppTShirtStyle],
                    [AppTShirtTransaction, createdUntilNextDayFilter],
                ]);

                const onHandTShirtsDailyGenerator = new OnHandDailyGenerator(
                    AppTShirtInventoryMeta,
                    AppTShirtInventory,
                    "tShirtId"
                );

                const tShirtsInventoryMeta = await OnHandDailyGenerator.listMeta(
                    AppTShirtInventoryMeta,
                    day
                );
                restaurants = OnHandItemContext.attachMetaToRestaurants(
                    restaurants,
                    tShirtsInventoryMeta,
                    day
                );

                const inventories = await onHandTShirtsDailyGenerator.loadInventories(
                    restaurants, // need inventory meta
                    tShirts,
                    day,
                    userName,
                    sectionName
                );

                // 2. Aggregate data
                const itemSizesById = sizes.reduce(AppUtilsService.groupDataByItem("id"), {});
                const itemStylesById = styles.reduce(AppUtilsService.groupDataByItem("id"), {});
                const inventoriesByItemId = inventories.reduce(
                    AppUtilsService.groupDataByItem("tShirtId"),
                    {}
                );

                const tShirtTransactionsByTShirtId = tShirtTransactions.reduce(
                    (acc, tShirtTransaction) => {
                        const tShirtId = tShirtTransaction.data.tShirtId;
                        acc[tShirtId] = acc[tShirtId] || [];
                        acc[tShirtId].push(tShirtTransaction);
                        return acc;
                    },
                    {}
                );

                // 3.1. Decorate data: attach related models (size, inventory, masterInventory)
                tShirts = tShirts.map((tShirt) => {
                    const {
                        data: { id, sizeId, styleId },
                    } = tShirt;
                    const size = itemSizesById[sizeId];
                    const style = itemStylesById[styleId];
                    const transactions = tShirtTransactionsByTShirtId[id] || [];
                    const stockTransactions = getStockTransactionsForToday(
                        transactions,
                        day?.time || 0
                    );
                    const stockedQuantity = stockTransactions.reduce((acc, curr) => {
                        const {
                            data: { quantity },
                        } = curr;
                        acc += quantity;
                        return acc;
                    }, 0);
                    const inventory = inventoriesByItemId[id];
                    const masterInventory = getMasterInventoryFromTransactions(transactions);
                    return Object.assign(tShirt, {
                        size,
                        style,
                        inventory,
                        masterInventory,
                        stockTransactions,
                        stockedQuantity,
                    });
                });

                // 3.2. Decorate data: compute metadata (requiredPacks, status)
                tShirts = tShirts.map(TShirtsStockingUtils.updateTShirtStockMetadata);

                // 4. set context
                sizes = sizes.sort((a, b) => a.data.id - b.data.id);

                restaurants = restaurants.map((restaurant, index) => {
                    restaurant.tShirtStyles = tShirts
                        .filter((tShirt) => tShirt.data.restaurantId === restaurant.data.id)
                        .reduce(AppUtilsService.groupDataByList("styleId"), {});
                    restaurant.index = index;
                    restaurant.selectedTShirt = TShirtsStockingUtils.findNextTShirt(
                        restaurant.tShirtStyles,
                        null,
                        NEEDS_STOCKING
                    );
                    return restaurant;
                });

                const restaurant = restaurants[0];

                setGlobalSpinner(false);

                return dispatch({
                    type: INIT,
                    payload: { restaurants, restaurant, sizes },
                });

                /* --------- */
                function getMasterInventoryFromTransactions(transactions) {
                    return transactions.reduce(
                        (acc, { data: { quantity } }) => (acc += quantity),
                        0
                    );
                }

                function getStockTransactionsForToday(transactions, dayTime) {
                    return transactions.filter((transaction) => {
                        const {
                            data: { createdAt, type },
                        } = transaction;
                        return isStockTransactionForToday(createdAt, dayTime, type);
                    });

                    /* ------- */
                    function isStockTransactionForToday(createdAt, dayTime, type) {
                        return (
                            createdAt >= dayTime &&
                            createdAt < dayTime + 24 * 60 * 60 &&
                            type === "STOCK"
                        );
                    }
                }
            } catch (e) {
                throw Error(e.message);
            } finally {
                setGlobalSpinner(false);
            }
        },
        [dispatch]
    );

    const updateTShirtMasterInventory = useCallback(
        async (diff, tShirt, restaurant) => {
            setGlobalSpinner(true);
            await TShirtsStockingUtils.supplyTShirt(tShirt, diff, appState);
            tShirt.masterInventory += diff;
            // replace TShirt START
            const tShirtIndex = restaurant.tShirtStyles[tShirt.data.styleId].findIndex(
                ({ data: { id } }) => id === tShirt.data.id
            );
            if (tShirtIndex >= 0) {
                restaurant.tShirtStyles[tShirt.data.styleId][tShirtIndex] = tShirt;
            }
            // replace TShirt END
            restaurant.selectedTShirt = TShirtsStockingUtils.findNextTShirt(
                restaurant.tShirtStyles,
                tShirt
            );
            setGlobalSpinner(false);
            return dispatch({ type: MASTER_INVENTORY_T_SHIRT, payload: { restaurant } });
        },
        [dispatch]
    );

    useEffect(() => {
        if (AppContextValidator.isValid(appState)) {
            loadData(appState.user.groups, appState.day, setGlobalSpinner);
        }
    }, [appState.day, appState.user]);

    // define the methods exported, wrapped
    const value = {
        tShirtsStocking,
        changeRestaurant,
        changeMode,
        selectTShirt,
        stockTShirt,
        updateTShirtMasterInventory,
    };

    return (
        <TShirtsStockingContext.Provider value={value}>{children}</TShirtsStockingContext.Provider>
    );
};

export { TShirtsStockingContext, TShirtsStockingProvider };
