/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const sendEmail = /* GraphQL */ `
  mutation SendEmail($email: String) {
    sendEmail(email: $email)
  }
`;
export const callS3Client = /* GraphQL */ `
  mutation CallS3Client($requests: String) {
    callS3Client(requests: $requests)
  }
`;
export const createRestaurant = /* GraphQL */ `
  mutation CreateRestaurant(
    $input: CreateRestaurantInput!
    $condition: ModelRestaurantConditionInput
  ) {
    createRestaurant(input: $input, condition: $condition) {
      id
      name
      alias
      usersGroup
      ratio
      offSeason
      open
      type
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateRestaurant = /* GraphQL */ `
  mutation UpdateRestaurant(
    $input: UpdateRestaurantInput!
    $condition: ModelRestaurantConditionInput
  ) {
    updateRestaurant(input: $input, condition: $condition) {
      id
      name
      alias
      usersGroup
      ratio
      offSeason
      open
      type
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteRestaurant = /* GraphQL */ `
  mutation DeleteRestaurant(
    $input: DeleteRestaurantInput!
    $condition: ModelRestaurantConditionInput
  ) {
    deleteRestaurant(input: $input, condition: $condition) {
      id
      name
      alias
      usersGroup
      ratio
      offSeason
      open
      type
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createDpsEmailRecipient = /* GraphQL */ `
  mutation CreateDpsEmailRecipient(
    $input: CreateDpsEmailRecipientInput!
    $condition: ModelDpsEmailRecipientConditionInput
  ) {
    createDpsEmailRecipient(input: $input, condition: $condition) {
      name
      restaurantId
      email
      isSubscribed
      createdAt
      updatedAt
    }
  }
`;
export const updateDpsEmailRecipient = /* GraphQL */ `
  mutation UpdateDpsEmailRecipient(
    $input: UpdateDpsEmailRecipientInput!
    $condition: ModelDpsEmailRecipientConditionInput
  ) {
    updateDpsEmailRecipient(input: $input, condition: $condition) {
      name
      restaurantId
      email
      isSubscribed
      createdAt
      updatedAt
    }
  }
`;
export const deleteDpsEmailRecipient = /* GraphQL */ `
  mutation DeleteDpsEmailRecipient(
    $input: DeleteDpsEmailRecipientInput!
    $condition: ModelDpsEmailRecipientConditionInput
  ) {
    deleteDpsEmailRecipient(input: $input, condition: $condition) {
      name
      restaurantId
      email
      isSubscribed
      createdAt
      updatedAt
    }
  }
`;
export const createDpsEmail = /* GraphQL */ `
  mutation CreateDpsEmail(
    $input: CreateDpsEmailInput!
    $condition: ModelDpsEmailConditionInput
  ) {
    createDpsEmail(input: $input, condition: $condition) {
      sentAt
      restaurantId
      sentBy
      recipients
      subject
      body
      configs
      createdAt
      updatedAt
    }
  }
`;
export const updateDpsEmail = /* GraphQL */ `
  mutation UpdateDpsEmail(
    $input: UpdateDpsEmailInput!
    $condition: ModelDpsEmailConditionInput
  ) {
    updateDpsEmail(input: $input, condition: $condition) {
      sentAt
      restaurantId
      sentBy
      recipients
      subject
      body
      configs
      createdAt
      updatedAt
    }
  }
`;
export const deleteDpsEmail = /* GraphQL */ `
  mutation DeleteDpsEmail(
    $input: DeleteDpsEmailInput!
    $condition: ModelDpsEmailConditionInput
  ) {
    deleteDpsEmail(input: $input, condition: $condition) {
      sentAt
      restaurantId
      sentBy
      recipients
      subject
      body
      configs
      createdAt
      updatedAt
    }
  }
`;
export const createDpsPack = /* GraphQL */ `
  mutation CreateDpsPack(
    $input: CreateDpsPackInput!
    $condition: ModelDpsPackConditionInput
  ) {
    createDpsPack(input: $input, condition: $condition) {
      id
      name
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateDpsPack = /* GraphQL */ `
  mutation UpdateDpsPack(
    $input: UpdateDpsPackInput!
    $condition: ModelDpsPackConditionInput
  ) {
    updateDpsPack(input: $input, condition: $condition) {
      id
      name
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteDpsPack = /* GraphQL */ `
  mutation DeleteDpsPack(
    $input: DeleteDpsPackInput!
    $condition: ModelDpsPackConditionInput
  ) {
    deleteDpsPack(input: $input, condition: $condition) {
      id
      name
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createDpsIngredient = /* GraphQL */ `
  mutation CreateDpsIngredient(
    $input: CreateDpsIngredientInput!
    $condition: ModelDpsIngredientConditionInput
  ) {
    createDpsIngredient(input: $input, condition: $condition) {
      id
      name
      dpsPackId
      dpsPackSize
      subDpsPackId
      subDpsPackSize
      subSubDpsPackId
      useSubPack
      ratio
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateDpsIngredient = /* GraphQL */ `
  mutation UpdateDpsIngredient(
    $input: UpdateDpsIngredientInput!
    $condition: ModelDpsIngredientConditionInput
  ) {
    updateDpsIngredient(input: $input, condition: $condition) {
      id
      name
      dpsPackId
      dpsPackSize
      subDpsPackId
      subDpsPackSize
      subSubDpsPackId
      useSubPack
      ratio
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteDpsIngredient = /* GraphQL */ `
  mutation DeleteDpsIngredient(
    $input: DeleteDpsIngredientInput!
    $condition: ModelDpsIngredientConditionInput
  ) {
    deleteDpsIngredient(input: $input, condition: $condition) {
      id
      name
      dpsPackId
      dpsPackSize
      subDpsPackId
      subDpsPackSize
      subSubDpsPackId
      useSubPack
      ratio
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createDpsMenuItem = /* GraphQL */ `
  mutation CreateDpsMenuItem(
    $input: CreateDpsMenuItemInput!
    $condition: ModelDpsMenuItemConditionInput
  ) {
    createDpsMenuItem(input: $input, condition: $condition) {
      id
      name
      restaurantId
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateDpsMenuItem = /* GraphQL */ `
  mutation UpdateDpsMenuItem(
    $input: UpdateDpsMenuItemInput!
    $condition: ModelDpsMenuItemConditionInput
  ) {
    updateDpsMenuItem(input: $input, condition: $condition) {
      id
      name
      restaurantId
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteDpsMenuItem = /* GraphQL */ `
  mutation DeleteDpsMenuItem(
    $input: DeleteDpsMenuItemInput!
    $condition: ModelDpsMenuItemConditionInput
  ) {
    deleteDpsMenuItem(input: $input, condition: $condition) {
      id
      name
      restaurantId
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createDpsMenuItemSale = /* GraphQL */ `
  mutation CreateDpsMenuItemSale(
    $input: CreateDpsMenuItemSaleInput!
    $condition: ModelDpsMenuItemSaleConditionInput
  ) {
    createDpsMenuItemSale(input: $input, condition: $condition) {
      dpsMenuItemId
      day
      type
      quantity
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateDpsMenuItemSale = /* GraphQL */ `
  mutation UpdateDpsMenuItemSale(
    $input: UpdateDpsMenuItemSaleInput!
    $condition: ModelDpsMenuItemSaleConditionInput
  ) {
    updateDpsMenuItemSale(input: $input, condition: $condition) {
      dpsMenuItemId
      day
      type
      quantity
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteDpsMenuItemSale = /* GraphQL */ `
  mutation DeleteDpsMenuItemSale(
    $input: DeleteDpsMenuItemSaleInput!
    $condition: ModelDpsMenuItemSaleConditionInput
  ) {
    deleteDpsMenuItemSale(input: $input, condition: $condition) {
      dpsMenuItemId
      day
      type
      quantity
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createDpsMenuItemSaleMeta = /* GraphQL */ `
  mutation CreateDpsMenuItemSaleMeta(
    $input: CreateDpsMenuItemSaleMetaInput!
    $condition: ModelDpsMenuItemSaleMetaConditionInput
  ) {
    createDpsMenuItemSaleMeta(input: $input, condition: $condition) {
      day
      restaurantId
      saleType
      storageKey
      status
      completion
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateDpsMenuItemSaleMeta = /* GraphQL */ `
  mutation UpdateDpsMenuItemSaleMeta(
    $input: UpdateDpsMenuItemSaleMetaInput!
    $condition: ModelDpsMenuItemSaleMetaConditionInput
  ) {
    updateDpsMenuItemSaleMeta(input: $input, condition: $condition) {
      day
      restaurantId
      saleType
      storageKey
      status
      completion
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteDpsMenuItemSaleMeta = /* GraphQL */ `
  mutation DeleteDpsMenuItemSaleMeta(
    $input: DeleteDpsMenuItemSaleMetaInput!
    $condition: ModelDpsMenuItemSaleMetaConditionInput
  ) {
    deleteDpsMenuItemSaleMeta(input: $input, condition: $condition) {
      day
      restaurantId
      saleType
      storageKey
      status
      completion
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createDpsRecipeItem = /* GraphQL */ `
  mutation CreateDpsRecipeItem(
    $input: CreateDpsRecipeItemInput!
    $condition: ModelDpsRecipeItemConditionInput
  ) {
    createDpsRecipeItem(input: $input, condition: $condition) {
      dpsMenuItemId
      dpsIngredientId
      quantity
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateDpsRecipeItem = /* GraphQL */ `
  mutation UpdateDpsRecipeItem(
    $input: UpdateDpsRecipeItemInput!
    $condition: ModelDpsRecipeItemConditionInput
  ) {
    updateDpsRecipeItem(input: $input, condition: $condition) {
      dpsMenuItemId
      dpsIngredientId
      quantity
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteDpsRecipeItem = /* GraphQL */ `
  mutation DeleteDpsRecipeItem(
    $input: DeleteDpsRecipeItemInput!
    $condition: ModelDpsRecipeItemConditionInput
  ) {
    deleteDpsRecipeItem(input: $input, condition: $condition) {
      dpsMenuItemId
      dpsIngredientId
      quantity
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createIngredientPack = /* GraphQL */ `
  mutation CreateIngredientPack(
    $input: CreateIngredientPackInput!
    $condition: ModelIngredientPackConditionInput
  ) {
    createIngredientPack(input: $input, condition: $condition) {
      id
      display
      name
      alias
      size
      order
      portionRatio
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateIngredientPack = /* GraphQL */ `
  mutation UpdateIngredientPack(
    $input: UpdateIngredientPackInput!
    $condition: ModelIngredientPackConditionInput
  ) {
    updateIngredientPack(input: $input, condition: $condition) {
      id
      display
      name
      alias
      size
      order
      portionRatio
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteIngredientPack = /* GraphQL */ `
  mutation DeleteIngredientPack(
    $input: DeleteIngredientPackInput!
    $condition: ModelIngredientPackConditionInput
  ) {
    deleteIngredientPack(input: $input, condition: $condition) {
      id
      display
      name
      alias
      size
      order
      portionRatio
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createIngredient = /* GraphQL */ `
  mutation CreateIngredient(
    $input: CreateIngredientInput!
    $condition: ModelIngredientConditionInput
  ) {
    createIngredient(input: $input, condition: $condition) {
      id
      name
      ingredientPackId
      salesRatio
      laborTime
      batchSize
      portionTime
      order
      onHandAlias
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateIngredient = /* GraphQL */ `
  mutation UpdateIngredient(
    $input: UpdateIngredientInput!
    $condition: ModelIngredientConditionInput
  ) {
    updateIngredient(input: $input, condition: $condition) {
      id
      name
      ingredientPackId
      salesRatio
      laborTime
      batchSize
      portionTime
      order
      onHandAlias
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteIngredient = /* GraphQL */ `
  mutation DeleteIngredient(
    $input: DeleteIngredientInput!
    $condition: ModelIngredientConditionInput
  ) {
    deleteIngredient(input: $input, condition: $condition) {
      id
      name
      ingredientPackId
      salesRatio
      laborTime
      batchSize
      portionTime
      order
      onHandAlias
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createIngredientInventory = /* GraphQL */ `
  mutation CreateIngredientInventory(
    $input: CreateIngredientInventoryInput!
    $condition: ModelIngredientInventoryConditionInput
  ) {
    createIngredientInventory(input: $input, condition: $condition) {
      ingredientOnHandAreaId
      day
      quantity
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateIngredientInventory = /* GraphQL */ `
  mutation UpdateIngredientInventory(
    $input: UpdateIngredientInventoryInput!
    $condition: ModelIngredientInventoryConditionInput
  ) {
    updateIngredientInventory(input: $input, condition: $condition) {
      ingredientOnHandAreaId
      day
      quantity
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteIngredientInventory = /* GraphQL */ `
  mutation DeleteIngredientInventory(
    $input: DeleteIngredientInventoryInput!
    $condition: ModelIngredientInventoryConditionInput
  ) {
    deleteIngredientInventory(input: $input, condition: $condition) {
      ingredientOnHandAreaId
      day
      quantity
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createIngredientInventoryMeta = /* GraphQL */ `
  mutation CreateIngredientInventoryMeta(
    $input: CreateIngredientInventoryMetaInput!
    $condition: ModelIngredientInventoryMetaConditionInput
  ) {
    createIngredientInventoryMeta(input: $input, condition: $condition) {
      day
      restaurantId
      storageKey
      status
      completion
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateIngredientInventoryMeta = /* GraphQL */ `
  mutation UpdateIngredientInventoryMeta(
    $input: UpdateIngredientInventoryMetaInput!
    $condition: ModelIngredientInventoryMetaConditionInput
  ) {
    updateIngredientInventoryMeta(input: $input, condition: $condition) {
      day
      restaurantId
      storageKey
      status
      completion
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteIngredientInventoryMeta = /* GraphQL */ `
  mutation DeleteIngredientInventoryMeta(
    $input: DeleteIngredientInventoryMetaInput!
    $condition: ModelIngredientInventoryMetaConditionInput
  ) {
    deleteIngredientInventoryMeta(input: $input, condition: $condition) {
      day
      restaurantId
      storageKey
      status
      completion
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createIngredientOnHandArea = /* GraphQL */ `
  mutation CreateIngredientOnHandArea(
    $input: CreateIngredientOnHandAreaInput!
    $condition: ModelIngredientOnHandAreaConditionInput
  ) {
    createIngredientOnHandArea(input: $input, condition: $condition) {
      id
      ingredientId
      onHandAreaId
      bulk
      portionRatio
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateIngredientOnHandArea = /* GraphQL */ `
  mutation UpdateIngredientOnHandArea(
    $input: UpdateIngredientOnHandAreaInput!
    $condition: ModelIngredientOnHandAreaConditionInput
  ) {
    updateIngredientOnHandArea(input: $input, condition: $condition) {
      id
      ingredientId
      onHandAreaId
      bulk
      portionRatio
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteIngredientOnHandArea = /* GraphQL */ `
  mutation DeleteIngredientOnHandArea(
    $input: DeleteIngredientOnHandAreaInput!
    $condition: ModelIngredientOnHandAreaConditionInput
  ) {
    deleteIngredientOnHandArea(input: $input, condition: $condition) {
      id
      ingredientId
      onHandAreaId
      bulk
      portionRatio
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createIngredientDeliveryArea = /* GraphQL */ `
  mutation CreateIngredientDeliveryArea(
    $input: CreateIngredientDeliveryAreaInput!
    $condition: ModelIngredientDeliveryAreaConditionInput
  ) {
    createIngredientDeliveryArea(input: $input, condition: $condition) {
      id
      ingredientId
      deliveryAreaId
      fixedNeed
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateIngredientDeliveryArea = /* GraphQL */ `
  mutation UpdateIngredientDeliveryArea(
    $input: UpdateIngredientDeliveryAreaInput!
    $condition: ModelIngredientDeliveryAreaConditionInput
  ) {
    updateIngredientDeliveryArea(input: $input, condition: $condition) {
      id
      ingredientId
      deliveryAreaId
      fixedNeed
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteIngredientDeliveryArea = /* GraphQL */ `
  mutation DeleteIngredientDeliveryArea(
    $input: DeleteIngredientDeliveryAreaInput!
    $condition: ModelIngredientDeliveryAreaConditionInput
  ) {
    deleteIngredientDeliveryArea(input: $input, condition: $condition) {
      id
      ingredientId
      deliveryAreaId
      fixedNeed
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createIngredientTransaction = /* GraphQL */ `
  mutation CreateIngredientTransaction(
    $input: CreateIngredientTransactionInput!
    $condition: ModelIngredientTransactionConditionInput
  ) {
    createIngredientTransaction(input: $input, condition: $condition) {
      ingredientId
      createdAt
      type
      restaurantId
      quantity
      createdBy
      updatedAt
    }
  }
`;
export const updateIngredientTransaction = /* GraphQL */ `
  mutation UpdateIngredientTransaction(
    $input: UpdateIngredientTransactionInput!
    $condition: ModelIngredientTransactionConditionInput
  ) {
    updateIngredientTransaction(input: $input, condition: $condition) {
      ingredientId
      createdAt
      type
      restaurantId
      quantity
      createdBy
      updatedAt
    }
  }
`;
export const deleteIngredientTransaction = /* GraphQL */ `
  mutation DeleteIngredientTransaction(
    $input: DeleteIngredientTransactionInput!
    $condition: ModelIngredientTransactionConditionInput
  ) {
    deleteIngredientTransaction(input: $input, condition: $condition) {
      ingredientId
      createdAt
      type
      restaurantId
      quantity
      createdBy
      updatedAt
    }
  }
`;
export const createMenuItem = /* GraphQL */ `
  mutation CreateMenuItem(
    $input: CreateMenuItemInput!
    $condition: ModelMenuItemConditionInput
  ) {
    createMenuItem(input: $input, condition: $condition) {
      id
      name
      restaurantId
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateMenuItem = /* GraphQL */ `
  mutation UpdateMenuItem(
    $input: UpdateMenuItemInput!
    $condition: ModelMenuItemConditionInput
  ) {
    updateMenuItem(input: $input, condition: $condition) {
      id
      name
      restaurantId
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteMenuItem = /* GraphQL */ `
  mutation DeleteMenuItem(
    $input: DeleteMenuItemInput!
    $condition: ModelMenuItemConditionInput
  ) {
    deleteMenuItem(input: $input, condition: $condition) {
      id
      name
      restaurantId
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createRecipeItem = /* GraphQL */ `
  mutation CreateRecipeItem(
    $input: CreateRecipeItemInput!
    $condition: ModelRecipeItemConditionInput
  ) {
    createRecipeItem(input: $input, condition: $condition) {
      menuItemId
      ingredientId
      quantity
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateRecipeItem = /* GraphQL */ `
  mutation UpdateRecipeItem(
    $input: UpdateRecipeItemInput!
    $condition: ModelRecipeItemConditionInput
  ) {
    updateRecipeItem(input: $input, condition: $condition) {
      menuItemId
      ingredientId
      quantity
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteRecipeItem = /* GraphQL */ `
  mutation DeleteRecipeItem(
    $input: DeleteRecipeItemInput!
    $condition: ModelRecipeItemConditionInput
  ) {
    deleteRecipeItem(input: $input, condition: $condition) {
      menuItemId
      ingredientId
      quantity
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createMenuItemSale = /* GraphQL */ `
  mutation CreateMenuItemSale(
    $input: CreateMenuItemSaleInput!
    $condition: ModelMenuItemSaleConditionInput
  ) {
    createMenuItemSale(input: $input, condition: $condition) {
      menuItemId
      day
      type
      quantity
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateMenuItemSale = /* GraphQL */ `
  mutation UpdateMenuItemSale(
    $input: UpdateMenuItemSaleInput!
    $condition: ModelMenuItemSaleConditionInput
  ) {
    updateMenuItemSale(input: $input, condition: $condition) {
      menuItemId
      day
      type
      quantity
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteMenuItemSale = /* GraphQL */ `
  mutation DeleteMenuItemSale(
    $input: DeleteMenuItemSaleInput!
    $condition: ModelMenuItemSaleConditionInput
  ) {
    deleteMenuItemSale(input: $input, condition: $condition) {
      menuItemId
      day
      type
      quantity
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createMenuItemSaleMeta = /* GraphQL */ `
  mutation CreateMenuItemSaleMeta(
    $input: CreateMenuItemSaleMetaInput!
    $condition: ModelMenuItemSaleMetaConditionInput
  ) {
    createMenuItemSaleMeta(input: $input, condition: $condition) {
      day
      restaurantId
      saleType
      storageKey
      status
      completion
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateMenuItemSaleMeta = /* GraphQL */ `
  mutation UpdateMenuItemSaleMeta(
    $input: UpdateMenuItemSaleMetaInput!
    $condition: ModelMenuItemSaleMetaConditionInput
  ) {
    updateMenuItemSaleMeta(input: $input, condition: $condition) {
      day
      restaurantId
      saleType
      storageKey
      status
      completion
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteMenuItemSaleMeta = /* GraphQL */ `
  mutation DeleteMenuItemSaleMeta(
    $input: DeleteMenuItemSaleMetaInput!
    $condition: ModelMenuItemSaleMetaConditionInput
  ) {
    deleteMenuItemSaleMeta(input: $input, condition: $condition) {
      day
      restaurantId
      saleType
      storageKey
      status
      completion
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createDeliveryArea = /* GraphQL */ `
  mutation CreateDeliveryArea(
    $input: CreateDeliveryAreaInput!
    $condition: ModelDeliveryAreaConditionInput
  ) {
    createDeliveryArea(input: $input, condition: $condition) {
      id
      name
      restaurantId
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateDeliveryArea = /* GraphQL */ `
  mutation UpdateDeliveryArea(
    $input: UpdateDeliveryAreaInput!
    $condition: ModelDeliveryAreaConditionInput
  ) {
    updateDeliveryArea(input: $input, condition: $condition) {
      id
      name
      restaurantId
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteDeliveryArea = /* GraphQL */ `
  mutation DeleteDeliveryArea(
    $input: DeleteDeliveryAreaInput!
    $condition: ModelDeliveryAreaConditionInput
  ) {
    deleteDeliveryArea(input: $input, condition: $condition) {
      id
      name
      restaurantId
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createOnHandArea = /* GraphQL */ `
  mutation CreateOnHandArea(
    $input: CreateOnHandAreaInput!
    $condition: ModelOnHandAreaConditionInput
  ) {
    createOnHandArea(input: $input, condition: $condition) {
      id
      name
      restaurantId
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateOnHandArea = /* GraphQL */ `
  mutation UpdateOnHandArea(
    $input: UpdateOnHandAreaInput!
    $condition: ModelOnHandAreaConditionInput
  ) {
    updateOnHandArea(input: $input, condition: $condition) {
      id
      name
      restaurantId
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteOnHandArea = /* GraphQL */ `
  mutation DeleteOnHandArea(
    $input: DeleteOnHandAreaInput!
    $condition: ModelOnHandAreaConditionInput
  ) {
    deleteOnHandArea(input: $input, condition: $condition) {
      id
      name
      restaurantId
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createOffSiteItem = /* GraphQL */ `
  mutation CreateOffSiteItem(
    $input: CreateOffSiteItemInput!
    $condition: ModelOffSiteItemConditionInput
  ) {
    createOffSiteItem(input: $input, condition: $condition) {
      id
      name
      offSiteAreaId
      offSiteUnitId
      conversion
      offSiteSubUnitId
      alertThreshold
      par
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateOffSiteItem = /* GraphQL */ `
  mutation UpdateOffSiteItem(
    $input: UpdateOffSiteItemInput!
    $condition: ModelOffSiteItemConditionInput
  ) {
    updateOffSiteItem(input: $input, condition: $condition) {
      id
      name
      offSiteAreaId
      offSiteUnitId
      conversion
      offSiteSubUnitId
      alertThreshold
      par
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteOffSiteItem = /* GraphQL */ `
  mutation DeleteOffSiteItem(
    $input: DeleteOffSiteItemInput!
    $condition: ModelOffSiteItemConditionInput
  ) {
    deleteOffSiteItem(input: $input, condition: $condition) {
      id
      name
      offSiteAreaId
      offSiteUnitId
      conversion
      offSiteSubUnitId
      alertThreshold
      par
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createOffSiteArea = /* GraphQL */ `
  mutation CreateOffSiteArea(
    $input: CreateOffSiteAreaInput!
    $condition: ModelOffSiteAreaConditionInput
  ) {
    createOffSiteArea(input: $input, condition: $condition) {
      id
      restaurantId
      name
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateOffSiteArea = /* GraphQL */ `
  mutation UpdateOffSiteArea(
    $input: UpdateOffSiteAreaInput!
    $condition: ModelOffSiteAreaConditionInput
  ) {
    updateOffSiteArea(input: $input, condition: $condition) {
      id
      restaurantId
      name
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteOffSiteArea = /* GraphQL */ `
  mutation DeleteOffSiteArea(
    $input: DeleteOffSiteAreaInput!
    $condition: ModelOffSiteAreaConditionInput
  ) {
    deleteOffSiteArea(input: $input, condition: $condition) {
      id
      restaurantId
      name
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createOffSiteUnit = /* GraphQL */ `
  mutation CreateOffSiteUnit(
    $input: CreateOffSiteUnitInput!
    $condition: ModelOffSiteUnitConditionInput
  ) {
    createOffSiteUnit(input: $input, condition: $condition) {
      id
      name
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateOffSiteUnit = /* GraphQL */ `
  mutation UpdateOffSiteUnit(
    $input: UpdateOffSiteUnitInput!
    $condition: ModelOffSiteUnitConditionInput
  ) {
    updateOffSiteUnit(input: $input, condition: $condition) {
      id
      name
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteOffSiteUnit = /* GraphQL */ `
  mutation DeleteOffSiteUnit(
    $input: DeleteOffSiteUnitInput!
    $condition: ModelOffSiteUnitConditionInput
  ) {
    deleteOffSiteUnit(input: $input, condition: $condition) {
      id
      name
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createOffSiteTransaction = /* GraphQL */ `
  mutation CreateOffSiteTransaction(
    $input: CreateOffSiteTransactionInput!
    $condition: ModelOffSiteTransactionConditionInput
  ) {
    createOffSiteTransaction(input: $input, condition: $condition) {
      offSiteItemId
      type
      createdAt
      status
      restaurantId
      quantity
      createdBy
      updatedAt
    }
  }
`;
export const updateOffSiteTransaction = /* GraphQL */ `
  mutation UpdateOffSiteTransaction(
    $input: UpdateOffSiteTransactionInput!
    $condition: ModelOffSiteTransactionConditionInput
  ) {
    updateOffSiteTransaction(input: $input, condition: $condition) {
      offSiteItemId
      type
      createdAt
      status
      restaurantId
      quantity
      createdBy
      updatedAt
    }
  }
`;
export const deleteOffSiteTransaction = /* GraphQL */ `
  mutation DeleteOffSiteTransaction(
    $input: DeleteOffSiteTransactionInput!
    $condition: ModelOffSiteTransactionConditionInput
  ) {
    deleteOffSiteTransaction(input: $input, condition: $condition) {
      offSiteItemId
      type
      createdAt
      status
      restaurantId
      quantity
      createdBy
      updatedAt
    }
  }
`;
export const createOffSiteAlert = /* GraphQL */ `
  mutation CreateOffSiteAlert(
    $input: CreateOffSiteAlertInput!
    $condition: ModelOffSiteAlertConditionInput
  ) {
    createOffSiteAlert(input: $input, condition: $condition) {
      offSiteItemId
      createdAt
      status
      snoozedUntil
      message
      createdBy
      lastUpdatedBy
      lastUpdatedAt
      updatedAt
    }
  }
`;
export const updateOffSiteAlert = /* GraphQL */ `
  mutation UpdateOffSiteAlert(
    $input: UpdateOffSiteAlertInput!
    $condition: ModelOffSiteAlertConditionInput
  ) {
    updateOffSiteAlert(input: $input, condition: $condition) {
      offSiteItemId
      createdAt
      status
      snoozedUntil
      message
      createdBy
      lastUpdatedBy
      lastUpdatedAt
      updatedAt
    }
  }
`;
export const deleteOffSiteAlert = /* GraphQL */ `
  mutation DeleteOffSiteAlert(
    $input: DeleteOffSiteAlertInput!
    $condition: ModelOffSiteAlertConditionInput
  ) {
    deleteOffSiteAlert(input: $input, condition: $condition) {
      offSiteItemId
      createdAt
      status
      snoozedUntil
      message
      createdBy
      lastUpdatedBy
      lastUpdatedAt
      updatedAt
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      orderAreaId
      name
      orderOnHandUnitId
      orderDeliveryUnitId
      orderVendorId
      discount
      par
      conversion
      alertThreshold
      offSiteItemId
      stockLimit
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      orderAreaId
      name
      orderOnHandUnitId
      orderDeliveryUnitId
      orderVendorId
      discount
      par
      conversion
      alertThreshold
      offSiteItemId
      stockLimit
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      orderAreaId
      name
      orderOnHandUnitId
      orderDeliveryUnitId
      orderVendorId
      discount
      par
      conversion
      alertThreshold
      offSiteItemId
      stockLimit
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createOrderArea = /* GraphQL */ `
  mutation CreateOrderArea(
    $input: CreateOrderAreaInput!
    $condition: ModelOrderAreaConditionInput
  ) {
    createOrderArea(input: $input, condition: $condition) {
      id
      restaurantId
      name
      order
      type
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateOrderArea = /* GraphQL */ `
  mutation UpdateOrderArea(
    $input: UpdateOrderAreaInput!
    $condition: ModelOrderAreaConditionInput
  ) {
    updateOrderArea(input: $input, condition: $condition) {
      id
      restaurantId
      name
      order
      type
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrderArea = /* GraphQL */ `
  mutation DeleteOrderArea(
    $input: DeleteOrderAreaInput!
    $condition: ModelOrderAreaConditionInput
  ) {
    deleteOrderArea(input: $input, condition: $condition) {
      id
      restaurantId
      name
      order
      type
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createOrderUnit = /* GraphQL */ `
  mutation CreateOrderUnit(
    $input: CreateOrderUnitInput!
    $condition: ModelOrderUnitConditionInput
  ) {
    createOrderUnit(input: $input, condition: $condition) {
      id
      name
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateOrderUnit = /* GraphQL */ `
  mutation UpdateOrderUnit(
    $input: UpdateOrderUnitInput!
    $condition: ModelOrderUnitConditionInput
  ) {
    updateOrderUnit(input: $input, condition: $condition) {
      id
      name
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrderUnit = /* GraphQL */ `
  mutation DeleteOrderUnit(
    $input: DeleteOrderUnitInput!
    $condition: ModelOrderUnitConditionInput
  ) {
    deleteOrderUnit(input: $input, condition: $condition) {
      id
      name
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createOrderVendor = /* GraphQL */ `
  mutation CreateOrderVendor(
    $input: CreateOrderVendorInput!
    $condition: ModelOrderVendorConditionInput
  ) {
    createOrderVendor(input: $input, condition: $condition) {
      id
      name
      email
      emailBcc
      isOffSite
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateOrderVendor = /* GraphQL */ `
  mutation UpdateOrderVendor(
    $input: UpdateOrderVendorInput!
    $condition: ModelOrderVendorConditionInput
  ) {
    updateOrderVendor(input: $input, condition: $condition) {
      id
      name
      email
      emailBcc
      isOffSite
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrderVendor = /* GraphQL */ `
  mutation DeleteOrderVendor(
    $input: DeleteOrderVendorInput!
    $condition: ModelOrderVendorConditionInput
  ) {
    deleteOrderVendor(input: $input, condition: $condition) {
      id
      name
      email
      emailBcc
      isOffSite
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createOrderInventory = /* GraphQL */ `
  mutation CreateOrderInventory(
    $input: CreateOrderInventoryInput!
    $condition: ModelOrderInventoryConditionInput
  ) {
    createOrderInventory(input: $input, condition: $condition) {
      orderId
      day
      quantity
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateOrderInventory = /* GraphQL */ `
  mutation UpdateOrderInventory(
    $input: UpdateOrderInventoryInput!
    $condition: ModelOrderInventoryConditionInput
  ) {
    updateOrderInventory(input: $input, condition: $condition) {
      orderId
      day
      quantity
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrderInventory = /* GraphQL */ `
  mutation DeleteOrderInventory(
    $input: DeleteOrderInventoryInput!
    $condition: ModelOrderInventoryConditionInput
  ) {
    deleteOrderInventory(input: $input, condition: $condition) {
      orderId
      day
      quantity
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createOrderInventoryMeta = /* GraphQL */ `
  mutation CreateOrderInventoryMeta(
    $input: CreateOrderInventoryMetaInput!
    $condition: ModelOrderInventoryMetaConditionInput
  ) {
    createOrderInventoryMeta(input: $input, condition: $condition) {
      day
      restaurantId
      storageKey
      status
      completion
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateOrderInventoryMeta = /* GraphQL */ `
  mutation UpdateOrderInventoryMeta(
    $input: UpdateOrderInventoryMetaInput!
    $condition: ModelOrderInventoryMetaConditionInput
  ) {
    updateOrderInventoryMeta(input: $input, condition: $condition) {
      day
      restaurantId
      storageKey
      status
      completion
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrderInventoryMeta = /* GraphQL */ `
  mutation DeleteOrderInventoryMeta(
    $input: DeleteOrderInventoryMetaInput!
    $condition: ModelOrderInventoryMetaConditionInput
  ) {
    deleteOrderInventoryMeta(input: $input, condition: $condition) {
      day
      restaurantId
      storageKey
      status
      completion
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTShirt = /* GraphQL */ `
  mutation CreateTShirt(
    $input: CreateTShirtInput!
    $condition: ModelTShirtConditionInput
  ) {
    createTShirt(input: $input, condition: $condition) {
      id
      restaurantId
      display
      name
      sizeId
      stockCapacity
      par
      packSize
      discount
      order
      styleId
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTShirt = /* GraphQL */ `
  mutation UpdateTShirt(
    $input: UpdateTShirtInput!
    $condition: ModelTShirtConditionInput
  ) {
    updateTShirt(input: $input, condition: $condition) {
      id
      restaurantId
      display
      name
      sizeId
      stockCapacity
      par
      packSize
      discount
      order
      styleId
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteTShirt = /* GraphQL */ `
  mutation DeleteTShirt(
    $input: DeleteTShirtInput!
    $condition: ModelTShirtConditionInput
  ) {
    deleteTShirt(input: $input, condition: $condition) {
      id
      restaurantId
      display
      name
      sizeId
      stockCapacity
      par
      packSize
      discount
      order
      styleId
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTShirtSize = /* GraphQL */ `
  mutation CreateTShirtSize(
    $input: CreateTShirtSizeInput!
    $condition: ModelTShirtSizeConditionInput
  ) {
    createTShirtSize(input: $input, condition: $condition) {
      id
      name
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTShirtSize = /* GraphQL */ `
  mutation UpdateTShirtSize(
    $input: UpdateTShirtSizeInput!
    $condition: ModelTShirtSizeConditionInput
  ) {
    updateTShirtSize(input: $input, condition: $condition) {
      id
      name
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteTShirtSize = /* GraphQL */ `
  mutation DeleteTShirtSize(
    $input: DeleteTShirtSizeInput!
    $condition: ModelTShirtSizeConditionInput
  ) {
    deleteTShirtSize(input: $input, condition: $condition) {
      id
      name
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTShirtStyle = /* GraphQL */ `
  mutation CreateTShirtStyle(
    $input: CreateTShirtStyleInput!
    $condition: ModelTShirtStyleConditionInput
  ) {
    createTShirtStyle(input: $input, condition: $condition) {
      id
      name
      restaurantId
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTShirtStyle = /* GraphQL */ `
  mutation UpdateTShirtStyle(
    $input: UpdateTShirtStyleInput!
    $condition: ModelTShirtStyleConditionInput
  ) {
    updateTShirtStyle(input: $input, condition: $condition) {
      id
      name
      restaurantId
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteTShirtStyle = /* GraphQL */ `
  mutation DeleteTShirtStyle(
    $input: DeleteTShirtStyleInput!
    $condition: ModelTShirtStyleConditionInput
  ) {
    deleteTShirtStyle(input: $input, condition: $condition) {
      id
      name
      restaurantId
      order
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTShirtInventory = /* GraphQL */ `
  mutation CreateTShirtInventory(
    $input: CreateTShirtInventoryInput!
    $condition: ModelTShirtInventoryConditionInput
  ) {
    createTShirtInventory(input: $input, condition: $condition) {
      tShirtId
      day
      quantity
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTShirtInventory = /* GraphQL */ `
  mutation UpdateTShirtInventory(
    $input: UpdateTShirtInventoryInput!
    $condition: ModelTShirtInventoryConditionInput
  ) {
    updateTShirtInventory(input: $input, condition: $condition) {
      tShirtId
      day
      quantity
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteTShirtInventory = /* GraphQL */ `
  mutation DeleteTShirtInventory(
    $input: DeleteTShirtInventoryInput!
    $condition: ModelTShirtInventoryConditionInput
  ) {
    deleteTShirtInventory(input: $input, condition: $condition) {
      tShirtId
      day
      quantity
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTShirtInventoryMeta = /* GraphQL */ `
  mutation CreateTShirtInventoryMeta(
    $input: CreateTShirtInventoryMetaInput!
    $condition: ModelTShirtInventoryMetaConditionInput
  ) {
    createTShirtInventoryMeta(input: $input, condition: $condition) {
      day
      restaurantId
      storageKey
      status
      completion
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTShirtInventoryMeta = /* GraphQL */ `
  mutation UpdateTShirtInventoryMeta(
    $input: UpdateTShirtInventoryMetaInput!
    $condition: ModelTShirtInventoryMetaConditionInput
  ) {
    updateTShirtInventoryMeta(input: $input, condition: $condition) {
      day
      restaurantId
      storageKey
      status
      completion
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteTShirtInventoryMeta = /* GraphQL */ `
  mutation DeleteTShirtInventoryMeta(
    $input: DeleteTShirtInventoryMetaInput!
    $condition: ModelTShirtInventoryMetaConditionInput
  ) {
    deleteTShirtInventoryMeta(input: $input, condition: $condition) {
      day
      restaurantId
      storageKey
      status
      completion
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTShirtSale = /* GraphQL */ `
  mutation CreateTShirtSale(
    $input: CreateTShirtSaleInput!
    $condition: ModelTShirtSaleConditionInput
  ) {
    createTShirtSale(input: $input, condition: $condition) {
      tShirtId
      day
      quantity
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTShirtSale = /* GraphQL */ `
  mutation UpdateTShirtSale(
    $input: UpdateTShirtSaleInput!
    $condition: ModelTShirtSaleConditionInput
  ) {
    updateTShirtSale(input: $input, condition: $condition) {
      tShirtId
      day
      quantity
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteTShirtSale = /* GraphQL */ `
  mutation DeleteTShirtSale(
    $input: DeleteTShirtSaleInput!
    $condition: ModelTShirtSaleConditionInput
  ) {
    deleteTShirtSale(input: $input, condition: $condition) {
      tShirtId
      day
      quantity
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTShirtSaleMeta = /* GraphQL */ `
  mutation CreateTShirtSaleMeta(
    $input: CreateTShirtSaleMetaInput!
    $condition: ModelTShirtSaleMetaConditionInput
  ) {
    createTShirtSaleMeta(input: $input, condition: $condition) {
      day
      restaurantId
      storageKey
      status
      completion
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTShirtSaleMeta = /* GraphQL */ `
  mutation UpdateTShirtSaleMeta(
    $input: UpdateTShirtSaleMetaInput!
    $condition: ModelTShirtSaleMetaConditionInput
  ) {
    updateTShirtSaleMeta(input: $input, condition: $condition) {
      day
      restaurantId
      storageKey
      status
      completion
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteTShirtSaleMeta = /* GraphQL */ `
  mutation DeleteTShirtSaleMeta(
    $input: DeleteTShirtSaleMetaInput!
    $condition: ModelTShirtSaleMetaConditionInput
  ) {
    deleteTShirtSaleMeta(input: $input, condition: $condition) {
      day
      restaurantId
      storageKey
      status
      completion
      lastUpdatedBy
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTShirtTransaction = /* GraphQL */ `
  mutation CreateTShirtTransaction(
    $input: CreateTShirtTransactionInput!
    $condition: ModelTShirtTransactionConditionInput
  ) {
    createTShirtTransaction(input: $input, condition: $condition) {
      tShirtId
      createdAt
      type
      quantity
      createdBy
      updatedAt
    }
  }
`;
export const updateTShirtTransaction = /* GraphQL */ `
  mutation UpdateTShirtTransaction(
    $input: UpdateTShirtTransactionInput!
    $condition: ModelTShirtTransactionConditionInput
  ) {
    updateTShirtTransaction(input: $input, condition: $condition) {
      tShirtId
      createdAt
      type
      quantity
      createdBy
      updatedAt
    }
  }
`;
export const deleteTShirtTransaction = /* GraphQL */ `
  mutation DeleteTShirtTransaction(
    $input: DeleteTShirtTransactionInput!
    $condition: ModelTShirtTransactionConditionInput
  ) {
    deleteTShirtTransaction(input: $input, condition: $condition) {
      tShirtId
      createdAt
      type
      quantity
      createdBy
      updatedAt
    }
  }
`;
