import deliveryFoodStyles from "../../../../DeliveryFood.module.css";
import { DeliveryFoodItemRestaurantCell } from "../../../../types/DeliveryFoodTypes";
import { DeliveryFoodItemRestaurantDeliveryCell } from "./DeliveryFoodItemRestaurantDeliveryCell";
import { DeliveryFoodItemRestaurantDeliveryFixedCell } from "./DeliveryFoodItemRestaurantDeliveryFixedCell";
import { DeliveryFoodItemRestaurantDeliverySalesCell } from "./DeliveryFoodItemRestaurantDeliverySalesCell";
import { DeliveryFoodItemRestaurantOnHandCell } from "./DeliveryFoodItemRestaurantOnHandCell";
import { DeliveryFoodItemRestaurantSalesMultipleCell } from "./DeliveryFoodItemRestaurantSalesMultipleCell";
import { DeliveryFoodItemRestaurantSalesSingleCell } from "./DeliveryFoodItemRestaurantSalesSingleCell";

const DeliveryFoodItemRestaurant = (config: DeliveryFoodItemRestaurantCell) => {
    const key = `food-item-restaurant-${config.restaurantId}-${config.index}`;

    return (
        <div key={key} className={deliveryFoodStyles.ItemRestaurant}>
            <DeliveryFoodItemRestaurantDeliveryCell config={config} />
            <DeliveryFoodItemRestaurantDeliverySalesCell config={config} />
            <DeliveryFoodItemRestaurantDeliveryFixedCell config={config} />
            <DeliveryFoodItemRestaurantSalesMultipleCell config={config} />
            <DeliveryFoodItemRestaurantOnHandCell config={config} />
            <DeliveryFoodItemRestaurantSalesSingleCell config={config} />
        </div>
    );
};

export default DeliveryFoodItemRestaurant;
