import { FaTasks, FaTruck } from "react-icons/fa";
import { GiChefToque, GiPlayerTime } from "react-icons/gi";
import { MdAttachMoney } from "react-icons/md";
import deliveryFoodHeaderStyles from "../DeliveryFoodHeader.module.css";

const onHand = {
    tooltip: "On Hand",
    headerCellPrintContent: () => <span>On Hand</span>,
    headerCellContent: () => {
        return (
            <div className={deliveryFoodHeaderStyles.IconDelivery}>
                <FaTasks color={"black"} size={20} />
                <span>ON HAND</span>
            </div>
        );
    },
};

const salesSingle = {
    tooltip: "Sales Single",
    headerCellPrintContent: () => <span>Sales Single</span>,
    headerCellContent: () => {
        return (
            <div className={deliveryFoodHeaderStyles.IconDelivery}>
                <MdAttachMoney color={"black"} size={20} />
                <span>Single</span>
            </div>
        );
    },
};

const salesMultiple = {
    tooltip: "Sales Multiple",
    headerCellPrintContent: () => <span>Sales Multiple</span>,
    headerCellContent: () => {
        return (
            <div className={deliveryFoodHeaderStyles.IconDelivery}>
                <div>
                    <MdAttachMoney color={"black"} size={20} />
                    <MdAttachMoney color={"black"} size={20} />
                </div>
                <span>Multiple</span>
            </div>
        );
    },
};

const delivery = {
    tooltip: "Delivery",
    headerCellPrintContent: () => <span>Delivery</span>,
    headerCellContent: () => {
        return (
            <div className={deliveryFoodHeaderStyles.IconDelivery}>
                <FaTruck color={"black"} size={20} />
                <span>DELIVERY</span>
            </div>
        );
    },
};

const deliveryFixed = {
    tooltip: "Delivery Fixed",
    headerCellPrintContent: () => <span>Delivery Fixed</span>,
    headerCellContent: () => {
        return (
            <div className={deliveryFoodHeaderStyles.IconDelivery}>
                <FaTruck color={"red"} size={20} />
                <span>Fixed</span>
            </div>
        );
    },
};

const deliverySales = {
    tooltip: "Delivery Sales",
    headerCellPrintContent: () => <span>Delivery Sales</span>,
    headerCellContent: () => {
        return (
            <div className={deliveryFoodHeaderStyles.IconDelivery}>
                <FaTruck color={"green"} size={20} />
                <span>Sales</span>
            </div>
        );
    },
};

const prep = {
    tooltip: "Prep",
    headerCellPrintContent: () => <span>Prep</span>,
    headerCellContent: () => {
        return (
            <div className={deliveryFoodHeaderStyles.IconDelivery}>
                <GiChefToque color={"black"} size={20} />
                <span>Prep</span>
            </div>
        );
    },
};

const prepTime = {
    tooltip: "Prep Time",
    headerCellPrintContent: () => <span>Prep time</span>,
    headerCellContent: () => {
        return (
            <div className={deliveryFoodHeaderStyles.IconDelivery}>
                <GiPlayerTime color={"black"} size={20} />
                <span>Prep Time</span>
            </div>
        );
    },
};

const prepForDeliveryTime = {
    tooltip: "Prep for Delivery Time",
    headerCellPrintContent: () => <span>Prep for delivery time</span>,
    headerCellContent: () => {
        return (
            <div className={deliveryFoodHeaderStyles.IconDelivery}>
                <div>
                    <GiPlayerTime color={"black"} size={20} />
                    <FaTruck color={"black"} size={20} />
                </div>
                <span>Prep Dlv T</span>
            </div>
        );
    },
};

const prepForDelivery = {
    tooltip: "Prep for Delivery",
    headerCellPrintContent: () => <span>Prep for Delivery</span>,
    headerCellContent: () => {
        return (
            <div className={deliveryFoodHeaderStyles.IconDelivery}>
                <div>
                    <GiChefToque color={"black"} size={20} />
                    <FaTruck color={"black"} size={20} />
                </div>
                <span>Prep Dlv</span>
            </div>
        );
    },
};

export {
    onHand,
    salesSingle,
    salesMultiple,
    delivery,
    deliveryFixed,
    deliverySales,
    prep,
    prepTime,
    prepForDeliveryTime,
    prepForDelivery,
};
