import OnHandOrderItemContext from "./OnHandOrderItemContext";
import {
    AppOrder,
    AppOrderArea,
    AppOrderInventory,
    AppOrderInventoryMeta,
} from "../../OrdersResources";

const extractItemName = (item) => item.data?.name;

// this instance is re-used => is kept separated
const OnHandOrdersContextConfig = new OnHandOrderItemContext(
    AppOrder,
    AppOrderInventory,
    AppOrderInventoryMeta,
    AppOrderArea,
    { extractItemName }
);

export default OnHandOrdersContextConfig;
