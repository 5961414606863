import Papa from "papaparse";
import SalesParser from "./SalesParser";

const TShirtSalesParser = (onUpdate, reader) => {
    // TODO: expose these column names in settings
    // TODO: throw meaningful errors
    // TODO: check that all rows have the same size
    // TODO: test if quantities can be parsed
    try {
        let salesData = Papa.parse(reader.result).data;
        console.log("Raw sales data: \n", salesData);

        salesData = SalesParser.filterRows(salesData);
        console.log("Filtered sales data: \n", salesData);

        salesData = SalesParser.parseRawSales(salesData);
        console.log("Parsed sales data: \n", salesData);

        salesData = SalesParser.sortParsedSales(salesData);
        console.log("Sorted sales data: \n", salesData);

        salesData = filterSortedSales(salesData);
        console.log("Filtered sales data: \n", salesData);

        salesData = SalesParser.mergeFilteredSales(salesData);
        console.log("Merged sales data: \n", salesData);

        salesData = splitSales(salesData);
        console.log("Split sales data: \n", salesData);

        return onUpdate(salesData);
    } catch (e) {
        throw Error(`Cannot parse given file because of ${e}`);
    }

    /* ---------- IMPL ---------- */
    function filterSortedSales(salesData) {
        return salesData.filter(([name, sold]) => {
            return (
                typeof name === "string" &&
                name.indexOf(" -") > 0 &&
                typeof sold === "number" &&
                sold >= 0
            );
        });
    }

    function splitSales(salesData) {
        return salesData.map(([name, quantity]) => {
            const splitName = name.split(" -");
            name = splitName[0];
            const size = splitName[1];
            return { name, size, quantity };
        });
    }
};

export default TShirtSalesParser;
