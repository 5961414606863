import { useContext } from "react";
import { OffSiteReordersContext } from "./context/OffSiteReordersContext";
import styles from "./OffSiteReorders.module.css";
import OffSiteReordersUndoButton from "./buttons/OffSiteReordersUndoButton";
import { OffSiteReordersAlertDisplay } from "./types/OffSiteReordersTypes";

export default function OffSiteReordersCompletedAlert(props: any) {
    const { alert, index }: { alert: OffSiteReordersAlertDisplay; index: number } = props;
    const { offSiteReordersState } = useContext(OffSiteReordersContext);

    if (!alert?.data?.status) {
        return null;
    }

    const className =
        offSiteReordersState.lastUpdatedAlert == alert
            ? `${styles.CompletedAlert} ${styles.LastUpdatedAlert}`
            : styles.CompletedAlert;

    return (
        <div key={`offsite-reorders-completed-alert-${index}`} className={className}>
            <span className={styles.ItemName}>{alert?.offSiteItem?.data.name}</span>
            <span className={styles.ItemStock}>{alert.stock}</span>
            <span className={styles.ItemPar}>{alert?.offSiteItem?.data.alertThreshold}</span>
            <span className={styles.ItemDoneBy}>{`${
                alert.data.lastUpdatedBy || "?"
            }, ${alert?.updatedAtMoment?.fromNow()}`}</span>
            <div className={styles.Controls}>
                <OffSiteReordersUndoButton alert={alert} />
            </div>
        </div>
    );
}
