import React, { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Section } from "../../containers/sections/Section";
import { AppContext } from "../../context/AppContext";
import AppContextValidator from "../../context/AppContextValidator";
import appMenuStyles from "./AppMenu.module.css";

const AppMenu = () => {
    const { appState } = useContext(AppContext);

    useEffect(() => {}, [appState.section, appState.user]);

    if (!AppContextValidator.isValidUser(appState.user)) {
        console.info("Invalid user!");
        return <div />;
    }

    if (!Section.isValidSection(appState.section)) {
        console.info("Invalid section!");
        return <div />;
    }

    const userAccessiblePages = appState.section.pages.filter(({ userAccess }) => {
        return userAccess(appState.user);
    });

    return (
        <aside key={"AppMenu-Location-list"} className={`no-print ${appMenuStyles.Main}`}>
            {userAccessiblePages.map(({ name, path, menuItem }) => {
                return (
                    <NavLink
                        key={`nav-link-${name}`}
                        to={path}
                        activeStyle={{ color: "#f50057", backgroundColor: "#EEE" }}
                        className={appMenuStyles.Item}
                    >
                        {menuItem(name)}
                    </NavLink>
                );
            })}
        </aside>
    );
};

export default AppMenu;
