import React, { useContext } from "react";
import { DeliveryOrdersContext } from "../context/DeliveryOrdersContext";
import deliveryOrdersStyles from "../DeliveryOrders.module.css";
import DeliveryOrdersUtils from "../DeliveryOrdersUtils";
import DeliveryOrdersEmailOrderEditor from "./DeliveryOrdersEmailOrderEditor";
import DeliveryOrdersEmailOrderEditorAdd from "./DeliveryOrdersEmailOrderEditorAdd";
import DeliveryOrdersEmailOrderLabel from "./DeliveryOrdersEmailOrderLabel";

export default function DeliveryOrdersEmailDialogEditor() {
    const { deliveryOrders } = useContext(DeliveryOrdersContext);

    const orders = DeliveryOrdersUtils.extractOrders(deliveryOrders.restaurant.emailVendor).sort(
        DeliveryOrdersUtils.sortOrders
    );

    return (
        <div className={deliveryOrdersStyles.DialogWrp}>
            <table border="1">
                <tr>
                    <th className={deliveryOrdersStyles.DialogHeaderCell}>
                        <span>Item</span>
                    </th>
                    <th className={deliveryOrdersStyles.DialogHeaderCell}>
                        <span>Delivery</span>
                    </th>
                </tr>
                {orders.map((order) => {
                    return (
                        <tr>
                            <td className={deliveryOrdersStyles.DialogCell}>
                                <DeliveryOrdersEmailOrderLabel order={order} />
                            </td>
                            <td className={deliveryOrdersStyles.DialogCell}>
                                <DeliveryOrdersEmailOrderEditor order={order} />
                            </td>
                        </tr>
                    );
                })}
            </table>
            <DeliveryOrdersEmailOrderEditorAdd />
        </div>
    );
}
