import { Restaurant } from "../../graphql/API";
import { Model } from "../../model/Model";
import { ModelConfig } from "../../model/ModelConfig";
import { ResourceColumnNumber } from "../../resources/columns/ResourceColumnNumber";
import { ResourceColumnSelect } from "../../resources/columns/select/ResourceColumnSelect";
import { ResourceColumnText } from "../../resources/columns/ResourceColumnText";
import { Resource } from "../../resources/Resource";

/* ---------- Restaurant Types ---------- */
const AppRestaurantTypes = {
    COMMISSARY: "COMMISSARY",
    RESTAURANT: "RESTAURANT",
    OFF_SITE: "OFF-SITE",
};

/* ---------- Restaurant ---------- */
class AppRestaurantModel extends Model {
    refDisplay(data: Restaurant) {
        return data.alias || "Unknown";
    }
}

const appRestaurantModel = new AppRestaurantModel(
    new ModelConfig(
        "Restaurant",
        10,
        200,
        "restaurants",
        "Restaurants",
        "Restaurant",
        "restaurantId",
        [
            new ResourceColumnText("id", "Id").setHideOnAdd().setKey().setSortDirection("ASC", 1),
            new ResourceColumnText("name", "Name").setKey().setVisible().setWidth("S"),
            new ResourceColumnNumber("ratio", "Ratio").setEditable().setVisible().setWidth("XS"),
            new ResourceColumnText("alias", "Alias").setEditable().setVisible().setWidth("XS"),
            new ResourceColumnSelect("type", "Type", [
                AppRestaurantTypes.COMMISSARY,
                AppRestaurantTypes.RESTAURANT,
                AppRestaurantTypes.OFF_SITE,
            ])
                .setVisible()
                .setWidth("S"),
            new ResourceColumnNumber("offSeason", "Off-Season")
                .setVisible()
                .setEditable()
                .setWidth("XS"),
            new ResourceColumnNumber("open", "Open").setVisible().setWidth("XS").setEditable(),
            new ResourceColumnText("usersGroup", "Users Group"),
            new ResourceColumnText("lastUpdatedBy", "Updated By").setOptional(),
            new ResourceColumnNumber("lastUpdatedAt", "Update Time").setOptional(),
        ]
    )
);

class AppRestaurant extends Resource {
    static getModel(): AppRestaurantModel {
        return appRestaurantModel;
    }

    getModel(): AppRestaurantModel {
        return appRestaurantModel;
    }

    constructor(public data: Restaurant) {
        super(data, appRestaurantModel);
    }
}

export { AppRestaurant, AppRestaurantTypes };
