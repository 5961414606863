import React, { useContext } from "react";
import { Button, InputAdornment, TextField } from "@material-ui/core";
import { DeliveryOrdersContext } from "../context/DeliveryOrdersContext";
import DeliveryOrdersUtils from "../DeliveryOrdersUtils";
import { MdClear } from "react-icons/md";
import deliveryOrdersStyles from "../DeliveryOrders.module.css";

export default function DeliveryOrdersEmailOrderEditor({ order }) {
    const { updateDeliveryOrder } = useContext(DeliveryOrdersContext);

    const orderQuantity = DeliveryOrdersUtils.getOrderQuantity(order);
    const orderUnit = DeliveryOrdersUtils.extractDeliveryUnitName(order);

    const styles = { backgroundColor: orderQuantity <= 0 ? "red" : null };

    const handleQuantityChange = (event) => {
        return updateDeliveryOrder(order, +event.target.value);
    };

    const handleQuantityDecrease = () => {
        return updateDeliveryOrder(order, Math.max(0, orderQuantity - 1));
    };

    const handleQuantityIncrease = () => {
        return updateDeliveryOrder(order, Math.max(1, orderQuantity + 1));
    };

    const handleQuantityClear = () => {
        return updateDeliveryOrder(order, 0);
    };

    return (
        <div style={styles} className={deliveryOrdersStyles.DialogOrderEditor}>
            <TextField
                label=""
                id="standard-start-adornment"
                InputProps={{
                    startAdornment: <InputAdornment position="start">{orderUnit}</InputAdornment>,
                }}
                value={orderQuantity}
                type={"number"}
                onChange={handleQuantityChange}
                className={deliveryOrdersStyles.DialogOrderEditorInput}
            />
            <Button
                onClick={handleQuantityDecrease}
                size={"small"}
                variant={"outlined"}
                disabled={orderQuantity <= 0}
                className={deliveryOrdersStyles.DialogOrderEditorButton}
            >
                - 1
            </Button>
            <Button
                onClick={handleQuantityIncrease}
                size={"small"}
                variant={"outlined"}
                className={deliveryOrdersStyles.DialogOrderEditorButton}
            >
                + 1
            </Button>
            <Button
                onClick={handleQuantityClear}
                size={"small"}
                variant={"outlined"}
                color={"secondary"}
                disabled={orderQuantity === 0}
                className={deliveryOrdersStyles.DialogOrderEditorButton}
            >
                <MdClear />
            </Button>
        </div>
    );
}
