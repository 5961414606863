import { makeStyles } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { useContext } from "react";
import { DataEditorContext } from "../../context/DataEditorContext";
import DataEditorUtils from "../../utils/DataEditorUtils";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            marginTop: theme.spacing(0),
        },
    },
}));

export default function DataEditorPagination() {
    const { dataEditorState, updateSelectedResourcePageIndex } = useContext(DataEditorContext);
    const classes = useStyles();

    const itemsCount = DataEditorUtils.getSelectedResourceItemsCount(dataEditorState);
    const pageSize = DataEditorUtils.getSelectedResourcePageSize(dataEditorState);
    const pageCount = DataEditorUtils.getPagesCount(itemsCount, pageSize);

    const onPageChange = (event: any, pageIndex: number) => {
        // TODO: do not keep page index at resource lvl...
        return updateSelectedResourcePageIndex(pageIndex - 1);
    };

    // too few pages
    if (pageCount < 2) {
        return null;
    }

    const defaultPage = DataEditorUtils.getPageIndex(dataEditorState.getSelectedResource()) || 1;

    return (
        <div>
            <div className={classes.root}>
                <Pagination
                    count={pageCount}
                    defaultPage={defaultPage}
                    shape="rounded"
                    variant="outlined"
                    siblingCount={0}
                    color="secondary"
                    size="small"
                    boundaryCount={1}
                    onChange={onPageChange}
                />
            </div>
        </div>
    );
}
