import React from "react";
import DeliveryOrdersUtils from "../../DeliveryOrdersUtils";

const DeliveryOrdersCellDelivery = ({ order }) => {
    const delivery = DeliveryOrdersUtils.extractDeliveryQty(order);
    const deliveryUnit = DeliveryOrdersUtils.extractDeliveryUnitName(order);

    return <span>{`${delivery === null ? "?" : delivery} ${deliveryUnit}`}</span>;
};

export default DeliveryOrdersCellDelivery;
