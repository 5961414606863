import React from "react";
import MenusItemRecipe from "./MenusItemRecipe";
import menuStyles from "../Menus.module.css";

const MenusItem = ({ item, index, displayConfigs: { hideMultiple } }) => {
    const { data, sales, recipe } = item;

    if (!(data && sales && recipe)) {
        console.info("Invalid Menu Item!");
        return <div />;
    }

    return (
        <div key={`FoodMenuItem-${index}`} className={menuStyles.menuContentItem}>
            <div className={`${menuStyles.menuItem} truncate`} key={`FoodMenuItemName${index}`}>
                {data.name}
            </div>
            <div className={menuStyles.menuItemSaleSingle} key={"FoodMenuItemSaleSingle"}>
                {sales.SINGLE ? sales.SINGLE.data?.quantity : ""}
            </div>
            {hideMultiple ? null : (
                <div className={menuStyles.menuItemSaleMultiple} key={"FoodMenuItemSaleMultiple"}>
                    {sales.MULTIPLE ? sales.MULTIPLE.data?.quantity : ""}
                </div>
            )}
            <MenusItemRecipe recipe={recipe} index={index} />
        </div>
    );
};

export default MenusItem;
