import React, { useContext, useEffect } from "react";
import onHandItemStyles from "../../../on-hand/components/item/OnHandItem.module.css";
import { OnHandContext } from "../../../on-hand/context/OnHandContext";

const OnHandOrderItemTitle = () => {
    const { onHandState } = useContext(OnHandContext);

    useEffect(() => {}, [onHandState.restaurant.group.item]);

    const item = onHandState.restaurant.group.item;
    return (
        <div className={onHandItemStyles.Title}>
            <span>
                <span>{item.onHandUnit?.data?.name}</span> of <strong>{item?.data?.name}</strong>
            </span>
        </div>
    );
};

export default OnHandOrderItemTitle;
