import { fetchResources, readSectionS3Files } from "../../../../resources/ResourcesService";

import AppUserGroups from "../../../../services/AppUserGroups";
import AppUtilsService from "../../../../services/AppUtilsService";
import { AppRestaurant } from "../../../admin/AdminResources";
import {
    AppOrder,
    AppOrderArea,
    AppOrderInventoryMeta,
    AppOrderUnits,
    AppOrderVendor,
} from "../../OrdersResources";
import DeliveryOrdersUtils from "../DeliveryOrdersUtils";
import { ALL_VENDOR } from "../DeliveryOrdersValues";
import { DeliveryOrdersEmail } from "../email/DeliveryOrdersEmail";

// TODO: move this to lambda
export default async function DeliveryOrdersResolver(userGroups, day) {
    const [appRestaurants] = await fetchResources([[AppRestaurant]]);
    let { restaurants, restaurantFilter } = AppUserGroups.getUserRestaurantWithFilter(
        appRestaurants,
        userGroups
    );
    const dayFilter = { day: { eq: day.data.time } };

    // GET DATA
    let [orders, orderAreas, orderUnits, orderVendors, orderInventoriesMeta] = await fetchResources(
        [
            [AppOrder],
            [AppOrderArea, restaurantFilter],
            [AppOrderUnits],
            [AppOrderVendor],
            [AppOrderInventoryMeta, dayFilter],
        ]
    );

    const orderInventoriesMetaStorageKeys = orderInventoriesMeta
        .map((oim) => oim.data?.storageKey)
        .filter((x) => x);
    let orderInventories = await readSectionS3Files(
        orderInventoriesMetaStorageKeys,
        AppOrderInventoryMeta
    );

    const orderUnitsById = orderUnits.reduce(AppUtilsService.groupDataByItem("id"), {});

    const orderVendorsById = orderVendors.reduce(AppUtilsService.groupDataByItem("id"), {});

    const orderInventoriesByOrderId = orderInventories.reduce(
        AppUtilsService.groupDataByItem("orderId"),
        {}
    );

    const ordersByAreaId = orders.reduce((acc, order) => {
        acc[order.data.orderAreaId] = acc[order.data.orderAreaId] || [];
        order.onHandUnit = orderUnitsById[order.data.orderOnHandUnitId];
        order.deliveryUnit = orderUnitsById[order.data.orderDeliveryUnitId];
        order.vendor = orderVendorsById[order.data.orderVendorId];
        order.inventory = orderInventoriesByOrderId[order.data.id];
        order.deliveryQuantity = DeliveryOrdersUtils.extractDeliveryQty(order);
        acc[order.data.orderAreaId].push(order);
        return acc;
    }, {});

    const orderAreasByRestId = orderAreas.reduce((acc, orderArea) => {
        acc[orderArea.data.restaurantId] = acc[orderArea.data.restaurantId] || [];
        orderArea.orders = ordersByAreaId[orderArea.data.id] || [];
        acc[orderArea.data.restaurantId].push(orderArea);
        return acc;
    }, {});

    const restaurantsById = Object.values(restaurants)
        .filter((restaurant) => orderAreasByRestId[restaurant.data.id])
        .reduce((acc, restaurant) => {
            const restaurantAreas = orderAreasByRestId[restaurant.data.id];
            const restaurantOrders = restaurantAreas.reduce((acc, area) => {
                acc = acc.concat(area.orders);
                return acc;
            }, []);
            const restaurantOrdersByVendorId = restaurantOrders.reduce(
                AppUtilsService.groupDataByList("orderVendorId"),
                {}
            );

            restaurant.vendors = Object.keys(restaurantOrdersByVendorId).map((orderVendorId) => {
                const vendor = Object.assign({}, orderVendorsById[orderVendorId]);
                vendor.email = new DeliveryOrdersEmail("orders@ocmdapp.com");
                vendor.email.subject = DeliveryOrdersEmail.getEmailSubject(
                    vendor,
                    restaurant.data.name,
                    day
                );
                vendor.notes = "";
                vendor.orders = restaurantOrdersByVendorId[orderVendorId].sort((a, b) =>
                    a?.data?.name.localeCompare(b?.data?.name)
                );
                // TODO: filter the orders that need delivery
                vendor.deliveryOrders = vendor.orders
                    .filter((order) => order.deliveryQuantity > 0)
                    .sort((a, b) => a?.data?.name.localeCompare(b?.data?.name));
                vendor.addedOrders = [];
                return vendor;
            });
            restaurant.allVendor = ALL_VENDOR;
            restaurant.displayVendor = restaurant.allVendor;
            restaurant.deliveryVendors = restaurant.vendors.filter(
                ({ deliveryOrders }) => deliveryOrders?.length > 0
            );
            restaurant.nonDeliveryVendors = restaurant.vendors.filter(
                ({ deliveryOrders }) => deliveryOrders?.length === 0
            );
            restaurant.deliveryVendor = restaurant.deliveryVendors[0];

            acc[restaurant.data.id] = restaurant;
            return acc;
        }, {});
    //
    return restaurantsById;
}
