import { ReactNode } from "react";
import { ContainerPathType } from "./ContainerPaths";
import { UserGroupType } from "./../services/AppUserGroupsData";

export class ContainerConfig {
    public path: ContainerPathType;

    constructor(
        public name: string,
        public key: string,
        public url: string,
        // TODO: the argument is actually appState.user.groups, but not type exists yet
        public userAccess: ({ groups }: { groups?: UserGroupType[] }) => boolean,
        public component: any,
        public menuItem: any,
        public tasksHeaderComponent: ReactNode = null
    ) {
        this.key = key || name;
        this.path = url;
    }
}
