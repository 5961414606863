import { FormikProps } from "formik";
import { DataEditorState } from "../../../containers/data-editor/types/DataEditorTypes";
import { ResourceColumn } from "../../ResourceColumn";
import {
    ResourceColumnSelectOption,
    ResourceColumnSelectRawOption,
} from "./ResourceColumnSelectTypes";
import ResourceColumnSelectUtils from "./ResourceColumnSelectUtils";

export class ResourceColumnSelect extends ResourceColumn {
    public options: ResourceColumnSelectOption[];
    public defaultValue: ResourceColumnSelectOption;

    constructor(
        public field: string,
        public name: string,
        rawOptions: ResourceColumnSelectRawOption[] = []
    ) {
        super(field, name, "select");

        this.options = ResourceColumnSelectUtils.transformRawOptions(rawOptions);
        this.defaultValue = this.options[0];
        this.validator = (data) => !!rawOptions?.includes(data);
    }

    prepRenderEdit(index: number, fmkProps: FormikProps<any>, dataEditorState: DataEditorState) {
        const { touched, errors, values, isSubmitting } = fmkProps;

        const resourceRefKey = ResourceColumnSelectUtils.getResourceRefKey(dataEditorState, this);

        const options = ResourceColumnSelectUtils.getAvailableOptions(
            dataEditorState,
            this,
            resourceRefKey
        );

        const selectedOption = ResourceColumnSelectUtils.getSelectedOption(this, options, values);

        const ResourceColumnSelectContent = () => {
            const { handleChange, handleBlur, isSubmitting } = fmkProps;
            const column = this;

            return (
                <select
                    disabled={isSubmitting}
                    name={column.field}
                    placeholder={column.placeholder}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={selectedOption?.selectValue || ""}
                    style={{ minWidth: "40px" }}
                >
                    {selectedOption ? null : (
                        <option
                            key={`select-option-${index}-missing`}
                            value={""}
                            label={"Select..."}
                        />
                    )}
                    {options.map((option: ResourceColumnSelectOption, index: number) => {
                        return (
                            <option
                                key={`select-option-${index}-${option.label}`}
                                value={option.selectValue}
                                label={option.label}
                            />
                        );
                    })}
                </select>
            );
        };

        return super.renderEdit(
            index,
            this.field,
            <ResourceColumnSelectContent />,
            errors,
            touched,
            isSubmitting
        );
    }
}
