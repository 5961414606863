import { ADMINS, DATA_EDITOR_USERS, MANAGERS, STORE_USERS } from "../../services/AppUserGroupsData";
import ContainerConfigBuilder from "../ContainerConfigBuilder";
import { ContainerPaths } from "../ContainerPaths";
import { ContainerConfigSection } from "./../ContainerConfigSection";
import DataEditorTShirts from "./data-editor/DataEditorTShirts";
import DataEditorTShirtsMenuItem from "./data-editor/DataEditorTShirtsMenuItem";
import OnHandTShirts from "./on-hand/OnHandTShirts";
import OnHandTShirtsMenuItem from "./on-hand/OnHandTShirtsMenuItem";
import SalesTShirts from "./sales/SalesTShirts";
import SalesTShirtsMenuItem from "./sales/SalesTShirtsMenuItem";
import TShirtsStockingWrp from "./stocking/context/TShirtsStockingWrp";
import TShirtsStockingMenuItem from "./stocking/TShirtsStockingMenuItem";

export const TShirtsContainerConfig: ContainerConfigSection = {
    OnHand: new ContainerConfigBuilder("On Hand")
        .setPath(ContainerPaths.ON_HAND)
        .setUserAccess([STORE_USERS, MANAGERS, ADMINS])
        .setComponent(OnHandTShirts)
        .setMenuItem(OnHandTShirtsMenuItem)
        .build(),
    Delivery: new ContainerConfigBuilder("Stocking")
        .setPath(ContainerPaths.STOCKING)
        .setUserAccess([STORE_USERS, MANAGERS, ADMINS])
        .setComponent(TShirtsStockingWrp)
        .setMenuItem(TShirtsStockingMenuItem)
        .build(),
    Sales: new ContainerConfigBuilder("Sales")
        .setPath(ContainerPaths.SALES)
        .setUserAccess([MANAGERS, ADMINS])
        .setComponent(SalesTShirts)
        .setMenuItem(SalesTShirtsMenuItem)
        .build(),
    DataEditor: new ContainerConfigBuilder("Editor")
        .setPath(ContainerPaths.EDITOR)
        .setUserAccess([DATA_EDITOR_USERS, ADMINS])
        .setComponent(DataEditorTShirts)
        .setMenuItem(DataEditorTShirtsMenuItem)
        .build(),
};
