import React, { useContext } from "react";

import { AppRestaurant } from "../../admin/AdminResources";
import DataEditor from "../../data-editor/DataEditor";
import { DataEditorProvider } from "../../data-editor/context/DataEditorContext";
import { DataEditorResource } from "../../data-editor/types/DataEditorTypes";
import { AppOffSiteArea, AppOffSiteItem, AppOffSiteUnit } from "../../off-site/OffSiteResources";
import {
    AppOrder,
    AppOrderArea,
    AppOrderInventory,
    AppOrderInventoryMeta,
    AppOrderUnits,
    AppOrderVendor,
} from "../OrdersResources";
import { AppContext } from "../../../context/AppContext";
import AppUserGroups from "../../../services/AppUserGroups";

const DataEditorOrders = (props: any) => {
    const { appState } = useContext(AppContext);
    const { history, location } = props;

    let resources: DataEditorResource[];

    const adminResources = [
        new DataEditorResource(AppOrder, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppOrderArea, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppOrderUnits, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppOrderVendor, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppOrderInventory, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppOrderInventoryMeta, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppOffSiteItem, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppOffSiteArea, { isVisibleInDataEditor: false }),
        new DataEditorResource(AppOffSiteUnit, { isVisibleInDataEditor: false }),
        new DataEditorResource(AppRestaurant, { isVisibleInDataEditor: false }),
    ];

    const nonAdminResources: DataEditorResource[] = [
        new DataEditorResource(AppOrder, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppOrderVendor, { isVisibleInDataEditor: true }),
        new DataEditorResource(AppOrderUnits, { isVisibleInDataEditor: false }),
        new DataEditorResource(AppOrderArea, { isVisibleInDataEditor: false }),
        new DataEditorResource(AppOrderUnits, { isVisibleInDataEditor: false }),
        new DataEditorResource(AppOffSiteItem, { isVisibleInDataEditor: false }),
        new DataEditorResource(AppOffSiteArea, { isVisibleInDataEditor: false }),
        new DataEditorResource(AppOffSiteUnit, { isVisibleInDataEditor: false }),
        new DataEditorResource(AppRestaurant, { isVisibleInDataEditor: false }),
    ];

    if (AppUserGroups.hasDataEditorAdminAccess(appState.user.groups)) {
        resources = adminResources;
    } else {
        resources = nonAdminResources;
    }

    return (
        <DataEditorProvider resources={resources} location={location} history={history}>
            <DataEditor location={location} />
        </DataEditorProvider>
    );
};

export default DataEditorOrders;
