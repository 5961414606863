import React from "react";
import deliveryTShirtsStyles from "../TShirtsStocking.module.css";
import { NEEDS_STOCKING } from "../t-shirt/TShirtsStockingStatus";

const TShirtsStockingStatusRequiredStock = ({ tShirts }) => {
    const requiredStocks = tShirts.reduce((acc, { requiredStock }) => {
        acc += requiredStock;
        return acc;
    }, 0);

    return (
        <div className={deliveryTShirtsStyles.Status}>
            {NEEDS_STOCKING.render(requiredStocks, true)}
        </div>
    );
};

export default TShirtsStockingStatusRequiredStock;
