import React from "react";
import { FaTasks } from "react-icons/fa";

const OnHandOrdersMenuItem = (name: string) => {
    return [
        <FaTasks key={`${name}-icon`} size={"1.5em"} />,
        <div key={`${name}-text`}>
            <span>{name}</span>
        </div>,
    ];
};

export default OnHandOrdersMenuItem;
